import { useObservable } from "azure-devops-ui/Core/Observable";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { lazy } from "../../../common/components/lazy/lazy";
import { Observer } from "../../../common/components/observer/observer";
import { Scenario } from "../../../common/components/scenario/scenario";
import { EventContext } from "../../../common/contexts/event";
import { useFetch } from "../../../common/hooks/usefetch";
import { EventDispatch } from "../../../common/utilities/dispatch";
import { IFetchCompleteEvent, IFetchPrepareEvent, IFetchTranslateEvent } from "../../../common/utilities/fetch";
import { format } from "../../../common/utilities/format";
import { redeemToken, requestBadgerToken, validatePassword, validatePasswordCOB } from "../../api/auth";
import { cancelResult, hasError } from "../../api/network";
import { apiOriginCOB, apiOriginODC, apiVersionCOB, apiVersionODC } from "../../api/util";
import { AuthorizationContext, PhotoAuthorizationContext } from "../../contexts/authorization";
import { SessionContext, getSignInUri } from "../../contexts/session";
import { useRejection } from "../../hooks/userejection";

const PasswordValidationDialog = lazy(() => import("../../dialogs/passwordvalidationdialog"));

const { LoadFailure } = window.Resources.Common;
const { SharingPasswordError } = window.Resources.PasswordDialog;

export interface ISecurityBoundaryProps {
  badgerToken?: string;
  id: string;
  migrated: boolean;
  url: URL;
}

interface IInnerSecurityBoundaryProps extends Omit<ISecurityBoundaryProps, "migrated"> {
  badgerValidated: boolean;
  setBadgerToken: (badgerToken: string, validated: boolean) => void;
  setDriveId: (driveId: string) => void;
}

export function SecurityBoundary(props: ISecurityBoundaryProps & { children: React.ReactNode }): React.ReactElement | null {
  const { migrated, ...innerProps } = props;

  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const [badgerToken, setBadgerToken] = React.useState(props.badgerToken);
  const [badgerValidated, setBadgerValidated] = React.useState(!!props.badgerToken);
  const [driveId, setDriveId] = React.useState(props.url.searchParams.get("drvId") ?? props.url.searchParams.get("cid") ?? sessionContext.driveId);
  const [_eventContext] = React.useState(() => new EventDispatch({ parentDispatch: eventContext }));

  const innerBoundary = migrated ? (
    <SecurityBoundarySPO
      {...innerProps}
      badgerToken={badgerToken}
      badgerValidated={badgerValidated}
      setBadgerToken={_setBadgerToken}
      setDriveId={setDriveId}
    />
  ) : (
    <SecurityBoundaryODC
      {...innerProps}
      badgerToken={badgerToken}
      badgerValidated={badgerValidated}
      setBadgerToken={_setBadgerToken}
      setDriveId={setDriveId}
    />
  );

  return (
    <EventContext.Provider value={_eventContext}>
      <SessionContext.Provider
        value={{
          ...sessionContext,
          apiOrigin: migrated ? apiOriginCOB : apiOriginODC,
          apiVersion: migrated ? apiVersionCOB : apiVersionODC,
          driveId,
          migrated
        }}
      >
        {innerBoundary}
      </SessionContext.Provider>
    </EventContext.Provider>
  );

  function _setBadgerToken(badgerToken: string, validated: boolean) {
    // Save the BadgerToken in the current history entry.
    unstable_batchedUpdates(() => {
      setBadgerToken(badgerToken);
      setBadgerValidated(validated);
    });
  }
}

function SecurityBoundarySPO(props: IInnerSecurityBoundaryProps & { children: React.ReactNode }): React.ReactElement | null {
  const { badgerToken, badgerValidated, children, url, setBadgerToken, setDriveId } = props;

  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const [badgerRequired, setBadgerRequired] = React.useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useObservable(false);

  // Create an error handler for reporting api errors to the application.
  const handleRejection = useRejection();

  const redeem = url.searchParams.get("redeem") || "";

  // Network calls used to redeem share token and request badger token
  const _requestBadgerToken = useFetch(requestBadgerToken, { name: "requestBadgerToken" });
  const _redeemToken = useFetch(redeemToken, { blocking: false, name: "redeemToken" });
  const _validatePassword = useFetch(validatePasswordCOB, { blocking: false, name: "validatePassword" });

  React.useLayoutEffect(() => {
    eventContext.addEventListener("fetchPrepare", prepareRequest);
    eventContext.addEventListener("fetchComplete", completeRequest);
    eventContext.addEventListener("fetchTranslate", translateResponse);

    return () => {
      eventContext.removeEventListener("fetchPrepare", prepareRequest);
      eventContext.removeEventListener("fetchComplete", completeRequest);
      eventContext.removeEventListener("fetchTranslate", translateResponse);
    };
  });

  // If we have a redeem value and need a badgerToken go create it.
  // Note, we may still need to supply a password for access.
  React.useMemo(() => {
    if (redeem && badgerRequired && !badgerToken) {
      _requestBadgerToken()
        .then((badgerToken) => {
          _redeemToken(redeem, badgerToken)
            .then((item) => {
              unstable_batchedUpdates(() => {
                setBadgerToken(badgerToken, true);

                // If the parentReference was returned on the item, update the
                // driveId with the proper value.
                if (item.parentReference) {
                  setDriveId(item.parentReference.driveId);
                }
              });
            })
            .catch((error) => {
              setBadgerToken(badgerToken, false);
              handleRejection(error);
            });
        })
        .catch(handleRejection);
    }
  }, [_requestBadgerToken, _redeemToken, badgerRequired, badgerToken, handleRejection, redeem, setBadgerToken, setDriveId]);

  const authorizationContext = React.useMemo(
    () => new PhotoAuthorizationContext(eventContext, sessionContext, redeem, badgerToken),
    [badgerToken, eventContext, redeem, sessionContext]
  );

  return (
    <AuthorizationContext.Provider value={authorizationContext}>
      <React.Fragment key={`${badgerToken}-${badgerValidated}`}>{children}</React.Fragment>
      <Observer values={{ showPasswordDialog }}>
        {({ showPasswordDialog }) =>
          showPasswordDialog ? (
            <React.Suspense fallback={null}>
              <Scenario scenarioName="validatePassword">
                <PasswordValidationDialog
                  onDismiss={() => setShowPasswordDialog(false)}
                  validatePassword={(password) => {
                    // Perform the validation now that we have the badger token ready.
                    return _validatePassword(password, redeem, badgerToken).then(() => {
                      unstable_batchedUpdates(() => {
                        setBadgerToken(badgerToken!, true);
                        setShowPasswordDialog(false);
                      });
                    });
                  }}
                />
              </Scenario>
            </React.Suspense>
          ) : null
        }
      </Observer>
    </AuthorizationContext.Provider>
  );

  function completeRequest(event: IFetchCompleteEvent) {
    if (event.result.status === "rejected") {
      if (hasError(event.result.reason, ["sharingPasswordIncorrect"])) {
        eventContext.dispatchEvent("showMessage", {
          messageType: "error",
          timeout: 3000,
          title: () => SharingPasswordError
        });
      }
    }
  }

  function prepareRequest(event: IFetchPrepareEvent) {
    // First prepare the origin and any potential apiVersion segments
    event.url = format(event.url, sessionContext.apiOrigin);
    event.url = format(event.url, sessionContext.apiVersion);

    // Lookup the configuration for the target domain.
    const url = new URL(event.url);
    const configuration = sessionContext.domainSettings[url.hostname];

    // If this domain supports the badger process either with authkey's or direct
    // badger tokens we will add any available to the requests.
    if (configuration.badger && badgerToken) {
      event.init.headers = Object.assign({ Authorization: `Badger ${badgerToken}` }, event.init.headers);
    }
  }

  function translateResponse(event: IFetchTranslateEvent) {
    event.translate((result) => {
      if (result.status === "rejected") {
        // IMPORTANT, dont allow userContentMigrated errors to bubble up from
        // the sharee, we don't want the currently logged in user to be marked
        // as migrated due to a migrated share link.
        if (hasError(result.reason, ["userContentMigrated"])) {
          result = cancelResult({ reason: { error: { code: "shareeContentMigrated", message: LoadFailure } }, status: "rejected" });
        }

        // Handle the sharing password request and cancel it.
        else if (hasError(result.reason, ["sharingPasswordRequired"])) {
          setShowPasswordDialog(true);
          result = cancelResult(result);
        }

        // Handle failures due to permissions and start the badger redemption process.
        // If the album is not found, we may need to start the badger process to get the full album driveId.
        else if (hasError(result.reason, ["accessDenied", "albumNotFound", "itemNotFound", "unauthenticated"])) {
          if (!badgerRequired) {
            setBadgerRequired(true);
          }

          // While we are validing we still want to cancel unless we get access
          // denied during the redeem process. This means the item is shared with
          // a specific person and that person is not logged in.
          if (!badgerValidated && result.reason.response.url.indexOf("/shares/u!") === -1) {
            result = cancelResult(result);
          } else if (!sessionContext.authenticated()) {
            result = cancelResult(result);
            window.location.href = getSignInUri(sessionContext, "sb");
          }
        }
      }

      return Promise.resolve(result);
    });
  }
}

function SecurityBoundaryODC(props: IInnerSecurityBoundaryProps & { children: React.ReactNode }): React.ReactElement | null {
  const { badgerToken, children, url, id, setBadgerToken } = props;

  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const [badgerPromise, setBadgerPromise] = React.useState<Promise<string> | Promise<unknown>>();
  const [showPasswordDialog, setShowPasswordDialog] = useObservable(false);
  const [validated, setValidated] = React.useState(0);

  // Create an error handler for reporting api errors to the application.
  const handleRejection = useRejection();

  const authkey = url.searchParams.get("authkey") || undefined;

  // Network calls used to redeem share token and request badger token
  const _requestBadgerToken = useFetch(requestBadgerToken, { name: "requestBadgerToken" });
  const _validatePassword = useFetch(validatePassword, { blocking: false, name: "validatePassword" });

  React.useEffect(() => {
    eventContext.addEventListener("fetchPrepare", prepareRequest);
    eventContext.addEventListener("fetchComplete", completeRequest);
    eventContext.addEventListener("fetchTranslate", translateResponse);

    return () => {
      eventContext.removeEventListener("fetchPrepare", prepareRequest);
      eventContext.removeEventListener("fetchComplete", completeRequest);
      eventContext.removeEventListener("fetchTranslate", translateResponse);
    };
  });

  const authorizationContext = React.useMemo(() => {
    if (sessionContext.authenticated()) {
      return new PhotoAuthorizationContext(eventContext, sessionContext, badgerToken);
    } else {
      return new PhotoAuthorizationContext(eventContext, sessionContext, authkey, badgerToken);
    }
  }, [authkey, badgerToken, eventContext, sessionContext]);

  return (
    <AuthorizationContext.Provider value={authorizationContext}>
      <React.Fragment key={validated}>{children}</React.Fragment>
      <Observer values={{ showPasswordDialog }}>
        {({ showPasswordDialog }) =>
          showPasswordDialog ? (
            <React.Suspense fallback={null}>
              <Scenario scenarioName="validatePassword">
                <PasswordValidationDialog
                  onDismiss={() => setShowPasswordDialog(false)}
                  validatePassword={(password) => {
                    if (badgerPromise) {
                      return badgerPromise.then((token) => {
                        if (typeof token === "string") {
                          // Perform the validation now that we have the badger token ready.
                          return _validatePassword(id, password, authkey || "", sessionContext.authenticated(), token).then(() => {
                            unstable_batchedUpdates(() => {
                              setBadgerToken(token, true);
                              setValidated(validated + 1);
                              setShowPasswordDialog(false);
                            });
                          });
                        }
                      });
                    } else {
                      return _validatePassword(id, password, authkey || "", sessionContext.authenticated()).then(() => {
                        unstable_batchedUpdates(() => {
                          setValidated(validated + 1);
                          setShowPasswordDialog(false);
                        });
                      });
                    }
                  }}
                />
              </Scenario>
            </React.Suspense>
          ) : null
        }
      </Observer>
    </AuthorizationContext.Provider>
  );

  function completeRequest(event: IFetchCompleteEvent) {
    if (event.result.status === "rejected") {
      if (hasError(event.result.reason, ["sharingPasswordIncorrect"])) {
        eventContext.dispatchEvent("showMessage", {
          messageType: "error",
          timeout: 3000,
          title: () => SharingPasswordError
        });
      }
    }
  }

  function prepareRequest(event: IFetchPrepareEvent) {
    // First prepare the origin and any potential apiVersion segments
    event.url = format(event.url, sessionContext.apiOrigin);
    event.url = format(event.url, sessionContext.apiVersion);

    // Lookup the configuration for the target domain.
    const url = new URL(event.url);
    const configuration = sessionContext.domainSettings[url.hostname];

    // If this domain supports the badger process either with authkey's or direct
    // badger tokens we will add any available to the requests.
    if (configuration.badger) {
      if (badgerToken) {
        event.init.headers = Object.assign({ Authorization: `Badger ${badgerToken}` }, event.init.headers);
      } else if (authkey && !url.searchParams.get("authkey")) {
        url.searchParams.set("authkey", authkey);
        event.url = url.toString();
      }
    }
  }

  function translateResponse(event: IFetchTranslateEvent) {
    event.translate((result) => {
      if (result.status === "rejected") {
        // IMPORTANT, dont allow userContentMigrated errors to bubble up from
        // the sharee, we don't want the currently logged in user to be marked
        // as migrated due to a migrated share link.
        if (hasError(result.reason, ["userContentMigrated"])) {
          result = cancelResult({ reason: { error: { code: "shareeContentMigrated", message: LoadFailure } }, status: "rejected" });
        }

        // If the shared link requires a password we will mark the event canceled
        // to prevent it from being displayed
        else if (hasError(result.reason, ["sharingPasswordRequired"])) {
          if (sessionContext.authenticated()) {
            setShowPasswordDialog(true);
          } else {
            unstable_batchedUpdates(() => {
              setBadgerPromise(_requestBadgerToken().catch(handleRejection));
              setShowPasswordDialog(true);
            });
          }

          result = cancelResult(result);
        } else if (!sessionContext.authenticated() && hasError(result.reason, ["unauthenticated"])) {
          result = cancelResult(result);
          window.location.href = getSignInUri(sessionContext, "sb");
        }
      }

      return Promise.resolve(result);
    });
  }
}
