import React from "react";
import { EventDispatch } from "../utilities/dispatch";
import { useTimeout } from "./usetimeout";

/**
 * Focus will go to the body whenever the element that has focus is removed from
 * the document, or the user clicks on a non-focusable element and the click is not
 * default prevented. This causes all types of focus management issues. This can
 * be detected by tracking a focusout event that is not followed up by a focusin.
 */
const focusLostDispatch = new EventDispatch();
let blurInProgress = false;

document.body.addEventListener("focusin", (event) => {
  // Note that the a blur is no longer in progress.
  blurInProgress = false;
});

document.body.addEventListener("focusout", (event) => {
  // Start tracking the next blur/focus series.
  blurInProgress = true;

  // Wait for the focusin event to fire to determine whether or not this focusout
  // will result in a target element receiving focus.
  window.setTimeout(() => {
    // istanbul ignore next - not possible to force this condition with the mock document.
    if (blurInProgress) {
      blurInProgress = false;
      if (document.activeElement === document.body) {
        focusLostDispatch.dispatchEvent("focusout", event);
      }
    }
  }, 0);
});

const focusableElementTypes = ["A", "BUTTON", "IFRAME", "INPUT", "SELECT", "TEXTAREA"];

/**
 * Prevents mouse actions from setting focus to the body when the mouse clicks
 * on an element with the css class name 'prevent-mouse-action'. This helps
 * address focus management in a comlex application.
 *
 * This works by allowing mouse operations on standard focusable elements but using
 * preventDefault on others. This doesn't interfere with users actions further
 * down the DOM tree since these events are processed before the body receieves the
 * event.
 *
 * The user can add prevent-mouse-action to any element. This will prevent default
 * for the mousedown action within this subtree (minus the default rules). The user
 * can add allow-mouse-action to any element. This will explicitly stop this code
 * from preventing mouse actions further up the tree.
 *
 * A common pattern is to add prevent-mouse-action to the root element, then add
 * allow-mouse-action to areas the use wants mousedown events to bubble out to the
 * document. A common need is for user-selection of text in the document. Having
 * prevent-mouse-action at the root acts like 'css: user-select: none' for the
 * entire page.
 */
document.body.addEventListener("mousedown", (event: MouseEvent) => {
  let target: HTMLElement | null = event.target as HTMLElement;

  while (target) {
    // If we encounter a prevent-mouse-action css class we will prevent default.
    if (target.classList.contains("prevent-mouse-action")) {
      event.preventDefault();
      break;
    }

    // Ignore all focusable elements.
    // Ignore elements that have an explicit tabIndex property.
    // Allow mousedown on elements with the css class allow-mouse-action.
    if (focusableElementTypes.indexOf(target.nodeName) >= 0 || target.getAttribute("tabIndex") || target.classList.contains("allow-mouse-action")) {
      return;
    }

    target = target.parentElement;
  }
});

/**
 * IFocusTrackerOptions can be used to control how the focus is managed in
 * different situations.
 */
export interface IFocusTrackerOptions {
  /**
   * Should the focus state be changed when the document loses focus.
   *
   * @default false
   */
  blurOnDocumentFocus?: boolean;

  /**
   * An optional delegate that allows the caller to react to the change in
   * focus during the change instead of waiting for the next render pass.
   */
  onFocusChange?: (hasFocus: boolean) => void;

  /**
   * If focus is transitioned to the body the caller can react to this by supplying
   * the onFocusLost delegate.
   */
  onFocusLost?: (event: FocusEvent) => void;

  /**
   * When focus is lost from the focus tracker reset the focus to the initially
   * focused element. This only applies when focus is lost to the body, not to
   * another valid focus element.
   *
   * @default false
   */
  reset?: boolean;
}

/**
 * The IFocusStatus represents the current state of focus as well as the
 * methods that should be attached to one more root items that are being tracked.
 */
export interface IFocusStatus {
  /**
   * hasFocus represents whether or not focus is currently within the
   * root elements or any of its children, including React portals.
   */
  hasFocus: boolean;

  /**
   * onBlur should be attached to one or more root element. These shouldn't
   * be siblings of each other.
   */
  onBlur: (event: React.FocusEvent) => void;

  /**
   * onFocus should be attached to one or more root element. These shouldn't
   * be siblings of each other.
   */
  onFocus: (event: React.FocusEvent) => void;
}

/**
 * useFocusTracker is useful for tracking whether or not focus is currently
 * within a given element.
 *
 * @returns The current status of the focus along with focus delegates.
 */
export function useFocusTracker(options?: IFocusTrackerOptions): IFocusStatus {
  const { blurOnDocumentFocus = false, onFocusChange, onFocusLost, reset = false } = options || {};

  const [initialFocus] = React.useState(document.activeElement);
  const blurInProgress = React.useRef(false);

  const [hasFocus, setHasFocus] = React.useState(false);
  const { setTimeout } = useTimeout();

  // Track focus lost events for the caller.
  React.useEffect(() => {
    if (onFocusLost) {
      if (hasFocus) {
        focusLostDispatch.addEventListener("focusout", onFocusLost);
        return () => focusLostDispatch.removeEventListener("focusout", onFocusLost);
      }
    }
  }, [hasFocus, onFocusLost]);

  return {
    hasFocus,
    onBlur: () => {
      // Note that we are in the process of changing focus to another element.
      // This MAY be within the element we are tracking so we dont want to fire
      // a series of blur/focus events.
      blurInProgress.current = true;

      // Wait for the target focus event to occur and evaluate at that point.
      setTimeout(() => {
        // istanbul ignore else - Cant move focus out of the document in testing.
        if (blurOnDocumentFocus || document.hasFocus()) {
          // If the blur was still in progress after allowing pending events to process,
          // focus isn't within our tracking element.
          if (blurInProgress.current) {
            blurInProgress.current = false;
            setHasFocus(false);

            // If the caller wants to be notified of the focus state change call them.
            onFocusChange && onFocusChange(false);

            // If the caller wants the focus reset to the element that had focus
            // when the tracker was created we will set to this element.
            if (reset && initialFocus) {
              (initialFocus as HTMLElement).focus();
            }
          }
        }
      }, 0);
    },
    onFocus: () => {
      // Clear out blurInProgress since another element inside our tracking element
      // just recieved focus.
      blurInProgress.current = false;

      if (!hasFocus) {
        setHasFocus(true);

        // If the caller wants to be notified of the focus state change call them.
        onFocusChange && onFocusChange(true);
      }
    }
  };
}
