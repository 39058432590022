import React from "react";
import { ILayout } from "../types/layout";

export function useCenterItem<T>(layout: React.RefObject<ILayout<T> | undefined>) {
  const targetItem = React.useRef<T>();

  const [count, setCount] = React.useState(0);

  React.useLayoutEffect(() => {
    const _targetItem = targetItem.current;
    const _layout = layout.current;

    if (_targetItem && _layout) {
      _layout.scrollTo(_targetItem, "center");
      targetItem.current = undefined;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return { centerOnItem };

  function centerOnItem(centerItem?: T) {
    const _layout = layout.current;

    if (_layout && !centerItem) {
      const centerTile = _layout.getCenterTile();
      if (centerTile) {
        centerItem = centerTile.item;
      }
    }

    if (centerItem) {
      targetItem.current = centerItem;
      setCount((count) => count + 1);
    }
  }
}
