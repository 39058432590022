import { FocusGroupContext } from "azure-devops-ui/FocusGroup";
import { FocusZoneContext } from "azure-devops-ui/FocusZone";
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { css, getSafeId } from "azure-devops-ui/Util";
import { getTabIndex } from "azure-devops-ui/Utilities/Focus";
import React from "react";
import { NavigationContext } from "../../../common/contexts/navigation";
import { IPhotoContent, IPhotoContentOptions } from "../../hooks/usephotocontent";
import { IPhotoDetails } from "../../types/photo";
import { Photo } from "../photo/photo";
import { TileOverlay } from "../tileoverlay/tileoverlay";

import "./carousel.css";

export interface ICarouselCardProps<T> {
  details: T;
  height: number;
  /**
   * unique identifier of the carousel item,
   * necessary for correct keyboard navigation
   */
  id: string;
  tabIndex?: number;

  /**
   * a unique name used to identify the card
   */
  type?: string;

  width: number;
}

export interface IStandardCarouselCard {
  className?: string;
  href?: string;
  subtitle?: React.ReactNode;
  title: string;
}

/**
 * The standard carousel card shows a basic tile with the backdrop being the
 * children of the component. The overlay contains the title and subtitle.
 * The card links to the supplied href.
 */
export function StandardCarouselCard(props: ICarouselCardProps<IStandardCarouselCard> & { children?: React.ReactNode }): React.ReactElement {
  const { children, details, height, id, type, width } = props;
  const { className, href, subtitle, title } = details;

  const focusgroupContext = React.useContext(FocusGroupContext);
  const focuszoneContext = React.useContext(FocusZoneContext);
  const navigationContext = React.useContext(NavigationContext);

  return (
    <a
      aria-label={title}
      className={css("bolt-carousel-item", className, "tile-overlay-container relative flex-row flex-align-start flex-justify-center rounded-12")}
      data-focuszone={focuszoneContext.focuszoneId}
      href={href}
      id={getSafeId(id)}
      key={href}
      onClick={(event) => {
        navigationContext.navigate(event, "navigateCard", {
          telemetryProperties: { type }
        });
      }}
      onFocus={() => {
        focusgroupContext.onFocus(id);
      }}
      style={{ height, width }}
      tabIndex={getTabIndex({ id }, focusgroupContext)}
    >
      {children}
      <TileOverlay className="flex-justify-end">
        <div aria-hidden={true} className="flex-column padding-12 overflow-hidden pointer-events-auto">
          <Tooltip overflowOnly={true}>
            <span className="bolt-carousel-item-title font-weight-semibold text-ellipsis">{title}</span>
          </Tooltip>
          <Tooltip overflowOnly={true}>
            <span className="bolt-carousel-item-subtitle body-s text-ellipsis">{subtitle}</span>
          </Tooltip>
        </div>
      </TileOverlay>
    </a>
  );
}

export interface IImageCarouselCard extends IStandardCarouselCard {
  url: string;
}

/**
 * StandardCarouselCard generates a simple image card with the given image url.
 * It is not intended for use with a OD photo, use the PhotoCarouselCard in this
 * case.
 */
export function ImageCarouselCard(props: ICarouselCardProps<IImageCarouselCard>): React.ReactElement {
  const { details, ...coreProps } = props;
  const { url, ...coreDetails } = details;

  return (
    <StandardCarouselCard details={coreDetails} {...coreProps}>
      <img alt="" className="bolt-carousel-image" src={details.url} />
    </StandardCarouselCard>
  );
}

export interface IPhotoCarouselCard extends IStandardCarouselCard {
  /**
   * The getPhotoContent function is used to retrieve the URL of the photo.
   * The URL and status are returned as an observable value, allowing the
   * function to resolve these either immediately, or asynchronously.
   */
  getPhotoContent: (photoId: string, options?: IPhotoContentOptions) => IPhotoContent;

  photo: IPhotoDetails;
}

/**
 * PhotoCarouselCard is used to render a OD photo, this does NOT include any
 * videos.
 */
export function PhotoCarouselCard(props: ICarouselCardProps<IPhotoCarouselCard>): React.ReactElement {
  const { details, height, width, ...coreProps } = props;
  const { getPhotoContent, photo, ...coreDetails } = details;

  return (
    <StandardCarouselCard details={coreDetails} height={height} width={width} {...coreProps}>
      <Photo
        aria-hidden={true}
        className={css("bolt-carousel-image", photo.dimensions.height > photo.dimensions.width * 1.25 ? "portrait-mode" : "landscape-mode")}
        dimensions={{ height, width }}
        downloadScale={{ height: Math.min(height * 2, photo.dimensions.height), width: Math.min(width * 2, photo.dimensions.width) }}
        getPhotoContent={getPhotoContent}
        fixed={true}
        photo={photo}
        transition={false}
      />
    </StandardCarouselCard>
  );
}

export interface IPlaceholderCardProps {
  height: number;
  width: number;
}

/**
 * PlaceholderCard can be used in a Carousel while cards are loading.
 */
export function PlaceholderCard(props: IPlaceholderCardProps): React.ReactElement {
  const { height, width } = props;

  return <div aria-hidden className="placeholder rounded-12" data-automationid="placeholder-card" style={{ height, width }} />;
}
