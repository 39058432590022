import React from "react";
import { EventContext } from "../../common/contexts/event";
import { useTrailingDebounce } from "../../common/hooks/usetrailingdebounce";
import { IGetPhotosOptions } from "../api/photo";
import { IItemPage } from "../types/item";
import { IPhoto } from "../types/photo";

/**
 * Hook that manages state for a component that uses Scrubber
 * @param delay Debounce delay for using the date from scrubber drag interactions
 */
export function useScrubberDrag(delay: number): {
  /**
   * Runs the provided getPhotos delegate with trailing debounce, providing it the most recent targetDate.
   * When the target date is committed (from a click, not from the middle of a drag), the delegate is run without delay.
   * @param getPhotosDelegate function that fetches photos
   * @returns photos from the most recent scrubber date
   */
  handleScrubberDate: (
    getPhotosDelegate: (driveId: string, options?: IGetPhotosOptions) => Promise<IItemPage<IPhoto>>,
    driveId: string,
    options?: Omit<IGetPhotosOptions, "initialPageDateTaken">
  ) => Promise<IItemPage<IPhoto>>;

  /**
   * Sets the target date state
   * @param targetDate the new target date
   * @param committed whether the new date is committed or is ephemeral (e.g. from an ongoing drag)
   */
  setTargetDate: (targetDate: Date | undefined, committed: boolean) => void;

  /**
   * The most recent target date, with the timezone offset accounted for.
   */
  targetDate: Date | undefined;
} {
  const eventContext = React.useContext(EventContext);

  const targetDateCommittedRef = React.useRef(true);

  const [targetDate, setTargetDate] = React.useState<Date | undefined>(undefined);

  const { debounce } = useTrailingDebounce<IItemPage<IPhoto>>();

  return {
    targetDate,
    setTargetDate: React.useCallback(updateState, []),
    handleScrubberDate: React.useCallback(getFromTargetDate, [debounce, delay, eventContext, targetDate])
  };

  function getFromTargetDate(
    getPhotosDelegate: (driveId: string, options?: IGetPhotosOptions) => Promise<IItemPage<IPhoto>>,
    driveId: string,
    options?: Omit<IGetPhotosOptions, "initialPageDateTaken">
  ): Promise<IItemPage<IPhoto>> {
    return debounce(
      getPhotosDelegate,
      targetDateCommittedRef.current ? 0 : delay,
      {
        onDiscard: () => {
          eventContext.dispatchEvent("telemetryAvailable", { action: "operationComplete", name: "scrubberUpdateDiscarded" });
        }
      },
      driveId,
      options ? { ...options, initialPageDateTaken: targetDate } : { initialPageDateTaken: targetDate }
    );
  }

  function updateState(targetDate: Date | undefined, committed: boolean): void {
    targetDateCommittedRef.current = committed;
    setTargetDate(targetDate ? new Date(targetDate.getTime() - targetDate.getTimezoneOffset() * 60 * 1000 + 1000) : undefined);
  }
}
