import React from "react";
import { ISettingsContext } from "../../common/contexts/settings";
import { IEventDispatch } from "../../common/utilities/platformdispatch";
import { IComplianceChecks, IFeedback, IFeedbackExtraData } from "../types/feedback";
import { ISessionContext } from "./session";

export const limitedFeedbackAppearancePages = new Set(["People", "Places", "Search", "Things"]);

export interface IFeedbackCustomization {
  /**
   * Updates to the content of the feedback dialog.
   */
  content?: React.ReactNode;

  /**
   * If the caller needs to add additional data in the feedback, they can return
   * a method used to add the data before the feedback is sent to the the
   * telemetry system.
   *
   * @param feedback The current feedback supplied by the dialog.
   */
  provideFeedback?: (feedback: IUserFeedback) => void;

  /**
   * Updates to the title of the feedback dialog.
   */
  title?: React.ReactNode;
}

export interface IFeedbackSettings {
  /**
   * Records the last provided Datetime for feedback submission
   */
  lastProvided?: number;

  /**
   * Determines if the feedback component should be shown
   * @default true
   */
  show?: boolean;
}

export type FeedbackCustomizationFunction = (location: FeedbackLocation, feedbackType: FeedbackType) => IFeedbackCustomization | null;
export type FeedbackLocation = "dialog" | "toggle";
export type FeedbackType = "Dislike" | "Like";

// Information the user provides that is included in the feedback sent to the service.
export interface IUserFeedback {
  /**
   * Textual feedback the user may have given.
   */
  comment?: string;

  /**
   * Email addres of the user, only when the user has opt'd into feedback.
   */
  email?: string;

  /**
   * Any extra data that should be supplied to the feedback.
   */
  extraData: IFeedbackExtraData[];

  /**
   * Context for the source of the feedback, where did the user give the feedback?
   */
  feedbackSource: string;

  /**
   * What was the sentiment of the users feedback.
   */
  feedbackType: FeedbackType;

  /**
   * Was a screenshot included with the feedback.
   */
  screenshot?: Blob;
}

export interface IFeedbackContext {
  /**
   * Add a custom presentation to one of the available feedback sources.
   * The customization function will be given the type of feedback that is being
   * presented and the customization can chose to apply changes by returning
   * a ReactNode.
   *
   * @param customization A function used to customize the feedback.
   */
  addCustomization: (customization: FeedbackCustomizationFunction) => void;

  /**
   * Code wishing to present feedback which allows customization can use this
   * method to request customization from the application.
   *
   * @param location A string that represents the type of feedback being presented.
   * @param feedbackType The type of feedback the user indicated they were providing.
   * @returns The custom feedback presentation.
   */
  customizeFeedback: (location: FeedbackLocation, feedbackType: FeedbackType | undefined) => IFeedbackCustomization | null;

  /**
   * provideFeedback is a function that should be used to report the feedback to
   * the feedback source. This will send the feedback with the appropriate
   * details to the tracking service.
   *
   * @param feedback The feedback details that should be reported.
   * @returns A promise which completes when the feedback reporting has completed.
   */
  provideFeedback: (feedback: IUserFeedback) => Promise<void>;

  /**
   * The application MUST call removeCustomization if it has called addCustomization
   * to ensure the customization is no longer applied once it is not needed.
   *
   * @param customization The same function supplied to the addCustomization
   */
  removeCustomization: (customization: FeedbackCustomizationFunction) => void;
}

export const FeedbackContext = React.createContext<IFeedbackContext>({
  addCustomization: () => {},
  customizeFeedback: () => null,
  provideFeedback: () => Promise.resolve(),
  removeCustomization: () => {}
});

// We create a default context that is bound to our SendFeedback endpoint.
export class SendFeedbackContext implements IFeedbackContext {
  private customizations: FeedbackCustomizationFunction[] = [];
  private eventContext: IEventDispatch;
  private sendFeedback: (feedback: IFeedback) => Promise<void>;
  private sessionContext: ISessionContext;
  private settingsContext: ISettingsContext;

  constructor(
    sendFeedback: (feedback: IFeedback) => Promise<void>,
    sessionContext: ISessionContext,
    eventContext: IEventDispatch,
    settingsContext: ISettingsContext
  ) {
    this.eventContext = eventContext;
    this.sendFeedback = sendFeedback;
    this.sessionContext = sessionContext;
    this.settingsContext = settingsContext;
  }

  public addCustomization(customization: FeedbackCustomizationFunction): void {
    this.customizations.push(customization);

    // Notify the application that feedback has been customized.
    this.eventContext.dispatchEvent("feedbackCustomized");
  }

  public customizeFeedback(location: FeedbackLocation, feedbackType: FeedbackType): IFeedbackCustomization | null {
    return this.customizations.length ? this.customizations[this.customizations.length - 1](location, feedbackType) : null;
  }

  public provideFeedback(feedback: IUserFeedback): Promise<void> {
    const { comment, email, feedbackSource, feedbackType, screenshot } = feedback;
    const graphProfile = this.sessionContext.graphProfile.value;

    // Before we process any of the feedback we need to verify the user meets our
    // requirements for sending feedback.
    const ageGroup = graphProfile?.ageGroup;
    const feedbackSupported = ageGroup === "0" || ageGroup === "3" || ageGroup === "4";

    // Just ignore feedback from sources where we shouldn't be collecting.
    if (!feedbackSupported) {
      this.eventContext.dispatchEvent("telemetryAvailable", {
        action: "exception",
        name: "sendFeedback",
        error: "unsupported",
        value: feedbackSource
      });

      return Promise.resolve();
    }

    const currentPage = this.sessionContext.getPage().current;

    if (limitedFeedbackAppearancePages.has(currentPage)) {
      this.settingsContext.setSetting<IFeedbackSettings>("feedback", { lastProvided: Date.now() });
    }

    // Build up the compliance checks performed for this feedback.
    const complianceChecks: IComplianceChecks = {
      authenticationType: "MSA",
      ageGroup: ageGroup === "0" ? "Undefined" : ageGroup === "3" ? "Adult" : "NotAdult",
      connectedExperiences: null,
      policyAllowContact: null,
      policyAllowContent: null,
      policyAllowFeedback: null,
      policyAllowScreenshot: null,
      policyAllowSurvey: null
    };

    // Add the extra proeprties used to describe the feedback
    const extraData: IFeedbackExtraData[] = [
      { id: "360036980771", value: feedbackSource },
      { id: "360039035053", value: "OneDrivePhotos" },

      // If the user didnt want their email shared we will OptOut.
      { id: "360039035052", value: !email },

      // Add the compliance checks values
      { id: "360039035056", value: JSON.stringify(complianceChecks) }
    ];

    // Attach a sessionId if we have one.
    if (this.sessionContext.session) {
      extraData.push({ id: "81090127", value: this.sessionContext.session });
    }

    // If the caller supplied any specific feedback items we will add them.
    extraData.push(...feedback.extraData);

    // SendFeedback to the OneDrive SendFeedback API endpoint
    return this.sendFeedback({
      market: this.sessionContext.locale,
      metadata: {
        app: "OneDrive",
        appVersion: this.sessionContext.clientVersion,
        backendVersion: "ODC",
        comment,
        device: {
          deviceModel: navigator.vendor,
          platform: "Web",
          platformVersion: navigator.userAgent
        },
        extraData,
        feedbackType,
        isDogfoodBuild: this.sessionContext.dogfoodUser,
        user: {
          aadPuid: graphProfile?.id,
          authEnvironment: "Prod",
          authType: "MSA",
          email: email || graphProfile?.userPrincipalName,
          firstName: graphProfile?.givenName || ".",
          isBusiness: false,
          isDogfood: this.sessionContext.dogfoodUser,
          lastName: graphProfile?.surname || ".",
          tenantId: "9188040d-6c67-4c5b-b112-36a304b66dad" // MSA
        }
      },
      screenshot
    });
  }

  public removeCustomization(customization: FeedbackCustomizationFunction): void {
    const index = this.customizations.indexOf(customization);
    if (index >= 0) {
      this.customizations.splice(index, 1);

      // Notify the application that feedback has been customized.
      this.eventContext.dispatchEvent("feedbackCustomized");
    }
  }
}
