import { IReadonlyObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";

export interface ITheme {
  backgroundColor: string;
  dark?: boolean;
  foregroundColor: string;
  themeId: string;
  themeName: string;
  userVisible: boolean;
}

export interface IThemeContext {
  currentTheme: IReadonlyObservableValue<ITheme>;
  setCurrentTheme: (themeName: string) => void;
  themes: { [themeName: string]: ITheme };
}

export const ThemeContext = React.createContext<IThemeContext>({
  currentTheme: new ObservableValue({ backgroundColor: "", foregroundColor: "", themeId: "", themeName: "", userVisible: false }),
  setCurrentTheme: () => {},
  themes: {}
});
