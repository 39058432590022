import React from "react";
import { passiveOptions } from "./uselistener";

interface IEventHandlers {
  onTouchMove: (event: TouchEvent) => void;
  onTouchEnd: (event: TouchEvent) => void;
}

export interface ICaptureState {
  onTouchStart: (event: React.TouchEvent) => void;
}

export function useTouchCapture(onTouchMove: (event: TouchEvent) => void, onTouchEnd?: (event: TouchEvent) => void): ICaptureState {
  const eventHandlers = React.useRef<IEventHandlers | undefined>(undefined);

  const onTouchStart = React.useCallback((): void => {
    if (!eventHandlers.current) {
      document.addEventListener("touchmove", _onTouchMove, passiveOptions);
      document.addEventListener("touchend", _onTouchEnd);

      eventHandlers.current = { onTouchEnd: _onTouchEnd, onTouchMove: _onTouchMove };
    }

    function _onTouchMove(event: TouchEvent) {
      onTouchMove(event);
    }

    function _onTouchEnd(event: TouchEvent) {
      onTouchEnd && onTouchEnd(event);
      removeEventListeners();
    }
  }, [onTouchMove, onTouchEnd]);

  React.useEffect(() => {
    return removeEventListeners;
  }, []);

  return { onTouchStart };

  function removeEventListeners(): void {
    const { current } = eventHandlers;

    if (current) {
      document.removeEventListener("touchmove", current.onTouchMove);
      document.removeEventListener("touchend", current.onTouchEnd);

      eventHandlers.current = undefined;
    }
  }
}
