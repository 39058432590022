import React from "react";
import { IItemFeed } from "../types/itemfeed";

export interface IFeedContext {
  add<T extends { id: string }>(itemFeed: IItemFeed<T>): number;
  get<T extends { id: string }>(feedId: number): IItemFeed<T> | undefined;
  delete(feedId: number): void;
}

let feedIndex = 0;

class FeedCache implements IFeedContext {
  private feeds = new Map<number, IItemFeed<any>>();

  public add<T extends { id: string }>(itemFeed: IItemFeed<T>): number {
    const feedId = ++feedIndex;
    this.feeds.set(feedId, itemFeed);
    return feedId;
  }

  public delete(feedId: number): void {
    this.feeds.delete(feedId);
  }

  public get<T extends { id: string }>(feedId: number): IItemFeed<T> | undefined {
    return this.feeds.get(feedId) as IItemFeed<T>;
  }
}

export const FeedContext = React.createContext<IFeedContext>(new FeedCache());
