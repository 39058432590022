import { IObservableArray } from "azure-devops-ui/Core/Observable";
import React from "react";
import { ISettingsContext } from "../../common/contexts/settings";
import { IEventDispatch } from "../../common/utilities/dispatch";
import { noop } from "../../common/utilities/func";
import { getSearchSuggestions } from "../api/userpreferences";
import { createRequest } from "../utilities/fetch";

const { SearchInspirations } = window.Resources;

// We will refresh up to once per 12 hour period.
const refreshPeriodMs = 1000 * 60 * 60 * 12;

/**
 * Name: "suggestedSearch"
 *
 * Setting used to track the suggested searches call, we want to refresh the
 * value every so often. This allows us to give better search inspirations.
 *
 */
export interface ISuggestedSearches {
  /**
   * Last time the suggested searches were refreshed.
   */
  lastRefresh?: number;

  /**
   * Set of available suggested searches if any have been
   */
  suggestedSearches?: string[];
}

export interface ISearchContext {
  addSearch: (searchText: string) => void;
  clearSearches: () => void;
  getSearches: () => Promise<string[]>;
  getSuggestions: () => string[];
  removeSearch: (searchText: string) => void;
}

export const SearchContext = React.createContext<ISearchContext>({
  addSearch: noop,
  clearSearches: noop,
  getSearches: () => Promise.resolve([]),
  getSuggestions: () => [],
  removeSearch: noop
});

// Convert the localized inspirations into an array.
const inspirations: string[] = [];

let term: keyof typeof SearchInspirations;
for (term in SearchInspirations) {
  inspirations.push(SearchInspirations[term]);
}

export class PhotoSearchContext implements ISearchContext {
  private eventDispatch: IEventDispatch;
  private recentSearches: IObservableArray<string>;
  private settingsContext: ISettingsContext;

  /**
   * Creates a new instance of the PhotoSearchContext.
   *
   * @param recentSearches The observable array of recent searches.
   */
  constructor(eventDispatch: IEventDispatch, settingsContext: ISettingsContext, recentSearches: IObservableArray<string>) {
    this.eventDispatch = eventDispatch;
    this.recentSearches = recentSearches;
    this.settingsContext = settingsContext;
  }

  /**
   * Adds a search to the searches array. If the search already exists, it will
   * be removed and added to the top of the list. If the list is longer than 6
   * items, the last item will be removed.
   *
   * @param searchText The search to be added.
   */
  public addSearch(searchText: string) {
    // Do all the updates in a local array so to avoid triggering multiple updates.
    const updatedSearches = [...this.recentSearches.value];

    // Do a case insensitive comparison to existing searches and remove any
    // duplicate that exist.
    for (let searchIndex = 0; searchIndex < updatedSearches.length; searchIndex++) {
      if (searchText.localeCompare(updatedSearches[searchIndex], undefined, { sensitivity: "base" }) === 0) {
        updatedSearches.splice(searchIndex, 1);
        break;
      }
    }

    // Prepend the new search and ensure we only track the latest 6.
    updatedSearches.unshift(searchText);
    updatedSearches.splice(6, 1);

    // Update the observable array of searches with the updated list.
    this.recentSearches.splice(0, this.recentSearches.length, ...updatedSearches);
  }

  /**
   * Clears the recent searches array
   */
  public clearSearches() {
    this.recentSearches.splice(0, this.recentSearches.length);
  }

  /**
   * Returns the searches array.
   *
   * @return A promise to the string[] of searches.
   */
  public getSearches(): Promise<string[]> {
    return Promise.resolve(this.recentSearches.value);
  }

  /**
   * Returns a list of suggestions that may be of interest to the user.
   *
   * @returns A list of suggestions that can be used to show the user.
   */
  public getSuggestions(): string[] {
    const currentTime = Date.now();
    const { lastRefresh, suggestedSearches } = this.settingsContext.getSetting<ISuggestedSearches>("suggestedSearches") || {
      lastRefresh: 0,
      suggestedSearches: []
    };

    // If we have a lastRefresh number and it is past our refresh window we
    // will start a new refresh pass and update the value (immediately so we
    // don't do it multiple times)
    if (typeof lastRefresh !== "number" || lastRefresh + refreshPeriodMs < currentTime) {
      const _getSearchSuggestions = createRequest(getSearchSuggestions, { eventDispatch: this.eventDispatch, name: "getSearchSuggestions" });

      _getSearchSuggestions()
        .then((suggestedSearches) => {
          this.settingsContext.mergeSetting("suggestedSearches", { suggestedSearches });
        })
        .catch(noop);

      this.settingsContext.mergeSetting("suggestedSearches", { lastRefresh: currentTime });
    }

    if (Array.isArray(suggestedSearches) && suggestedSearches.length > 0) {
      return suggestedSearches.filter((value) => typeof value === "string");
    }

    return inspirations;
  }

  /**
   * Removes a specified search from the searches array.
   *
   * @param searchText The search to remove.
   */
  public removeSearch(searchText: string) {
    const searchIndex = this.recentSearches.value.indexOf(searchText);

    if (searchIndex !== -1) {
      this.recentSearches.splice(searchIndex, 1);
    }
  }
}
