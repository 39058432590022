import React from "react";
import { headerHeight } from "../components/photoheader/photoheader";
import { IDimensions } from "../types/item";
import { ILayoutElement, ILayoutItem, ILayoutOptions, ILayoutResult, IRectangle, ITileDetails, ITilePadding, LayoutFunction } from "../types/layout";
import { fitToDimensions } from "../utilities/image";
import { noTilePadding, renderBasicPlaceholder } from "../utilities/layout";
import { getPlaceholders, groupItems } from "./util";

const defaultSize = 200;

/**
 * IRiverLayoutOptions are used to define the set of layout characteristics to
 * use with a river layout algorithm.
 */
export interface IRiverLayoutOptions<T, S = undefined> extends ILayoutOptions<T, S> {
  /**
   * All items in the view will have the same size based on these dimensions.
   * Items will still fill the rows but all have the same size, including any
   * non-full rows.
   */
  fixedDimensions?: IDimensions;

  /**
   * isSameSection is used to determine if items should be rendered in the UX
   * within the same section. Sections are sets of items that have a header
   * that applies to all the items in the section.
   */
  isSameSection?: (item1?: T, item2?: T) => boolean;

  /**
   * When creating the tile rectangle additional space can be requested in the
   * tile. This additional space will be added to the computed rectangle after
   * the items dimensions are applied.
   *
   * @default { bottom: 0 }
   */
  padding?: ITilePadding;

  /**
   * An optional header render function can be supplied for rendering a custom
   * header. If no function is supplied headers wont be generated.
   */
  renderSectionHeader?: (items: T[], rect: IRectangle) => React.ReactElement;
}

export function riverLayout<T extends { dimensions: IDimensions; id: string }, S = undefined>(
  layoutOptions: IRiverLayoutOptions<T, S>
): LayoutFunction<T> {
  const {
    fixedDimensions,
    isSameSection,
    isSimilar,
    itemSpacing = 16,
    padding = noTilePadding,
    renderItem,
    renderOptions,
    renderPlaceholder = renderBasicPlaceholder,
    renderSectionHeader,
    zoomLevel = defaultSize
  } = layoutOptions;
  let placeholderRatios: number[] | undefined;

  // For fixedDimensions we want to used a single fixed ratios.
  if (fixedDimensions) {
    placeholderRatios = [fixedDimensions.width / fixedDimensions.height];
  }

  // We need to do all the initialization work, like getting a consistent set of
  // leading & trailing placeholders.
  const _leadingPlaceholders = getPlaceholders(placeholderRatios);
  const _trailingPlaceholders = getPlaceholders(placeholderRatios);

  return function buildLayout(
    items: T[],
    layoutWidth: number,
    pivotItem: T | undefined,
    leadingPlaceholders: boolean,
    trailingPlaceholders: boolean
  ): ILayoutResult<T> {
    const elements: ILayoutElement<T>[] = [];
    const layoutItems: ILayoutItem<T>[] = [];
    const rows: Array<{ itemCount: number; rowHeight: number }> = [];
    const tiles: ITileDetails<T>[] = [];
    let pivotIndex = 0;
    let rowHeight = zoomLevel;

    // Group the items by our similar items delegate
    const groups = groupItems(items, isSimilar);

    // Build up the set of dimensions we will be using for each of the items
    // in this layout. This includes leading placeholders, items, trailing
    // placeholders.
    if (leadingPlaceholders) {
      for (let placeholderIndex = 0; placeholderIndex < _leadingPlaceholders.length; placeholderIndex++) {
        layoutItems.push({ dimensions: fitToDimensions(zoomLevel, 0, 1, _leadingPlaceholders[placeholderIndex]) });
      }

      if (pivotItem) {
        pivotIndex += _leadingPlaceholders.length;
      }
    }

    // Determine the dates for each of the items and add its ratio to the
    // set of items.
    for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
      const group = groups[groupIndex];
      const item = group[0];
      let dimensions = item.dimensions;

      // Determine which group is the pivotIndex
      if (pivotItem) {
        for (let itemIndex = 0; itemIndex < group.length; itemIndex++) {
          if (group[itemIndex] === pivotItem) {
            pivotIndex += groupIndex;
            pivotItem = undefined;
            break;
          }
        }
      }

      // If the item has not defined height/width we will use the target height
      // and assume a square item.
      if (!dimensions.height || !dimensions.width) {
        dimensions = { height: rowHeight, width: rowHeight };
      }

      // Use the fixedDimensions to layout each item if they were supplied.
      // Any embedded dimensions are ignored.
      dimensions = fixedDimensions || fitToDimensions(zoomLevel, 0, dimensions.height, dimensions.width);

      // Make sure the image has a minimum width to ensure space for the overlay.
      dimensions.width = Math.max(125, dimensions.width);

      // Push this item into the set of layout items.
      layoutItems.push({ group, dimensions, item });
    }

    if (trailingPlaceholders) {
      for (let placeholderIndex = 0; placeholderIndex < _trailingPlaceholders.length; placeholderIndex++) {
        layoutItems.push({ dimensions: fitToDimensions(zoomLevel, 0, 1, _trailingPlaceholders[placeholderIndex]) });
      }
    }

    // Compute the rows used to layout the items. This is done in two phases
    // first from the pivot item to the end inclusive followed by items from the
    // pivot item to the start.
    computeRows(pivotIndex, 1);
    if (pivotIndex) {
      computeRows(pivotIndex - 1, -1);
    }

    let itemIndex = 0;
    let lastSectionItem: T | undefined = undefined;
    let tileIndex = 0;
    let top = 0;

    // Go through each row and layout the items. This includes the optional section headers.
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const { itemCount, rowHeight } = rows[rowIndex];

      // Layout any section headers into the row before items.
      if (isSameSection && renderSectionHeader) {
        let rowHasHeader = false;
        let left = 0;

        for (let rowItemIndex = 0; rowItemIndex < itemCount; rowItemIndex++) {
          const { dimensions, item } = layoutItems[itemIndex + rowItemIndex];
          let headerWidth = dimensions.width;

          // If this item is creating a new section we will render its header.
          if (item && !isSameSection(lastSectionItem, item)) {
            const headerItems: T[] = [item];
            let sectionIndex = itemIndex + rowItemIndex + 1;

            rowHasHeader = true;
            lastSectionItem = item;

            for (; sectionIndex < layoutItems.length; ) {
              const { dimensions, item } = layoutItems[sectionIndex];

              if (item && isSameSection(lastSectionItem, item)) {
                headerWidth += dimensions.width + itemSpacing;
                headerItems.push(item);
                rowItemIndex++;
                sectionIndex++;
              } else {
                break;
              }
            }

            const rect = { height: headerHeight, left, top, width: Math.min(headerWidth, layoutWidth - left) };
            elements.push({ element: renderSectionHeader(headerItems, rect), rect });
          }

          // Update the start of the next header if one exists, this may be much
          // larger than the row but that is ok since there wont be more on this
          // row.
          left += headerWidth + itemSpacing;
        }

        // If this row contained a header we will move the top down by the row height.
        if (rowHasHeader) {
          top += headerHeight;
        }
      }

      // Layout the items for this row.
      for (let left = 0, rowItemIndex = 0; rowItemIndex < itemCount; rowItemIndex++) {
        const { dimensions, group, item } = layoutItems[itemIndex + rowItemIndex];
        const rect = { height: dimensions.height + padding.bottom, left, top, width: dimensions.width };

        if (group && item) {
          const tileDetails: ITileDetails<T> = { group, item, padding, rect };

          tiles.push(tileDetails);
          elements.push({ element: renderItem(tileDetails, tileIndex++, renderOptions), rect, tileDetails });
        } else {
          elements.push({ element: renderPlaceholder(rect, padding, tileIndex++), rect });
        }

        // Update the left for the next item.
        left += rect.width + itemSpacing;
      }

      // Setup for the start of the next row.
      itemIndex += itemCount;
      top += rowHeight + itemSpacing + padding.bottom;
    }

    return { elements, height: top - itemSpacing, items: items || [], layoutWidth, pivotItem, tiles };

    // Determine the set of items that will fit on the rows given the target height.
    // This is done by computing the set of items that will fit in a row at the target
    // height maintaing their aspect ratio. We track the aspect ratio of each item
    // in the row and adjust the height to line up the items with a complete row.
    function computeRows(startIndex: number, nextItemOffset: 1 | -1) {
      let itemCount = 0;
      let rowPadding = 0;
      let rowWidth = 0;

      for (let itemIndex = startIndex; itemIndex >= 0 && itemIndex < layoutItems.length; ) {
        const { dimensions } = layoutItems[itemIndex];

        // Determine if this photo is going to overflow the row, if it is we
        // will not include it in the row unless the zoomLevel was defined as
        // the maximum size in which case we will add it and shrink the tiles.
        // Otherwise we will grow the tiles to fill the row without it.
        let overflow = rowWidth + rowPadding + itemSpacing + dimensions.width > layoutWidth;

        // Ensure we have at least one item for this row and if this item
        // wont fit we have the set of items for this row.
        if (itemCount === 0 || !overflow) {
          rowWidth += dimensions.width;
          rowPadding += itemSpacing;
          itemCount++;
          itemIndex += nextItemOffset;

          // If there are more items we will move to the next.
          if (itemIndex >= 0 && itemIndex < layoutItems.length) {
            continue;
          }
        }

        // If we are using fixedDimensions and this isn't the first row we will
        // just keep using the height given by the first row.
        if (!fixedDimensions || rows.length === 0) {
          if (overflow) {
            // The height needs to be scaled for the items to fill the row.
            rowHeight = (zoomLevel * (layoutWidth - rowPadding + itemSpacing)) / rowWidth;
          } else {
            rowHeight = zoomLevel;
          }
        }

        // Go through the items in the row now that we have the row computed and
        // update to the actual layout dimensions.
        for (let dimensionIndex = itemIndex - itemCount * nextItemOffset; dimensionIndex !== itemIndex; dimensionIndex += nextItemOffset) {
          const layoutItem = layoutItems[dimensionIndex];
          layoutItem.dimensions = fitToDimensions(rowHeight, 0, layoutItem.dimensions.height, layoutItem.dimensions.width);
        }

        // Save this completed row.
        if (nextItemOffset === -1) {
          rows.splice(0, 0, { itemCount, rowHeight });
        } else {
          rows.push({ itemCount, rowHeight });
        }

        // Reset the row computations for the next row.
        itemCount = 0;
        rowPadding = 0;
        rowWidth = 0;
      }
    }
  };
}
