import { IDimensions } from "../types/item";
import { ILayoutElement, ILayoutItem, ILayoutOptions, ILayoutResult, ITileDetails, LayoutFunction } from "../types/layout";
import { fitToDimensions } from "../utilities/image";
import { noTilePadding, renderBasicPlaceholder } from "../utilities/layout";
import { getPlaceholders, groupItems, max, min } from "./util";

const defaultColumnWidth = 200;

/**
 * Layout options custom to the masonry layout. These options define the
 * specific layout options that are used.
 */
export interface IMasonryLayoutOptions<T, S = undefined> extends ILayoutOptions<T, S> {}

/**
 * Function used to create a masonry layout.
 *
 * @param layoutOptions
 * @returns
 */
export function masonryLayout<T extends { dimensions: IDimensions; id: string }, S = undefined>(
  layoutOptions: IMasonryLayoutOptions<T, S>
): LayoutFunction<T> {
  const {
    isSimilar,
    itemSpacing = 16,
    renderItem,
    renderOptions,
    renderPlaceholder = renderBasicPlaceholder,
    zoomLevel = defaultColumnWidth
  } = layoutOptions;

  // We need to do all the initialization work, like getting a consistent set of
  // leading & trailing placeholders.
  const _leadingPlaceholders = getPlaceholders();
  const _trailingPlaceholders = getPlaceholders();

  return function buildLayout(
    items: T[] | undefined,
    layoutWidth: number,
    pivotItem: T | undefined,
    leadingPlaceholders: boolean,
    trailingPlaceholders: boolean
  ): ILayoutResult<T> {
    const columnCount = Math.max(1, Math.floor(layoutWidth / zoomLevel));
    const columnHeights: number[] = [];
    const columnWidth = (layoutWidth + itemSpacing) / columnCount - itemSpacing;
    const elements: ILayoutElement<T>[] = [];
    const layoutItems: ILayoutItem<T>[] = [];
    const tiles: ITileDetails<T>[] = [];

    // Prefill the column heights with 0 for each available column.
    for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
      columnHeights[columnIndex] = 0;
    }

    if (items) {
      // Group the items by our similar items delegate
      const groups = groupItems(items, isSimilar);

      // Build up the set of imageSizes we will be using for each of the items
      // in this layout. This includes leading placeholders, items, trailing
      // placeholders.
      if (leadingPlaceholders) {
        for (let placeholderIndex = 0; placeholderIndex < _leadingPlaceholders.length; placeholderIndex++) {
          layoutItems.push({
            dimensions: fitToDimensions(0, columnWidth, 1, _leadingPlaceholders[placeholderIndex])
          });
        }
      }

      // Determine the dates for each of the items and add its ratio to the
      // set of items.
      for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        const item = groups[groupIndex][0];
        let dimensions = item.dimensions;

        // If the item has not defined height/width we will use the columnWidth
        // and assume a square item.
        if (!dimensions.height || !dimensions.width) {
          dimensions = { height: columnWidth, width: columnWidth };
        }

        dimensions = fitToDimensions(0, columnWidth, dimensions.height, dimensions.width);

        // Make sure the image has a minimum height to ensure we can contain our commands.
        dimensions.height = Math.max(125, Math.round(dimensions.height));

        // Push this item into the set of layout items
        layoutItems.push({
          group: groups[groupIndex],
          dimensions,
          item: groups[groupIndex][0]
        });
      }
      if (trailingPlaceholders) {
        for (let placeholderIndex = 0; placeholderIndex < _trailingPlaceholders.length; placeholderIndex++) {
          layoutItems.push({
            dimensions: fitToDimensions(0, columnWidth, 1, _trailingPlaceholders[placeholderIndex])
          });
        }
      }
    } else {
      for (let placeholderIndex = 0; placeholderIndex < _trailingPlaceholders.length; placeholderIndex++) {
        layoutItems.push({
          dimensions: fitToDimensions(0, columnWidth, 1, _trailingPlaceholders[placeholderIndex])
        });
      }
    }

    // Layout the items available.
    for (let itemIndex = 0, tileIndex = 0; itemIndex < layoutItems.length; itemIndex++) {
      const { dimensions, group, item } = layoutItems[itemIndex];
      const { index, value } = min(columnHeights);

      // Compute the rectangle we will use to layout either the placeholder, or the item tile.
      const rect = { height: dimensions.height, left: index * (columnWidth + itemSpacing), top: value, width: columnWidth };

      if (group && item) {
        const tileDetails: ITileDetails<T> = { group, item, rect };

        tiles.push(tileDetails);
        elements.push({ element: renderItem(tileDetails, tileIndex++, renderOptions), rect, tileDetails });
      } else {
        elements.push({ element: renderPlaceholder(rect, noTilePadding, tileIndex++), rect });
      }

      columnHeights[index] += rect.height + itemSpacing;
    }

    return { elements, height: max(columnHeights).value - itemSpacing, items: items || [], layoutWidth, pivotItem, tiles };
  };
}
