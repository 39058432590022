// istanbul ignore file - We can't emulate the ResizeObserver in the mock document.
import React from "react";
import { useTimeout } from "./usetimeout";

export function useResize(
  observedElement: React.RefObject<HTMLElement> | undefined,
  callback: (entries: ResizeObserverEntry[], observer: ResizeObserver) => void
): void {
  const cachedRect = React.useRef<DOMRect | undefined>(undefined);

  const { setTimeout } = useTimeout();

  const observer = React.useMemo(() => {
    return new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      const contentRect = entries[0].contentRect;
      const _cachedRect = cachedRect.current;

      if (!_cachedRect || _cachedRect.height !== contentRect.height || _cachedRect.width !== contentRect.width) {
        setTimeout(() => {
          callback(entries, observer);
          cachedRect.current = contentRect;
        }, 0);
      }
    });
  }, [callback, setTimeout]);

  React.useEffect(() => {
    if (observedElement && observedElement.current) {
      const _observedElement = observedElement.current;

      observer.observe(_observedElement);
      return () => {
        observer.unobserve(_observedElement);
      };
    }
  }, [callback, observedElement, observer]);
}
