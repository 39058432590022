import React from "react";
import { useTimeout } from "./usetimeout";

/**
 * Options to control how the mouse tracker behaves during enter/leave
 */
export interface IMouseTrackerOptions {
  /**
   * How long after the mouse has left an element should the state of the
   * mouse be updated to false.
   *
   * This can be useful when the mouse might quickly move out and back in and
   * you don't want the leave behavior, an example is popup menus with sub menus.
   *
   * @default 0
   */
  leaveDelay?: number;

  /**
   * An optional delegate that allows the caller to react to the change in
   * mouse state during the change instead of waiting for the next render pass.
   */
  onMouseChange?: (hasMouse: boolean) => void;
}

/**
 * The IMouseStatus represents the current state of the mouse as well as the
 * methods that should be attached to one more root items that are being tracked.
 */
export interface IMouseStatus {
  /**
   * hasMouse represents whether or not the mouse is currently hovering over the
   * root element or any of its children, including React portals.
   */
  hasMouse: boolean;

  /**
   * onMouseEnter should be attached to one or more root element. These shouldn't
   * be siblings of each other.
   */
  onMouseEnter: (event: React.MouseEvent) => void;

  /**
   * onMouseLeave should be attached to one or more root element. These shouldn't
   * be siblings of each other.
   */
  onMouseLeave: (event: React.MouseEvent) => void;
}

/**
 * useMouseTracker is useful for tracking whether or not the mouse is currently
 * over a given element. It can provide multi-root as well as delayed behavior.
 *
 * @param options Options to control enter/leave behaviors.
 * @returns The current status of the mouse along with mouse delegates.
 */
export function useMouseTracker(options?: IMouseTrackerOptions): IMouseStatus {
  const { leaveDelay = 0, onMouseChange } = options || {};

  const leaveInProgress = React.useRef(false);

  const [hasMouse, setHasMouse] = React.useState(false);
  const { setTimeout } = useTimeout();

  return {
    hasMouse,
    onMouseEnter: () => {
      leaveInProgress.current = false;

      if (!hasMouse) {
        setHasMouse(true);

        // If the caller wants to be notified of the mouse state change call them.
        onMouseChange && onMouseChange(true);
      }
    },
    onMouseLeave: () => {
      // Note that we are in the process of the mouse transitioning to another element.
      // We need to wait for the leaveDelay to process the event.
      leaveInProgress.current = true;

      if (leaveDelay) {
        setTimeout(() => {
          // If the mouse has not moved back within the element borders within the timeout
          // we will update our hover state.
          if (leaveInProgress.current) {
            leaveInProgress.current = false;
            setHasMouse(false);

            // If the caller wants to be notified of the mouse state change call them.
            onMouseChange && onMouseChange(false);
          }
        }, leaveDelay);
      } else {
        setHasMouse(false);

        // If the caller wants to be notified of the mouse state change call them.
        onMouseChange && onMouseChange(false);
      }
    }
  };
}
