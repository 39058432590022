import React from "react";

export interface ITrackChildrenProps {
  /**
   * The child element to be rendered.
   */
  children: React.ReactElement;

  /**
   * Method to be called when the component is mounted.
   */
  mount: () => void;

  /**
   * Method to be called when the component is unmounted.
   */
  unmount: () => void;
}

export function TrackChildren({ children, mount, unmount }: ITrackChildrenProps): React.ReactElement {
  React.useEffect(() => {
    mount();

    // return unmount;
    return () => {
      unmount();
    };
  }, [mount, unmount]);

  return children;
}
