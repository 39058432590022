import { formatDateAPI, formatURL } from "../../common/utilities/format";
import { IItemPage, IRecommendationItemPage } from "../types/item";
import { INetworkPhoto, IPhoto } from "../types/photo";
import { roundDate } from "../utilities/util";
import { graphResponse } from "./network";
import { defaultPhotoSelect } from "./photo";
import { preparePhoto } from "./util";

const recomendationsApi = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/oneDrive.recommendedItems?{{parameters}}";

export interface IGetRecommendationsOptions {
  date?: Date;
  rangeInDaysForStartDate?: number;
}

export function getRecommendations(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  options?: IGetRecommendationsOptions
): Promise<IRecommendationItemPage> {
  const { date, rangeInDaysForStartDate } = options || {};
  const params: { [parameterName: string]: string } = { expand: "thumbnails", prefer: "getStaticThumbnailUrls" };
  const currentDate = date || new Date();
  const currentUtcDate = new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000);

  if (rangeInDaysForStartDate) {
    const startDateDateRangeStart = new Date(currentDate.getTime() - rangeInDaysForStartDate * 24 * 60 * 60 * 1000);
    const dateRangeStartString = formatDateAPI(roundDate(startDateDateRangeStart));
    const dateRangeEndString = formatDateAPI(roundDate(currentUtcDate), false);
    params.filter = "recommendation/startDate ge " + dateRangeStartString + " and recommendation/startDate le " + dateRangeEndString;
  } else {
    params.targetDate = formatDateAPI(roundDate(currentUtcDate), false);
  }

  return graphResponse(fetch(formatURL(recomendationsApi, { driveId, parameters: new URLSearchParams(params) })));
}

const recommendedItemsApi = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/recommendedItems/{{recommendation}}/children?{{parameters}}";

export function getRecommendedItems(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  recommendation: string
): Promise<IItemPage<IPhoto>> {
  return graphResponse(
    fetch(
      formatURL(recommendedItemsApi, { driveId, parameters: new URLSearchParams({ expand: "tags", select: defaultPhotoSelect }), recommendation })
    )
  ).then((photoPage: IItemPage<INetworkPhoto>) => {
    return { ...photoPage, value: photoPage.value.map(preparePhoto) };
  });
}
