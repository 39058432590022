import { deleteCookie, getCookies } from "../../common/utilities/browser";
import { createRequest as _createRequest, FetchFunction, IFetchEndEvent, IFetchOptions } from "../../common/utilities/fetch";

// Set of headers that should be recorded when a request completes.
const report = new Map<string, string>([
  ["Content-Length", "contentLength"],
  ["ms-cv", "ms_cv"],
  ["RateLimit-Limit", "rateLimitLimit"],
  ["RateLimit-Remaining", "rateLimitRemaining"],
  ["RateLimit-Reset", "rateLimitReset"],
  ["Retry-After", "retryAfter"],
  ["sprequestguid", "spRequestGuid"],
  ["sprequestduration", "spRequestDuration"],
  ["X-Cache-Origin", "cacheOrigin"],
  ["X-Msedge-Ref", "msEdgeRef"]
]);

/**
 * createRequest is a wrapper around the platform createRequest code. This
 * wrapper includes the photos specific behaviors for network requests.
 *
 * @param fetchFunction The underlying function that builds and runs the network request.
 * @param options Options used to control the behaviors of the network request.
 * @returns A promise of the deserialized value from the underlying function.
 */
export function createRequest<T, P extends any[]>(fetchFunction: FetchFunction<T, P>, options?: IFetchOptions): (...args: P) => Promise<T> {
  return _createRequest(fetchFunction, { retryCallback, ...options });
}

/**
 * The retryCallback used to determine if a given network failure should be
 * retried.
 *
 * @param requestUrl The URL of the resource requested.
 * @param init Options used to make the request.
 * @param response The failed netork response.
 * @param options The options suppled to the fetch call.
 * @returns
 */
export function retryCallback(requestUrl: string, init: RequestInit, response: Response, _options?: IFetchOptions): Promise<boolean> {
  let retry = false;

  // If we got an unauthorized result we will clear our cookies which will force
  // a refresh when the next call is made.
  if (response.status === 401 || response.status === 403) {
    const headers = new Headers(init.headers);

    if (requestUrl.indexOf("ump=1") >= 0 || headers.has("Authorization")) {
      const cookies = getCookies();

      // Delete all accesstoken cookies before we return and retry.
      for (let cookieName in cookies) {
        if (cookieName.indexOf("AccessToken-") === 0) {
          deleteCookie(cookieName);
        }
      }

      retry = true;
    }
  }

  return Promise.resolve(retry);
}

/**
 * Given a fetch event that has finished the network request we need to
 * extract the set of properties we want to track in telemetry.
 */
export function extractTelemetry(event: IFetchEndEvent): void {
  const { response, telemetryProperties } = event;

  // Push the basic httpStatus onto the telemetry.
  telemetryProperties.httpStatus = response.status;

  // Add the requested headers to the telemetry.
  report.forEach((propertyName: string, headerName: string) => {
    const headerValue = response.headers.get(headerName);

    if (headerValue) {
      telemetryProperties[propertyName] = headerValue;
    }
  });
}
