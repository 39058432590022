import React from "react";
import { EventContext } from "../../contexts/event";

/**
 * Properties passed to the the ErrorBoundary
 */
export interface IFallbackProps {
  /**
   * Raw error that occurred during the rendering of the children.
   */
  error: Error;

  /**
   * React details about the component that caused the failure.
   */
  errorInfo: React.ErrorInfo;
}

/**
 * The IErrorBoundaryProperties are used to render
 */
export interface IErrorBoundaryProps {
  /**
   *
   * Callback that allows the caller to get notified of errors before error events are sent
   */
  errorCallback?: (error: Error, errorInfo: React.ErrorInfo) => void;

  /**
   * The type of component to render when an error is caught
   */
  fallbackComponent?: React.FunctionComponent<IFallbackProps>;
}

interface IErrorBoundaryState {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

/**
 * This is an ErrorBoundary that always shows child content, regardless of error state.
 * When there is an error state, the ErrorBoundary will render a WrappedComponent, of the given
 * type and props, as a sibling of the rest of the content.
 */
export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  // We want the telemetry context within the ErrorBoudary. This allows us to
  // easily fire off a telemetry event when an error occurs.
  static contextType = EventContext;

  state: IErrorBoundaryState = {};

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });

    this.props.errorCallback && this.props.errorCallback(error, errorInfo);

    this.context.dispatchEvent("telemetryAvailable", {
      action: "exception",
      error,
      errorInfo,
      name: "unhandledException",
      errorName: "errorBoundary"
    });
  }

  public render(): React.ReactNode {
    if (this.state.error && this.state.errorInfo) {
      const { fallbackComponent: FallbackComponent } = this.props;

      if (FallbackComponent) {
        return React.createElement(FallbackComponent, {
          error: this.state.error,
          errorInfo: this.state.errorInfo
        });
      }

      return null;
    }

    // If no error, this component is a no-op
    return this.props.children;
  }
}
