let pressed: Set<string> | undefined = undefined;

/**
 * Used to get the pressed state of a key given the key string.
 *
 * @param key The key to check its pressed state.
 * @returns true if the key is currently pressed otherwise false.
 */
export function getKeyState(key: string): boolean {
  return pressed ? pressed.has(key) : false;
}

/**
 * initializeKeyboardState is used to setup the state of the keyboard. If the
 * caller wants to use getKeyState function they should call
 * initializeKeyboardState before the application gets up and running.
 *
 * This allows the library to setup event handlers to track the actual state
 * of the keys before keyboard events begin happening and the state of the keys
 * get out of sync.
 */
export function initializeKeyboardState() {
  if (!pressed) {
    pressed = new Set<string>();

    window.addEventListener("keydown", (event: KeyboardEvent) => pressed!.add(event.key), true);
    window.addEventListener("keyup", (event: KeyboardEvent) => pressed!.delete(event.key), true);
  }
}
