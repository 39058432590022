import { css } from "azure-devops-ui/Util";
import React from "react";
import { lazy } from "../../../common/components/lazy/lazy";
import { EventContext } from "../../../common/contexts/event";
import { SessionContext } from "../../contexts/session";
import { useRejection } from "../../hooks/userejection";
import { uploadPhotos } from "../../operations/photo";
import { IDragDropOptions } from "../../types/view";

import "./uploadtarget.css";

const DropTarget = lazy(() => import("./droptarget"));

export interface IUploadTargetProps extends React.HTMLAttributes<HTMLElement> {
  getDragDropOptions: () => IDragDropOptions | undefined;
}

export function UploadTarget(props: IUploadTargetProps & { children: React.ReactNode }): React.ReactElement {
  const { children, className, getDragDropOptions, ...remainingProps } = props;

  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const localDragOperation = React.useRef(false);

  const [dragActive, setDragActive] = React.useState(false);

  const handleRejection = useRejection();

  return (
    <div
      {...remainingProps}
      className={css("upload-target", className, "relative flex-column flex-grow overflow-hidden")}
      onDragEnd={dragEnd}
      onDragEnter={dragEnter}
      onDragStart={dragStart}
    >
      {dragActive && (
        <React.Suspense fallback={null}>
          <DropTarget message={getDragDropOptions()?.message} onDragLeave={dragLeave} onDrop={dragDrop} />
        </React.Suspense>
      )}
      {children}
    </div>
  );

  function dragDrop(event: React.DragEvent) {
    if (event.dataTransfer && event.dataTransfer.types.indexOf("Files") >= 0) {
      let files: File[];

      // Handle the drop data as either an array of DataTransferItems or Files
      if (event.dataTransfer.files && event.dataTransfer.files.length) {
        files = [...event.dataTransfer.files];
      } else {
        files = [...event.dataTransfer.items].map((transferItem) => transferItem.getAsFile()).filter((file) => file) as File[];
      }

      // Upload the photos to the service.
      uploadPhotos(eventContext, sessionContext.driveId, files, getDragDropOptions()?.getTargetFolder).catch(handleRejection);

      // go back to the existing UX while the upload completes.
      setDragActive(false);
      event.preventDefault();
    }
  }

  function dragEnd() {
    localDragOperation.current = false;
  }

  function dragEnter(event: React.DragEvent) {
    // Make sure we don't start drag & drop when disabled.
    // Make sure we don't handle local drag operations, only external ones.
    if (
      !event.defaultPrevented &&
      !getDragDropOptions()?.disabled &&
      !localDragOperation.current &&
      event.dataTransfer &&
      event.dataTransfer.types.indexOf("Files") >= 0
    ) {
      event.preventDefault();
      setDragActive(true);
    }
  }

  function dragLeave() {
    setDragActive(false);
  }

  function dragStart() {
    localDragOperation.current = true;
  }
}
