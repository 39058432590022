import { ITelemetryEvent } from "../../common/utilities/platformdispatch";
import { IScenarioDetails, getScenarioEvent } from "../../common/utilities/scenario";

/**
 * Pillars are the broad categories of customer promises that ASHA tracks across all ODSP products.
 * Component pillars are tied to components (including component rendering).
 * Operation pillars are tied to operations (ie. delete).
 */
export type ComponentPillar = "Boot" | "View";

export type OperationPillar = "Create" | "Delete" | "Edit" | "Manage" | "Social";

export type Pillar = ComponentPillar | OperationPillar;

export interface IFailedScenarioProperties {
  /**
   * The name of the scenario that failed.
   */
  name: string;

  /**
   * The error code of the specific response that caused the failure.
   */
  code: string;

  /**
   * The message of the specific response that caused the failure.
   */
  message: string;

  /**
   * The type of the scenario that failed.
   */
  type: string;

  /**
   * The pillar the scenario is tied to - if this is available, the scenario is a customer promise.
   */
  pillar?: Pillar;
}

/**
 * Vetoes are issues that customers experience that cause the customer promise to fail.
 * They are set as a type so that mapping to ASHA is easier.
 * Veto names must be unique across all pillars.
 */

/**
 * Names of vetoes that can occur from network request failures.
 */
export type NetworkRequestVetoName =
  | "BadRequest"
  | "GeneralException"
  | "NetworkError"
  | "ResourceNotFound"
  | "UnknownNetworkRequestError"
  | "UserRequestThrottled"
  | "UserUnauthenticated";

/**
 * Names of vetoes that can occur during boot scenarios.
 */
export type BootVetoName =
  | "BootChunkLoadError"
  | "BootPerformanceGoalNotMet"
  | "BootSubScenarioThrottled"
  | "PhotosWebAppBootComponentRenderError"
  | "PhotosWebAppUnknownBootError";

/**
 * Names of vetoes that can occur during create scenarios.
 */
export type CreateVetoName =
  | "CreateBadRequest"
  | "CreateChunkLoadError"
  | "CreateGeneralException"
  | "CreateImageLoadError"
  | "CreateNetworkError"
  | "CreatePerformanceGoalNotMet"
  | "CreateSubScenarioThrottled"
  | "CreateUserRequestThrottled"
  | "CreateUserUnauthenticated"
  | "CreateUnknownNetworkRequestError"
  | "PhotosWebAppUnknownCreateError";

/**
 * Names of vetoes that can occur during delete scenarios.
 */
export type DeleteVetoName =
  | "DeleteBadRequest"
  | "DeleteChunkLoadError"
  | "DeleteGeneralException"
  | "DeleteImageLoadError"
  | "DeleteItemNotFound"
  | "DeleteNetworkError"
  | "DeletePerformanceGoalNotMet"
  | "DeleteResourceNotFound"
  | "DeleteSubScenarioThrottled"
  | "DeleteUserRequestThrottled"
  | "DeleteUserUnauthenticated"
  | "DeleteUnknownNetworkRequestError"
  | "PhotosWebAppUnknownDeleteError";

/**
 * Names of vetoes that can occur during edit scenarios.
 */
export type EditVetoName =
  | "EditBadRequest"
  | "EditChunkLoadError"
  | "EditGeneralException"
  | "EditImageLoadError"
  | "EditNetworkError"
  | "EditPerformanceGoalNotMet"
  | "EditResourceNotFound"
  | "EditSubScenarioThrottled"
  | "EditUserRequestThrottled"
  | "EditUserUnauthenticated"
  | "EditUnknownNetworkRequestError"
  | "PhotosWebAppUnknownEditError";

/**
 * Names of vetoes that can occur during manage scenarios.
 */
export type ManageVetoName =
  | "ManageBadRequest"
  | "ManageChunkLoadError"
  | "ManageGeneralException"
  | "ManageImageLoadError"
  | "ManageNetworkError"
  | "ManagePerformanceGoalNotMet"
  | "ManageResourceNotFound"
  | "ManageSubScenarioThrottled"
  | "ManageUserRequestThrottled"
  | "ManageUserUnauthenticated"
  | "ManageUnknownNetworkRequestError"
  | "PhotosWebAppUnknownManageError";

/**
 * Names of vetoes that can occur during social scenarios.
 */
export type SocialVetoName =
  | "SocialBadRequest"
  | "SocialChunkLoadError"
  | "SocialGeneralException"
  | "SocialNetworkError"
  | "SocialPerformanceGoalNotMet"
  | "SocialResourceNotFound"
  | "SocialSubScenarioThrottled"
  | "SocialUserRequestThrottled"
  | "SocialUserUnauthenticated"
  | "SocialUnknownNetworkRequestError"
  | "PhotosWebAppUnknownSocialError";

/**
 * Names of vetoes that can occur during view scenarios.
 */
export type ViewVetoName =
  | NetworkRequestVetoName
  | "ChunkLoadError"
  | "ComponentRenderFailure"
  | "ImageLoadError"
  | "PhotosWebAppViewComponentRenderError"
  | "PhotosWebAppUnknownError"
  | "ViewPerformanceGoalNotMet"
  | "ViewSubScenarioThrottled";

export type VetoName = BootVetoName | CreateVetoName | DeleteVetoName | EditVetoName | ManageVetoName | SocialVetoName | ViewVetoName;

/**
 * Vetoes are issues that customers experience that cause the customer promise to fail.
 */
export interface IVeto {
  /**
   * The name of the veto. Pillar performance is segmented by veto occurrence.
   */
  name: VetoName;

  /**
   * The error of the specific response that caused the veto.
   */
  errorCode: string;
}

/**
 * Utility function that creates a list of all failed child scenarios
 */
export function populateFailedSubScenarios(event: ITelemetryEvent, failed: IFailedScenarioProperties[]): IFailedScenarioProperties[] {
  const { children, result, scenarioName, scenarioType } = event;

  if (result?.status === "rejected" && !result.reason?.isCanceled) {
    failed.push({
      name: scenarioName,
      code: result.reason?.error?.code || result.reason?.message || "noErrorCode",
      message: result.reason?.error?.message || result.reason?.message || JSON.stringify(result.reason),
      pillar: event.properties?.pillar,
      type: scenarioType
    });
  }

  if (children) {
    for (const child of children) {
      if (!child.properties?.pillar) {
        populateFailedSubScenarios(child, failed);
      }
    }
  }

  return failed;
}

/**
 * A customer promise event which is dispactched through the telemetry provider with the "customerPromise" event.
 */
export interface ICustomerPromiseEvent extends ITelemetryEvent {
  action: "customerPromise";
  duration: number;
  pillar: Pillar;
  resultType: string;
  veto?: IVeto;
}

/**
 * Standard evaluation of vetoes - if a sub-scenario fails, add a veto to the list.
 * @param scenarioDetails The details of the scenario completion
 * @returns list of vetoes and their error codes
 */
export function evaluateScenarioVetoes(scenarioDetails: IScenarioDetails): IVeto[] {
  const vetoes: IVeto[] = [];
  const failedSubScenarios = populateFailedSubScenarios(getScenarioEvent("scenarioComplete", scenarioDetails), []);

  switch (scenarioDetails.properties?.pillar) {
    // While collecting information on possible boot failures, use a generic veto name
    case "Boot":
      for (const failedSubScenario of failedSubScenarios) {
        // Catch CDN script loading failures
        if (failedSubScenario.code.includes("Loading chunk") || failedSubScenario.code.includes("Loading CSS chunk")) {
          vetoes.push({ name: "BootChunkLoadError", errorCode: failedSubScenario.code.includes("CSS") ? "CSSChunkLoadError" : "JSChunkLoadError" });
        } else {
          vetoes.push({ name: "PhotosWebAppUnknownBootError", errorCode: failedSubScenario.code });
        }
      }
      break;
    case "Create":
      for (const failedSubScenario of failedSubScenarios) {
        vetoes.push(getCreateVeto(failedSubScenario));
      }
      break;
    case "Delete":
      for (const failedSubScenario of failedSubScenarios) {
        vetoes.push(getDeleteVeto(failedSubScenario));
      }
      break;
    case "Edit":
      for (const failedSubScenario of failedSubScenarios) {
        vetoes.push(getEditVeto(failedSubScenario));
      }
      break;
    case "Manage":
      for (const failedSubScenario of failedSubScenarios) {
        vetoes.push(getManageVeto(failedSubScenario));
      }
      break;
    case "Social":
      for (const failedSubScenario of failedSubScenarios) {
        vetoes.push(getSocialVeto(failedSubScenario));
      }
      break;
    case "View":
      for (const failedSubScenario of failedSubScenarios) {
        switch (failedSubScenario.type) {
          case "componentRender":
            vetoes.push({ name: "ComponentRenderFailure", errorCode: failedSubScenario.code });
            break;
          case "imageLoad":
            // Trim the error code to remove any guids for ASHA aggregation
            vetoes.push({
              name: "ImageLoadError",
              errorCode: failedSubScenario.code.includes("Image") ? "Image failed to load" : "Content failed to load"
            });
            break;
          case "networkRequest":
            vetoes.push(getNetworkRequestVeto(failedSubScenario));
            break;
          case "scriptDownload":
            vetoes.push({ name: "ChunkLoadError", errorCode: failedSubScenario.code.includes("CSS") ? "CSSChunkLoadError" : "JSChunkLoadError" });
            break;
          default:
            vetoes.push({ name: "PhotosWebAppUnknownError", errorCode: failedSubScenario.code });
        }
      }
  }
  return vetoes;
}

/**
 * Get the veto for a network request failure
 * @param failedSubScenario The failed network request scenario
 * @returns The veto for the network request failure
 */
export function getNetworkRequestVeto(failedSubScenario: IFailedScenarioProperties): IVeto {
  const networkRequestVeto: IVeto = {
    name: "UnknownNetworkRequestError",
    errorCode: failedSubScenario.code
  };

  switch (networkRequestVeto.errorCode.toLowerCase()) {
    case "activitylimitreached":
    case "throttledrequest":
      networkRequestVeto.name = "UserRequestThrottled";
      break;
    case "accessdenied":
    case "invalidrequest":
    case "nonjsonerror":
    case "notsupported":
      networkRequestVeto.name = "BadRequest";
      break;
    case "itemnotfound":
    case "itemdoesnotexist":
      networkRequestVeto.name = "ResourceNotFound";
      break;
    case "failed to fetch":
    case "load failed":
    case "networkerror when attempting to fetch resource.":
    case "preparationfailed":
      networkRequestVeto.name = "NetworkError";
      break;
    case "unauthenticated":
      networkRequestVeto.name = "UserUnauthenticated";
      break;
    case "generalexception":
    case "unexpectedstatus":
      networkRequestVeto.name = "GeneralException";
      break;
    default:
      break;
  }
  return networkRequestVeto;
}

/**
 * Get the veto for a failure with the create operation
 */
export function getCreateVeto(failedSubScenario: IFailedScenarioProperties): IVeto {
  const createVeto: IVeto = {
    name: "PhotosWebAppUnknownCreateError",
    errorCode: failedSubScenario.code
  };
  if (failedSubScenario.type === "networkRequest" || failedSubScenario.type === "operationExecute") {
    const networkRequestVeto = getNetworkRequestVeto(failedSubScenario);
    const createVetoName = `Create${networkRequestVeto.name}` as CreateVetoName;
    createVeto.name = createVetoName;
  }
  return createVeto;
}

/**
 * Get the veto for a failure with the delete operation
 */
export function getDeleteVeto(failedSubScenario: IFailedScenarioProperties): IVeto {
  const deleteVeto: IVeto = {
    name: "PhotosWebAppUnknownDeleteError",
    errorCode: failedSubScenario.code
  };
  if (failedSubScenario.type === "networkRequest" || failedSubScenario.type === "operationExecute") {
    switch (deleteVeto.errorCode.toLowerCase()) {
      case "itemnotfound":
        deleteVeto.name = "DeleteItemNotFound";
        break;
      default:
        const networkRequestVeto = getNetworkRequestVeto(failedSubScenario);
        const deleteVetoName = `Delete${networkRequestVeto.name}` as DeleteVetoName;
        deleteVeto.name = deleteVetoName;
    }
  }
  return deleteVeto;
}

export function getEditVeto(failedSubScenario: IFailedScenarioProperties): IVeto {
  const editVeto: IVeto = {
    name: "PhotosWebAppUnknownEditError",
    errorCode: failedSubScenario.code
  };
  if (failedSubScenario.type === "networkRequest" || failedSubScenario.type === "operationExecute") {
    const networkRequestVeto = getNetworkRequestVeto(failedSubScenario);
    const editVetoName = `Edit${networkRequestVeto.name}` as EditVetoName;
    editVeto.name = editVetoName;
  }
  return editVeto;
}

export function getManageVeto(failedSubScenario: IFailedScenarioProperties): IVeto {
  const manageVeto: IVeto = {
    name: "PhotosWebAppUnknownManageError",
    errorCode: failedSubScenario.code
  };
  if (failedSubScenario.type === "networkRequest" || failedSubScenario.type === "operationExecute") {
    const networkRequestVeto = getNetworkRequestVeto(failedSubScenario);
    const manageVetoName = `Manage${networkRequestVeto.name}` as ManageVetoName;
    manageVeto.name = manageVetoName;
  }
  return manageVeto;
}

export function getSocialVeto(failedSubScenario: IFailedScenarioProperties): IVeto {
  const socialVeto: IVeto = {
    name: "PhotosWebAppUnknownSocialError",
    errorCode: failedSubScenario.code
  };
  if (failedSubScenario.type === "networkRequest" || failedSubScenario.type === "operationExecute") {
    const networkRequestVeto = getNetworkRequestVeto(failedSubScenario);
    const socialVetoName = `Social${networkRequestVeto.name}` as SocialVetoName;
    socialVeto.name = socialVetoName;
  }
  return socialVeto;
}
