import React from "react";
import { useTimeout } from "./usetimeout";
import { useTouchCapture } from "./usetouchcapture";

let touchCount = 0;

/**
 * The preventTouchMenu is used to prevent the context menu from appearing when the
 * user opens the context menu with a touch action.
 *
 * Some browsers will activate the context menu while holding the touch, we will use
 * the active touch count to prevent the menu as well.
 *
 * @param event Event from the onContextMenu handler.
 */
// istanbul ignore next - The test framework doesnt allow the mouseevent to be used as
//  a pointer event like the browser supports.
export function preventTouchMenu(event: React.MouseEvent | React.PointerEvent): void {
  if (touchCount || (event.nativeEvent as PointerEvent).pointerType === "touch") {
    event.preventDefault();
  }
}

export interface ILongTouchOptions {
  /**
   * How long does the caller have to hold the touch to generate a selection.
   *
   * @delay 500 (milliseconds)
   */
  holdDelay?: number;
}

export interface ITouchStatus {
  onTouchStart: (event: React.TouchEvent) => void;
}

export function useLongTouch(onTouchWithHold: (event: React.TouchEvent) => void, options?: ILongTouchOptions): ITouchStatus {
  const { holdDelay = 500 } = options || {};

  const touchPoint = React.useRef({ clientX: -1, clientY: -1, identifier: -1 });

  const { clearTimeout, setTimeout } = useTimeout();

  const onTouchEnd = React.useCallback(
    (event: TouchEvent) => {
      // Update the number of touches that are currently active.
      touchCount = event.touches.length;
      clearTimeout();
    },
    [clearTimeout]
  );

  const onTouchMove = React.useCallback(
    (event: TouchEvent) => {
      for (let touchIndex = 0; touchIndex < event.changedTouches.length; touchIndex++) {
        const changedTouch = event.changedTouches[touchIndex];

        if (changedTouch.identifier === touchPoint.current.identifier) {
          // If the user moves significantly while the touch is active we will cancel
          // timeout tracking the long touch.
          if (Math.abs(touchPoint.current.clientX - changedTouch.clientX) > 8 || Math.abs(touchPoint.current.clientY - changedTouch.clientY) > 8) {
            clearTimeout();
          }

          break;
        }
      }
    },
    [clearTimeout]
  );

  const { onTouchStart: onCaptureTouchStart } = useTouchCapture(onTouchMove, onTouchEnd);

  const onTouchStart = React.useCallback(
    (event: React.TouchEvent): void => {
      onCaptureTouchStart(event);

      if ((touchCount = event.touches.length) === 1) {
        const newTouch = event.touches[0];

        // Save the touchPoint where we started.
        touchPoint.current = { clientX: newTouch.clientX, clientY: newTouch.clientY, identifier: newTouch.identifier };

        setTimeout(() => {
          // Mark the touchEnd as preventDefault to prevent the right click menu.
          touchPoint.current = { clientX: -1, clientY: -1, identifier: -1 };

          // Call the users delegate.
          onTouchWithHold(event);
        }, holdDelay);
      } else {
        clearTimeout();
      }
    },
    [clearTimeout, holdDelay, onCaptureTouchStart, onTouchWithHold, setTimeout]
  );

  return { onTouchStart };
}
