import { LayoutAlgorithm } from "../types/layout";

const validAlgorithm: LayoutAlgorithm[] = ["grid", "masonry", "river"];

export function validateLayoutAlgorithm(layoutAlgorithm?: string): LayoutAlgorithm | undefined {
  if (layoutAlgorithm) {
    for (let index = 0; index < validAlgorithm.length; index++) {
      if (layoutAlgorithm === validAlgorithm[index]) {
        return layoutAlgorithm;
      }
    }
  }

  return undefined;
}
