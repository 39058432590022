import { IReadonlyObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";
import { ISettingsContext } from "../../common/contexts/settings";
import { IEventDispatch } from "../../common/utilities/dispatch";
import { updateEmailPreferences, updateFeaturePreferences, updatePhotoPreferences } from "../../photos/api/userpreferences";
import { IEmailPreferences, IFeaturePreferences, IPhotoPreferences } from "../types/userpreferences";
import { createRequest } from "../utilities/fetch";

/**
 * Context for tracking a user's service-side preferences
 */
export interface IUserPreferencesContext {
  /**
   * Gets the active user preferences
   *
   * @param type 'photo', 'email', or 'feature'
   */
  getUserPreferences(type: "photo"): IReadonlyObservableValue<IPhotoPreferences | null>;
  getUserPreferences(type: "email"): IReadonlyObservableValue<IEmailPreferences | null>;
  getUserPreferences(type: "feature"): IReadonlyObservableValue<IFeaturePreferences | null>;

  /**
   * Sets the active user preferences
   *
   * @param type 'photo', 'email', or 'feature'
   * @param userPreferences The photo, email, or feature settings preferences to update with
   * @returns A promise for the completion of the update.
   */
  setUserPreferences(type: "photo", userPreferences: IPhotoPreferences): Promise<void>;
  setUserPreferences(type: "email", userPreferences: IEmailPreferences): Promise<void>;
  setUserPreferences(type: "feature", userPreferences: IFeaturePreferences): Promise<void>;
}

export const UserPreferencesContext = React.createContext<IUserPreferencesContext>({
  getUserPreferences: () => new ObservableValue(null),
  setUserPreferences: () => Promise.resolve()
});

export class PhotoUserPreferencesContext implements IUserPreferencesContext {
  public photoPreferences: ObservableValue<IPhotoPreferences | null>;
  public emailPreferences: ObservableValue<IEmailPreferences | null>;
  public featurePreferences: ObservableValue<IFeaturePreferences | null>;

  private settingContext: ISettingsContext;

  private updatePhotoPreferences: (preferences: Partial<IPhotoPreferences>) => Promise<IPhotoPreferences>;
  private updateEmailPreferences: (preferences: Partial<IEmailPreferences>) => Promise<IEmailPreferences>;
  private updateFeaturePreferences: (preferences: Partial<IFeaturePreferences>) => Promise<IFeaturePreferences>;

  constructor(eventDispatch: IEventDispatch, settingContext: ISettingsContext) {
    this.settingContext = settingContext;

    const photoPrefSetting = settingContext.getSetting<IPhotoPreferences>("photoPreferences");
    const emailPrefSetting = settingContext.getSetting<IEmailPreferences>("emailPreferences");
    const featurePrefSetting = settingContext.getSetting<IFeaturePreferences>("featurePreferences");

    this.photoPreferences = new ObservableValue(photoPrefSetting ?? null);
    this.emailPreferences = new ObservableValue(emailPrefSetting ?? null);
    this.featurePreferences = new ObservableValue(featurePrefSetting ?? null);

    this.updatePhotoPreferences = createRequest(updatePhotoPreferences, { eventDispatch, name: "updateUserPhotoPreferences" });
    this.updateEmailPreferences = createRequest(updateEmailPreferences, { eventDispatch, name: "updateUserEmailPreferences" });
    this.updateFeaturePreferences = createRequest(updateFeaturePreferences, {
      eventDispatch,
      name: "updateUserFeaturePreferences"
    });
  }

  public getUserPreferences(type: "photo"): IReadonlyObservableValue<IPhotoPreferences | null>;
  public getUserPreferences(type: "email"): IReadonlyObservableValue<IEmailPreferences | null>;
  public getUserPreferences(type: "feature"): IReadonlyObservableValue<IFeaturePreferences | null>;
  public getUserPreferences(
    type: "photo" | "email" | "feature"
  ):
    | IReadonlyObservableValue<IPhotoPreferences | null>
    | IReadonlyObservableValue<IEmailPreferences | null>
    | IReadonlyObservableValue<IFeaturePreferences | null> {
    switch (type) {
      case "photo":
        return this.photoPreferences;
      case "email":
        return this.emailPreferences;
      case "feature":
        return this.featurePreferences;
    }
  }

  public setUserPreferences(type: "photo", userPreferences: IPhotoPreferences): Promise<void>;
  public setUserPreferences(type: "email", userPreferences: IEmailPreferences): Promise<void>;
  public setUserPreferences(type: "feature", userPreferences: IFeaturePreferences): Promise<void>;
  public setUserPreferences(
    type: "photo" | "email" | "feature",
    userPreferences: IPhotoPreferences | IEmailPreferences | IFeaturePreferences
  ): Promise<void> {
    switch (type) {
      case "photo":
        return this.updatePhotoPreferences(userPreferences as Partial<IPhotoPreferences>).then((result) => {
          this.photoPreferences.value = result;
          this.settingContext.setSetting("photoPreferences", result);
        });

      case "email":
        return this.updateEmailPreferences(userPreferences as Partial<IEmailPreferences>).then((result) => {
          this.emailPreferences.value = result;
          this.settingContext.setSetting("emailPreferences", result);
        });

      case "feature":
        return this.updateFeaturePreferences(userPreferences as Partial<IFeaturePreferences>).then((result) => {
          this.featurePreferences.value = result;
          this.settingContext.setSetting("featurePreferences", result);
        });
    }
  }
}
