import React from "react";
import { useEventListener } from "../../common/hooks/uselistener";
import { useTimeout } from "../../common/hooks/usetimeout";
import { getLayoutStyle, LayoutStyle } from "../utilities/layout";

export function useLayoutStyle(styleChanged?: (layoutStyle: LayoutStyle) => void): LayoutStyle {
  const layoutStyle = React.useRef(getLayoutStyle());
  const [, setCount] = React.useState(0);

  const { setTimeout } = useTimeout();

  // Signup for window size changes to determine the effect on the layout style.
  useEventListener(
    window,
    "resize",
    React.useCallback(() => {
      setTimeout(() => {
        const updatedLayoutStyle = getLayoutStyle();

        // Determine if the size change actually changed the layout style.
        if (updatedLayoutStyle !== layoutStyle.current) {
          // If the caller wanted to have a delef
          styleChanged && styleChanged(updatedLayoutStyle);

          // Update the layout style.
          layoutStyle.current = updatedLayoutStyle;
          setCount((count) => count + 1);
        }
      }, 20);
    }, [setCount, setTimeout, styleChanged])
  );

  return layoutStyle.current;
}
