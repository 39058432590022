import React from "react";

/**
 * Simple check used to determine if a navigation is using one of the meta forms.
 * Meta navigations are ones like open in New Window, New Tab, etc. These are
 * performed via middle click, Alt click, Ctrl click, Shift click, Meta click (Mac).
 *
 * @param event The keyboard or mouse click event that is triggering the navigation.
 * @returns true if this is a meta navigation, false otherwise.
 */
export function isMetaNavigation(event: KeyboardEvent | MouseEvent | React.KeyboardEvent | React.MouseEvent): boolean {
  // Note: Space event counts as a click event (with mouse button #4 - mouse wheel/middle button) when screen reader is on
  // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
  return event.type === "click" && ((event as MouseEvent).buttons === 4 || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey);
}
