// istanbul ignore file - We can't emulate element sizing & layout effectively
// so getting meaningful test results is extremely difficult. We will add some
// UX tests to show how this renders correctly.
import React from "react";
import { useResize } from "../../hooks/useresize";
import { useTimeout } from "../../hooks/usetimeout";

export interface IResponsiveLayoutProps {
  /**
   * The ResponsiveLayout component supports taking a single child and the child
   * must be a function that recieves an object with the responsiveIndex.
   *
   * @param responsiveIndex The index corresponds to the index of the last
   * hidden elements. If -1 is supplied no elements are currently hidden.
   */
  children: (props: { responsiveIndex: number }) => React.ReactElement;

  /**
   * The parent element that contains all responsive & ignored elements.
   */
  containerElement: React.RefObject<HTMLElement>;

  /**
   * Set of elements that are treated as responsive elements.
   * These elements are the ones that measured and added/removed as there is not
   * enough space available.
   */
  responsiveSelectors: string[];
}

/**
 * Used to create a responsive layout within an component. The caller should
 * supply all children that are "responsive" or hidable through the
 * responsiveSelectors parameter.
 *
 * Even though this components doesn't mount any elements, it is in the form of
 * a component. This allows the parent component to use the ResponsiveLayout to
 * isolate the state of this sub-system to this component instead of using a
 * hook in the parent.
 *
 * NOTE: This component while not complex has a fair number of requirements that
 * when not met will cause layout issues. The developer needs to ensure the
 * elements for the specified selectors do not change since the component reads
 * and caches the references. The selectors can identity one or more elements
 * and when more than one element is matched the group of elements is treated
 * as a single entitiy, all of the elements will have the same visibility.
 *
 */
export function ResponsiveLayout(props: IResponsiveLayoutProps): React.ReactElement {
  const { children, containerElement, responsiveSelectors } = props;

  // We track the responsiveIndex of the last completed render pass.
  const hiddenIndex = React.useRef(-1);

  const [, setCount] = React.useState(0);

  const { setTimeout } = useTimeout();

  // Signup for resizes on the containerElement.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useResize(containerElement, React.useCallback(processElement, [responsiveSelectors]));

  // Before going through and processing the elements for visibility lets make
  // sure the elements we think should be hidden are in fact hidden.
  React.useLayoutEffect(() => {
    const responsiveElements = getResponsiveElements();
    let responsiveIndex = hiddenIndex.current;

    // Reset the hidden class for currently hidden elements.
    while (responsiveIndex >= 0) {
      const elements = responsiveElements[responsiveSelectors[responsiveIndex--]];
      if (elements) {
        elements.forEach((element) => element.classList.add("hidden"));
      }
    }

    // After each render we need to determine if anything has changed.
    processElement();
  });

  // We just return the children, we don't mount any elements.
  return children({ responsiveIndex: hiddenIndex.current });

  function processElement() {
    const { current } = containerElement;
    const responsiveElements = getResponsiveElements();

    if (current) {
      let responsiveIndex = hiddenIndex.current;

      // If the container is overflowing, or we have hidden elements, we need to
      // determine whether or not we should show or hide any elements.
      if (current.scrollWidth > current.clientWidth) {
        while (responsiveIndex < responsiveSelectors.length - 1) {
          const elements = responsiveElements[responsiveSelectors[++responsiveIndex]];

          // We use the hidden class to hide the element.
          if (elements) {
            elements.forEach((element) => element.classList.add("hidden"));

            // Determine whether or not we have enough room now.
            if (current.scrollWidth <= current.clientWidth) {
              break;
            }
          }
        }
      } else {
        while (responsiveIndex >= 0) {
          const elements = responsiveElements[responsiveSelectors[responsiveIndex]];

          // If there is room in the container we will show the element.
          if (elements) {
            elements.forEach((element) => element.classList.remove("hidden"));

            // Check if adding this element fits, if not re-hide it and we are done.
            if (current.scrollWidth > current.clientWidth) {
              elements.forEach((element) => element.classList.add("hidden"));
              break;
            }
          }

          responsiveIndex--;
        }
      }

      if (responsiveIndex !== hiddenIndex.current) {
        hiddenIndex.current = responsiveIndex;
        setTimeout(() => setCount((count) => count + 1), 0);
      }
    }
  }

  function getResponsiveElements() {
    const { current } = containerElement;
    const responsiveElements: { [selector: string]: NodeListOf<HTMLElement> | null } = {};

    if (current) {
      // Lookup the responsive selectors to find the matching elements.
      for (let elementIndex = 0; elementIndex < responsiveSelectors.length; elementIndex++) {
        const selector = responsiveSelectors[elementIndex];
        const elements = current.querySelectorAll<HTMLElement>(selector);

        if (elements.length) {
          responsiveElements[selector] = elements;
        } else {
          responsiveElements[selector] = null;
        }
      }
    }

    return responsiveElements;
  }
}
