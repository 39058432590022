import React from "react";

export interface ISettingsContext {
  getSetting<T extends object>(settingName: string): T | undefined;
  mergeSetting<T extends object>(settingName: string, settingValue: Partial<T>): void;
  setSetting<T extends object>(settingName: string, settingValue: T | undefined): void;
}

export const SettingsContext = React.createContext<ISettingsContext>({
  getSetting: <T extends object>(settingName: string): T | undefined => undefined,
  mergeSetting: <T extends object>(settingName: string, settingValue: Partial<T> | undefined): void => {},
  setSetting: <T extends object>(settingName: string, settingValue: T | undefined): void => {}
});

export class LocaleStorageSettingsContext implements ISettingsContext {
  private settingsRoot: string | undefined = undefined;

  constructor(settingsRoot?: string) {
    this.settingsRoot = settingsRoot;
  }

  public getSetting<T extends object>(settingName: string): T | undefined {
    const underlyingValue = localStorage.getItem(this.getSettingPath(settingName));

    if (underlyingValue) {
      try {
        return JSON.parse(underlyingValue) as T;
      } catch {
        // If the setting stored in local storage is not valid JSON, we will
        // just eat the exception and return undefined.
      }
    }

    return undefined;
  }

  public mergeSetting<T extends object>(settingName: string, settingValue: Partial<T>): void {
    this.setSetting(settingName, Object.assign({}, this.getSetting(settingName), settingValue));
  }

  public setSetting<T extends object>(settingName: string, settingValue: T | undefined): void {
    localStorage.setItem(this.getSettingPath(settingName), JSON.stringify(settingValue));
  }

  private getSettingPath(settingName: string): string {
    return `${this.settingsRoot}${settingName}`;
  }
}
