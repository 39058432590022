export interface IGraphInnerError {
  code: string;
  date?: string;
  innererror?: IGraphInnerError;
  innerError?: IGraphInnerError; // This is due to a case issue with the ODC /drive API error object.
  "request-id"?: string;
}

export interface IGraphError {
  error: {
    code: string;
    innererror?: IGraphInnerError;
    innerError?: IGraphInnerError; // This is due to a case issue with the ODC /drive API error object.
    localizedMessage?: string;
    message: string;
  };

  statusCode: number;
}

/**
 * cancelResult can be used to mark an result as "canceled". This will prevent
 * the error from being displayed and should indicate that the error should no
 * longer be procesed. This is generally used during fetchTranlations to mark
 * results as canceled to prevent further processing.
 *
 * This will update the supplied result in-place so the incoming object will be
 * changed.
 *
 * @param result The rejected result that should be canceled. Note successfull
 * results can't be canceled. They should be translated to a rejected result.
 * @param The updated result with the cancelation.
 */
export function cancelResult(result: PromiseSettledResult<unknown>): PromiseSettledResult<unknown> {
  if (result.status === "rejected") {
    result.reason.isCanceled = true;
  }

  return result;
}

export function graphBlob(response: Response): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    if (response.ok) {
      response.blob().then(resolve, reject);
    } else {
      reject(response);
    }
  });
}

export function graphImage(response: Response): Promise<Blob | null> {
  return new Promise<Blob | null>((resolve, reject) => {
    if (response.ok) {
      response.blob().then(resolve, reject);
    } else if (response.status === 404) {
      resolve(null);
    } else {
      reject(response);
    }
  });
}

export function graphJSON<T>(response: Response): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    if (response.ok) {
      const contentType = response.headers.get("content-type");

      if (contentType && /application\/json/.test(contentType)) {
        response.json().then(resolve, reject);
      } else {
        reject(response);
      }
    } else {
      reject(response);
    }
  });
}

export function graphNoContent(response: Response): Promise<void> {
  return new Promise((resolve, reject) => {
    if (response.ok && response.status === 204) {
      resolve();
    } else {
      reject(response);
    }
  });
}

/**
 * Given a raw URL make the request and process the response with the given
 * response handler.
 *
 * @param fetch The fetch method used to make the network request
 * @param url The URL to execute the request against.
 * @param init Optional set of fetch options used to create the network request.
 * @params responseHandler The response handler to process the resposne. This either
 * uses the graphJSON handler by default.
 * @returns A Promise<T> created via the network request and response processing.
 */
export function graphRequest<T>(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  url: string,
  init?: RequestInit,
  responseHandler: (response: Response) => Promise<T> = graphJSON
): Promise<T> {
  if (init) {
    return fetch(url, init).then(responseHandler);
  } else {
    return fetch(url).then(responseHandler);
  }
}

/**
 * graphResponse is used to process a fetch response and either returned
 * the json deserialized object, or reject with an error. This will be some form
 * deserialized JSON error object, or core networking error.
 *
 * @param responsePromise The promise that resulted from the fetch request.
 * @param responseHandler The response handler to process the resposne. This either
 * uses the graphJSON handler by default.
 * @returns A Promise<T> created via the network request and response processing.
 */
export function graphResponse<T>(responsePromise: Promise<Response>, responseHandler: (response: Response) => Promise<T> = graphJSON): Promise<T> {
  return responsePromise.then(responseHandler);
}

/**
 * Walks a given Graph error (inner error) and finds if any of the provided
 * error codes are found, and if found, which error code. If no match then
 * undefined is returned.
 *
 * @param graphError The error to inspect
 * @param errorCodes A list of groups of error codes to match against
 * @returns The matched error code.
 */
export function hasError(graphError: Partial<IGraphError> | undefined, errorCodes: string[]): string | undefined {
  let innerError: IGraphInnerError | undefined = graphError?.error;
  while (innerError) {
    if (errorCodes.includes(innerError.code)) {
      return innerError.code;
    }

    innerError = innerError.innererror || innerError.innerError;
  }

  return undefined;
}
