import React from "react";

let _uniqueId = 0;

/**
 * useId is used to create a unique id for a component
 * @returns the unique id
 *
 * Note: this can be removed after react has been upgraded to v18^, because it has its own useId hook
 */
export function useId() {
  const [uniqueId] = React.useState(() => ++_uniqueId);

  return uniqueId;
}
