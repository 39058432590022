import { Button } from "azure-devops-ui/Button";
import { TextField } from "azure-devops-ui/TextField";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { EventContext } from "../../../common/contexts/event";
import { FeatureContext } from "../../../common/contexts/feature";
import { NavigationContext } from "../../../common/contexts/navigation";
import { useFocusTracker } from "../../../common/hooks/usefocustracker";
import { useObservableArray, useSubscription } from "../../../common/hooks/useobservable";
import { useTimeout } from "../../../common/hooks/usetimeout";
import { SearchContext } from "../../contexts/search";
import { UserPreferencesContext } from "../../contexts/userpreferences";
import { IRecognizedEntity } from "../../types/photo";
import * as Icons from "../illustration/icons";
import { ISearchDropdown, SearchDropdown } from "./searchdropdown";

import "./searchform.css";

const { ClearSearchButton, PreviewLabel, SearchButton } = window.Resources.SearchInput;

export interface ISearchForm {
  updateLayout: () => void;
}

export interface ISearchFormProps {
  /**
   * The string to initialize the search input with. This may be an applied
   * search, or a recommendation, etc.
   */
  activeSearch?: string;

  /**
   * Custom css className added to the root of the search form.
   */
  className?: string;

  /**
   * The seach form component offers an API through the componentRef prop. If
   * the caller wishes to access this API it should supply a reference.
   */
  componentRef?: React.MutableRefObject<ISearchForm | undefined>;

  /**
   * Should the search dropdown be available to the user.
   *
   * @default false
   */
  enableDropdown?: boolean;

  /**
   * Placeholder text for the search input.
   */
  placeholder?: string;
}

export interface ISearchFilter {
  active: boolean;
  displayName: string;
  id: string;
}

export interface IMatchedDateFilter extends ISearchFilter {
  filterType: "date";
  endDate: Date;
  startDate: Date;
}

export interface IMatchedPersonFilter extends ISearchFilter {
  filterType: "person";
  recognizedEntityId: string;
}

export type IMatchedFilter = IMatchedDateFilter | IMatchedPersonFilter;

export function SearchForm(props: ISearchFormProps): React.ReactElement {
  const { activeSearch = "", className, componentRef, enableDropdown = false, placeholder } = props;

  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);
  const navigationContext = React.useContext(NavigationContext);
  const searchContext = React.useContext(SearchContext);
  const userPreferencesContext = React.useContext(UserPreferencesContext);

  const formElement = React.useRef<HTMLFormElement>(null);
  const inputElement = React.useRef<HTMLTextAreaElement & HTMLInputElement>(null);
  const searchDropdown = React.useRef<ISearchDropdown>();

  const [searchText, setSearchText] = React.useState(activeSearch || "");
  const [showDropdown, setShowDropdown] = React.useState(false);

  const [matchedFilters] = useObservableArray<IMatchedFilter>([]);

  const { setTimeout: setCollapseTimeout } = useTimeout();
  const { setTimeout: setFocusTimeout } = useTimeout();
  const { setTimeout: setExpandTimeout } = useTimeout();
  const { setTimeout: setFilterTimeout } = useTimeout();

  const { onBlur, onFocus } = useFocusTracker({
    onFocusChange: (hasFocus) => {
      setExpandTimeout(() => setShowDropdown(enableDropdown && hasFocus), 0);
      updateMatchedFilters(searchText);
    }
  });

  const enableSearchFilters = useSubscription(featureContext.featureEnabled("enableSearchFilters"));
  const photoPreferences = useSubscription(userPreferencesContext.getUserPreferences("photo"));

  React.useImperativeHandle(componentRef, () => ({ updateLayout }));

  React.useEffect(() => {
    inputElement.current?.setAttribute("name", "q");
    inputElement.current?.setAttribute("enterkeyhint", "search");
  }, []);

  return (
    <form
      action="/search"
      className={css(className, "relative search-form flex-row flex-justify-center flex-grow", searchText && "search-term-available")}
      onBlur={onBlur}
      onClick={(event) => {
        if (!event.defaultPrevented) {
          inputElement.current?.focus();
          setExpandTimeout(() => setShowDropdown(enableDropdown), 0);
        }
      }}
      onFocus={onFocus}
      onKeyDown={(event) => {
        if (!event.defaultPrevented && enableDropdown) {
          if (event.key === "ArrowDown" || event.key === "ArrowUp") {
            setShowDropdown(true);
            event.preventDefault();

            // We need to delay the focus given that some of the scenarios
            // require the combobox to open first.
            setFocusTimeout(() => searchDropdown.current?.focus({ item: event.key === "ArrowDown" ? "first" : "last" }), 0);
          } else if (event.key === "Escape") {
            setShowDropdown(false);
          }
        }
      }}
      ref={formElement}
      role="search"
      method="get"
    >
      <TextField
        ariaControls={showDropdown ? "search-dropdown" : undefined}
        ariaExpanded={enableDropdown ? showDropdown : undefined}
        ariaHasPopup={enableDropdown ? true : undefined}
        className="search-text-field overflow-hidden"
        containerClassName="search-input flex-grow body-m overflow-hidden"
        inputClassName="overflow-hidden"
        inputElement={inputElement}
        onChange={(_event, value) => {
          setSearchText(value);
          setFilterTimeout(() => updateMatchedFilters(value), 300);
        }}
        onKeyDown={(event) => {
          if (!event.defaultPrevented) {
            // Using deprecated keyCode because enter is not capturing Go on mobile.
            if (event.key === "Enter") {
              if (searchText) {
                performSearch(event, searchText, "keyboard");
              }

              // Need to prevent default even without text to prevent form submission.
              event.preventDefault();
            }
          }
        }}
        placeholder={placeholder}
        prefixIconProps={{ render: (className) => <Icons.Search className={css(className, "flex-noshrink small")} /> }}
        role={enableDropdown ? "combobox" : undefined}
        suffixIconProps={{
          render: () => (
            <>
              <Button
                ariaLabel={ClearSearchButton}
                className={css("input-button", !searchText && "hidden")}
                iconProps={{ render: (className) => <Icons.Clear className={className} /> }}
                onClick={(event) => {
                  eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "clearSearch" });
                  setSearchText("");
                  inputElement.current?.focus();
                  event.preventDefault();
                }}
                subtle={true}
              />
              <Button
                ariaLabel={SearchButton}
                className={css("input-button search-arrow-button", !searchText && "hidden")}
                iconProps={{ render: (className) => <Icons.ArrowForward className={css("xsmall margin-4", className)} /> }}
                onClick={(event) => performSearch(event, searchText, "button")}
                primary={true}
              />
              {enableDropdown ? <span className="search-preview-label margin-horizontal-16 body-s">{PreviewLabel}</span> : null}
            </>
          )
        }}
        value={searchText}
      />
      {showDropdown ? (
        <SearchDropdown
          anchorElement={formElement}
          clearSearch={(searchTerm) => (searchTerm ? searchContext.removeSearch(searchTerm) : searchContext.clearSearches())}
          comboboxElement={inputElement}
          componentRef={searchDropdown}
          executeSearch={(value: string, mode: string) => performSearch(undefined, value, mode)}
          inspirations={searchContext.getSuggestions()}
          listId="search-dropdown"
          matchedFilters={matchedFilters}
          onDismiss={() => setShowDropdown(false)}
          recentSearches={searchContext.getSearches()}
        />
      ) : null}
    </form>
  );

  function performSearch(event: React.KeyboardEvent | React.MouseEvent | undefined, value: string, mode: string) {
    // We don't want any spacing at the start or end of the search terms.
    value = value.trim();

    // Update the component to show the current text and hide the dropdown when
    // a search is performed.
    setSearchText(value);

    // We need to delay the collapse since this will force focus to the dropdown
    // re-opening the dropdown after it closes.
    setCollapseTimeout(() => setShowDropdown(false), 1);

    // Save this search in the search context.
    searchContext.addSearch(value);

    // Update the matched filters based on the search text.
    updateMatchedFilters(value);

    const searchParams = new URLSearchParams();
    searchParams.append("q", value);
    matchedFilters.value.forEach((filter) => {
      if (filter.active) {
        if (filter.filterType === "date") {
          searchParams.append("displayDate", filter.displayName);
          searchParams.append("startDate", filter.startDate.toISOString());
          searchParams.append("endDate", filter.endDate.toISOString());
        } else if (filter.filterType === "person") {
          searchParams.append("personName", filter.displayName);
          searchParams.append("personId", filter.recognizedEntityId);
        }
      }
    });

    navigationContext.navigate(event, "navigateSearch", {
      href: navigationContext.prepare(`/search?${searchParams}`),
      telemetryProperties: {
        florenceSearch: photoPreferences?.florenceSearch || false,
        mode
      },
      useReplace: activeSearch ? true : false
    });
  }

  function updateMatchedFilters(searchText: string) {
    const currentFilters: IMatchedFilter[] = [];

    // If search filters are disabled, clear the matched filters and return.
    if (!enableSearchFilters) {
      matchedFilters.removeAll();
      return;
    }

    const recognizedEntities: IRecognizedEntity[] | undefined = []; // todo get recognized entities

    // Map the recognized entities in tuples for easy lookup based on name split on non-word chars
    const entitiesList: [string, IRecognizedEntity][] = [];
    recognizedEntities.forEach((entity) => {
      const partialNames = entity.identity.user.displayName?.split(/\W+/).filter((term) => term);
      if (partialNames) {
        partialNames.forEach((part) => {
          entitiesList.push([part.toLowerCase(), entity]);
        });
      }
    });

    // Split the search text into words
    const words = new Set(searchText.split(/\W+/).filter((term) => term));
    const matchedItems = new Set<string>();

    // Check for date filters across all words.
    words.forEach((word) => {
      const dateMatch = word.match(/\d{4}/);
      const personMatch = entitiesList.filter(([partialName]) => word.toLowerCase().localeCompare(partialName) === 0);

      if (dateMatch) {
        const displayName = dateMatch[0];
        if (!matchedItems.has(displayName)) {
          const year = parseInt(displayName);
          if (1900 < year && year < new Date().getFullYear() + 50) {
            const startDate = new Date(`${year}-01-01`);
            const endDate = new Date(`${year + 1}-01-01`);
            currentFilters.push({ filterType: "date", active: false, displayName, id: displayName, endDate, startDate });
            matchedItems.add(displayName);
          }
        }
      } else if (personMatch) {
        for (const [, entity] of personMatch) {
          if (!matchedItems.has(entity.id)) {
            currentFilters.push({
              filterType: "person",
              active: false,
              displayName: entity.identity.user.displayName || word,
              id: entity.id,
              recognizedEntityId: entity.id
            });
            matchedItems.add(entity.id);
          }
        }
      }
    });

    // Persist active state from the previous filters.
    matchedFilters.value.forEach((filter) => {
      const currentFilter = currentFilters.find((currentFilter) => currentFilter.id === filter.id);
      if (currentFilter) {
        currentFilter.active = filter.active;
      }
    });

    // Replace the matched filters with the new ones if they are different.
    if (filtersChanged(matchedFilters.value, currentFilters)) {
      matchedFilters.splice(0, matchedFilters.length, ...currentFilters);
    }

    function filtersChanged(filters1: IMatchedFilter[], filters2: IMatchedFilter[]): boolean {
      const set1 = new Set(filters1.map((filter) => filter.id));
      const set2 = new Set(filters2.map((filter) => filter.id));

      if (set1.size !== set2.size) {
        return true;
      }

      for (const filter of set1) {
        if (!set2.has(filter)) {
          return true;
        }
      }

      return false;
    }
  }

  function updateLayout() {
    searchDropdown.current?.updateLayout();
  }
}
