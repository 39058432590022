import React from "react";

interface IFocusCaptureContext {
  callbacks: Array<() => boolean | void>;
}

const FocusCaptureContext = React.createContext<IFocusCaptureContext>({ callbacks: [] });

export interface IFocusEvents {
  onBlur: (event: React.FocusEvent) => void;
  onFocus: (event: React.FocusEvent) => void;
}

export function useFocusCapture(defaultElement: React.RefObject<HTMLElement> | string | null | undefined): IFocusEvents {
  const focusCaptureContext = React.useContext(FocusCaptureContext);

  const lastFocusedElement = React.useRef<HTMLElement>();

  const resetFocus = React.useCallback(() => {
    for (let index = focusCaptureContext.callbacks.length - 1; index >= 0; index--) {
      if (focusCaptureContext.callbacks[index]()) {
        break;
      }
    }
  }, [focusCaptureContext]);

  React.useEffect(() => {
    let focusElement: HTMLElement | undefined | null;

    if (typeof defaultElement === "string") {
      focusElement = document.querySelector<HTMLElement>(defaultElement);
    } else {
      focusElement = defaultElement?.current;
    }

    if (focusElement) {
      focusElement.focus();
    }

    // We don't want to move focus if the defaultElement changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Add the callback to the set of existing callbacks.
    focusCaptureContext.callbacks.push(evaluateFocus);

    // Cleanup should remove the old callback from the set of callbacks.
    return () => {
      // Remove our handler from the list of capture callbacks.
      focusCaptureContext.callbacks.splice(focusCaptureContext.callbacks.indexOf(evaluateFocus), 1);

      // Perform a focus reset pass.
      resetFocus();
    };

    function evaluateFocus(): boolean | void {
      if (lastFocusedElement.current && document.contains(lastFocusedElement.current)) {
        lastFocusedElement.current.focus({ preventScroll: true });
        return true;
      }
    }
  }, [resetFocus, focusCaptureContext]);

  return { onBlur, onFocus };

  function onBlur(event: React.FocusEvent) {
    if (document.hasFocus() && !event.relatedTarget) {
      resetFocus();
    }
  }

  function onFocus(event: React.FocusEvent) {
    if (event.currentTarget.contains(event.target)) {
      lastFocusedElement.current = event.target as HTMLElement;
    } else {
      lastFocusedElement.current = undefined;
    }
  }
}
