import { Button } from "azure-devops-ui/Button";
import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { Clear, Filter, InlineHeader, Person } from "../illustration/icons";

const { ApplyButton, ClearButton } = window.Resources.Common;

interface ISearchFilterPillProps {
  /**
   * The display name of the filter.
   */
  displayName: string;
  /**
   * The type of the filter, e.g., date, person.
   */
  filterType: "date" | "person";

  /**
   * Whether the pill is active or not.
   */
  active?: boolean;

  /**
   * Callback function when the pill is clicked. If not passed, the pill is not clickable.
   */
  onClick?: () => void;
}

export function SearchFilterPill(props: ISearchFilterPillProps): React.ReactElement {
  const { displayName, filterType, active = false, onClick } = props;
  const [activeState, setActiveState] = React.useState(active);

  const clickable = !!onClick;

  const handleClick = () => {
    if (clickable) {
      onClick();
      setActiveState(!activeState);
    }
  };

  return (
    <Pill
      className={"overflow-hidden padding-4 " + (activeState && "bolt-pill-active")}
      contentClassName="flex-row flex-align-center bolt-pill-button"
      onClick={clickable ? handleClick : undefined}
      size={PillSize.large}
      variant={PillVariant.outlined}
    >
      <div className="flex-row flex-align-center">
        {filterType === "date" ? (
          <InlineHeader className="flex-noshrink" />
        ) : filterType === "person" ? (
          <Person className="flex-noshrink" />
        ) : (
          <Filter className="flex-noshrink" />
        )}
        <span className="flex-grow padding-horizontal-8 text-ellipsis">{displayName}</span>
        {clickable && (
          <Button
            ariaLabel={activeState ? ClearButton : ApplyButton}
            className="bolt-pill-button icon-only padding-horizontal-4"
            excludeTabStop={true}
            iconProps={{
              render: (className) => <Clear className={css("small", !activeState && "rotate-45", className)} />
            }}
            subtle={true}
          />
        )}
      </div>
    </Pill>
  );
}
