import { v4 } from "uuid";
import { supportsMediaSource } from "../../common/utilities/browser";
import { IDimensions, INetworkPermissions, IPermissions } from "../types/item";
import { INetworkPhoto, IPhoto } from "../types/photo";
import { IUserPreferences } from "../types/userpreferences";

/**
 * All photo API's go through one of the supported api domains. Depending on how
 * the service is configured these domains may vary.
 */
export interface IApiOrigin {
  authOrigin: string;
  badgerApiOrigin: string;
  feedbackOrigin: string;
  graphOrigin: string;
  odcOrigin: string;
  vroomOrigin: string;
  searchOrigin: string;
}

/**
 * Set of API versions used to configure different network calls.
 * These are mapped to different prefixes dependening on the network origin.
 */
export interface IApiVersion {
  apiVersion1: string;
  apiVersion2: string;

  // Specific API versions.
  accountVersion: string;
  downloadVersion: string;
  favoritesVersion: string;
  peopleVersion: string;
}

/**
 * Features supported by specific domains.
 */
export interface IDomainConfiguration {
  badger?: boolean;
  scope: string;
  supportsAccessParam?: boolean;
  supportsUMP?: boolean;
}

/**
 * Settings used to configure how the network layer integrates with each of the
 * target domains.
 */
export interface IDomainSettings {
  [hostName: string]: IDomainConfiguration;
}

export interface IManifestURLOptions {
  baseUrl?: URL;
  format?: "dash" | "hls" | "mp4";
  pretranscode?: string;
  transcodeahead?: string;
  params?: URLSearchParams | { [parameterName: string]: string };
}

// Setup our mapping of api name to endpoint mapping.
export const apiOriginODC: IApiOrigin = {
  authOrigin: "",
  badgerApiOrigin: "https://api-badgerp.svc.ms",
  feedbackOrigin: "https://storage.live.com",
  graphOrigin: "https://graph.microsoft.com",
  odcOrigin: "https://storage.live.com",
  vroomOrigin: "https://api.onedrive.com",
  searchOrigin: "https://api.onedrive.com"
};

export const apiOriginCOB: IApiOrigin = {
  authOrigin: "",
  badgerApiOrigin: "https://api-badgerp.svc.ms",
  feedbackOrigin: "https://storage.live.com",
  graphOrigin: "https://graph.microsoft.com",
  odcOrigin: "https://storage.live.com",
  vroomOrigin: "https://my.microsoftpersonalcontent.com",
  searchOrigin: "https://api.onedrive.com"
};

export const apiOriginSandbox: IApiOrigin = {
  authOrigin: "",
  badgerApiOrigin: "https://api-badgerp.svc.ms",
  feedbackOrigin: "https://storage.live.com",
  graphOrigin: "https://graph.microsoft.com",
  odcOrigin: "https://storage.live.com",
  vroomOrigin: "https://my.msftsptest.com",
  searchOrigin: "https://my.msftsptest.com"
};

export const apiVersionODC: IApiVersion = {
  apiVersion1: "v1.0",
  apiVersion2: "v1.0",

  // Specific API versions.
  accountVersion: "v2.1",
  downloadVersion: "v1.0",
  favoritesVersion: "v2.1",
  peopleVersion: "v2.1"
};

export const apiVersionCOB: IApiVersion = {
  apiVersion1: "_api/v2.0",
  apiVersion2: "_api/v2.1",

  // Specific API versions.
  accountVersion: "_api/v2.1",
  downloadVersion: "_api/v2.1",
  favoritesVersion: "_api/v2.1",
  peopleVersion: "_api/v2.1"
};

export const domainSettings: IDomainSettings = {
  "api.onedrive.com": { badger: true, scope: "OneDrive.ReadWrite", supportsAccessParam: true, supportsUMP: true },
  "graph.microsoft.com": { scope: "User.Read" },
  "my.microsoftpersonalcontent.com": { badger: true, scope: "OneDrive.ReadWrite", supportsUMP: true },

  // The scope for sandbox must be in lower case. Otherwise it would return 401.
  "my.msftsptest.com": { badger: true, scope: "onedrive-devbox.readwrite", supportsUMP: true },
  "storage.live.com": { scope: "OneDrive.ReadWrite", supportsAccessParam: true }
};

/**
 * Function to populate the required fields of url search params to fetch a manifest
 *
 * @param url baseUrl for the manifest
 * @param options
 */
export function addManifestParams(url: URL, options?: IManifestURLOptions): void {
  const params = getManifestParams(options ?? {});

  const entries = Array.from(params.entries());
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    url.searchParams.append(entry[0], entry[1]);
  }
}

const quoteExpression = /'/g;

/**
 * For OData strings that contain single quote (') these are escaped with double single quotes
 *
 * Example O'Fallon = O''Fallon
 *
 * @param value Raw input string with single quotes.
 * @returns The escapted string.
 */
export function escapeODataQuote(value: string): string {
  return value.replace(quoteExpression, "''");
}

/**
 * Function that returns required search parameters for the manifest file request
 *
 * @param options
 * @returns
 */
export function getManifestParams(options: IManifestURLOptions): URLSearchParams {
  const { params, format, pretranscode = "0", transcodeahead = "0" } = options;
  const _params = new URLSearchParams(params);

  _params.append("format", format ? format : supportsMediaSource() ? "dash" : "hls");
  _params.append("pretranscode", pretranscode);
  _params.append("transcodeahead", transcodeahead);
  _params.append("part", "index");
  _params.append("ccat", "2");
  _params.append("psi", v4());

  return _params;
}

// If a photo doesn't have dimensions we will add default dimensions.
export const defaultPhotoDimensions: IDimensions = { height: 512, width: 512 };

/**
 * Used to convert a network photo into a application photo. This involves
 * ensuring we have required properties having the best values available.
 *
 * @param networkPhoto Incoming network photo (INetworkPhoto)
 * @returns The prepared application photo (IPhoto)
 */
export function preparePhoto(networkPhoto: INetworkPhoto): IPhoto {
  const { image, thumbnails, video } = networkPhoto;
  let dimensions: IDimensions;

  // Make sure we have a photo property, the code depends on it in the future.
  if (!networkPhoto.photo) {
    networkPhoto.photo = {};
  }

  // Ensure the photo has a dimensions property with the best value we can
  // determine from the photo / video.
  if (image && image.height && image.width) {
    dimensions = { height: image.height, width: image.width };
  } else if (video) {
    dimensions = { height: video.height, width: video.width };
  } else if (thumbnails) {
    dimensions = { height: thumbnails[0].large.height, width: thumbnails[0].large.width };
  } else {
    // We will default to 512x512 if no size if given.
    dimensions = defaultPhotoDimensions;
  }

  // @CONVERGENCE: Location comes in two different formats. The legacy ODC
  // format and the converged format. The product is built off the legacy
  // version so we will convert the SPO format.
  const location = networkPhoto.location;

  if (location) {
    const coordinates = location.coordinates;

    if (coordinates) {
      location.altitude = coordinates.altitude;
      location.latitude = coordinates.latitude;
      location.longitude = coordinates.longitude;
    }
  }

  // Add the dimensions property to the photo.
  return Object.assign(networkPhoto, { dimensions });
}

/**
 * Function to replace grantedTo in permissions with the grantedToV2 if it exists
 *
 * @param permissions
 * @returns
 */
export function preparePermissions(permissions: INetworkPermissions): IPermissions {
  const updatedPermissions = [...permissions.value].map((permission) => {
    if (permission.grantedToV2) {
      permission.grantedTo = {
        user: permission.grantedToV2.siteUser
      };
    }

    return permission;
  });

  return { ...permissions, value: updatedPermissions };
}

export function prepareUserPreferences(preference: IUserPreferences): IUserPreferences {
  const { featureSettings = {} } = preference;
  const { faceExperience, florenceSearch, florenceSearchIngestion, ...filteredSettings } = featureSettings;

  // Ensure we have a photo object if one was not returned to copy potential
  // featureSettings into.
  if (faceExperience || florenceSearch) {
    if (!preference.photo) {
      preference.photo = {};
    }

    // If the face and florence feature values were returned in feature settings
    // we will move them to the photo preferences (ODB compat layer).
    if (faceExperience) {
      preference.photo.faceExperience = true;
    }

    if (florenceSearch) {
      preference.photo.florenceSearch = true;
    }
  }

  // Remove the properties we either don't want or moved.
  preference.featureSettings = filteredSettings;

  if (preference.photo) {
    // autoTagging is set to on by default so if this property is not set, it means the autoTagging is on
    preference.photo.autoTagging = preference.photo.autoTagging !== false;
  }

  return preference;
}
