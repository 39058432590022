import { css } from "azure-devops-ui/Util";
import React from "react";
import { useSubscription } from "../../../common/hooks/useobservable";
import { IDownloadOptions } from "../../api/photo";
import { usePhotoContent } from "../../hooks/usephotocontent";
import { BackgroundContext, IBackground } from "../../contexts/background";
import { IReadonlyObservableValue } from "azure-devops-ui/Core/Observable";
import { IDimensions } from "../../types/item";
import { fitToDimensions } from "../../utilities/image";

export interface IBackgroundProps {
  /**
   * A specific background to render, when it isn't supplied it will render the
   * background from the context for the specified name.
   */
  background?: IBackground | IReadonlyObservableValue<IBackground | undefined>;

  /**
   * The downloadPhoto function is used to retrieve the URL of the photo. It
   * can return a remote URL or one that references a local object created
   * via window.URL.createObjectURL.
   */
  downloadPhoto: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    photoId: string,
    options?: IDownloadOptions
  ) => Promise<Blob>;

  /**
   * Name of the background, this should be unique for the given usage.
   */
  name: string;
}

export function Background(props: IBackgroundProps): React.ReactElement | null {
  const { downloadPhoto, name } = props;

  const backgroundContext = React.useContext(BackgroundContext);
  const background = useSubscription(props.background || backgroundContext.getBackground(name));

  return background ? <BackgroundContent background={background} downloadPhoto={downloadPhoto} name={name} /> : null;
}

interface IBackgroundContentProps {
  /**
   * Properties of the basic background image.
   */
  background: IBackground;

  /**
   * The dimensions that should be downloaded. If no dimensions are supplied the
   * background will use the current browsers window size. If it changes
   * dramatically it will potentially download an updated version.
   */
  dimensinons?: IDimensions;

  /**
   * The downloadPhoto function is used to retrieve the URL of the photo. It
   * can return a remote URL or one that references a local object created
   * via window.URL.createObjectURL.
   */
  downloadPhoto: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    photoId: string,
    options?: IDownloadOptions
  ) => Promise<Blob>;

  /** Unique name of the background */
  name: string;
}

function BackgroundContent(props: IBackgroundContentProps): React.ReactElement | null {
  const { background, dimensinons = { height: window.outerHeight, width: window.outerWidth }, downloadPhoto, name } = props;
  const { attributes, customContent, overlayAttributes, photo } = background;

  // Create the image URL used to populate the background.
  const photoContent = usePhotoContent(photo.id, downloadPhoto, {
    photoScale: fitToDimensions(dimensinons.height, dimensinons.width, photo.dimensions.height, photo.dimensions.width)
  });

  const photoUrl = useSubscription(photoContent.url);
  const photoStatus = useSubscription(photoContent.status);

  // Create any custom elements if customization was supplied.
  const customElements = customContent ? (typeof customContent === "function" ? customContent(name) : customContent) : null;

  if (photoUrl && photoStatus === "fulfilled") {
    return (
      <div
        {...attributes}
        className={css(attributes?.className, `background-layer-${name}`, "background-layer absolute-fill")}
        style={{ backgroundImage: `url(${photoUrl})` }}
      >
        {customElements}
        <div {...overlayAttributes} className={css(overlayAttributes?.className, "background-layer-overlay absolute-fill")} />
      </div>
    );
  }

  return null;
}
