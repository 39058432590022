import { IReadonlyObservableValue } from "azure-devops-ui/Core/Observable";
import { css, getSafeId } from "azure-devops-ui/Util";
import React from "react";
import { useMouseCapture } from "../../../common/hooks/usemousecapture";
import { useSubscription } from "../../../common/hooks/useobservable";
import { useTimeout } from "../../../common/hooks/usetimeout";
import { useTouchCapture } from "../../../common/hooks/usetouchcapture";
import { preventDefault } from "../../../common/utilities/func";

import "./slider.css";

export interface ISliderProps {
  ariaLabel?: string;
  ariaValueText?: string;
  className?: string;
  delay?: number;
  excludeTabStop?: boolean;
  id?: string;
  max: number;
  min: number;
  onChange?: (value: number) => void;
  orientation?: "horizontal" | "vertical";
  pivot?: "center" | "end" | "start";
  step?: number;
  value: IReadonlyObservableValue<number> | number;
}

export function Slider(props: ISliderProps): React.ReactElement {
  const {
    ariaLabel,
    ariaValueText,
    className,
    delay,
    excludeTabStop,
    id,
    max,
    min,
    onChange,
    orientation = "horizontal",
    pivot = "start",
    step = 1
  } = props;
  const horizontal = orientation === "horizontal";

  const sliderElement = React.useRef<HTMLDivElement>(null);
  const targetValue = React.useRef<number | undefined>(undefined);

  const { setTimeout } = useTimeout({ preserve: true });

  const range = max - min;
  const midPoint = range / 2 + min;

  // Make sure we have an up to date value for the slider.
  const value = Math.max(min, Math.min(max, useSubscription(props.value)));

  const { onMouseDown } = useMouseCapture(
    (event) => !event.defaultPrevented && event.buttons === 1 && setValue(valueFromPoint(event.clientX, event.clientY))
  );
  const { onTouchStart } = useTouchCapture(
    (event) => !event.defaultPrevented && setValue(valueFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY))
  );

  // Make sure our thumb is rendered in the proper location based on layout.
  const thumbPosition = ((value - min) / range) * 100 + "%";
  const thumbStyle: React.CSSProperties = horizontal ? { insetInlineStart: thumbPosition } : { top: thumbPosition };
  const orientationClassName = horizontal ? "flex-row" : "flex-column";

  return (
    <div
      aria-label={ariaLabel}
      aria-valuenow={value}
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuetext={ariaValueText}
      className={css(
        `bolt-slider bolt-slider-pivot-${pivot} bolt-slider-orient-${orientation}`,
        className,
        orientationClassName,
        "relative flex-align-center flex-grow"
      )}
      id={getSafeId(id)}
      onClick={(event) => {
        if (!event.defaultPrevented) {
          setValue(valueFromPoint(event.clientX, event.clientY));
          event.preventDefault();
        }
      }}
      onDragStart={preventDefault}
      onKeyDown={onKeyDown}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      ref={sliderElement}
      role="slider"
      tabIndex={excludeTabStop ? undefined : 0}
    >
      <span className={css("bolt-slider-track bolt-slider-track-leading", orientationClassName, "flex-align-center pre-midpoint pre-value")} />
      <div className={css("bolt-slider-track", orientationClassName, "flex-align-center flex-grow relative")}>
        {value < midPoint ? (
          <>
            <span
              className={css("bolt-slider-track", orientationClassName, "flex-align-center pre-midpoint pre-value")}
              style={getSize(Math.min(50, (value - min) / range) * 100)}
            />
            <span
              className={css("bolt-slider-track", orientationClassName, "flex-align-center pre-midpoint post-value")}
              style={getSize((0.5 - Math.min(0.5, (value - min) / range)) * 100)}
            />
            <span className={css("bolt-slider-track", orientationClassName, "flex-align-center post-midpoint post-value")} style={getSize(50)} />
          </>
        ) : value === midPoint ? (
          <>
            <span className={css("bolt-slider-track", orientationClassName, "flex-align-center pre-midpoint pre-value")} style={getSize(50)} />
            <span />
            <span className={css("bolt-slider-track", orientationClassName, "flex-align-center post-midpoint post-value")} style={getSize(50)} />
          </>
        ) : (
          <>
            <span className={css("bolt-slider-track", orientationClassName, "flex-align-center pre-midpoint pre-value")} style={getSize(50)} />
            <span
              className={css("bolt-slider-track", orientationClassName, "flex-align-center post-midpoint pre-value")}
              style={getSize(Math.min(50, (value - midPoint) / range) * 100)}
            />
            <span
              className={css("bolt-slider-track", orientationClassName, "flex-align-center post-midpoint post-value")}
              style={getSize((0.5 - Math.min(0.5, (value - midPoint) / range)) * 100)}
            />
          </>
        )}
        <span className="bolt-slider-thumb absolute" key="thumb" onClick={preventDefault} style={thumbStyle} />
      </div>
      <span className={css("bolt-slider-track bolt-slider-track-trailing", orientationClassName, "flex-align-center post-midpoint post-value")} />
    </div>
  );

  function getSize(size: number): { height?: string; width?: string } {
    return horizontal ? { width: size + "%" } : { height: size + "%" };
  }

  function onKeyDown(event: React.KeyboardEvent) {
    let change = 0;

    // When the document is in RTL we need to reverse the direction.
    const multiplier = document.documentElement.dir === "rtl" ? -1 : 1;

    // Determine if the keystroke should affect a change.
    if (event.key === "ArrowLeft" || event.key === "ArrowDown") {
      change = -step;
      event.preventDefault();
    } else if (event.key === "ArrowRight" || event.key === "ArrowUp") {
      change = step;
      event.preventDefault();
    }

    // Update the caller with the latest value on change.
    if (change) {
      setValue(value + change * multiplier);
    }
  }

  function setValue(newValue: number): void {
    // Update the caller with the latest value on change.
    newValue = Math.min(max, Math.max(min, newValue));

    if (onChange && newValue !== value && newValue !== targetValue.current) {
      if (delay) {
        targetValue.current = newValue;

        setTimeout(() => {
          onChange(targetValue.current!);
          targetValue.current = undefined;
        }, delay);
      } else {
        onChange(newValue);
      }
    }
  }

  function valueFromPoint(clientX: number, clientY: number): number {
    // istanbul ignore else - Con't figure out how to test this, we have live site errors.
    if (sliderElement.current) {
      const clientRect = sliderElement.current.getBoundingClientRect();

      // Value = MinValue + rounded(stepCount * clickPercentage) * stepValue
      let value: number;

      if (horizontal) {
        const clickLocation = (clientX - clientRect.x) / clientRect.width;
        if (document.documentElement.dir === "rtl") {
          value = max - Math.round((range / step) * clickLocation) * step;
        } else {
          value = min + Math.round((range / step) * clickLocation) * step;
        }
      } else {
        const clickLocation = (clientY - clientRect.y) / clientRect.height;
        value = min + Math.round((range / step) * clickLocation) * step;
      }

      return value;
    }

    // istanbul ignore next - Con't figure out how to test this, we have live site errors.
    return min;
  }
}
