import { Button } from "azure-devops-ui/Button";
import { Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { Callout } from "../../../common/components/callout/callout";
import { lazy } from "../../../common/components/lazy/lazy";
import { Scenario } from "../../../common/components/scenario/scenario";
import { EventContext } from "../../../common/contexts/event";
import { SettingsContext } from "../../../common/contexts/settings";
import { getCookie } from "../../../common/utilities/browser";
import { noop } from "../../../common/utilities/func";
import { IEventDispatch } from "../../../common/utilities/platformdispatch";
import { FeedbackContext, IFeedbackSettings, limitedFeedbackAppearancePages } from "../../contexts/feedback";
import { ISessionContext, SessionContext } from "../../contexts/session";
import * as Icons from "../illustration/icons";
import "./beta.css";

const { SettingsButton, ShareFeedback, PositiveFeedback, NegativeFeedback } = window.Resources.Common;

const timeGapForFeedbackSubmission = 1000 * 3600 * 24 * 14;

const BetaSettingsDialog = lazy(() => import("../../dialogs/betasettingsdialog"));
const ViewFeedbackDialog = lazy(() => import("../../dialogs/viewfeedbackdialog"));

export interface IBetaProps {}
type FeedbackType = "Like" | "Dislike";

export function Beta(props: IBetaProps): React.ReactElement | null {
  const eventContext = React.useContext(EventContext);
  const feedbackContext = React.useContext(FeedbackContext);
  const sessionContext = React.useContext(SessionContext);
  const settingsContext = React.useContext(SettingsContext);

  const betaElement = React.useRef<HTMLDivElement>(null);

  const [, setCount] = React.useState(0);
  const [showFeedback, setShowFeedback] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [feedbackType, setFeedbackType] = React.useState<FeedbackType>("Like");

  React.useEffect(() => {
    eventContext.addEventListener("feedbackCustomized", feedbackCustomized);

    return () => eventContext.removeEventListener("feedbackCustomized", feedbackCustomized);

    function feedbackCustomized() {
      setCount((count) => count + 1);
    }
  }, [eventContext, feedbackContext, setCount]);

  const development = sessionContext.environment === "development" || sessionContext.ring === "dogfood" || getCookie("Development");

  // Determine whether or not there is a customized toggle UX.
  const customizedFeedback = feedbackContext.customizeFeedback("toggle", feedbackType);

  // Determine whether or not to show feedback control
  const currentPage = sessionContext.getPage().current;
  const feedbackSettings = settingsContext.getSetting<IFeedbackSettings>("feedback");

  const showFeedbackControl =
    (!limitedFeedbackAppearancePages.has(currentPage) ||
      feedbackSettings === undefined ||
      feedbackSettings.lastProvided === undefined ||
      Date.now() > feedbackSettings.lastProvided + timeGapForFeedbackSubmission) &&
    (feedbackSettings === undefined || feedbackSettings.show);

  if (showFeedbackControl || development) {
    return (
      <>
        <Callout
          ariaLabel={ShareFeedback}
          className="beta-callout flex-row flex-align-end flex-justify-end"
          contentClassName="beta-callout-content depth-16"
          fixedLayout={true}
          onDismiss={noop}
          portalProps={{ className: "beta-portal" }}
          role="banner"
        >
          <div
            className="flex-row flex-gap-4 flex-align-center padding-left-12 padding-right-4 padding-vertical-4 white-space-pre-wrap"
            ref={betaElement}
          >
            {customizedFeedback?.content || <span className="margin-right-4">{ShareFeedback}</span>}
            <Button
              ariaLabel={PositiveFeedback}
              className="positive-feedback transparent"
              iconProps={{
                render: (className?: string) => <Icons.ThumbsUp className={className} />
              }}
              onClick={(event) => giveFeedback(event, eventContext, sessionContext, "Like", setFeedbackType, setShowFeedback)}
            />
            <Button
              ariaLabel={NegativeFeedback}
              className="negative-feedback transparent"
              iconProps={{
                render: (className?: string) => <Icons.ThumbsDown className={className} />
              }}
              onClick={(event) => giveFeedback(event, eventContext, sessionContext, "Dislike", setFeedbackType, setShowFeedback)}
            />
            {development && (
              <>
                <div className="separator-line-left" />
                <Button
                  ariaHasPopup={true}
                  ariaLabel={SettingsButton}
                  className="rounded-8"
                  iconProps={{ render: (className: string) => <Icons.Settings className={className} /> }}
                  onClick={() => {
                    eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "openBetaSettings" });
                    setShowSettings(true);
                  }}
                  subtle={true}
                />
              </>
            )}
          </div>
        </Callout>
        {showFeedback && (
          <React.Suspense fallback={null}>
            <Scenario scenarioName="feedbackdialog">
              <ViewFeedbackDialog onDismiss={() => setShowFeedback(false)} feedbackType={feedbackType} />
            </Scenario>
          </React.Suspense>
        )}
        {showSettings && betaElement.current && (
          <React.Suspense fallback={null}>
            <Scenario scenarioName="betasettingsdialog">
              <BetaSettingsDialog
                anchorElement={betaElement.current}
                anchorOrigin={{ horizontal: Location.end, vertical: Location.start }}
                calloutOrigin={{ horizontal: Location.end, vertical: Location.end }}
                onDismiss={() => setShowSettings(false)}
              />
            </Scenario>
          </React.Suspense>
        )}
      </>
    );
  } else {
    return null;
  }
}

function giveFeedback(
  event: any,
  eventContext: IEventDispatch,
  sessionContext: ISessionContext,
  feedbackType: FeedbackType,
  setFeedbackType: (feedbackType: FeedbackType) => void,
  setShowFeedback: (showFeedback: boolean) => void
) {
  eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "giveFeedback", value: feedbackType });

  // Only show the feedback dialog if the user is age appropriate.
  switch (sessionContext.graphProfile.value?.ageGroup) {
    case "0": // Undefined
    case "3": // Adult
    case "4": // NotAdult:
      setFeedbackType(feedbackType);
      setShowFeedback(true);

      // Prevent navigation until we close the feedback dialog.
      event.preventDefault();
      break;

    default:
  }
}
