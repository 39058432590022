import { IReadonlyObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import { IExperiment, IFeature, IFeatureContext, IFeatureOptions } from "../../common/contexts/feature";
import { ISettingsContext } from "../../common/contexts/settings";
import { getCookie } from "../../common/utilities/browser";
import { ISessionInformation } from "./session";

const {
  PhotoLayoutCategory,
  CompactLayoutDescription,
  CompactLayoutTitle,
  GroupPhotosDescription,
  GroupPhotosTitle,
  InlineDateDescription,
  InlineDateTitle
} = window.Resources.Feature;

interface _IFeature extends Exclude<IFeature, "featureEnabled"> {
  featureEnabled: ObservableValue<boolean>;
}

interface _IExperiment<T> extends Exclude<IExperiment<T>, "experimentGroup"> {
  experimentGroup: ObservableValue<T>;
}

export class PhotoFeatureContext implements IFeatureContext {
  private disabledFeature = new ObservableValue(false);
  private settingsContext: ISettingsContext | undefined;

  // Set of features available to the user.
  private _features: { [featureId: string]: _IFeature } = {};
  private _experiments: { [experimentId: string]: _IExperiment<any> } = {};

  constructor(settingsContext?: ISettingsContext, sessionInformation?: ISessionInformation) {
    const development = getCookie("Development");
    const pseudoLoc = getCookie("PseudoLoc");
    const includeDevelopment = window.location.hostname === "localhost" || !!development;

    // Add the standard features.
    Object.assign(this._features, {
      /* Features for the all photos layout */
      autoGroupPhotos: {
        featureCategory: PhotoLayoutCategory,
        featureDescription: GroupPhotosDescription,
        featureEnabled: new ObservableValue<boolean>(false),
        featureName: GroupPhotosTitle,
        userVisible: false
      },
      backgroundImage: {
        featureCategory: "General",
        featureDescription: "Allow the user to select an image to show as the background of the application.",
        featureEnabled: new ObservableValue(false),
        featureName: "Custom backgrounds",
        userVisible: true
      },
      excludeTagsFromAllPhotosClient: {
        featureCategory: "General",
        featureDescription: "Speed up All Photos View by not fetching tag metadata",
        featureEnabled: new ObservableValue(true),
        featureName: "Exclude Tags from All Photos View",
        userVisible: true
      },
      searchDebug: {
        featureCategory: "General",
        featureDescription: "Show details about how photos were matched in the search results",
        featureEnabled: new ObservableValue<boolean>(false),
        featureName: "Show Search Metadata",
        userVisible: true
      },
      showAlbums: {
        featureCategory: "General",
        featureDescription: "Show the albums pivot.",
        featureEnabled: new ObservableValue<boolean>(true),
        featureName: "Show Albums",
        userVisible: true
      },
      showCompactLayout: {
        featureCategory: PhotoLayoutCategory,
        featureDescription: CompactLayoutDescription,
        featureEnabled: new ObservableValue<boolean>(true),
        featureName: CompactLayoutTitle,
        userVisible: false
      },
      showFilenames: {
        featureCategory: PhotoLayoutCategory,
        featureDescription: "",

        // Default to filenames visible when embedded.
        featureEnabled: new ObservableValue<boolean>(!!sessionInformation?.embedded),
        userVisible: false
      },
      showHeaders: {
        featureCategory: PhotoLayoutCategory,
        featureDescription: InlineDateDescription,
        featureEnabled: new ObservableValue<boolean>(true),
        featureName: InlineDateTitle,
        userVisible: false
      },
      showHome: {
        featureCategory: "General",
        featureDescription: "Enable the Home pivot",
        featureEnabled: new ObservableValue<boolean>(false),
        featureName: "Show home pivot",
        userVisible: true
      },
      throttleNetwork: {
        featureCategory: "General",
        featureDescription: "Throttle the network requests based on rate limits.",
        featureEnabled: new ObservableValue<boolean>(true),
        featureName: "Throttle network requests",
        transient: true,
        userVisible: true
      },
      timelineDrag: {
        featureCategory: "General",
        featureDescription: "Enable live updates when dragging along the timeline",
        featureEnabled: new ObservableValue<boolean>(true),
        featureName: "Timeline drag",
        userVisible: true
      },
      useSandbox: {
        featureCategory: "Network",
        featureDescription: "Use the sandbox API endpoint for VRoom COB requests.",
        featureEnabled: new ObservableValue<boolean>(false),
        featureName: "Use Sandbox",
        userVisible: true
      },
      useSearchV21API: {
        featureCategory: "Network",
        featureDescription: "When available, use the Vroom 2.1 Search API",
        featureEnabled: new ObservableValue<boolean>(false),
        featureName: "Use Search V2.1 API",
        userVisible: true
      },
      enableSearchFilters: {
        featureCategory: "General",
        featureDescription: "Enable search filters",
        featureEnabled: new ObservableValue<boolean>(false),
        featureName: "Search Filters",
        userVisible: true
      },
      iframeReportAbuse: {
        featureCategory: "General",
        featureDescription: "Use iframe-based report abuse dialog.",
        featureEnabled: new ObservableValue<boolean>(false),
        featureName: "iFrame Report Abuse",
        userVisible: true
      }
    });

    if (includeDevelopment) {
      Object.assign(this._features, {
        breakOnTTI: {
          featureCategory: "Developer Only",
          featureDescription: "Break into the debugger when UX scenarios are marked complete. MUST have developer tools open for this to work!",
          featureEnabled: new ObservableValue<boolean>(false),
          featureName: "Break on TTI",
          userVisible: true
        },
        fullSearchResults: {
          featureCategory: "Developer Only",
          featureDescription: "Show the entire search result set when using ACS",
          featureEnabled: new ObservableValue<boolean>(false),
          featureName: "Full search results",
          userVisible: true
        },
        pseudoLoc: {
          featureCategory: "Developer Only",
          featureDescription:
            "Enable pseudo localization. This helps developers ensure the product is prepared for localization. NOTE: This requires a pseudo-loc build.",
          featureEnabled: new ObservableValue<boolean>(!!pseudoLoc),
          featureName: "Enable pseudo localization",
          requireRefresh: true,
          userVisible: true
        },
        useOnePlayer: {
          featureCategory: "General",
          featureDescription: "Use OnePlayer to play videos",
          featureEnabled: new ObservableValue<boolean>(false),
          featureName: "Use OnePlayer",
          transient: true,
          userVisible: true
        }
      });
    }

    // istanbul ignore else - Ignore the else case we always test with NODE_ENV === "test".
    if (process.env.NODE_ENV === "test") {
      Object.assign(this._features, {
        testFeature: {
          featureCategory: "Testing",
          featureDescription: "Feature to verify non user visible features in tests.",
          featureEnabled: new ObservableValue<boolean>(true),
          featureName: "Hidden Feature",
          userVisible: false
        },
        testTransient: {
          featureCategory: "Transient",
          featureDescription: "Feature to verify transient feature states.",
          featureEnabled: new ObservableValue<boolean>(true),
          featureName: "Hidden Feature",
          transient: true,
          userVisible: false
        }
      });
    }

    // Add any server ramps, this should be done before settings are loaded,
    // this will allow non-transient settings to override server ramps.
    // NOTE: If you want local control over a server ramp one can be added.
    if (sessionInformation) {
      Object.keys(sessionInformation.ramps).forEach((rampName) => {
        // Update a standard feature state if the server returns it as a ramp
        if (this._features[rampName]) {
          this._features[rampName].featureEnabled.value = sessionInformation.ramps[rampName];
        } else {
          this._features[rampName] = {
            featureCategory: "Server ramp",
            featureDescription: "",
            featureEnabled: new ObservableValue(sessionInformation.ramps[rampName]),
            featureName: rampName,
            transient: true,
            userVisible: includeDevelopment
          };
        }
      });

      Object.keys(sessionInformation.experiments!).forEach((experimentName) => {
        this._experiments[experimentName] = {
          description: "",
          experimentGroup: new ObservableValue(sessionInformation.experiments![experimentName])
        };
      });
    }

    // Initialize the features and experiments from the settingsContext.
    if (settingsContext) {
      try {
        const featuresObject = settingsContext.getSetting<{ [setting: string]: boolean }>("featureStates");

        if (featuresObject) {
          for (const featureName in featuresObject) {
            const feature = this._features[featureName];

            if (feature && !feature.transient) {
              feature.featureEnabled.value = !!featuresObject[featureName];
            }
          }
        }

        const experimentsObject = settingsContext.getSetting<{ [setting: string]: boolean }>("experimentStates");

        if (experimentsObject) {
          for (const experimentName in experimentsObject) {
            const experiment = this._experiments[experimentName];

            if (experiment) {
              experiment.experimentGroup.value = experimentsObject[experimentName];
            }
          }
        }
      } catch {
        // Ignore failures and default to default settings.
      }
    }

    // Save the context which we will use to update our persisted state.
    this.settingsContext = settingsContext;
  }

  public get experiments(): { [experimentId: string]: IExperiment<any> } {
    return this._experiments;
  }

  public get features(): { [featureId: string]: IFeature } {
    return this._features;
  }

  public getFeatureStates(): { [featureId: string]: boolean } {
    const featureStates: { [featureId: string]: boolean } = {};

    // Create a plain object with "feature: state" for each feature.
    for (const featureName in this._features) {
      featureStates[featureName] = this._features[featureName].featureEnabled.value;
    }

    return featureStates;
  }

  /**
   * Returns the experiment group the user is participating in given an experiment id. Since the
   * value of the experiment can be anything, a type must be passed. If it fails to parse the type
   * this will return undefined, in which case caller can choose how to treat it (usually should show
   * control)
   *
   * @param experimentId
   * @returns
   */
  public experimentGroup<T>(experimentId: string): IReadonlyObservableValue<T | undefined> {
    const experiment = this._experiments[experimentId];

    if (experiment) {
      return experiment.experimentGroup as ObservableValue<T>;
    }

    // Warn the caller if the experiment they requested was not a defined experiment.
    // istanbul ignore next - warn for local development only
    if (window.location.hostname === "localhost") {
      console.warn(`Requested experiment ${experimentId} is not currently defined.`);
    }

    return new ObservableValue(undefined);
  }

  public featureEnabled(featureId: string, _options?: IFeatureOptions): IReadonlyObservableValue<boolean> {
    const feature = this._features[featureId];

    if (feature) {
      return feature.featureEnabled;
    }

    // Warn the caller if the feature they requested was not a defined feature.
    if (window.location.hostname === "localhost") {
      console.warn(`Requested feature ${featureId} is not currently defined.`);
    }

    return this.disabledFeature;
  }

  public setExperimentGroup<T>(experimentId: string, experimentValue: T): void {
    if (this._experiments[experimentId]) {
      this._experiments[experimentId].experimentGroup.value = experimentValue;

      // Update the user settings with the new state of the features.
      if (this.settingsContext) {
        const settings: { [setting: string]: T } = {};

        // Create a plain object with "feature: state" for each feature.
        for (const experimentName in this._experiments) {
          const experiment = this._experiments[experimentName];

          settings[experimentName] = experiment.experimentGroup.value;
        }

        this.settingsContext.setSetting("experimentStates", settings);
      }
    }
  }

  public setFeatureEnabled = (featureId: string, featureEnabled: boolean): void => {
    if (this._features[featureId]) {
      this._features[featureId].featureEnabled.value = featureEnabled;

      // Update the user settings with the new state of the features.
      if (this.settingsContext) {
        const settings: { [setting: string]: boolean } = {};

        // Create a plain object with "feature: state" for each feature.
        for (const featureName in this._features) {
          const feature = this._features[featureName];

          if (!feature.transient) {
            settings[featureName] = feature.featureEnabled.value;
          }
        }

        this.settingsContext.setSetting("featureStates", settings);
      }
    }
  };
}
