import { useSetting } from "./usesetting";

export function useZoom(setZoomLevel: (value: number) => void, centerOnItem: () => void) {
  const [, setUxSetting] = useSetting("uxSetting");

  return { zoom };

  function zoom(value: number): void {
    centerOnItem();

    // Update the setting with the updated zoomLevel.
    setUxSetting({ zoomLevel: value });
    setZoomLevel(value);
  }
}
