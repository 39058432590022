import React from "react";
import { IScenario } from "../utilities/scenario";
import { noop } from "../utilities/func";

/**
 * The IScenarioContext is used to track the active scenario for the current
 * state. You can create scenarios using the Scenario component which will
 * automatically update the context or useScenario to create a new scenario
 * and optionally set it as the active scenario.
 */
export interface IScenarioContext {
  getScenario: () => IScenario<unknown> | void;
}

export const ScenarioContext = React.createContext<IScenarioContext>({ getScenario: noop });
