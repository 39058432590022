import { Button } from "azure-devops-ui/Button";
import { Link } from "azure-devops-ui/Link";
import { MenuButton } from "azure-devops-ui/Menu";
import { css, getSafeId, getSafeIdSelector } from "azure-devops-ui/Util";
import React from "react";
import { lazy } from "../../common/components/lazy/lazy";
import { Observer } from "../../common/components/observer/observer";
import { ResponsiveLayout } from "../../common/components/responsivelayout/responsivelayout";
import { Scenario } from "../../common/components/scenario/scenario";
import { useFocusTracker } from "../../common/hooks/usefocustracker";
import { useObservable, useSubscription } from "../../common/hooks/useobservable";
import { useResize } from "../../common/hooks/useresize";
import { getFocusElements } from "../../common/utilities/focus";
import * as Icons from "../components/illustration/icons";
import { ISearchForm, SearchForm } from "../components/search/searchform";
import { SessionContext } from "../contexts/session";
import { UserPreferencesContext } from "../contexts/userpreferences";
import { useLayoutStyle } from "../hooks/uselayoutstyle";
import { getActiveSearch } from "../utilities/util";
import { NpmMeControlContainer } from "./mecontrol/mecontrolcontainer";
import { IMeControl } from "./mecontrol/mecontroltypes";

import "./header.css";

declare global {
  interface Window {
    MeControl?: IMeControl;
  }
}

const { MoreButton, SignIn } = window.Resources.Common;
const { AppLauncher, HelpAltText, SettingsAltText, UpgradeAltText } = window.Resources.Header;
const { MobileSearchPlaceholder, SearchButton, SearchPlaceholder, SearchPlaceholder2 } = window.Resources.SearchInput;

const ApplicationPanel = lazy(() => import("../panels/apppanel"));
const HelpPanel = lazy(() => import("../panels/helppanel"));
const SettingsPanel = lazy(() => import("../panels/settingspanel"));

export interface IHeaderProps {
  "aria-hidden"?: boolean;
  className?: string;
}

export function Header(props: IHeaderProps): React.ReactElement {
  const { className } = props;

  const sessionContext = React.useContext(SessionContext);
  const userPreferencesContext = React.useContext(UserPreferencesContext);

  const responsiveElement = React.useRef<HTMLDivElement>(null);
  const searchElement = React.useRef<HTMLDivElement>(null);
  const searchForm = React.useRef<ISearchForm>();

  const [searchActive, setSearchActive] = useObservable(false);
  const [showApplication, setShowApplication] = useObservable(false);
  const [showHelp, setShowHelp] = useObservable(false);
  const [showSettings, setShowSettings] = useObservable(false);

  const layoutStyle = useLayoutStyle();
  const mobileLayout = layoutStyle === "mobile";

  const { onBlur, onFocus } = useFocusTracker({ onFocusChange: setSearchActive });
  const photoPreferences = useSubscription(userPreferencesContext.getUserPreferences("photo"));

  useResize(
    responsiveElement,
    React.useCallback(() => searchForm.current?.updateLayout(), [])
  );

  return (
    <ResponsiveLayout containerElement={responsiveElement} responsiveSelectors={[getSafeIdSelector("header-commands"), getSafeIdSelector("title")]}>
      {({ responsiveIndex }) => (
        <div
          aria-hidden={props["aria-hidden"]}
          className={css("photo-view-header", className, "body-l flex-row flex-noshrink flex-align-center")}
          ref={responsiveElement}
          role="banner"
        >
          <div className="flex-row flex-noshrink">
            {sessionContext.standalone ? (
              <Icons.OneDrive className="onedrive-application-icon margin-8" />
            ) : (
              <Button
                ariaHasPopup={true}
                ariaLabel={AppLauncher}
                className="header-button app-launcher-button focus-treatment"
                iconProps={{ className: "padding-horizontal-8", iconName: "WaffleOffice365" }}
                onClick={(event) => {
                  setShowApplication(true);
                  event.preventDefault();
                }}
                subtle={true}
              />
            )}
          </div>
          <Link
            className="photo-view-product-title flex-row flex-align-center flex-align-self-stretch flex-noshrink font-weight-semibold"
            href="https://onedrive.live.com"
            id={getSafeId("title")}
          >
            <span>OneDrive</span>
          </Link>
          <div className="flex-row flex-grow flex-align-center flex-justify-center">
            <div className="search-bar-container flex-row flex-justify-center pointer-events-none">
              <Observer values={{ searchActive }}>
                {({ searchActive }) => (
                  <div
                    className={css(
                      "search-bar flex-row flex-noshrink overflow-hidden pointer-events-auto",
                      searchActive && "active",
                      mobileLayout ? "absolute margin-horizontal-12" : "margin-horizontal-44",
                      mobileLayout && !searchActive && "hidden"
                    )}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    ref={searchElement}
                  >
                    {!photoPreferences?.florenceSearch ? (
                      <SearchForm
                        activeSearch={getActiveSearch(sessionContext)}
                        className="basic-search"
                        placeholder={mobileLayout ? MobileSearchPlaceholder : SearchPlaceholder}
                      />
                    ) : (
                      <SearchForm
                        activeSearch={getActiveSearch(sessionContext)}
                        componentRef={searchForm}
                        enableDropdown={true}
                        placeholder={SearchPlaceholder2}
                      />
                    )}
                  </div>
                )}
              </Observer>
            </div>
          </div>
          {mobileLayout && (
            <Button
              ariaLabel={SearchButton}
              className="header-button focus-treatment"
              iconProps={{ className: "margin-horizontal-8", render: (className: string) => <Icons.Search className={className} /> }}
              onClick={() => {
                setSearchActive(true);

                setTimeout(() => {
                  let focusElements: HTMLElement[] | undefined;

                  if (responsiveElement.current) {
                    focusElements = getFocusElements(responsiveElement.current, true);
                  }

                  if (focusElements && focusElements.length) {
                    const firstInputElement = focusElements.find((element) => {
                      return element.nodeName === "INPUT";
                    });
                    if (firstInputElement) {
                      firstInputElement.focus();
                    }
                  }
                });
              }}
              subtle={true}
            />
          )}
          <Button
            ariaLabel={UpgradeAltText}
            className="header-button focus-treatment"
            href="https://onedrive.live.com/?ocid=PROD%5FOneDrive%2DWeb%5FSuiteNav%5FNormal%5FHeaderDiamond&v=upgrade&hideLeftNav=true"
            iconProps={{ render: (className: string) => <Icons.Diamond className={className} /> }}
            subtle={true}
          />
          <div className="flex-row flex-justify-end flex-noshrink" id={getSafeId("header-commands")}>
            <Button
              ariaLabel={SettingsAltText}
              className="header-button focus-treatment"
              iconProps={{ render: (className: string) => <Icons.Settings className={className} /> }}
              onClick={() => setShowSettings(true)}
              subtle={true}
            />
            <Button
              ariaLabel={HelpAltText}
              className="header-button focus-treatment"
              iconProps={{ render: (className: string) => <Icons.Help className={css(className, "no-rotate")} /> }}
              onClick={() => setShowHelp(true)}
              subtle={true}
            />
          </div>
          <MenuButton
            ariaLabel={MoreButton}
            buttonClassName="header-button"
            className={css("flex-noshrink", responsiveIndex === -1 && "hidden")}
            contextualMenuProps={{
              className: "compact-menu",
              menuProps: {
                id: "overflow-menu",
                items: [
                  {
                    id: "settings",
                    iconProps: { render: (className: string) => <Icons.Settings className={className} /> },
                    onActivate: () => setShowSettings(true),
                    text: SettingsAltText
                  },
                  {
                    id: "help",
                    iconProps: { render: (className: string) => <Icons.Help className={className} /> },
                    onActivate: () => setShowHelp(true),
                    text: HelpAltText
                  }
                ]
              }
            }}
            hideDropdownIcon={true}
            iconProps={{ className: "padding-horizontal-8", iconName: "MoreVertical" }}
            subtle={true}
          />
          <div
            className="photo-header-profile flex-row flex-align-center flex-noshrink padding-horizontal-8 header-mecontrol-tab"
            aria-label={SignIn}
          >
            <NpmMeControlContainer />
          </div>
          <React.Suspense fallback={null}>
            <Observer values={{ showApplication }}>
              {({ showApplication }) =>
                showApplication ? (
                  <Scenario scenarioName="applicationpanel">
                    <ApplicationPanel onDismiss={() => setShowApplication(false)} />
                  </Scenario>
                ) : null
              }
            </Observer>
          </React.Suspense>
          <React.Suspense fallback={null}>
            <Observer values={{ showHelp }}>
              {({ showHelp }) =>
                showHelp ? (
                  <Scenario scenarioName="helppanel">
                    <HelpPanel onDismiss={() => setShowHelp(false)} />
                  </Scenario>
                ) : null
              }
            </Observer>
          </React.Suspense>
          <React.Suspense fallback={null}>
            <Observer values={{ showSettings }}>
              {({ showSettings }) =>
                showSettings ? (
                  <Scenario scenarioName="settingspanel">
                    <SettingsPanel onDismiss={() => setShowSettings(false)} />
                  </Scenario>
                ) : null
              }
            </Observer>
          </React.Suspense>
        </div>
      )}
    </ResponsiveLayout>
  );
}
