import { IReadonlyObservableArray, ObservableLike } from "azure-devops-ui/Core/Observable";
import { ISelection, Selection } from "./selection";

const defaultOptions: IRangeSelectionOptions = {};

export interface IRangeSelectionOptions {
  /**
   * Control whether or not proposed selections should be allowed. A function
   * can be supplied to control the availability of a proposed selection.
   *
   * @default false
   */
  ignoreProposal?: () => boolean;

  /**
   * selected is an optional set of initially selected items.
   */
  selected?: Iterable<string> | null;
}

/**
 * An IRangeSelection is an advanced form of an ISelection that tracks a
 * proposed selection. This can be used to present a UX hint to what a range
 * selection operation will operate on.
 */
export interface IRangeSelection extends ISelection {
  proposeSelection: (id?: string) => void;
  selectRange: (id: string, merge?: boolean) => void;
}

export class RangeSelection<T extends { id: string }> extends Selection implements IRangeSelection {
  private _ignoreProposal: () => boolean;
  private _items: (() => IReadonlyObservableArray<T> | T[]) | IReadonlyObservableArray<T> | T[];
  private _previousSelection: { pivot: string; range: Set<string> } | undefined = undefined;
  private _proposed: string | undefined;

  constructor(items: (() => IReadonlyObservableArray<T> | T[]) | IReadonlyObservableArray<T> | T[], options?: IRangeSelectionOptions) {
    const { ignoreProposal, selected } = options || defaultOptions;

    super({ multiSelect: true, selected });

    this._ignoreProposal = ignoreProposal || (() => false);
    this._items = items;
  }

  public proposeSelection(id?: string): void {
    // Determine whether or not the selection allows proposals.
    if (this._ignoreProposal()) {
      return;
    }

    // Save the currently proposed id and send a notification for the range that
    // would be selected with this proposal.
    if (id) {
      if (this._proposed !== id) {
        this.notify(this.getRange(id), "proposeSelection");
      }
    } else if (this._proposed) {
      this.notify(new Set<string>(), "proposeSelection");
    }

    // Save the last proposed id, we dont want
    this._proposed = id;
  }

  public selectRange(id: string, merge?: boolean): void {
    const pivot = this.pivot;

    if (pivot) {
      const range = this.getRange(id);

      // If we merge this range selection we will not look at the previous
      // range selection and unselect the inverted range.
      if (!merge) {
        // If we are updating the selectedRange using the same pivot as the last
        // selection we will change the previous selection to the latest selection.
        if (this._previousSelection && this._previousSelection.pivot === pivot) {
          const invertedRange: string[] = [];
          const previousRange = this._previousSelection.range;

          // We need to determine if there are any items being unselected, these are
          // items that are in the previous set but not in the new set.
          for (const id of previousRange) {
            if (!range.has(id)) {
              invertedRange.push(id);
            }
          }

          // We will first unselect the items from the previous range select that
          // are no longer required.
          this.unselect(invertedRange);
        }
      }

      // Select the updated range of items.
      this.select(range, true);

      // Save this selection for this range selection.
      this._previousSelection = { pivot, range };
    }
  }

  private getRange(id: string): Set<string> {
    const range = new Set<string>();
    const pivot = this.pivot;
    let pivotFound = false;

    if (pivot && pivot !== id) {
      const items = ObservableLike.getValue(typeof this._items === "function" ? this._items() : this._items);
      let inSelection = false;

      for (let index = 0; index < items.length; index++) {
        const item = items[index];

        // If this item is on either end of the selection range we will either
        // start selecting, or end the range.
        if (item.id === id || item.id === pivot) {
          pivotFound = pivotFound || item.id === pivot;

          if (inSelection) {
            range.add(item.id);
            break;
          } else {
            inSelection = true;
          }
        }

        // If this item is in the selection range we will add it to the items
        // being added to the selection. We add all items in between whether
        // they are already selected or not.
        if (inSelection) {
          range.add(item.id);
        }
      }
    }

    // Ensure we found the pivot, otherwise we didnt find both ends of the range
    return pivotFound ? range : new Set<string>();
  }
}
