import React from "react";
import { EventContext } from "../../contexts/event";
import { ScenarioContext } from "../../contexts/scenario";
import { usePromise } from "../../hooks/usepromise";
import { defer } from "../../utilities/promise";
import { IScenario } from "../../utilities/scenario";

export function lazy<T extends React.ComponentType<any>>(factory: () => Promise<{ default: T }>): T {
  const resolvedModule: { default: T | undefined } = { default: undefined };
  let localPromise: Promise<{ default: T }> | undefined;

  // Wrap the supplied factory with our local factory for tracking the
  // completion of the component loading.
  const localFactory = () => {
    localPromise =
      localPromise ||
      new Promise<{ default: T }>((resolve, reject) =>
        factory()
          .then((module) => {
            // Track the resolved component so we dont need to wait on
            // promises on subsequent renders.
            resolvedModule.default = module.default;

            // Return the resolved module to the caller.
            resolve(module);
          })
          .catch(reject)
      );

    return localPromise;
  };

  return Shim as T;

  function Shim(props: any): React.ReactElement {
    const eventContext = React.useContext(EventContext);
    const scenarioContext = React.useContext(ScenarioContext);

    const childScenario = React.useRef<IScenario<unknown> | undefined>(undefined);
    const exoticComponent = React.useRef<React.LazyExoticComponent<any> | undefined>(undefined);
    const { trackPromise } = usePromise();
    const [, setCount] = React.useState(0);

    // Create a promise that completes once the lazy component renders with
    // the underlying component resolved.
    const [{ reject, resolve, promise }] = React.useState(() => defer<void>());

    // We will track the status of the loading promise. If the component hasn't been
    // resolved we will add the loading promise to any active scenario. If there is
    // no active scenario we will just continue as normal.
    let lazyComponent: React.FunctionComponentElement<any>;

    if (!resolvedModule.default) {
      const componentPromise = trackPromise(localFactory(), "Shim.componentPromise");
      const currentScenario = scenarioContext.getScenario();

      // We will attach the local promise to our current scenario
      if (currentScenario) {
        childScenario.current = currentScenario.waitUntil(
          promise,
          { scenarioName: "lazyLoad", scenarioType: "scriptDownload" },
          (scenarioDetails) => {
            Object.assign(scenarioDetails.properties, { componentName: resolvedModule?.default?.name });
          }
        );
      }

      // Once the promise of the lazy component has been resolved we can render
      // the resulting component.
      componentPromise
        .then(() => setCount((count) => count + 1))
        .catch((error) => {
          if (!error.isCanceled) {
            eventContext.dispatchEvent("telemetryAvailable", { action: "exception", error, name: "lazyLoad" });
          }

          // Complete the scenario with the failure.
          reject(error);
        });
    }

    // Until we have resolved the asynchronous load we will just return the LazyExoticComponent.
    if (!exoticComponent.current) {
      exoticComponent.current = React.lazy(localFactory);
    }

    lazyComponent = React.createElement(exoticComponent.current, props);

    // If we are loading the component asynchronously and we have an active scenario
    // we will update the active scenario to be our asyncLoad scenario.
    if (childScenario.current) {
      const lazyScenario = childScenario.current;

      lazyComponent = (
        <ScenarioContext.Provider
          value={{
            getScenario: () => {
              if (lazyScenario.status() === "working") {
                return lazyScenario;
              }
            }
          }}
        >
          {lazyComponent}
        </ScenarioContext.Provider>
      );
    }

    React.useEffect(() => {
      // We have completed a render with a our async component available.
      if (resolvedModule.default) {
        resolve();
      }
    });

    return lazyComponent;
  }
}
