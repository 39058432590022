import React from "react";
import { Callout as BoltCallout, ICallout, ICalloutProps as IBoltCalloutProps } from "azure-devops-ui/Callout";
import { css } from "azure-devops-ui/Util";
import { AriaSiblingsHidden } from "./ariasiblingshidden";

let _componentId = 0;

export interface ICalloutProps extends IBoltCalloutProps {
  /**
   * The callout component offers an API through the componentRef prop. If the
   * caller wishes to access this API it should supply a reference.
   */
  componentRef?: React.MutableRefObject<ICallout | undefined>;
}

export function Callout(props: ICalloutProps & { children?: React.ReactNode }): JSX.Element {
  const { children, componentRef, contentClassName, modal } = props;

  const calloutRef = React.useRef<BoltCallout>(null);

  const [ariaRootClassName] = React.useState(() => `aria-callout-element-${++_componentId}`);

  React.useImperativeHandle(componentRef, () => ({
    updateLayout: () => calloutRef.current?.updateLayout()
  }));

  let calloutChildren = children;

  if (modal) {
    calloutChildren = <AriaSiblingsHidden selector={`.${ariaRootClassName}`}>{children}</AriaSiblingsHidden>;
  }

  return (
    <BoltCallout {...props} contentClassName={css(contentClassName, ariaRootClassName)} ref={calloutRef}>
      {calloutChildren}
    </BoltCallout>
  );
}
