import { unstable_batchedUpdates } from "react-dom";

export declare type EventDelegate<E> = (event: E) => void;

interface IEventListener<E> {
  delegate: EventDelegate<E>;
  once: boolean;
}

export interface IEventDispatch {
  addEventListener<E>(eventType: string, delegate: EventDelegate<E>): void;
  dispatchEvent<E>(eventType: string, event: E): void;
  once<E>(eventType: string, delegate: EventDelegate<E>): void;
  removeEventListener<E>(eventType: string, delegate: EventDelegate<E>): void;
}

export class EventDispatch implements IEventDispatch {
  private batchUpdates: boolean;
  private listeners: { [eventType: string]: IEventListener<any>[] } = {};
  private parentDispatch: IEventDispatch | undefined = undefined;

  constructor(options?: { batchUpdates?: boolean; parentDispatch?: IEventDispatch }) {
    const { batchUpdates = true, parentDispatch } = options || {};

    // Initialize the batchUpdates value from the requested options.
    this.batchUpdates = batchUpdates;
    this.parentDispatch = parentDispatch;
  }

  public addEventListener<E>(eventType: string, delegate: EventDelegate<E>) {
    if (!(eventType in this.listeners)) {
      this.listeners[eventType] = [];
    }

    this.listeners[eventType].push({ once: false, delegate });
  }

  public dispatchEvent<E>(eventType: string, event: E): void {
    const delegates = this.listeners[eventType];
    let onceCleanup: Array<IEventListener<any>> | undefined;

    // Depending on whether or not the caller wants batchedUpdates
    // enabled we will either call it within the batchedUpdates callback
    // or not.
    if (this.batchUpdates) {
      unstable_batchedUpdates(dispatch);
    } else {
      dispatch();
    }

    if (onceCleanup) {
      for (let i = 0; i < onceCleanup.length; i++) {
        const index = this.listeners[eventType].indexOf(onceCleanup[i]);
        if (index !== -1) {
          this.listeners[eventType].splice(index, 1);
        }
      }
    }

    // Now that all local event processing is complete, pass this to the parent
    // dispatch if one was available.
    if (this.parentDispatch) {
      this.parentDispatch.dispatchEvent(eventType, event);
    }

    function dispatch() {
      if (delegates) {
        const stack = delegates.slice();
        for (let i = 0; i < stack.length; i++) {
          const entry = stack[i];

          try {
            entry.delegate(event);
          } catch (error) {
            // Ignore the failure in the event handler.
            // @TODO: Need to add a feature to allow callers to add an error handler.
          }

          if (entry.once) {
            if (!onceCleanup) {
              onceCleanup = [entry];
            } else {
              onceCleanup.push(entry);
            }
          }
        }
      }
    }
  }

  public once<E>(eventType: string, delegate: EventDelegate<E>): void {
    if (!(eventType in this.listeners)) {
      this.listeners[eventType] = [];
    }

    this.listeners[eventType].push({ once: true, delegate });
  }

  public removeEventListener<E>(eventType: string, delegate: EventDelegate<E>): void {
    if (!(eventType in this.listeners)) {
      return;
    }

    const stack = this.listeners[eventType];
    for (let i = 0, l = stack.length; i < l; i++) {
      if (stack[i].delegate === delegate) {
        stack.splice(i, 1);
        return;
      }
    }
  }
}
