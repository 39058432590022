import React from "react";
import { headerHeight } from "../components/photoheader/photoheader";
import { IDimensions } from "../types/item";
import { ILayoutElement, ILayoutItem, ILayoutOptions, ILayoutResult, IRectangle, ITileDetails, ITilePadding, LayoutFunction } from "../types/layout";
import { noTilePadding, renderBasicPlaceholder } from "../utilities/layout";
import { getPlaceholders, groupItems } from "./util";

const defaultSize = 200;

/**
 * Layout options custom to the grid layout. These options define the
 * specific layout options that are used.
 */
export interface IGridLayoutOptions<T, S = undefined> extends ILayoutOptions<T, S> {
  /**
   * isSameSection is used to determine if items should be rendered in the UX
   * within the same section. Sections are sets of items that have a header
   * that applies to all the items in the section.
   */
  isSameSection?: (item1?: T, item2?: T) => boolean;

  /**
   * When creating the tile rectangle additional space can be requested in the
   * tile. This additional space will be added to the computed rectangle after
   * the items dimensions are applied.
   *
   * @default { bottom: 0 }
   */
  padding?: ITilePadding;

  /**
   * An optional header render function can be supplied for rendering a custom
   * header. If no function is supplied headers wont be generated.
   */
  renderSectionHeader?: (items: T[], rect: IRectangle) => React.ReactElement;
}

/**
 * Function used to create a grid layout.
 *
 * @param layoutOptions
 * @returns
 */
export function gridLayout<T extends { dimensions: IDimensions; id: string }, S = undefined>(
  layoutOptions: IGridLayoutOptions<T, S>
): LayoutFunction<T> {
  const {
    isSameSection,
    isSimilar,
    itemSpacing = 16,
    padding = noTilePadding,
    renderItem,
    renderOptions,
    renderPlaceholder = renderBasicPlaceholder,
    renderSectionHeader,
    zoomLevel = defaultSize
  } = layoutOptions;

  // We need to do all the initialization work, like getting a consistent set of
  // leading & trailing placeholders.
  const _leadingPlaceholders = getPlaceholders();
  const _trailingPlaceholders = getPlaceholders();

  return function buildLayout(
    items: T[] | undefined,
    layoutWidth: number,
    pivotItem: T | undefined,
    leadingPlaceholders: boolean,
    trailingPlaceholders: boolean
  ): ILayoutResult<T> {
    const columnCount = Math.max(1, Math.floor(layoutWidth / zoomLevel));
    const columnWidth = (layoutWidth + itemSpacing) / columnCount - itemSpacing;
    const dimensions: IDimensions = { height: columnWidth, width: columnWidth };
    const elements: ILayoutElement<T>[] = [];
    const layoutItems: ILayoutItem<T>[] = [];
    const tiles: ITileDetails<T>[] = [];
    let left = 0;
    let top = 0;

    if (items) {
      // Group the items by our similar items delegate
      const groups = groupItems(items, isSimilar);

      // Build up the set of imageSizes we will be using for each of the items
      // in this layout. This includes leading placeholders, items, trailing
      // placeholders.
      if (leadingPlaceholders) {
        for (let placeholderIndex = 0; placeholderIndex < _leadingPlaceholders.length; placeholderIndex++) {
          layoutItems.push({ dimensions });
        }
      }

      // Determine the dates for each of the items and add its ratio to the
      // set of items.
      for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        const item = groups[groupIndex][0];

        // Push this item into the set of layout items.
        layoutItems.push({
          group: groups[groupIndex],
          dimensions,
          item
        });
      }

      if (trailingPlaceholders) {
        for (let placeholderIndex = 0; placeholderIndex < _trailingPlaceholders.length; placeholderIndex++) {
          layoutItems.push({ dimensions });
        }
      }
    } else {
      for (let placeholderIndex = 0; placeholderIndex < _trailingPlaceholders.length; placeholderIndex++) {
        layoutItems.push({ dimensions });
      }
    }

    // Layout the items available.
    for (let itemIndex = 0, tileIndex = 0; itemIndex < layoutItems.length; ) {
      let rowHasHeader = false;
      let rowStartIndex = itemIndex;
      let topOffset = 0;

      // Determine the set of items that will fit on this row given the target height.
      // This is done by computing the set of items that will fit in a row at the target
      // height maintaing their aspect ratio. We track the aspect ratio of each item
      // in the row and adjust the height to line up the items with a complete row.
      while (itemIndex < layoutItems.length) {
        const { item } = layoutItems[itemIndex];

        // Determine if this row is going to show a date header.
        if (isSameSection) {
          rowHasHeader = rowHasHeader || itemIndex === 0 || !isSameSection(layoutItems[itemIndex - 1].item, item);
        }

        // Break after we have gone through all the items on the row.
        if (++itemIndex === rowStartIndex + columnCount) {
          break;
        }
      }

      // If this row has a date header make sure the items start below the headers.
      if (rowHasHeader) {
        topOffset += headerHeight;
      }

      // Now go back and layout the items for this row.
      for (left = 0; rowStartIndex < itemIndex; rowStartIndex++) {
        const { group, dimensions, item } = layoutItems[rowStartIndex];

        // If this row is rendering a date header, we need to determine if this
        // item needs a header or just space allocated for it.
        if (group && isSameSection && renderSectionHeader && (rowStartIndex === 0 || !isSameSection(item, layoutItems[rowStartIndex - 1].item))) {
          let headerItems: T[] = [...group];
          let headerWidth = dimensions.width;

          // Added items to this header width on this row as long as the
          // item continues to have the same date.
          for (
            let headerIndex = rowStartIndex + 1;
            headerIndex < layoutItems.length && isSameSection(item, layoutItems[headerIndex].item);
            headerIndex++
          ) {
            const { group, dimensions } = layoutItems[headerIndex];
            if (group) {
              // If this grouped item is on this row add its width to the header.
              if (headerIndex < itemIndex) {
                headerWidth += dimensions.width + itemSpacing;
              }

              headerItems.push(...group);
            }
          }

          const rect = { height: headerHeight, left, top, width: Math.min(headerWidth, layoutWidth - left) };
          elements.push({ element: renderSectionHeader(headerItems, rect), rect });
        }

        // Compute the rectangle we will use to layout either the placeholder, or the item tile.
        const rect = { height: dimensions.height + padding.bottom, left: left, width: dimensions.width, top: top + topOffset };

        if (group && item) {
          const tileDetails: ITileDetails<T> = { group, item, padding, rect };

          tiles.push(tileDetails);
          elements.push({ element: renderItem(tileDetails, tileIndex++, renderOptions), rect, tileDetails });
        } else {
          elements.push({ element: renderPlaceholder(rect, padding, tileIndex++), rect });
        }

        // Update the left for the next item.
        left += dimensions.width + itemSpacing;
      }

      // Add the height of this row to the top for the next row.
      top += dimensions.height + topOffset + itemSpacing + padding.bottom;
    }

    return { elements, height: top - itemSpacing, items: items || [], layoutWidth, pivotItem, tiles };
  };
}
