import { IReadonlyObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";
import { EventContext } from "../../common/contexts/event";
import { useObservable } from "../../common/hooks/useobservable";
import { binarySearch } from "../../common/utilities/binarysearch";
import { noop } from "../../common/utilities/func";
import { ISelection } from "../../common/utilities/selection";
import { defaultPhotoDimensions } from "../api/util";
import { SessionContext } from "../contexts/session";
import { IFavoriteEvent, IPhotoCreatedEvent, IPhotoUpdatedEvent, IPhotosDeletedEvent, ITagAddedEvent, ITagDeletedEvent } from "../types/change";
import { IItemPage } from "../types/item";
import { IItemFeed } from "../types/itemfeed";
import { IPhoto } from "../types/photo";
import { getPhotoTakenDate } from "../utilities/image";
import { useItemFeed } from "./useitemfeed";
import { useRejectionNoOp } from "./userejection";

export interface IPhotoFeedOptions {
  /**
   * The ensureData flag can be supplied which will cause the feed to only
   * return pages with data when they are available. If an empty page is
   * returned with either a next link, the link will be followed until a
   * page with data is returned.
   *
   * NOTE: This will NOT evaluate previous pages under any condition.
   *
   * @default false
   */
  ensureData?: boolean;

  /**
   * A filter function can be supplied to filter out photos that are not needed
   * in the feed. The caller should filter the array down to the set of items
   * that should be added.
   *
   * @param items The items being added to the feed. Filter out unwanted items.
   */
  filterFunction?: (items: IPhoto[], getItem: (id: string) => IPhoto | void) => void;

  /**
   * An optional id that represents the root id of the feed request.
   */
  id?: string;

  /**
   * An optional selection that is maintained as changes to the items occur.
   */
  selection?: ISelection;
}

export interface IAllPhotoFeed extends IItemFeed<IPhoto> {
  newestPhoto: IReadonlyObservableValue<Date | undefined>;
  oldestPhoto: IReadonlyObservableValue<Date | undefined>;
}

export function useAllPhotoFeed(
  getItems: (url?: string) => Promise<IItemPage<IPhoto>>,
  getOldestPhoto: (driveId: string, id?: string) => Promise<IPhoto | undefined>,
  options?: IPhotoFeedOptions
): IAllPhotoFeed & { feedId: number } {
  const { id } = options || {};

  const oldestFilter = React.useRef<string | undefined>(undefined);

  const sessionContext = React.useContext(SessionContext);

  const [newestPhoto, setNewestPhoto] = useObservable<Date | undefined>(undefined);
  const [oldestPhoto, setOldestPhoto] = useObservable<Date | undefined>(undefined);

  // We will handle errors on getting the oldest photo and just do nothing.
  const handleRejection = useRejectionNoOp();

  const _getItems = React.useCallback(
    (url?: string) => {
      // The first time items are requested we will request the oldest photo as well.
      if (!oldestFilter.current || oldestFilter.current !== id) {
        oldestFilter.current = id;

        getOldestPhoto(sessionContext.driveId, id)
          .then((oldestPhoto) => {
            // Make sure this is the latest call, we dont want to use an older calls result.
            if (oldestFilter.current === id) {
              oldestPhoto && setOldestPhoto(getPhotoTakenDate(oldestPhoto));
            }
          })
          .catch(handleRejection);
      }

      return getItems(url).then((photoPage) => {
        // Determine if this photo is newer than the previous newest photo.
        if (!newestPhoto.value && photoPage.value.length) {
          setNewestPhoto(getPhotoTakenDate(photoPage.value[0]));
        }

        return photoPage;
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getOldestPhoto, getItems, newestPhoto, setNewestPhoto, setOldestPhoto]
  );

  const itemFeed = usePhotoFeed(_getItems, options);

  return { ...itemFeed, newestPhoto, oldestPhoto };
}

export function usePhotoFeed(
  getItems: (url?: string) => Promise<IItemPage<IPhoto>>,
  options?: IPhotoFeedOptions
): IItemFeed<IPhoto> & { feedId: number } {
  const { ensureData, filterFunction = noop, selection } = options || {};

  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const [duplicateMap] = React.useState<Map<string, IPhoto>>(new Map());
  const [hashMap] = React.useState<Map<string, IPhoto>>(new Map());

  const itemFeed = useItemFeed(getItems, {
    ensureData,
    filterFunction: React.useCallback(
      (photos: IPhoto[], getItem: (id: string) => IPhoto | undefined) => {
        // Call the filter function we will process this before we do our local
        // duplicate processing.
        filterFunction(photos, getItem);

        // Go through the array and detect and remove duplicates
        for (let index = 0; index < photos.length; index++) {
          let photo = photos[index];

          // Remove the video format that is not supported by the photo viewer.
          if (!sessionContext.migrated && photo.file?.mimeType.includes("mpeg")) {
            // if video is not supported, remove it and dont add the hash of the video to the hashmap
            photos.splice(index--, 1);
            continue;
          }

          const existingPhoto = getItem(photo.id);

          // Ensure we don't have any duplicates when the item is initially
          // added to the feed. This will prevent a dirty photo from being
          // used in the maps.
          if (existingPhoto) {
            // NOTE: For now if the photo's hash changes we will not change its
            // duplicate state, this may be a little jarry to introduce a new photo
            // when you save it. The next refresh though will have them be different
            // photos since the hashes wont match.
            photo = Object.assign(existingPhoto, photo);
            photos.splice(index, 1, existingPhoto);
          }

          photo.duplicates = [];

          // If the photo has a hash and there is another photo with the same hash
          // we will define this as a duplicate of the previous photo with this hash.
          const hash = photo.file?.hashes?.sha256Hash;

          if (hash) {
            const originPhoto = hashMap.get(hash);

            if (originPhoto) {
              // Remove the photo from the resulting feed array.
              photos.splice(index--, 1);

              // Save the duplicate photo in the origin and the duplicate map.
              originPhoto.duplicates!.push(photo);
              duplicateMap.set(photo.id, originPhoto);
            } else {
              hashMap.set(hash, photo);
            }
          }
        }
      },
      [duplicateMap, filterFunction, hashMap, sessionContext.migrated]
    ),
    initialize: React.useCallback(() => {
      duplicateMap.clear();
      hashMap.clear();
    }, [duplicateMap, hashMap])
  });

  // Setup our operation listeners to keep our items up to date.
  React.useEffect(() => {
    eventContext.addEventListener<any>("dataChanged", dataChanged);

    return () => {
      eventContext.removeEventListener("dataChanged", dataChanged);
    };

    // @NOTE: DONT make changes to the itemMap, the underlying feed will update
    // the map with the items collection changes. Make all changes to the
    // photos directly, or update the items array.
    function dataChanged(event: IPhotoCreatedEvent | IPhotosDeletedEvent | IPhotoUpdatedEvent | ITagAddedEvent | ITagDeletedEvent | IFavoriteEvent) {
      if (event.changeType === "photoCreated") {
        const createdPhoto = event.data.photo;
        const existingPhoto = itemFeed.getItem(createdPhoto.id, true);

        // Check if we already have the photo in the feed and if so check if its to most up to date version.
        if (!existingPhoto) {
          const hash = createdPhoto.file?.hashes?.sha256Hash;
          let originPhoto: IPhoto | undefined;

          // Ensure newly added photos have clean duplicate array.
          createdPhoto.duplicates = [];

          // If this is a duplicate of another photo, add it to the duplicate list of the origin.
          if (hash && (originPhoto = hashMap.get(hash)) !== undefined) {
            originPhoto.duplicates!.push(createdPhoto);
            duplicateMap.set(createdPhoto.id, originPhoto);
          } else {
            // @NOTE: We can offer a filter function that the caller can supply that
            //  can be used to determine whether the created photo applies to the
            //  current feed. For now we will assume yes for all created photos.

            // Find the best location for this photo to be added to the list of photos.
            // @NOTE: We could pass in a function for insertion which handles different
            //  sort orders. For now we are always sorted newest to oldest.
            const insertIndex = binarySearch(itemFeed.items.value, createdPhoto, (photo1, photo2) => {
              return getPhotoTakenDate(photo1).getTime() - getPhotoTakenDate(photo2).getTime();
            });

            itemFeed.items.splice(insertIndex, 0, createdPhoto);

            if (hash) {
              hashMap.set(hash, createdPhoto);
            }
          }
        } else {
          // photo has a more recent modified date - so the photo has been edited
          if (
            existingPhoto?.lastModifiedDateTime &&
            createdPhoto.lastModifiedDateTime &&
            new Date(existingPhoto.lastModifiedDateTime) < new Date(createdPhoto.lastModifiedDateTime)
          ) {
            Object.assign(existingPhoto, createdPhoto);
          }
        }
      } else if (event.changeType === "photosDeleted") {
        const photoIds = event.data.photoIds;

        if (selection) {
          selection.unselect(photoIds);
        }

        for (let deletedIndex = 0; deletedIndex < photoIds.length; deletedIndex++) {
          const deletedPhotoId = photoIds[deletedIndex];

          // Remove this photo from the origin photo if it is a duplicate.
          const originPhoto = duplicateMap.get(deletedPhotoId);
          if (originPhoto?.duplicates) {
            for (let duplicateIndex = 0; duplicateIndex < originPhoto.duplicates.length; duplicateIndex++) {
              if (originPhoto.duplicates[duplicateIndex].id === deletedPhotoId) {
                originPhoto.duplicates.splice(duplicateIndex, 1);
                duplicateMap.delete(deletedPhotoId);
                break;
              }
            }

            continue;
          }

          // Now go through the root photos (non-duplicates) and cleanup any
          // photos, promoting a duplicate photo if one exists.
          for (let index = 0; index < itemFeed.items.length; index++) {
            const photo = itemFeed.items.value[index];
            const hash = photo.file?.hashes?.sha256Hash;

            if (photo.id === deletedPhotoId) {
              itemFeed.items.splice(index, 1);

              // If there are duplicate photos we will promote the first duplicate to the origin photo.
              if (hash) {
                if (photo.duplicates?.length) {
                  const duplicatePhoto = photo.duplicates[0];

                  // Prepare the duplicate photo as the origin photo, moving over
                  // the duplicate array and removing yourself from it. Then
                  // remove yourself from the duplicate map.
                  duplicatePhoto.duplicates = photo.duplicates;
                  duplicatePhoto.duplicates.splice(0, 1);
                  duplicateMap.delete(duplicatePhoto.id);
                  hashMap.set(hash, duplicatePhoto);

                  // Add the duplicate photo to the same location in the feed that
                  // the photo was deleted.
                  itemFeed.items.splice(index, 0, duplicatePhoto);
                } else {
                  // Otherwise there are no more photos with this hash so we will
                  // remove this photo from the hash map.
                  hashMap.delete(hash);
                }
              }

              break;
            }
          }
        }
      } else if (event.changeType === "photoUpdated") {
        const photo = itemFeed.getItem(event.data.photo.id);

        // Merge the new photo into the old photo. This will replace its
        // internal properties with the updated photos properties.
        if (photo) {
          // Don't overwrite the existing dimensions if the updated photo has
          // default dimensions, it means they weren't requested.
          if (event.data.photo.dimensions === defaultPhotoDimensions) {
            event.data.photo.dimensions = photo.dimensions;
          }

          // Merge the photo objects first to ensure we don't lose details.
          event.data.photo.photo = { ...photo?.photo, ...event.data.photo.photo };

          // We can't change the photo instance so merge into it.
          Object.assign(photo, event.data.photo);
        }
      } else if (event.changeType === "tagAdded") {
        const photo = itemFeed.getItem(event.data.photoId);

        if (photo) {
          if (photo.tags) {
            if (!photo.tags.find((tag) => tag.localizedName === event.data.tag)) {
              photo.tags.push({ autoTagged: {}, localizedName: event.data.tag, name: event.data.tag, source: "UserDefined" });
            }
          } else {
            photo.tags = [{ autoTagged: {}, localizedName: event.data.tag, name: event.data.tag, source: "UserDefined" }];
          }
        }
      } else if (event.changeType === "tagDeleted") {
        const photo = itemFeed.getItem(event.data.photoId);

        if (photo?.tags) {
          for (let tagIndex = 0; tagIndex < photo.tags.length; tagIndex++) {
            if (photo.tags[tagIndex].localizedName === event.data.tag) {
              photo.tags.splice(tagIndex, 1);
              break;
            }
          }
        }
      } else if (event.changeType === "favoriteChange") {
        for (let photoIndex = 0; photoIndex < event.data.photoIds.length; photoIndex++) {
          const photoId = event.data.photoIds[photoIndex];

          if (itemFeed.getItem(photoId)) {
            for (let feedIndex = 0; feedIndex < itemFeed.items.length; feedIndex++) {
              if (itemFeed.items.value[feedIndex].id === photoId) {
                itemFeed.items.change(photoIndex, itemFeed.items.value[photoIndex]);
                break;
              }
            }
          }
        }
      }
    }
  }, [duplicateMap, hashMap, eventContext, itemFeed, selection]);

  return itemFeed;
}
