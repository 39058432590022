import React from "react";
import { NameplatePlaceholder, Placeholder } from "../components/placeholder/placeholder";
import { IRectangle, ITilePadding } from "../types/layout";

/**
 * Default padding used for a tileLayout with no padding.
 */
export const noTilePadding: ITilePadding = { bottom: 0 };
export const nameTilePadding: ITilePadding = { bottom: 44 };

// LayoutStyle represents how the application is laid out given the space available.
export declare type LayoutStyle = "mobile" | "tablet" | "desktop";

/**
 * Used to get the window size, used to determine the layout
 */
export function getLayoutStyle(): LayoutStyle {
  const { innerWidth } = window;
  return innerWidth < 640 ? "mobile" : innerWidth < 1024 ? "tablet" : "desktop";
}

/**
 * Used to check if document.fullscreenElement is supported in the user's browser
 */
export function fullscreenSupported(): boolean {
  return document.fullscreenElement !== undefined && window === window.top;
}

/**
 * The basic placeholder element for layouts if ILayoutOptions.renderPlaceholder
 * is not provided
 *
 * @param rect The tile's position and size.
 * @param padding Tile padding defined for the placeholder.
 * @param index The index of the tile in this tile set.
 * @returns A placeholder element with the given dimensions.
 */
export const renderBasicPlaceholder = (rect: IRectangle, _padding: ITilePadding, index: number): React.ReactElement => {
  return <Placeholder className="absolute" key={`placeholder-${index}`} rect={rect} />;
};

/**
 * A NameplatePlaceholder renders a single line under a basic placeholder when
 * padding is supplied.
 *
 * @param rect The tile's position and size.
 * @param padding Tile padding defined for the placeholder.
 * @param index The index of the tile in this tile set.
 * @returns A placeholder element with the given dimensions.
 */
export const renderNameplatePlaceholder = (rect: IRectangle, padding: ITilePadding, index: number): React.ReactElement => {
  return <NameplatePlaceholder className="absolute" key={`placeholder-${index}`} padding={padding} rect={rect} />;
};
