import { formatURL } from "../../common/utilities/format";
import { IFeedback } from "../types/feedback";

// Example - https://storage.live.com/SendFeedback?mkt=en%2DUS
const feedbackApi = "{{feedbackOrigin}}/SendFeedback?{{parameters}}";

export function sendFeedback(fetch: (url: string, init?: RequestInit) => Promise<Response>, feedback: IFeedback): Promise<void> {
  const formData = new FormData();

  // Setup the FormData object which is used to create a multipart-mime message.
  formData.append("<Feedback>", new File([JSON.stringify(feedback.metadata)], "<Feedback>", { type: "application/json" }));

  // If a screenshot was supplied attach it to the data.
  if (feedback.screenshot) {
    formData.append("<Sceenshot>", new File([feedback.screenshot], "<Screenshot>", { type: "image/jpg" }));
  }

  return fetch(formatURL(feedbackApi, { parameters: new URLSearchParams({ mkt: feedback.market }) }), {
    body: formData,
    method: "POST"
  }).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(response);
    }
  });
}
