import { FocusZoneContext } from "azure-devops-ui/FocusZone";
import { IListItemDetails } from "azure-devops-ui/List";
import { getTabIndex } from "azure-devops-ui/Utilities/Focus";
import React from "react";

export interface IUnstyledListItemProps<T> extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {
  details: IListItemDetails<T>;
  index: number;
}

export function UnstyledListItem<T>(props: IUnstyledListItemProps<T> & { children?: React.ReactNode }) {
  const { children, details, index, ...rowProps } = props;

  const focuszoneContext = React.useContext(FocusZoneContext);

  return (
    <tr
      aria-posinset={index + 1}
      aria-setsize={details.listProps.itemProvider.length}
      data-focuszone={details.excludeFocusZone ? undefined : focuszoneContext.focuszoneId}
      data-row-index={index}
      role="listitem"
      tabIndex={getTabIndex(details)}
      {...rowProps}
      onFocus={(event) => {
        props.onFocus && props.onFocus(event);

        // Notify the underlying list of the focus change.
        details.onFocusItem(index, event);
      }}
    >
      <td>{children}</td>
    </tr>
  );
}
