import { css } from "azure-devops-ui/Util";
import React from "react";
import { IRectangle, ITilePadding } from "../../types/layout";
import { noTilePadding } from "../../utilities/layout";

export interface IPlaceholderProps {
  className?: string;
  rect: IRectangle;
}

export function Placeholder(props: IPlaceholderProps): React.ReactElement {
  const {
    className,
    rect: { height, left, top, width }
  } = props;

  return <div aria-hidden={true} className={css("placeholder", className, "rounded-6")} style={{ height, left, top, width }}></div>;
}

export interface INameplatePlaceholderProps extends IPlaceholderProps {
  padding?: ITilePadding;
}

export function NameplatePlaceholder(props: INameplatePlaceholderProps): React.ReactElement {
  const {
    className,
    padding = noTilePadding,
    rect: { height, left, top, width }
  } = props;

  if (padding.bottom) {
    // Compute the updated placeholder given the padding supplied.
    const placeholderRect: IRectangle = {
      height: height - padding.bottom,
      left,
      top,
      width
    };

    return (
      <>
        <Placeholder className="absolute" rect={placeholderRect} />
        <div
          aria-hidden={true}
          className={css("placeholder-nameplate", className, "flex-row flex-align-end")}
          style={{ height: padding.bottom, left, top: top + height - padding.bottom, width }}
        >
          <span className="placeholder-name placeholder flex-grow margin-horizontal-8 rounded-6">&nbsp;</span>
        </div>
      </>
    );
  } else {
    return <Placeholder className={className} rect={{ height, left, top, width }} />;
  }
}
