import React from "react";

interface IEventHandlers {
  _onMouseMove: (event: MouseEvent) => void;
  _onMouseUp: (event: MouseEvent) => void;
  _onScroll: () => void;
}

const scrollEventOptions: AddEventListenerOptions = { capture: true, passive: true };

export interface ICaptureState {
  onMouseDown: (event: React.MouseEvent) => void;
}

export function useMouseCapture(onMouseMove: (event: MouseEvent) => void, onMouseUp?: (event: MouseEvent) => void): ICaptureState {
  const eventHandlers = React.useRef<IEventHandlers | undefined>(undefined);

  const onMouseDown = React.useCallback(
    (event: React.MouseEvent): void => {
      let lastMouse = event.nativeEvent;

      // If we don't have our event handlers currently attached we will set them up.
      if (!eventHandlers.current) {
        document.addEventListener("dragend", _onMouseUp);
        document.addEventListener("mousemove", _onMouseMove);
        document.addEventListener("mouseup", _onMouseUp);
        document.addEventListener("scroll", _onScroll, scrollEventOptions);

        // Save the handler instances for cleanup on unmount.
        eventHandlers.current = { _onMouseMove, _onMouseUp, _onScroll };
      }

      function _onMouseMove(event: MouseEvent): void {
        lastMouse = event;
        onMouseMove(event);
      }

      function _onMouseUp(event: MouseEvent): void {
        onMouseUp && onMouseUp(event);

        if (event.buttons === 0) {
          removeEventListeners();
        }
      }

      function _onScroll(): void {
        onMouseMove(lastMouse);
      }
    },
    [onMouseMove, onMouseUp]
  );

  React.useEffect(() => {
    return removeEventListeners;
  }, []);

  return { onMouseDown };

  function removeEventListeners(): void {
    const { current } = eventHandlers;

    if (current) {
      document.removeEventListener("dragend", current._onMouseUp);
      document.removeEventListener("mousemove", current._onMouseMove);
      document.removeEventListener("mouseup", current._onMouseUp);
      document.removeEventListener("scroll", current._onScroll, scrollEventOptions);

      eventHandlers.current = undefined;
    }
  }
}
