import { Button } from "azure-devops-ui/Button";
import { ContentSize, ICallout } from "azure-devops-ui/Callout";
import { IObservableArray, ObservableArray } from "azure-devops-ui/Core/Observable";
import { FocusZone, FocusZoneDirection } from "azure-devops-ui/FocusZone";
import { Link } from "azure-devops-ui/Link";
import { IListItemDetails, IListRow, List } from "azure-devops-ui/List";
import { css } from "azure-devops-ui/Util";
import { IFocusable, getTabIndex } from "azure-devops-ui/Utilities/Focus";
import { Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Callout } from "../../../common/components/callout/callout";
import { Observer } from "../../../common/components/observer/observer";
import { EventContext } from "../../../common/contexts/event";
import { useFocusTrap } from "../../../common/hooks/usefocustrap";
import { useEventListener } from "../../../common/hooks/uselistener";
import { preventDefault } from "../../../common/utilities/func";
import { Clear, LightBulb, Recent } from "../illustration/icons";
import { UnstyledListItem } from "../listitem/unstyledlistitem";
import { IMatchedFilter } from "../search/searchform";
import { SearchFilterPill } from "./searchfilterpill";

const { ClearAllButton, ClearButton } = window.Resources.Common;
const { FilterHeader, InspirationHeader, RecentHeader, SearchDropdownList } = window.Resources.SearchInput;

type ISearchItem =
  | {
      clear?: boolean;
      text: string;
      type: "header";
    }
  | {
      index: number;
      text: string;
      type: "recent";
    }
  | {
      index: number;
      text: string;
      type: "insipration";
    }
  | {
      index: number;
      text: string;
      type: "filter";
    };

export interface IFocusOptions {
  /**
   * When setting focus to the dropdown, should focus go to the first item in the
   * list or the last item in the list?
   */
  item: "first" | "last";
}

export interface ISearchDropdown extends IFocusable<IFocusOptions> {
  updateLayout: () => void;
}

export interface ISearchDropdownProps {
  /**
   * The element the dropdown will be positioned relative too.
   */
  anchorElement: React.RefObject<HTMLElement>;

  /**
   * Function the dropdown can use to remove existing recent searches.
   *
   * @param searchTerm The search term that should be removed, if nothing is
   * passed for this parameter all recent searches are removed.
   */
  clearSearch: (searchTerm?: string) => void;

  /**
   * The comboboxElement that receives focus when the user exits the dropdown
   * to reenter the parent component.
   */
  comboboxElement: React.RefObject<HTMLElement>;

  /**
   * The dropdown component offers an API through the componentRef prop. If the
   * caller wishes to access this API it should supply a reference.
   */
  componentRef?: React.MutableRefObject<ISearchDropdown | undefined>;

  /**
   * Callback delegate the component can use to execute a specific search.
   *
   * @param searchText The text that should be searched.
   * @param searchType The dropdown should tell the caller what type of search
   *  is being performed.
   */
  executeSearch: (searchText: string, searchType: "recent" | "suggested") => void;

  /**
   * Set of suggested searches the user can pick from. These are not recent
   * searches but unique suggestions for this user.
   */
  inspirations: string[];

  /**
   * The parent component must supply an Id that is used to setup the
   * aria-controls relationship in the dropdown.
   */
  listId: string;

  /**
   * The caller can supply a set of search filters that have been matched to the
   * current search term. This is used to provide the user with a list of
   * possible filters they can apply to their search.
   */
  matchedFilters: IObservableArray<IMatchedFilter>;

  /**
   * Called when the dropdown should be dismissed.
   */
  onDismiss: () => void;

  /**
   * The caller can supply a set of recent searches for the drop down. This is
   * done through a promise of strings. Each string should be a unique search
   * value.
   */
  recentSearches: Promise<string[]>;
}

export function SearchDropdown(props: ISearchDropdownProps): React.ReactElement | null {
  const {
    anchorElement,
    clearSearch,
    comboboxElement,
    componentRef,
    executeSearch,
    inspirations,
    listId,
    matchedFilters,
    onDismiss,
    recentSearches
  } = props;

  const eventContext = React.useContext(EventContext);

  const dropdownCallout = React.useRef<ICallout>();
  const rootElement = React.useRef<HTMLDivElement>(null);
  const clearAllRecentsButton = React.useRef<Link>(null);

  const [, setCount] = React.useState(0);

  const [itemProvider] = React.useState(() => {
    const searchItems = new ObservableArray<ISearchItem>([]);

    // Go through the stored search setting and load up any recent searches.
    recentSearches.then((recentSearches) => {
      unstable_batchedUpdates(() => {
        let dataIndex = 0;

        for (let index = 0; index < recentSearches.length; index++) {
          const recentSearch = recentSearches[index];

          if (!searchItems.length) {
            searchItems.push({ clear: true, text: RecentHeader, type: "header" });
          }

          searchItems.push({ index: dataIndex++, text: recentSearch, type: "recent" });
        }

        // Add in three random inspirations.
        if (inspirations.length) {
          searchItems.push({ text: InspirationHeader, type: "header" });

          // To avoid duplicates we will randomize the array and take the first three.
          for (let index = inspirations.length - 1; index > 0; index--) {
            const target = Math.round(Math.random() * index);
            const temp = inspirations[target];
            inspirations[target] = inspirations[index];
            inspirations[index] = temp;
          }

          // Convert these inspirational strings into search items.
          searchItems.push(
            ...inspirations.slice(0, 3).map((inspiration): ISearchItem => ({ index: dataIndex++, text: inspiration, type: "insipration" }))
          );
        }

        // Add in any matched filters. (These items should only show up if there are filters to show.)
        searchItems.push({ text: FilterHeader, type: "header" });
        searchItems.push({ index: dataIndex++, text: "placeholder", type: "filter" });

        eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "searchOpened", count: recentSearches.length });
      });
    });

    return searchItems;
  });

  React.useImperativeHandle(componentRef, () => ({ focus, updateLayout }));

  // Dismiss the dropdown when you click outside the dropdown.
  useEventListener(document, "mousedown", (event: MouseEvent) => {
    if (!(anchorElement.current?.contains(event.target as HTMLElement) || rootElement.current?.contains(event.target as HTMLElement))) {
      onDismiss();
    }
  });

  const onMouseDown = useFocusTrap(rootElement);

  return anchorElement.current ? (
    <Callout
      anchorElement={anchorElement.current}
      anchorOffset={{ horizontal: 0, vertical: 4 }}
      anchorOrigin={{ horizontal: Location.start, vertical: Location.end }}
      className="search-dropdown-callout"
      componentRef={dropdownCallout}
      contentClassName="search-dropdown-content depth-8 rounded-8 overflow-auto"
      contentSize={ContentSize.Auto}
      fixedLayout={true}
      focuszoneProps={{ circularNavigation: true, handleTabKey: true, includeDefaults: true }}
      onDismiss={onDismiss}
      width={anchorElement.current.getBoundingClientRect().width}
    >
      <div
        className="flex-row flex-grow padding-horizontal-8 padding-vertical-8"
        onKeyDown={(event) => {
          if (!event.defaultPrevented) {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
              comboboxElement.current?.focus();
              event.preventDefault();
            }
          }
        }}
        onMouseDown={onMouseDown}
        ref={rootElement}
      >
        <List
          ariaLabel={SearchDropdownList}
          className="search-dropdown-list"
          id={listId}
          itemProvider={itemProvider}
          onActivate={activateItem}
          renderRow={renderRow}
          role="listbox"
          singleClickActivation={true}
          virtualize={false}
          width="100%"
        />
      </div>
    </Callout>
  ) : null;

  function renderRow(index: number, item: ISearchItem, details: IListItemDetails<ISearchItem>) {
    if (item.type === "header") {
      if (item.text === FilterHeader) {
        return (
          <Observer values={{ matchedFilters }}>
            {({ matchedFilters }) => {
              const filters = matchedFilters as IMatchedFilter[];
              if (!filters.length) {
                return null;
              }
              return searchDropdownHeader(item, index, details);
            }}
          </Observer>
        );
      }
      return searchDropdownHeader(item, index, details);
    }

    if (item.type === "filter") {
      return (
        <Observer values={{ matchedFilters }}>
          {({ matchedFilters }) => {
            const filters = matchedFilters as IMatchedFilter[];
            if (filters.length) {
              return (
                <tr
                  aria-posinset={index + 1}
                  aria-selected={false}
                  aria-setsize={details.listProps.itemProvider.length}
                  role="option"
                  tabIndex={getTabIndex(details)}
                >
                  <td>
                    <div className="flex-row flex-wrap flex-noshrink flex-gap-4 padding-horizontal-12 overflow-hidden">
                      {filters.map((filter, index) => {
                        return (
                          <SearchFilterPill
                            key={index}
                            displayName={filter.displayName}
                            filterType={filter.filterType}
                            active={filter.active}
                            onClick={() => {
                              filter.active = !filter.active;
                            }}
                          />
                        );
                      })}
                    </div>
                  </td>
                </tr>
              );
            }
            return null;
          }}
        </Observer>
      );
    }

    if (item.type === "recent") {
      return (
        <UnstyledListItem
          aria-selected={false}
          aria-setsize={itemProvider.length - 2}
          className="photos-list-row"
          details={details}
          index={index}
          key={index}
          role="option"
          onKeyDown={(event) => {
            if (!event.defaultPrevented && event.key === "Delete") {
              removeSearch(item.text);
              event.preventDefault();

              // Need to set focus to the most appropriate item since this is
              // being removed from the list.
            }
          }}
        >
          <FocusZone direction={FocusZoneDirection.Horizontal} handleTabKey={false}>
            <div className="flex-row flex-align-center padding-left-12">
              <Recent className="flex-noshrink" />
              <span className="flex-grow padding-left-8 text-ellipsis">{item.text}</span>
              <span aria-hidden={true} className="flex-noshrink">
                <Button
                  ariaLabel={ClearButton}
                  excludeTabStop={true}
                  iconProps={{ render: (className) => <Clear className={css("small", className)} /> }}
                  onClick={(event) => {
                    removeSearch(item.text);
                    event.preventDefault();
                  }}
                  subtle={true}
                />
              </span>
            </div>
          </FocusZone>
        </UnstyledListItem>
      );
    }

    return (
      <UnstyledListItem
        aria-selected={false}
        aria-setsize={itemProvider.length - (itemProvider.value[1].type === "recent" ? 2 : 1)}
        className="photos-list-row"
        details={details}
        index={index}
        key={index}
        role="option"
      >
        <div className="flex-row flex-align-center padding-horizontal-12 padding-vertical-4">
          <LightBulb className="flex-noshrink" />
          <span className="flex-grow padding-left-8 text-ellipsis">{item.text}</span>
        </div>
      </UnstyledListItem>
    );
  }

  function searchDropdownHeader(item: ISearchItem, index: number, details: IListItemDetails<ISearchItem>) {
    if (item.type === "header") {
      return (
        <tr
          onFocus={(event) => {
            if (item.clear) {
              clearAllRecentsButton.current?.focus();

              // Notify the underlying list of the focus change.
              details.onFocusItem(index, event);
            }
          }}
          onKeyDown={(event) => {
            if (!event.defaultPrevented && event.key === "Enter") {
              removeSearch();
              comboboxElement.current?.focus();
              event.preventDefault();
            }
          }}
          tabIndex={item.clear ? getTabIndex(details) : undefined}
        >
          <td>
            <div className="flex-row flex-align-baseline font-weight-semibold padding-8" onClick={preventDefault}>
              <div aria-hidden={true} className="flex-grow">
                {item.text}
              </div>
              {item.clear && (
                <Link
                  className="body-s focus-treatment"
                  excludeTabStop={true}
                  onClick={() => {
                    removeSearch();
                    comboboxElement.current?.focus();
                  }}
                  ref={clearAllRecentsButton}
                >
                  {ClearAllButton}
                </Link>
              )}
            </div>
          </td>
        </tr>
      );
    }
    return null;
  }

  function activateItem(_event: React.SyntheticEvent<HTMLElement>, listRow: IListRow<ISearchItem>) {
    const { text, type } = listRow.data;
    if (type === "recent" || type === "insipration") {
      executeSearch(text, type === "recent" ? "recent" : "suggested");
    }
  }

  function focus(options: IFocusOptions) {
    if (rootElement.current) {
      const { item } = options;
      const items = rootElement.current.querySelectorAll<HTMLElement>("tr[tabindex]");

      if (item === "first") {
        items[0].focus();
      } else {
        items[items.length - 1].focus();
      }
    }
  }

  function removeSearch(searchTerm?: string) {
    // Update the setting so we track the change to the search terms.
    clearSearch(searchTerm);

    // Update our local provider to update the UX.
    for (let index = 0; index < itemProvider.length; index++) {
      if (itemProvider.value[index].type === "recent") {
        if (!searchTerm || itemProvider.value[index].text === searchTerm) {
          itemProvider.splice(index, 1);

          // If there are no more recent searches we will remove the header.
          if (index === 1 && itemProvider.value[1].type === "header") {
            itemProvider.splice(0, 1);
            break;
          }

          index--;
        }
      }
    }
  }

  function updateLayout() {
    setCount((count) => count + 1);
    dropdownCallout.current?.updateLayout();
  }
}
