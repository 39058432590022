import * as React from "react";
import { EventContext } from "../contexts/event";
import { EventDelegate } from "../utilities/dispatch";

/**
 * A hook that will subscribe to an event on the EventContext, unsubscribe when the component
 * is re-rendered, and unsubscribed when the component is unmounted.
 *
 * This prevents the double-registration of event listeners while a component is re-rendered,
 * since using useEffect to unregister the event listener will unregister it after the
 * component is re-rendered.
 *
 * @param eventName The event to subscribe to
 * @param listener The listener that will listen for the event
 */
export function useEventContextListener<E>(eventName: string, listener: EventDelegate<E>) {
  const eventContext = React.useContext(EventContext);
  const registeredListener = React.useRef<EventDelegate<E> | undefined>(undefined);
  if (registeredListener.current !== listener) {
    if (registeredListener.current) {
      eventContext.removeEventListener(eventName, registeredListener.current);
    }

    eventContext.addEventListener(eventName, listener);
    registeredListener.current = listener;
  }

  React.useEffect(() => {
    return () => {
      eventContext.removeEventListener(eventName, listener);
    };
  }, [eventContext, eventName, listener]);
}
