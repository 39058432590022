import React from "react";

export interface INavigationOptions<T> {
  fallback?: string;
  href?: string;
  state?: T;
  telemetryProperties?: { [propertyName: string]: string | number | boolean | undefined | null };
  useBack?: boolean;
  useReplace?: boolean;
}

export interface INavigationContext {
  navigate<T>(event: React.KeyboardEvent | React.MouseEvent | undefined, name: string, options?: INavigationOptions<T>): void;
  prepare(route: string): string;
  prepare(route: string | undefined): string | undefined;
}

// NOTE: This context DOES NOT track the navigations itself, the application must
// implement the navigation tracking. If using react-router, there is a simple
// useLocationTracker hook that will provide this behavior.
export const NavigationContext = React.createContext<INavigationContext>({ navigate: () => {}, prepare: (route: string) => route });
