import { IMenuItem, MenuButton } from "azure-devops-ui/Menu";
import { css } from "azure-devops-ui/Util";
import { Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { lazy } from "../../../common/components/lazy/lazy";
import { Observer } from "../../../common/components/observer/observer";
import { ResponsiveLayout } from "../../../common/components/responsivelayout/responsivelayout";
import { Scenario } from "../../../common/components/scenario/scenario";
import { TrackChildren } from "../../../common/components/trackchildren/trackchildren";
import { EventContext } from "../../../common/contexts/event";
import { useObservable } from "../../../common/hooks/useobservable";
import { getItem } from "../../api/item";
import { downloadPhoto } from "../../api/photo";
import { FavoritesContext } from "../../contexts/favorite";
import { SessionContext } from "../../contexts/session";
import { addFavorite, addTag, deletePhotos, deleteTag, loadPhotoDetails, removeFavorite, updatePhoto } from "../../operations/photo";
import { getDuplicatedPhotosParentFromPhoto } from "../../utilities/util";
import { Delete, InformationInverted, Share } from "../illustration/icons";
import { DeleteQuickCommand, DetailsQuickCommand, FavoriteQuickCommand, ShareQuickCommand } from "../quickcommand/quickcommand";
import { IPhotoTileProps, PhotoTile } from "./phototile";

const DeletePhotoDialog = lazy(() => import("../../dialogs/deletephotodialog"));
const DetailsPanel = lazy(() => import("../../panels/detailspanel"));
const ShareDialogODB = lazy(() => import("../../dialogs/sharedialogodb"));
const ShareDialogODC = lazy(() => import("../../dialogs/sharedialogodc"));

const { DeleteButton, MoreButton, InfoButton, ShareButton } = window.Resources.Common;

export interface IPhotoTileCommandProps extends IPhotoTileProps {
  /**
   * Set callout ReactNode
   */
  setCallout?: (callout: React.ReactElement | null) => void;

  /**
   * Should the delete command be shown on this tile?
   *
   * @default true
   */
  showDelete?: boolean;

  /**
   * Should the favorite status and command be shown on this tile?
   *
   * @default true
   */
  showFavorite?: boolean;

  /**
   * Should the information command be shown on this tile?
   *
   * @default true
   */
  showInfo?: boolean;

  /**
   * Should the share command be shown on this tile?
   *
   * @default true
   */
  showShare?: boolean;
}

export function PhotoTileCommand(props: IPhotoTileCommandProps): React.ReactElement {
  const { photo, showDelete = true, showFavorite = true, showInfo = true, showShare = true, telemetryProperties, setCallout, ...tileProps } = props;

  const eventContext = React.useContext(EventContext);
  const favoritesContext = React.useContext(FavoritesContext);
  const sessionContext = React.useContext(SessionContext);

  const commandElement = React.useRef<HTMLDivElement>(null);
  const detailsPanelShown = React.useRef(false);

  const [, setCount] = React.useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useObservable(false);
  const [showShareDialog, setShowShareDialog] = useObservable(false);

  const menuItems: IMenuItem[] = [];
  let deleteCommand: React.ReactElement | null = null;
  let favoriteCommand: React.ReactElement | null = null;
  let infoCommand: React.ReactElement | null = null;
  let shareCommand: React.ReactElement | null = null;

  if (showFavorite && sessionContext.accountType !== "business") {
    favoriteCommand = (
      <FavoriteQuickCommand
        key="favorite"
        onClick={(event) => {
          eventContext.dispatchEvent("telemetryAvailable", {
            ...telemetryProperties,
            action: "userAction",
            name: "favoriteQuickAction"
          });

          favoritesContext.favorites.has(photo.id) ? removeFavorite(eventContext, [photo.id]) : addFavorite(eventContext, [photo.id]);
          setCount((count) => count + 1);
          event.preventDefault();
        }}
        photo={photo}
      />
    );
  }

  if (showDelete) {
    deleteCommand = (
      <DeleteQuickCommand
        key="download"
        onClick={() => {
          setShowDeleteDialog(true);
        }}
        photo={photo}
      />
    );

    menuItems.push({
      className: "phototile-command",
      id: "phototiie-delete-command",
      iconProps: { render: (className: string) => <Delete className={className} /> },
      text: DeleteButton,
      onActivate: () => {
        eventContext.dispatchEvent("telemetryAvailable", {
          ...telemetryProperties,
          action: "userAction",
          name: "deleteQuickAction"
        });

        setShowDeleteDialog(true);
      }
    });
  }

  if (showInfo) {
    infoCommand = <DetailsQuickCommand key="details" onClick={() => showDetailsPanel()} photo={photo} />;

    menuItems.push({
      className: "phototile-command",
      id: "phototiie-info-command",
      iconProps: { render: (className: string) => <InformationInverted className={className} /> },
      text: InfoButton,
      onActivate: () => {
        eventContext.dispatchEvent("telemetryAvailable", {
          ...telemetryProperties,
          action: "userAction",
          name: "detailsQuickAction"
        });

        showDetailsPanel();
      }
    });
  }

  if (showShare) {
    shareCommand = <ShareQuickCommand key="share" onClick={() => setShowShareDialog(true)} photo={photo} />;

    menuItems.push({
      className: "phototile-command",
      id: "phototiie-share-command",
      iconProps: { render: (className: string) => <Share className={className} /> },
      text: ShareButton,
      onActivate: () => {
        eventContext.dispatchEvent("telemetryAvailable", {
          ...telemetryProperties,
          action: "userAction",
          name: "shareQuickAction"
        });

        setShowShareDialog(true);
      }
    });
  }

  return (
    <>
      <PhotoTile
        {...tileProps}
        commandActive={() =>
          showDeleteDialog.value ? "delete" : detailsPanelShown.current ? "details" : showShareDialog.value ? "share" : undefined
        }
        commands={
          <ResponsiveLayout containerElement={commandElement} key="command-layout" responsiveSelectors={[".quick-command-container"]}>
            {({ responsiveIndex }) => (
              <div className="flex-row flex-justify-between flex-grow overflow-hidden padding-4 rhythm-horizontal-4" ref={commandElement}>
                <div className="favorite-quick-action tile-hover flex-row rounded pointer-events-auto" role="presentation">
                  {favoriteCommand}
                </div>
                <div className="quick-command-container flex-row flex-align-center flex-noshrink pointer-events-auto" role="presentation">
                  {shareCommand}
                  {deleteCommand}
                  {infoCommand}
                </div>
                {menuItems.length > 0 && (
                  <MenuButton
                    ariaLabel={MoreButton}
                    className={css(
                      "transparent icon-only tile-hover flex-row rounded pointer-events-auto flex-noshrink margin-left-8",
                      responsiveIndex === -1 && "hidden"
                    )}
                    contextualMenuProps={{
                      anchorOffset: { horizontal: 0, vertical: 4 },
                      anchorOrigin: { horizontal: Location.start, vertical: Location.end },
                      className: "phototile-dropdown compact-menu",
                      menuOrigin: { horizontal: Location.start, vertical: Location.start },
                      menuProps: {
                        id: "overflow-menu",
                        items: menuItems
                      }
                    }}
                    hideDropdownIcon={true}
                    iconProps={{ iconName: "More", className: "text-on-image-background" }}
                    subtle={true}
                  />
                )}
              </div>
            )}
          </ResponsiveLayout>
        }
        photo={photo}
        showFavorite={showFavorite}
        telemetryProperties={telemetryProperties}
      />
      <Observer values={{ showDeleteDialog }}>
        {({ showDeleteDialog }) => {
          return showDeleteDialog ? (
            <React.Suspense fallback={null}>
              <Scenario scenarioName="deletephotos">
                <DeletePhotoDialog
                  deletePhotos={deletePhotos}
                  duplicatePhotoParentName={getDuplicatedPhotosParentFromPhoto(photo)}
                  items={[photo]}
                  onDismiss={() => setShowDeleteDialog(false)}
                  photoIds={[photo.id, ...(photo.duplicates?.map((duplicatedPhoto) => duplicatedPhoto.id) ?? [])]}
                />
              </Scenario>
            </React.Suspense>
          ) : null;
        }}
      </Observer>
      <Observer values={{ showShareDialog }}>
        {({ showShareDialog }) => {
          const ShareDialog = sessionContext.accountType === "business" ? ShareDialogODB : ShareDialogODC;
          return showShareDialog ? (
            <React.Suspense fallback={null}>
              <Scenario scenarioName="sharedialog">
                <ShareDialog items={[photo]} onDismiss={() => setShowShareDialog(false)} />
              </Scenario>
            </React.Suspense>
          ) : null;
        }}
      </Observer>
    </>
  );

  function showDetailsPanel() {
    if (setCallout) {
      const detailsPanel = (
        <React.Suspense key={`details-panel-${photo.id}`} fallback={null}>
          <TrackChildren
            mount={() => {
              detailsPanelShown.current = true;
            }}
            unmount={() => {
              detailsPanelShown.current = false;
              // refresh the command on unmount
              setCount((count) => count + 1);
            }}
          >
            <Scenario scenarioName="detailspanel">
              <DetailsPanel
                addTag={addTag}
                deleteTag={deleteTag}
                downloadPhoto={downloadPhoto}
                getItem={getItem}
                loadPhotoDetails={loadPhotoDetails}
                onDismiss={() => setCallout?.(null)}
                photo={photo}
                updatePhoto={updatePhoto}
              />
            </Scenario>
          </TrackChildren>
        </React.Suspense>
      );
      setCallout(detailsPanel);
    }
  }
}
