import { defer, IDeferred } from "./promise";

interface ITask<T> {
  callback?: () => Promise<T>;
  deferredTask?: IDeferred<T>;
}

/**
 * createTaskQueue is used to queue up asynchronous work and only allow a small
 * number of concurrent tasks to execute at once.
 *
 * Queues are usually designed to be short lived entities that are used to throttle
 * a burst of asynchronous work like a large set of network requests. You don't
 * want to start all up front but you will complete the entire set shortly.
 *
 * @param maxConcurrent The maximum number of tasks that can execute concurrently.
 * If more tasks are queued than can be executed the will wait for other tasks to
 * complete. The queue is processed in first-in first-out order.
 *
 * @returns A method that allows the caller to queue tasks.
 */
export function createTaskQueue(maxConcurrent: number = 1) {
  let queuedTasks: ITask<unknown>[] = [];
  let executingCount = 0;
  let startIndex = 0;

  /**
   * completeTask reduces the active task count and determines if new tasks are
   * available to run.
   */
  function completeTask(task: ITask<unknown>) {
    executingCount--;
    evaluateQueue();

    // Release resources associated with this task.
    task.callback = undefined;
    task.deferredTask = undefined;
  }

  /**
   * evaluateQueue will determine if there are open slots in the execution count
   * to allow new tasks to run and start them.
   */
  function evaluateQueue() {
    if (executingCount < maxConcurrent && startIndex < queuedTasks.length) {
      const task = queuedTasks[startIndex++];

      // Increment the number of concurrently running tasks.
      executingCount++;

      // Run the task, and when it completes decrement the count and re-evaluate
      // the task queue.
      task.callback!().then(
        (result) => {
          task.deferredTask!.resolve(result);
          completeTask(task);
        },
        (error) => {
          task.deferredTask!.reject(error);
          completeTask(task);
        }
      );
    }
  }

  /**
   * allows the caller to determine the number of pending tasks in the queue. This
   * generally shouldn't be used because the caller can track this through their
   * own promises. It is generally added for test validation.
   *
   * @returns The number of currently queued tasks.
   */
  function getQueueLength(): number {
    return queuedTasks.length - startIndex;
  }

  /**
   * queueTask is the method that allows callers to queue up asynchronous work.
   * When there is free tasks in the queue the callback will be executed and
   * its async work can beging, returning a promise for completion.
   *
   * @param callback The callback function that will be executed when the task
   * is run.
   * @returns Promise for the completion of the task.
   */
  function queueTask<T>(callback: () => Promise<T>): Promise<T> {
    const deferredTask = defer<T>();

    // Queue the callback along with the associated deferred task.
    queuedTasks.push({ callback, deferredTask });

    evaluateQueue();
    return deferredTask.promise;
  }

  return { getQueueLength, queueTask };
}
