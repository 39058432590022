import { IEventDispatch } from "./dispatch";
import { parseNetworkError } from "./network";

/**
 * A FetchFunction is a function that executes the fetch network request and
 * returns the response. It SHOULD include a unique name that will be used in
 * telemetry to identify the action.
 *
 * The supplied function will be given the request options as the first parameter
 * followed by all parameters supplied by the caller.
 */
export type FetchFunction<T, P extends any[]> = (fetch: (url: string, init?: RequestInit) => Promise<Response>, ...args: P) => Promise<T>;

/**
 * A FetchTranslation is a function that is used to translate a network response
 * into another response.
 */
export type FetchTranslation = (result: PromiseSettledResult<unknown>) => Promise<PromiseSettledResult<unknown>>;

/**
 * Basic telemetry properties that are tracked throughout the fetch process.
 */
export interface IFetchTelemetry {
  [key: string]: string | number | boolean;
}

/**
 * The fetchEnd event is fired after the network request is complete,
 * including any retries. The response is the raw network response returned
 * from the network. The initiating method may do extra processing.
 */
export interface IFetchEndEvent {
  /**
   * The fetch options supplied to the fetch function when it was setup.
   */
  options?: IFetchOptions;

  /**
   * The raw response returned from the network layer. This is done before the
   * method converts the response into a result.
   */
  response: Response;

  /**
   * Set of telemetry properties that should be reported from the fetch events.
   * This data should be augmented by the caller during event callbacks.
   */
  telemetryProperties: IFetchTelemetry;
}

/**
 * The fetchComplete makes the IFetchCompleteEvent object available in the event.
 */
export interface IFetchCompleteEvent {
  /**
   * The length of time the request took to execute in milliseconds.
   */
  duration: number;

  /**
   * The endTime of the request.
   */
  endTime: number;

  /**
   * The name of the method called for this request.
   */
  name: string;

  /**
   * The resolved options be used for this fetch operation. This the combination
   * of the user supplied options and the defaults when not supplied.
   */
  options?: IFetchOptions;

  /**
   * The result of the fetch request.
   */
  result: PromiseSettledResult<any>;

  /**
   * The start time of the request.
   */
  startTime: number;

  /**
   * Set of telemetry properties that should be reported from the fetch events.
   * This data should be augmented by the caller during event callbacks.
   */
  telemetryProperties: IFetchTelemetry;

  /**
   * The URL being used to execute the request.
   */
  url: string;
}

/**
 * The fetchPrepare makes the IFetchPrepareEvent object available in the event.
 */
export interface IFetchPrepareEvent {
  /**
   * init makes the request init object available to the listener. The listener can
   * then use this event to update the init with its required state.
   */
  init: RequestInit;

  /**
   * The name of the method called for this request.
   */
  name: string;

  /**
   * The fetch options supplied to the fetch function when it was setup.
   */
  options?: IFetchOptions;

  /**
   * Set of telemetry properties that should be reported from the fetch events.
   * This data should be augmented by the caller during event callbacks.
   */
  telemetryProperties: IFetchTelemetry;

  /**
   * The URL being used to execute the request.
   */
  url: string;

  /**
   * waitUntil can be called and given a promise. This promise signals to the fetch
   * call that the call is not ready and should wait until the supplied promise is
   * complete before executing.
   *
   * @param promise a promise that must complete before the fetch call can be made.
   * @param tag optional tag for diagnotics when waiting for an async completion.
   */
  waitUntil: (promise: Promise<void>, tag?: string) => void;
}

/**
 * The fetchStart makes the IFetchStartEvent object available in the event.
 */
export interface IFetchStartEvent {
  /**
   * The name of the method called for this request.
   */
  name: string;

  /**
   * The fetch options supplied to the fetch function when it was setup.
   */
  options?: IFetchOptions;

  /**
   * Set of telemetry properties that should be reported from the fetch events.
   * This data should be augmented by the caller during event callbacks.
   */
  telemetryProperties: IFetchTelemetry;

  /**
   * The URL being used to execute the request.
   */
  url: string;
}

/**
 * The fetchTranslate event can be used to translate the response from the
 * fetch into another response. This can be useful when the application
 * conditions need to change the result.
 */
export interface IFetchTranslateEvent {
  /**
   * Registers the provided callback to translate network responses before they
   * are returned to their caller. Translators are invoked asynchronously, in
   * the order that they are registered, with subsequent translators passed the
   * result of the previous translator.
   *
   * Translation doesn't begin until the fetchTranslate event has completed and
   * all translators have been registered.
   *
   * @param callback To translate the result the caller can supply a translation
   * function. The translation function is given the current result and should
   * return the desired result.
   */
  translate: (callback: FetchTranslation) => void;
}

/**
 * When using the createRequest hook you can options that affect how the fetch
 * operation handles specfic circumstances.
 */
export interface IFetchOptions {
  /**
   * The default options prevent duplicate requests from being executed at the same time.
   * This means that if the fetch function is called twice with the same arguments
   * while the first request is active, it will return the results of the current
   * request with the same arguments.
   *
   * This uses reference equality to determine duplicates, so two calls with
   * different objects with the same values are still different. This is similar
   * to how React dependency lists work.
   *
   * @default false
   */
  allowDuplicate?: boolean;

  /**
   * Optional data the caller can pass through the request. This data is available to the
   * event handlers and this can be used to provide custom behaviors where needed.
   */
  readonly data?: any;

  /**
   * The caller can supply an event dispatch which is used to notify you when requests
   * are being prepared and completed. If an eventDispatch isn't supplied the hooks
   * can't be used to participate in the fetch processing.
   */
  eventDispatch?: IEventDispatch;

  /**
   * A custom fetch function can be supplied and will be used to execute the
   * the fetch call. If one is not supplied window.fetch will be used. When
   * calling from the service worker you MUST supply self.fetch or another
   * implementation since window.fetch is not available.
   *
   * @default window.fetch
   */
  fetch?: (url: string, init?: RequestInit) => Promise<Response>;

  /**
   * Name that is used to record information related to this fetch call. If no name
   * is supplied a static name will be used. Due to minification using the fetch
   * functions name isn't useful as they are minified.
   *
   * @default __unnammed__
   */
  name?: string;

  /**
   * When a network request fails should the response be parsed into an error
   * object. If the response is JSON the body will be returned as the result.
   *
   * @default true
   */
  parseFailure?: boolean;

  /**
   * A retryCallback can be supplied, this will be used to determine if a retry
   * can be attempted based on the previous request that failed. The return
   * value is a promise to allow the caller to perform asynchronous work before
   * beginning the retry.
   *
   * @param requestUrl The URL of the resource requested.
   * @param init Options used to make the request.
   * @param response The response from the current network call.
   * @param options The options that were supplied to the createRequest call.
   * @returns A promise whether a retry should be attempted or not.
   */
  retryCallback?: (requestUrl: string, init: RequestInit, response: Response, options?: IFetchOptions) => Promise<boolean>;

  /**
   * Set of initial telemetryProperties that are available for this network request.
   */
  telemetryProperties?: IFetchTelemetry;

  /**
   * Requests can optionally be created with a timeout. This will cause the
   * network request to be aborted after the specified timeout.
   */
  timeoutMs?: number;
}

export function createRequest<T, P extends any[]>(fetchFunction: FetchFunction<T, P>, options?: IFetchOptions): (...args: P) => Promise<T> {
  const activeRequests: Array<{ args: P; fetchPromise: Promise<T> }> = [];

  return (...args: P): Promise<T> => {
    const {
      allowDuplicate = false,
      eventDispatch,
      fetch = window.fetch,
      name = "__unnamed__",
      parseFailure = true,
      retryCallback,
      timeoutMs
    } = options || {};

    let remainingRetries = 1;

    // If the caller requested duplicate requests be removed we need to look for
    // an in process request that matches the incoming request.
    if (!allowDuplicate) {
      for (let activeIndex = 0; activeIndex < activeRequests.length; activeIndex++) {
        const activeRequest = activeRequests[activeIndex];

        // They must have the same number of arguments to be equal.
        if (args.length === activeRequest.args.length) {
          let duplicate = true;

          // Compare each of the arguments for reference equality.
          for (let argumentIndex = 0; argumentIndex < activeRequest.args.length; argumentIndex++) {
            if (args[argumentIndex] !== activeRequest.args[argumentIndex]) {
              duplicate = false;
              break;
            }
          }

          if (duplicate) {
            return activeRequest.fetchPromise;
          }
        }
      }
    }

    // Execute the supplied function with the localFetch delegate.
    // We perform this within a local promise context to get through the then/catch/finally
    // all before the return to the caller. This lets us track the time taken in
    // the network call without capturing the returnee's time.
    const fetchPromise = new Promise<T>((resolve, reject) => {
      const telemetryProperties: IFetchTelemetry = { ...options?.telemetryProperties };
      const startTime = Date.now();
      let resolvedUrl: string;

      // Call the underlying function passed in by the caller.
      fetchFunction(localFetch, ...args)
        .then((value: T) => completeRequest({ status: "fulfilled", value }))
        .catch((reason: any) => {
          if (parseFailure && (typeof Response === "object" || typeof Response === "function") && reason instanceof Response) {
            return parseNetworkError(reason).then((reason) => {
              completeRequest({ reason, status: "rejected" });
            });
          } else {
            completeRequest({ reason, status: "rejected" });
          }
        });

      // CompleteRequest should be called to finish up telemetry, handle parallel
      // request processing, and any other cleanup.
      function completeRequest(result: PromiseSettledResult<T>): Promise<void> {
        const endTime = Date.now();
        const translators: FetchTranslation[] = [];
        let translationIndex = 0;

        // Collect the set of translations required before starting translation.
        eventDispatch?.dispatchEvent("fetchTranslate", {
          translate: (callback: FetchTranslation) => {
            translators.push(callback);
          }
        });

        // Perform the backlog of translations then complete the event.
        return translate(result).then((result) => {
          const fetchCompleteEvent: IFetchCompleteEvent = {
            duration: endTime - startTime,
            endTime,
            name,
            options,
            result,
            startTime,
            telemetryProperties,
            url: resolvedUrl
          };

          eventDispatch?.dispatchEvent("fetchComplete", fetchCompleteEvent);

          // Remove the completed request from the set of active requests.
          activeRequests.splice(
            activeRequests.findIndex((activeRequest) => activeRequest.fetchPromise === fetchPromise),
            1
          );

          // Resolve/Reject the request with the events result. This can be changed
          // by the event handler.
          if (result.status === "fulfilled") {
            resolve(result.value);
          } else {
            reject(result.reason);
          }
        });

        function translate(result: PromiseSettledResult<T>): Promise<PromiseSettledResult<T>> {
          if (translators.length > translationIndex) {
            return translators[translationIndex++](result)
              .then((result: PromiseSettledResult<T>) => {
                if (result.status === "rejected") {
                  const { reason } = result;

                  if (parseFailure && (typeof Response === "object" || typeof Response === "function") && reason instanceof Response) {
                    return parseNetworkError(reason).then((reason) => {
                      return translate({ reason, status: "rejected" });
                    });
                  }
                }

                return translate(result);
              })
              .catch(() => translate(result));
          }

          return Promise.resolve(result);
        }
      }

      function localFetch(url: string, init?: RequestInit): Promise<Response> {
        const _init: RequestInit = { ...init };
        const pendingPromises: Promise<void>[] = [];
        const event: IFetchPrepareEvent = {
          init: _init,
          name,
          options,
          telemetryProperties,
          url,
          waitUntil: (promise: Promise<void>) => pendingPromises.push(promise)
        };
        let abortController: AbortController | undefined;

        // If a timeout was specified, we will use an AbortController to abort the
        // request after the timeout has past.
        if (timeoutMs) {
          abortController = new AbortController();
          window.setTimeout(() => abortController?.abort(), timeoutMs);
        }

        // We will start with the initial url supplied by the function. This may
        // be updated through the preparation process, but we want to ensure it
        // has a value in completion telemetry.
        resolvedUrl = event.url;

        // Prepare the request init object and dispatch it through the fetchPrepare event.
        eventDispatch?.dispatchEvent("fetchPrepare", event);

        // Wait for the pending promises to resolve before making calling the fetch function.
        return Promise.all(pendingPromises)
          .catch((reason) => {
            // We failed to resolve the preparation so the call will not be made,
            // but we want to record as many details as possible about the
            // failure, including the current state or the URL resolution.
            resolvedUrl = event.url;

            return Promise.reject({
              error: {
                code: "preparationFailed",
                message: reason instanceof Error ? reason.toString() : typeof reason === "string" ? reason : JSON.stringify(reason)
              }
            });
          })
          .then(() => {
            // fetchPrepare may have updated the url and we need to use the updated value.
            resolvedUrl = event.url;

            eventDispatch?.dispatchEvent("fetchStart", {
              name,
              options,
              url: resolvedUrl
            });

            // Execute the fetch request.
            const fetchPromise = fetch(resolvedUrl, { signal: abortController?.signal, ..._init }).then((response) => {
              // Clear any abortController that was setup.
              abortController = undefined;

              // If the request failed and we have remainingRetries we will check if the caller
              // wants to attempt a retry of this network call.
              if (retryCallback && response.status >= 400 && response.status < 600 && remainingRetries--) {
                return retryCallback(resolvedUrl, _init, response, options).then((retry) => {
                  if (retry) {
                    // Record that a retry was done in the telemetry.
                    telemetryProperties.retry = true;
                    return localFetch(url, init);
                  }

                  return processResponse(response);
                });
              }

              return processResponse(response);
            });

            return fetchPromise;
          });

        function processResponse(response: Response): Response {
          eventDispatch?.dispatchEvent("fetchEnd", { options, response, telemetryProperties });
          return response;
        }
      }
    });

    // Add the resulting promise to our pending result map when we are latest only.
    // This allows us to ignore results from earlier calls that complete after the
    // new call has started.
    if (!allowDuplicate) {
      activeRequests.push({ args, fetchPromise });
    }

    return fetchPromise;
  };
}
