import { Button } from "azure-devops-ui/Button";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { EventContext } from "../../../common/contexts/event";
import { useId } from "../../../common/hooks/useid";
import { format } from "../../../common/utilities/format";
import { AdditionCircle, SubtractionCircle } from "../illustration/icons";
import { Slider } from "../slider/slider";

import "./zoom.css";

const { ZoomButtonWithZoomInfo, ZoomInButton, ZoomLevel, ZoomOutButton } = window.Resources.Common;

const _maxZoomLevel = 500;
const _minZoomLevel = 150;
const _zoomIncrement = 25;

export interface IZoomProps {
  className?: string;
  maxZoomLevel?: number;
  minZoomLevel?: number;
  role?: string;
  setZoomLevel: (value: number) => void;
  zoomIncrement?: number;
  zoomLevel: number;
}

export function Zoom(props: IZoomProps): React.ReactElement {
  const {
    className,
    maxZoomLevel = _maxZoomLevel,
    minZoomLevel = _minZoomLevel,
    role = "menuitem",
    setZoomLevel,
    zoomIncrement = _zoomIncrement,
    zoomLevel
  } = props;

  const eventContext = React.useContext(EventContext);
  const uniqueId = useId();

  return (
    <div className="flex-row rhythm-horizontal-4">
      <Button
        ariaLabel={format(ZoomButtonWithZoomInfo, {
          zoomButton: ZoomOutButton,
          zoomLevel
        })}
        iconProps={{ render: (className: string) => <SubtractionCircle className={className} /> }}
        id={`zoom-out-${uniqueId}`}
        onClick={() => {
          const value = Math.max(minZoomLevel, zoomLevel - zoomIncrement);
          eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "zoomOutButton", value });
          setZoomLevel(value);
        }}
        role={role}
        subtle={true}
        tooltipProps={{
          addAriaDescribedBy: false,
          text: format(ZoomButtonWithZoomInfo, {
            zoomButton: ZoomOutButton,
            zoomLevel
          })
        }}
      />
      <Slider
        ariaLabel={ZoomLevel}
        className={css("photo-zoom-slider", className)}
        delay={100}
        excludeTabStop={true}
        id="photo-zoom"
        max={maxZoomLevel}
        min={minZoomLevel}
        onChange={(value: number) => {
          eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "zoomSliderAllPhotos", value });
          setZoomLevel(value);
        }}
        pivot="center"
        step={zoomIncrement}
        value={zoomLevel}
      />
      <Button
        ariaLabel={format(ZoomButtonWithZoomInfo, {
          zoomButton: ZoomInButton,
          zoomLevel
        })}
        iconProps={{ render: (className: string) => <AdditionCircle className={className} /> }}
        id={`zoom-in-${uniqueId}`}
        onClick={() => {
          const value = Math.min(maxZoomLevel, zoomLevel + zoomIncrement);
          eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "zoomInButton", value });
          setZoomLevel(value);
        }}
        role={role}
        subtle={true}
        tooltipProps={{
          addAriaDescribedBy: false,
          text: format(ZoomButtonWithZoomInfo, {
            zoomButton: ZoomInButton,
            zoomLevel
          })
        }}
      />
    </div>
  );
}
