import { Button } from "azure-devops-ui/Button";
import React from "react";
import { EventContext } from "../../../common/contexts/event";
import { FeatureContext } from "../../../common/contexts/feature";
import { useSubscription } from "../../../common/hooks/useobservable";
import * as Icons from "../illustration/icons";

export interface ICollapseMenuProps {
  className?: string;
  role?: string;
}

const { Active, Inactive, ToggleCompactView } = window.Resources.Common;

export function CompactButton(props: ICollapseMenuProps): React.ReactElement {
  const { className, role = "menuitem" } = props;

  const featureContext = React.useContext(FeatureContext);
  const eventContext = React.useContext(EventContext);
  const showCompactLayout = useSubscription(featureContext.featureEnabled("showCompactLayout"));

  const updateSetting = () => {
    eventContext.dispatchEvent("telemetryAvailable", {
      action: "userAction",
      feature: "showCompactLayout",
      name: "toggleFeature",
      value: !showCompactLayout
    });

    featureContext.setFeatureEnabled("showCompactLayout", !showCompactLayout);
  };

  return (
    <Button
      ariaLabel={`${ToggleCompactView}, ${showCompactLayout ? Active : Inactive}`}
      className={className}
      iconProps={{
        render: (className?: string) => (showCompactLayout ? <Icons.Expanded className={className} /> : <Icons.Compact className={className} />)
      }}
      id="compact"
      onClick={updateSetting}
      role={role}
      subtle={true}
    />
  );
}
