import { getFocusElements } from "../utilities/focus";

/**
 * useFocusTrap is used to prevent the mousedown events from bubbling up through
 * the element clicked to determine if the element clicked on should process the
 * mousedown event. This is done by only allowing mousedown events on focusable
 * elements and will have some side effects. An example is it will prevent \
 * selection of text in non-focusable elements.
 *
 * @param rootElement The rootElement to trap the focus within. This element is
 * used to determine the set of eligible focus targets.
 * @returns An MouseDown event handler that can be bound to one or more elements.
 */
export function useFocusTrap(rootElement: React.RefObject<HTMLElement>): React.MouseEventHandler {
  function onMouseDown(event: React.MouseEvent) {
    if (rootElement.current) {
      const elements = getFocusElements(rootElement.current);
      let preventDefault = true;

      for (let element of elements) {
        if (element.contains(event.target as HTMLElement)) {
          preventDefault = false;
          break;
        }
      }

      if (preventDefault) {
        event.preventDefault();
      }
    }
  }

  return onMouseDown;
}
