import { hasError } from "../api/network";

export type ResultType = "Success" | "Failure" | "ExpectedFailure";

/**
 * A set of error codes to determine whether an encountered error should be treated as expected.
 */
export const expected_error_codes = new Set([
  "GeoLocInvalidRequest",
  "ItemDoesNotExist",
  "activityLimitReached",
  "extensionError",
  "invalidRange",
  "itemNotFound",
  "malwareDetected",
  "nameAlreadyExists",
  "notAllowed",
  "quotaLimitReached",
  "resourceModified",
  "resyncRequired",
  "syncStateNotFound",
  "unauthenticated"
]);

const expected_error_messages = new Set([
  "The user aborted a request.",
  "<safe>The user aborted a request.</safe>",
  "The operation was aborted. ",
  "<safe>The operation was aborted. </safe>",
  "Fetch is aborted",
  "<safe>Fetch is aborted</safe>",
  "Failed to fetch",
  "<safe>Failed to fetch</safe>",
  "NetworkError when attempting to fetch resource.",
  "<safe>NetworkError when attempting to fetch resource.</safe>",
  "The Internet connection appears to be offline.",
  "<safe>The Internet connection appears to be offline.</safe>",
  "Connection reset by peer",
  "<safe>Connection reset by peer</safe>",
  '{"status":-1,"code":"NoConnection"}',
  '<safe>{"status":-1,"code":"NoConnection"}</safe>',
  "Timeout",
  "<safe>Timeout</safe>",
  "Load failed",
  "User migrated.",
  "The Permission and password is not matched.",
  "The sharing link no longer exists, or you do not have permission to access it.",
  "ownerCannotShareWithSelf"
]);

/**
 * Set of Vroom innerError codes that are expected and should be reported as such (even if the parent error is not marked as expected)
 */
const expectedVroomInnerErrors = [
  "faceAINotAccessibleException",
  "faceAINotReadyException",
  "faceAITemporarilyUnavailableException",
  "biometricConsentPendingException",
  "biometricConsentNotFoundException",
  "biometricConsentDeniedException",
  "biometricConsentRevokedException",
  "biometricConsentRequiredForDefaultUsersException",
  "faceAIUsageRestrictedException"
];

/**
 * Used to determine if the failure is an expected failure or not.
 *
 * @param code The error code returned from the network
 * @param message An optional message that may indicdate failure details.
 * @param error An optional error response body object returned by the network
 * @returns ExpectedFailure when it matches a well-known and expected condition.
 */
export function getFailureType(code: string, message?: string, error?: any): ResultType {
  if (
    expected_error_codes.has(code) ||
    (message && (expected_error_messages.has(message) || message.includes("Network issue"))) ||
    (error && hasError(error, expectedVroomInnerErrors))
  ) {
    return "ExpectedFailure";
  }

  return "Failure";
}
