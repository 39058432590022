import React from "react";
import { ICancelablePromise, makeCancelable } from "../utilities/promise";

/**
 * Custom hook to cancel promises when the component unmounts
 *
 * Example:
 * ```
 * const { trackPromise } = usePromise();
 *
 * useEffect(() => {
 *  const x: Promise<number> = svc.doSomethingAsync();
 *  trackPromise(x).then(() => ...).catch(() => ...);
 * });
 * ```
 */
export function usePromise(deps?: React.DependencyList) {
  // Create the container for tracking the active promises.
  const promises = React.useRef<ICancelablePromise<unknown>[]>([]);

  // If a dependency list was supplied and they change we will start a new
  // set of promises, and let the old promises get canceled on unmount. We
  // need to start a new set since the caller has changed dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useMemo(() => (promises.current = []), deps);

  // When the owning component unmounts we will cancel all promises.
  React.useEffect(() => {
    const iterationPromises = promises.current;

    // Called when the component unmounts to cancel all pending promises
    return () => {
      iterationPromises.forEach((p) => p.cancel({ unmount: true }));

      // We can't null our the array, we must just reset it since the component can
      // continue to be used after this hook.
      // See: https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/85
      iterationPromises.splice(0, iterationPromises.length - 1);
    };
  }, []);

  return { trackPromise: React.useCallback(trackPromise, []) };

  /**
   * Creates a promise that will be canceled if the effect cleanup is executed.
   *
   * @param promise Underlying promise that is made cancelable.
   * @param tag A string that can optionally be added to the promise that will be included
   * in the cancelation reason. This can help the developer understand what originated the
   * cancelable promise.
   * @returns A promise that represents the underlying promises result that can be canceled.
   */
  function trackPromise<T>(promise: Promise<T>, tag?: string): Promise<T> {
    const cancelablePromise = makeCancelable(promise, tag || "trackPromise");
    promises.current.push(cancelablePromise);

    // Make sure to clear our the promise from our tracking array when it completes.
    return cancelablePromise.finally(() => {
      promises.current.splice(promises.current.indexOf(cancelablePromise), 1);
    });
  }
}
