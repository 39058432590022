import React from "react";
import { Scenario } from "../../../common/components/scenario/scenario";
import { FeatureContext } from "../../../common/contexts/feature";
import { useTimeout } from "../../../common/hooks/usetimeout";
import { ThrottleContext } from "../../contexts/throttle";

/**
 * A throttle delay is mapped to a throttling value and the length of time in
 * milliseconds it should be throttled.
 */
export interface IThrottleDelay {
  /**
   * What percentage of throttling should this throttle be applied.
   */
  throttle: number;

  /**
   * The length of time in milliseconds the throttle should last.
   */
  delayMs: number;
}

/**
 * Throttling progresses from start to end or unmount depending on whehter
 * or not the throttle component stays mounted for the duration of the
 * throttle
 */
export type ThrottleStatus = "throttleStart" | "throttleEnd" | "throttleUnmount";

export interface IThrottleProps {
  /**
   * If the children of the throttle are blocking the active scenario we need to
   * block while we are throttling.
   */
  blocking?: boolean;

  /**
   * The component will use a default set of rules for throttling the children
   * but can have a custom configuration if needed.
   *
   * The values in the configuration should be ordered by the throttle value
   * descending. The component will enumerate through the delays until it finds
   * first one that satifies the conditions.
   */
  configuration: IThrottleDelay[];

  /**
   * The origin being used in the throttling request.
   */
  origin: string;

  /**
   * A placeholder can be rendered why the throttle is being applied. If no
   * placeholder is supplied no children will be generated.
   */
  placeholder?: React.ReactElement;

  /**
   * The caller can supply a callback function that is called when throttling
   * is applied. This allows the caller to understand the impact of the throttle
   * on the children.
   *
   * @param throttleDelay The throttle delay that was applied to the children
   */
  throttleCallback?: (throttleDelay: IThrottleDelay, status: ThrottleStatus) => void;
}

/**
 * The throttle component can be wrapped around a set of elements to delay the
 * mounting of the components until throttling checks are passed. The goal of
 * this is to reduce network traffic when the user is getting close to throttle
 * limits.
 */
export function Throttle(props: IThrottleProps & { children: React.ReactElement }): React.ReactElement | null {
  const { blocking, children, configuration, origin, placeholder = null } = props;

  const featureContext = React.useContext(FeatureContext);
  const throttleContext = React.useContext(ThrottleContext);

  // Timeout used to re-render with the children available.
  const { setTimeout } = useTimeout();

  // Produce a function for tracking the throttleStatus notifications.
  // This function tracks the status of the throttle and wont send the unmount
  // status if we end the throttling before unmounting.
  const [throttleCallback] = React.useState(() => {
    let throttleActive = false;

    return (throttleDelay: IThrottleDelay | undefined, throttleStatus: ThrottleStatus) => {
      if (throttleDelay) {
        if (throttleStatus === "throttleStart") {
          throttleActive = true;
        } else if (throttleStatus === "throttleEnd") {
          throttleActive = false;
        } else if (throttleActive === false) {
          return;
        }

        props.throttleCallback && props.throttleCallback(throttleDelay, throttleStatus);
      }
    };
  });

  // Compute the initial throttle delay for this component.
  const [throttleDelay] = React.useState<IThrottleDelay | undefined>(() => {
    // Only apply throttles if network throttling is enabled.
    if (featureContext.featureEnabled("throttleNetwork").value) {
      const throttle = throttleContext.throttle(origin);

      for (let index = 0; index < configuration.length; index++) {
        const throttleDelay = configuration[index];

        // If the throttle value meets this configured throttle condition
        // execute the throttle process.
        if (throttle >= throttleDelay.throttle) {
          throttleCallback(throttleDelay, "throttleStart");

          setTimeout(() => {
            throttleCallback(throttleDelay, "throttleEnd");
            setThrottleActive(false);
          }, throttleDelay.delayMs);

          return throttleDelay;
        }
      }
    }

    return undefined;
  });

  const [throttleActive, setThrottleActive] = React.useState<boolean>(throttleDelay ? true : false);

  // If we unmount while throttling is still active we need to notify the callback.
  React.useEffect(() => () => throttleCallback(throttleDelay, "throttleUnmount"), [throttleDelay, throttleCallback]);

  let childElement = throttleActive ? placeholder : children;

  // If this is a blocking scenario and it was rendered with a delay
  // we will always render it with a scenario component to ensure it is
  // tracked with the parent scenario.
  if (blocking && throttleDelay) {
    childElement = (
      <Scenario complete={() => !throttleActive} scenarioName="throttleChildren">
        {childElement}
      </Scenario>
    );
  }

  return childElement;
}
