import {
  IEmailPreferences,
  IFeaturePreferences,
  IFeaturePreferencesComplexKeys,
  IPhotoPreferences,
  IUserPreferences
} from "../types/userpreferences";
import { graphResponse } from "./network";
import { prepareUserPreferences } from "./util";

const searchSuggestionApi = "{{vroomOrigin}}/{{apiVersion2}}/drive/searchSuggestions?$filter=type eq 'Photo'";

interface ISuggestion {
  suggestion: string;
  type: "Photo";
}

interface ISuggestedSearches {
  "@odata.context": string;
  value: ISuggestion[];
}

/**
 * API used to retrieve a set of suggested searches.
 *
 * @param fetch underlying network function
 * @returns An array of search suggestions.
 */
export function getSearchSuggestions(fetch: (url: string, init?: RequestInit) => Promise<Response>): Promise<string[]> {
  return graphResponse(fetch(searchSuggestionApi)).then((result: ISuggestedSearches) => {
    return result.value.map((value) => value.suggestion);
  });
}

const userPreferencesApi = "{{vroomOrigin}}/{{apiVersion2}}/drive/userPreferences";
const userPreferencesApiPhoto = "{{vroomOrigin}}/{{apiVersion2}}/drive/userPreferences/photo";
const userPreferencesApiEmail = "{{vroomOrigin}}/{{apiVersion2}}/drive/userPreferences/email";
const userPreferencesApiFeature = "{{vroomOrigin}}/{{apiVersion2}}/drive/userPreferences/featureSettings";

/**
 * Get the user's preferences
 *
 * @param fetch
 * @returns The user's preferences
 */
export function getUserPreferences(fetch: (url: string, init?: RequestInit) => Promise<Response>): Promise<IUserPreferences> {
  return graphResponse(fetch(userPreferencesApi)).then((preferences: IUserPreferences) => {
    if (preferences.featureSettings) {
      expandFeaturePreferences(preferences.featureSettings);
    }

    return prepareUserPreferences(preferences);
  });
}

/**
 * Update the user's photos preferences
 *
 * @param fetch
 * @param preferences
 * @returns The updated photo preferences
 */
export function updatePhotoPreferences(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  preferences: Partial<IPhotoPreferences>
): Promise<IPhotoPreferences> {
  return graphResponse(
    fetch(userPreferencesApiPhoto, {
      body: JSON.stringify(preferences),
      headers: { "content-type": "application/json" },
      method: "PATCH"
    })
  );
}

/**
 * Update the user's email preferences
 *
 * @param fetch
 * @param preferences
 * @returns The updated email preferences
 */
export function updateEmailPreferences(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  preferences: Partial<IEmailPreferences>
): Promise<IEmailPreferences> {
  return graphResponse(
    fetch(userPreferencesApiEmail, {
      body: JSON.stringify(preferences),
      headers: { "content-type": "application/json" },
      method: "PATCH"
    })
  );
}

/**
 * Update the user's feature preferences
 *
 * @param fetch
 * @param preferences
 * @returns The updated feature preferences
 */
export function updateFeaturePreferences(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  preferences: Partial<IFeaturePreferences>
): Promise<IFeaturePreferences> {
  // Subkeys MUST be stringified - service does not support nested objects
  const body: { [K in keyof IFeaturePreferences]?: string | boolean | null } = {};
  for (const key in preferences) {
    // This object is being phased out, only save the known values.
    if (key !== "web" && key !== "recentSearches") {
      continue;
    }

    const k = key as keyof IFeaturePreferences;
    const value = preferences[k];
    if (value != null && typeof value === "object") {
      body[k] = JSON.stringify(value);
    } else {
      body[k] = value;
    }
  }

  return graphResponse(
    fetch(userPreferencesApiFeature, {
      body: JSON.stringify(body),
      headers: { "content-type": "application/json" },
      method: "PATCH"
    })
  ).then((response: IFeaturePreferences) => {
    expandFeaturePreferences(response);
    return response;
  });
}

/**
 * Expands out the feature settings from the server's JSON-flattened format
 * The service only supports primitive values within user preferences, so any object values
 * must be flattened out to a JSON string when stored in the service and are retrieved as such
 * so we need to pop them back into objects
 *
 * @param featurePreferences The JSON-flattened feature preferences to be expanded in-place
 */
function expandFeaturePreferences(featurePreferences: IFeaturePreferences) {
  // Add any new complex keys here
  const objectKeys: IFeaturePreferencesComplexKeys[] = ["web"];

  for (const key of objectKeys) {
    if (key in featurePreferences) {
      featurePreferences[key] = JSON.parse(featurePreferences[key] as string);
    }
  }
}
