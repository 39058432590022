import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Link } from "azure-devops-ui/Link";
import { Observer } from "../../common/components/observer/observer";
import { INavigationContext } from "../../common/contexts/navigation";
import { format, formatComponent } from "../../common/utilities/format";
import { IEventDispatch } from "../../common/utilities/platformdispatch";
import { createTaskQueue } from "../../common/utilities/taskqueue";
import { IAlbumApi } from "../api/album";
import { preparePhoto } from "../api/util";
import { IAlbumCreatedEvent, IAlbumPhotosRemovedEvent, IAlbumUpdatedEvent, IAlbumsDeletedEvent } from "../types/change";
import { IMessage, MessageType } from "../types/message";
import { IAlbum, IModifyAlbumResponse, IPhoto } from "../types/photo";
import { processModifyAlbumResponse } from "../utilities/albums";
import { getThumbnailCollection } from "../utilities/image";
import { IOperationOptions, executeOperation } from "../utilities/operation";
import { validAlbumName } from "../utilities/util";

const {
  AddToAlbum,
  AddToAlbumFailed,
  AddedToAlbum,
  RemovedFromAlbum,
  RemoveFromAlbum,
  RemoveFromAlbumFailed,
  UpdateAlbumName,
  UpdateAlbumNameFailed,
  UpdatedAlbumName,
  UpdateCoverPhoto,
  UpdateCoverPhotoFailed,
  UpdatedCoverPhoto
} = window.Resources.AlbumOperations;
const { SpecialCharacterError } = window.Resources.CreateAlbumDialog;

const concurrentOperations = 5;

export function addToAlbum(
  driveId: string,
  albumApi: IAlbumApi,
  eventDispatch: IEventDispatch,
  navigationContext: INavigationContext,
  albumName: string,
  albumId: string,
  photos: IPhoto[],
  batchSize: number
): Promise<PromiseSettledResult<void>[]> {
  const completedWork = new ObservableValue(0);
  const messageType = new ObservableValue<MessageType>("progress");

  let errorCount = 0;

  const albumMessage: IMessage = {
    completedWork,
    messageType,
    title: (
      <Observer values={{ completedWork }}>
        {({ completedWork }) => {
          if (completedWork < photos.length) {
            return <>{format(AddToAlbum, { albumName, itemCount: photos.length })}</>;
          }
          return (
            <>
              {formatComponent(
                format(errorCount > 0 ? AddToAlbumFailed : AddedToAlbum, {
                  albumName,
                  errorCount,
                  itemCount: photos.length
                }),
                (content) => (
                  <Link
                    className="album-navigation padding-horizontal-4"
                    href={`/album/${albumId}`}
                    key="link"
                    onClick={(event) => {
                      navigationContext.navigate(event, "navigateAlbum", { telemetryProperties: { mode: "messageAddToAlbum" } });
                    }}
                  >
                    {content}
                  </Link>
                )
              )}
            </>
          );
        }}
      </Observer>
    ),
    totalWork: photos.length
  };

  eventDispatch.dispatchEvent("showMessage", albumMessage);

  // Details for customer promise data
  const addToAlbumOperationOptions: IOperationOptions = {
    customerPromise: {
      name: "AddToAlbum",
      perfGoal: 2000,
      pillar: "Manage"
    }
  };

  return executeOperation(
    "addToAlbum",
    eventDispatch,
    (createRequest, dispatchChange) => {
      const _addToAlbum = createRequest(albumApi.addToAlbum, { name: "addToAlbum" });
      const addQueue = createTaskQueue(concurrentOperations);

      // Create batch requests to all the photos to the album in goups.
      // For migrated user, albumBatch is set as true, and each group size will be 40; otherwise it will 1.
      const pending: Promise<IModifyAlbumResponse>[] = [];
      for (let index = 0; index < photos.length; index += batchSize) {
        const photosToAdd = photos.slice(index, index + batchSize).map((photo) => photo.id);
        const _groupedAddToAlbum = () =>
          _addToAlbum(driveId, albumId, photosToAdd).finally(() => (completedWork.value = completedWork.value + photosToAdd.length));
        pending.push(addQueue.queueTask(_groupedAddToAlbum));
      }

      return Promise.allSettled(pending).then((results) => {
        const {
          flattenedResult,
          modifiedPhotoIds: addedPhotoIds,
          addedPhotos = [],
          shouldReloadAlbum = false
        } = processModifyAlbumResponse(
          results,
          photos.map((photo) => photo.id),
          batchSize
        );

        errorCount = flattenedResult.length - addedPhotoIds.length;
        messageType.value = errorCount > 0 ? "error" : "success";

        if (shouldReloadAlbum) {
          const _getPhotos = createRequest(albumApi.getAlbumPhotos, { name: "getAlbumPhotos" });
          _getPhotos(driveId, albumId).then((result) => {
            const photos = result.value.map(preparePhoto);
            dispatchChange<IAlbumUpdatedEvent>({ changeType: "albumUpdated", data: { albumId, addedPhotos: photos } });
            return photos;
          });
        } else if (addedPhotoIds.length) {
          dispatchChange<IAlbumUpdatedEvent>({
            changeType: "albumUpdated",
            data: { albumId, addedPhotos: addedPhotos.length === 0 ? photos : addedPhotos }
          });
        }

        eventDispatch.dispatchEvent("hideMessage", albumMessage);

        return flattenedResult;
      });
    },
    addToAlbumOperationOptions
  );
}

export function createAlbum(
  albumApi: IAlbumApi,
  eventDispatch: IEventDispatch,
  navigationContext: INavigationContext,
  driveId: string,
  albumName: string,
  batchSize: number,
  photoIds?: string[]
): Promise<IAlbum> {
  // Details for customer promise data
  const createAlbumOperationOptions: IOperationOptions = {
    customerPromise: {
      name: "CreateAlbum",
      perfGoal: 2000,
      pillar: "Create"
    }
  };

  return executeOperation(
    "createAlbum",
    eventDispatch,
    (createRequest, dispatchChange) => {
      if (!validAlbumName(albumName)) {
        return Promise.reject(SpecialCharacterError);
      }

      const _createAlbum = createRequest(albumApi.createAlbum, { name: "createAlbum" });
      const _getAlbum = createRequest(albumApi.getAlbum, { name: "getAlbum" });

      // NOTE: COB Account only support process 40 items for now. So create album will process first 40 items and call add to album
      // to add rest photos into the created album within group.
      // If batch is assigned a value greater than 0, we will group photos and call createAlbum and addToAlbum separately.
      // If batch is assigned a value less than or equals to 0, we will process all photos with createAblum.
      const pendingPhotosToAdd = photoIds && batchSize > 0 ? photoIds?.slice(batchSize) : [];
      const _createAblumPromise =
        batchSize > 0 ? _createAlbum(driveId, albumName, photoIds?.slice(0, batchSize)) : _createAlbum(driveId, albumName, photoIds);
      return _createAblumPromise
        .then((album) => {
          if (pendingPhotosToAdd?.length > 0) {
            const _addToAlbum = createRequest(albumApi.addToAlbum, { name: "addToAlbum" });
            const groupedPhotosToAdd = [];
            for (let index = 0; index < pendingPhotosToAdd.length; index += batchSize) {
              const _groupedAddToAlbum = _addToAlbum(driveId, album.id, pendingPhotosToAdd.slice(index, index + batchSize));
              groupedPhotosToAdd.push(_groupedAddToAlbum);
            }
            return Promise.all(groupedPhotosToAdd).then(() => album);
          }
          return album;
        })
        .then((album) => {
          // NOTE: The albumCreated album is not a fully qualified album so we will
          // go back and read the created album from the server.
          const albumMessage: IMessage = {
            messageType: "success",
            title: (
              <>
                {formatComponent(format(AddedToAlbum, { albumName, itemCount: photoIds?.length }), (content) => (
                  <Link
                    className="album-navigation padding-horizontal-4"
                    href={`/album/${album.id}`}
                    key="link"
                    onClick={(event) => {
                      navigationContext.navigate(event, "navigateAlbum", { telemetryProperties: { mode: "messageCreateAlbum" } });
                    }}
                  >
                    {content}
                  </Link>
                ))}
              </>
            ),
            timeout: 3000
          };

          eventDispatch.dispatchEvent("showMessage", albumMessage);

          return _getAlbum(driveId, album.id).then((album) => {
            dispatchChange<IAlbumCreatedEvent>({ changeType: "albumCreated", data: { album } });
            return album;
          });
        });
    },
    createAlbumOperationOptions
  );
}

export function deleteAlbums(
  albumApi: IAlbumApi,
  eventDispatch: IEventDispatch,
  driveId: string,
  albumIds: string[]
): Promise<PromiseSettledResult<void>[]> {
  // Details for customer promise data
  const deleteAlbumOperationOptions: IOperationOptions = {
    customerPromise: {
      name: "DeleteAlbum",
      perfGoal: 2000 * albumIds.length,
      pillar: "Delete"
    }
  };

  return executeOperation(
    "deleteAlbum",
    eventDispatch,
    (createRequest, dispatchChange) => {
      const _deleteAlbum = createRequest(albumApi.deleteAlbum, { name: "deleteAlbum" });
      const deleteQueue = createTaskQueue(concurrentOperations);

      return Promise.allSettled(albumIds.map((albumId) => deleteQueue.queueTask(() => _deleteAlbum(driveId, albumId)))).then((results) => {
        const deletedIds = results.filter((result) => result.status === "fulfilled").map((_result, index) => albumIds[index]);

        if (deletedIds.length) {
          dispatchChange<IAlbumsDeletedEvent>({ changeType: "albumsDeleted", data: { albumIds: deletedIds } });
        }

        return results;
      });
    },
    deleteAlbumOperationOptions
  );
}

export function removeAlbumPhotos(
  albumApi: IAlbumApi,
  eventDispatch: IEventDispatch,
  driveId: string,
  albumName: string,
  albumId: string,
  photoIds: string[],
  batchSize: number
): Promise<PromiseSettledResult<void>[]> {
  const completedWork = new ObservableValue(0);
  const errorCount = new ObservableValue(0);
  const messageType = new ObservableValue("progress" as MessageType);

  const albumMessage: IMessage = {
    completedWork,
    messageType,
    title: (
      <Observer values={{ completedWork, errorCount }}>
        {({ completedWork, errorCount }) => {
          const displayText = completedWork < photoIds.length ? RemoveFromAlbum : errorCount > 0 ? RemoveFromAlbumFailed : RemovedFromAlbum;
          return <>{format(displayText, { albumName, errorCount, itemCount: photoIds.length })}</>;
        }}
      </Observer>
    ),
    totalWork: photoIds.length
  };

  eventDispatch.dispatchEvent("showMessage", albumMessage);

  // Details for customer promise data
  const removeAlbumPhotosOperationOptions: IOperationOptions = {
    customerPromise: {
      name: "RemoveFromAlbum",
      perfGoal: 2000 * photoIds.length,
      pillar: "Manage"
    }
  };

  return executeOperation(
    "removeAlbumPhotos",
    eventDispatch,
    (createRequest, dispatchChange) => {
      const _removeAlbumPhoto = createRequest(albumApi.removeAlbumPhoto, { name: "removeAlbumPhoto" });
      const removeQueue = createTaskQueue(concurrentOperations);

      // Create batch requests to remove photos in groups.
      // For migrated user, group size will be 40; otherwise it will 1.
      const pending: Promise<IModifyAlbumResponse>[] = [];
      for (let index = 0; index < photoIds.length; index += batchSize) {
        const photosToRemove = photoIds.slice(index, index + batchSize);
        const _groupedPhotos = () =>
          _removeAlbumPhoto(driveId, albumId, photosToRemove).finally(() => (completedWork.value = completedWork.value + photosToRemove.length));
        pending.push(removeQueue.queueTask(_groupedPhotos));
      }

      return Promise.allSettled(pending).then((results) => {
        const { flattenedResult, modifiedPhotoIds: removedPhotoIds } = processModifyAlbumResponse(results, photoIds, batchSize);

        errorCount.value = flattenedResult.length - removedPhotoIds.length;
        messageType.value = errorCount.value > 0 ? "error" : "success";
        if (removedPhotoIds.length) {
          dispatchChange<IAlbumPhotosRemovedEvent>({ changeType: "albumPhotosRemoved", data: { albumId, removedPhotoIds } });
        }

        eventDispatch.dispatchEvent("hideMessage", albumMessage);

        return flattenedResult;
      });
    },
    removeAlbumPhotosOperationOptions
  );
}

export function setCoverPhoto(
  albumApi: IAlbumApi,
  eventDispatch: IEventDispatch,
  driveId: string,
  albumId: string,
  coverPhotoId: string,
  photo: IPhoto
): Promise<void> {
  const messageType = new ObservableValue<MessageType>("progress");
  const albumMessage: IMessage = {
    messageType,
    title: (
      <Observer values={{ messageType }}>
        {({ messageType }) => {
          const displayText = messageType === "progress" ? UpdateCoverPhoto : messageType === "success" ? UpdatedCoverPhoto : UpdateCoverPhotoFailed;
          return <>{displayText}</>;
        }}
      </Observer>
    )
  };
  const thumbnail = getThumbnailCollection(coverPhotoId, photo.dimensions);

  eventDispatch.dispatchEvent("showMessage", albumMessage);

  return executeOperation("updateAlbum", eventDispatch, (createRequest, dispatchChange) => {
    const setCoverPhoto = createRequest(albumApi.setCoverPhoto, { name: "updateAlbum" });

    return setCoverPhoto(driveId, albumId, coverPhotoId)
      .then(() => {
        messageType.value = "success";

        dispatchChange<IAlbumUpdatedEvent>({ changeType: "albumUpdated", data: { albumId, coverPhotoId, thumbnail } });
        eventDispatch.dispatchEvent("hideMessage", albumMessage);
      })
      .catch(() => {
        messageType.value = "error";
      });
  });
}

export function updateAlbum(albumApi: IAlbumApi, eventDispatch: IEventDispatch, driveId: string, albumId: string, albumName: string): Promise<void> {
  if (!validAlbumName(albumName)) {
    return Promise.reject(SpecialCharacterError);
  }

  const messageType = new ObservableValue("progress" as MessageType);
  const albumMessage: IMessage = {
    messageType,
    title: (
      <Observer values={{ messageType }}>
        {({ messageType }) => {
          const displayText = messageType === "progress" ? UpdateAlbumName : messageType === "success" ? UpdatedAlbumName : UpdateAlbumNameFailed;
          return <>{displayText}</>;
        }}
      </Observer>
    )
  };

  eventDispatch.dispatchEvent("showMessage", albumMessage);

  // Details for customer promise data
  const updateAlbumOperationOptions: IOperationOptions = {
    customerPromise: {
      perfGoal: 2000,
      pillar: "Edit"
    }
  };

  return executeOperation(
    "updateAlbum",
    eventDispatch,
    (createRequest, dispatchChange) => {
      const updateAlbum = createRequest(albumApi.updateAlbum, { name: "updateAlbum" });

      return updateAlbum(driveId, albumId, albumName)
        .then(() => {
          messageType.value = "success";

          dispatchChange<IAlbumUpdatedEvent>({ changeType: "albumUpdated", data: { albumId, albumName } });
          eventDispatch.dispatchEvent("hideMessage", albumMessage);
        })
        .catch(() => {
          messageType.value = "error";
        });
    },
    updateAlbumOperationOptions
  );
}
