import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { initializeKeyboardState } from "./common/utilities/keystate";
import { IWorkerDetails, IWorkerMessage } from "./photos/types/worker";

// @NOTE: This looks like an unused import but it is required because
// we are using React components.
import React from "react";
import ReactDOM from "react-dom";
import Application from "./application";
import { getLayoutStyle } from "./photos/utilities/layout";

// We will track the serviceWorkers capabilities in an observable array
// and make this available to the application via the SessionContext.
const serviceWorkerDetails = new ObservableValue<IWorkerDetails>({ available: false, capabilities: [] });

// Register our service worker as the application starts up.
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", (event) => {
    const data: IWorkerMessage = event.data;

    // If we receieved a detailsResponse message we will update the value
    // within the ObservableValue.
    if (data && data.type === "detailsResponse") {
      serviceWorkerDetails.value = { ...data.event, available: true };
    }
  });

  // Register the service worker, and then request it's capabilities.
  navigator.serviceWorker.register("/serviceworker.js").then((registration) => {
    // Ensure the controller is available before requesting details,
    // it wont be available on a hard refresh (Shift/Ctrl-F5).
    if (navigator.serviceWorker.controller !== null) {
      registration.active?.postMessage({ type: "detailsRequest" });

      registration.addEventListener("updatefound", () => {
        const { installing } = registration;

        // If a new service worker is being installed we will wait for it
        // to be activated then ask for its details.
        installing?.addEventListener("statechange", () => {
          if (installing.state === "activated") {
            registration.active?.postMessage({ type: "detailsRequest" });
          }
        });
      });
    }
  });
}

// We will inject the application into the rootElement.
const rootElement = document.getElementById("root");
let currentLayoutClass = getLayoutClass();

// Add the layout style className to to body element.
document.body.classList.add(currentLayoutClass);

// As the layout mode changes we will change the css className used to render
// the application.
window.addEventListener("resize", () => {
  const updatedLayoutClass = getLayoutClass();

  if (updatedLayoutClass !== currentLayoutClass) {
    document.body.classList.remove(currentLayoutClass);
    document.body.classList.add(updatedLayoutClass);

    // Save the updated layout class.
    currentLayoutClass = updatedLayoutClass;
  }
});

// We will be use the getKeyState API to access the state of a given key outside
// the context of the context of a keyboard event.
initializeKeyboardState();

function renderApplication() {
  ReactDOM.render(<Application workerDetails={serviceWorkerDetails} />, rootElement);
}

// Determine if the browser needs polyfills
if (!window.ResizeObserver || !Promise.allSettled) {
  import("./polyfill").finally(renderApplication);
} else {
  renderApplication();
}

// istanbul ignore next - DEVELOPMENT: Filter warnings we don't care about.
if (process.env.NODE_ENV === "development") {
  const error = console.error;

  console.error = function () {
    // Eat certain messages we dont want shown to the user, we understand the warnings and except them.
    if (arguments && arguments[0] && arguments[0].indexOf) {
      // This warning is thrown by react if there is something other than a <tr> as a child of a <tbody>.
      // We use <a>'s instead of <tr>'s to make the entire row a hyperlink.
      // We use <div>'s instead of <tr>'s for our row overlays.
      if (
        arguments[0].indexOf("Warning: validateDOMNesting(...): %s cannot appear as a child of <%s>.%s%s%s") === 0 &&
        (((arguments[1] === "<a>" || arguments[1] === "<div>") && arguments[2] === "tbody") || (arguments[1] === "<td>" && arguments[2] === "a"))
      ) {
        return;
      }

      if (error) {
        // @ts-ignore don't use `arguments` - prefer ...rest parameters.
        error.apply(this, arguments);
      }
    }
  };
}

export function getLayoutClass(): string {
  const layoutStyle = getLayoutStyle();

  return layoutStyle === "desktop" ? "desktop-layout" : layoutStyle === "tablet" ? "tablet-layout" : "mobile-layout";
}
