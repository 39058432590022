import React from "react";

/**
 * A general function that performs no action and can be used in place of
 * most delegate handlers when no specific behavior is required.
 */
export function noop(): void {}

/**
 * preventDefault can be used to mark an event as handled and future handlers
 * shouldn't act upon the event.
 *
 * @param event The event which should have its default prevented.
 */
export function preventDefault(event: React.SyntheticEvent | Event): void {
  event.preventDefault();
}
