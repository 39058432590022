import { css } from "azure-devops-ui/Util";
import React from "react";

import "./illustrationview.css";

export interface IIllustrationViewProps {
  className?: string;
  illustration: React.ReactElement;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
}

export function IllustrationView(props: IIllustrationViewProps): React.ReactElement {
  const { className, illustration, subtitle, title } = props;

  return (
    <div
      className={css(
        "illustration-view standard-view",
        className,
        "absolute-fill flex-column flex-grow flex-align-center flex-justify-center text-align-center padding-40"
      )}
    >
      <React.Suspense fallback={null}>{illustration}</React.Suspense>
      <span aria-level={2} className="title-s margin-top-16" role="heading">
        {title}
      </span>
      {subtitle ? <span className="margin-top-8">{subtitle}</span> : null}
    </div>
  );
}
