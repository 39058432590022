import type { INavigationContext } from "../../common/contexts/navigation";
import { format, formatURL } from "../../common/utilities/format";
import { downloadPhoto } from "../api/photo";
import { ICarouselItem } from "../components/carousel/carousel";
import { IPhotoCarouselCard, PhotoCarouselCard } from "../components/carousel/carouselcard";
import { getPhotoContent } from "../hooks/usephotocontent";
import { IRecommendationItemPage } from "../types/item";

const {
  MemoriesSubtitle,
  MemoriesTitle,
  LastWeekSubtitle,
  LastWeekTitle,
  OnThisDaySubtitle,
  OnThisDayTitle,
  PreviousMonthTitle,
  RecentHighlightsTitle,
  RecentHighlightsSubtitle,
  RecentPhotosTitle,
  RecentPlacesSubtitle
} = window.Resources.Common;

/**
 * @param recommendationPage Recommended Page response returned by recommendation api
 * @returns Carousel Items
 */
export function createRecommendationCards(
  recommendationPage: IRecommendationItemPage,
  navigationContext: INavigationContext
): ICarouselItem<IPhotoCarouselCard>[] {
  // Convert the recommended items into carousel items.
  const _carouselItems: ICarouselItem<IPhotoCarouselCard>[] = recommendationPage.value.map(
    (recommendedItem, index): ICarouselItem<IPhotoCarouselCard> => {
      const { id, recommendation, thumbnails } = recommendedItem;
      const { count, recommendationType, startDate, subtitle, title } = recommendation;
      const { large } = thumbnails[0];

      // Compute the subtitle, title and effective count.
      const _subtitle = getRecommendationSubtitle(recommendationType, subtitle, startDate);
      const _title = getRecommendationTitle(recommendationType, title, startDate);
      const params: { count?: string; subtitle?: string; title?: string } = { title: _title, subtitle: _subtitle };

      // Don't include count if one isn't available.
      if (count) {
        params.count = count.toString();
      }

      const carouselItem: ICarouselItem<IPhotoCarouselCard> = {
        details: {
          href: navigationContext.prepare(
            formatURL("/moments/{{recommendationType}}/{{id}}?{{parameters}}", {
              id,
              parameters: new URLSearchParams(params),
              recommendationType
            })
          ),
          getPhotoContent: getPhotoContent(downloadPhoto),
          photo: {
            dimensions: { height: large.height, width: large.width },
            id: large.sourceItemId!
          },
          subtitle: _subtitle,
          title: _title
        },
        id,
        // see documentation/client/componentspecific/foryoucarousel.md for ordering logic
        order: recommendationType === "OnThisDay" ? 30 : 50 + index,
        render: (cardProps) => <PhotoCarouselCard key={`recommendation-${index}`} {...cardProps} type={recommendationType} />
      };

      return carouselItem;
    }
  );

  return _carouselItems;
}

const subtitles: { [recommendationType: string]: string } = {
  OnLastWeek: LastWeekSubtitle,
  OnThisDay: OnThisDaySubtitle,
  RecentHighlights: RecentHighlightsTitle,
  RecentPlaces: RecentPlacesSubtitle
};

const titles: { [recommendationType: string]: string } = {
  OnLastWeek: LastWeekTitle,
  OnThisDay: OnThisDayTitle,
  RecentHighlights: RecentHighlightsSubtitle,
  RecentPhotos: RecentPhotosTitle,
  RecentPlaces: RecentPlacesSubtitle
};

const monthFormat = Intl.DateTimeFormat(undefined, { month: "long" });

export function getRecommendationSubtitle(recommendationType: string, subtitle?: string | null, startDate?: string | null): string {
  let _subtitle = subtitle || subtitles[recommendationType];

  // Some items use a custom substitle.
  if (!_subtitle) {
    if (recommendationType === "PreviousMonth" && startDate) {
      _subtitle = new Date(startDate).getFullYear().toString();
    } else if (recommendationType === "ExploreThings" || recommendationType === "ExploreLocations") {
      _subtitle = "";
    } else {
      _subtitle = MemoriesSubtitle;
    }
  }

  return _subtitle;
}

export function getRecommendationTitle(recommendationType: string, title?: string | null, startDate?: string | null): string {
  let _title = title || titles[recommendationType];

  // Some items use a custom title.
  if (!title) {
    if (recommendationType === "PreviousMonth" && startDate) {
      _title = format(PreviousMonthTitle, { month: monthFormat.format(new Date(startDate)) });
    }
  }

  return _title || MemoriesTitle;
}
