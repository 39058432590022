import { css } from "azure-devops-ui/Util";
import React from "react";

import "./tileoverlay.css";

export type OverlayLayout = "none" | "bottom" | "center" | "split";

export interface ITileOverlayProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;

  /**
   * Limiting the focus border to only show when the tile-overlay-container has
   * focus but it is NOT within the overlay itself.
   *
   * @default false
   */
  limitedFocus?: boolean;

  /**
   * Simple overlays only show a single border. Where an advanced overlay shows
   * both an inner and outer border.
   *
   * @default false
   */
  simple?: boolean;
}

export function TileOverlay(props: ITileOverlayProps & { children?: React.ReactNode }): React.ReactElement {
  const { children, className, limitedFocus, simple, ...attributes } = props;

  return (
    <>
      <div
        {...attributes}
        className={css(
          "tile-overlay",
          className,
          "absolute-fill flex-column flex-grow rounded-12 pointer-events-none",
          limitedFocus && "limited-overlay-focus",
          simple && "simple-overlay"
        )}
      >
        {children}
      </div>
      {!simple && <div className="tile-overlay-border absolute-fill flex-column pointer-events-none rounded-12" />}
    </>
  );
}
