export const photoDimensions = {
  dimension60Rect: {
    height: 60,
    width: 60
  },
  dimension160Rect: {
    height: 160,
    width: 160
  },
  dimension200Rect: {
    height: 200,
    width: 200
  },
  dimension256Rect: {
    height: 256,
    width: 256
  },
  dimension280Rect: {
    height: 280,
    width: 280
  }
};

const defaultPlaceholderCount = 100;

// Default ratios used to create placeholders (came from a sample account with actual photos).
// prettier-ignore
const defaultRatios = [
  0.5625, 0.5625, 0.5625, 0.5625, 0.5625, 0.5625,
  0.75, 0.75, 0.75, 0.75, 0.75, 0.75,
  1.33, 1.33, 1.33, 1.33, 1.33, 1.33,
  1.45,
  1.77, 1.77, 1.77, 1.77, 1.77, 1.77
];

/**
 * getPlaceholders is used to generate a set of placeholders. This is a set
 * of random aspect ratios for the placeholder based on the ratio distribution.
 *
 * @param ratios Ratios that should be used when generating the placeholders.
 * @param placeholderCount The number of placeholders that should be generated.
 */
export function getPlaceholders(ratios: number[] = defaultRatios, placeholderCount: number = defaultPlaceholderCount): number[] {
  const placeholderRatios: number[] = [];

  for (let index = 0; index < placeholderCount; index++) {
    placeholderRatios.push(ratios[Math.floor(Math.random() * ratios.length)]);
  }

  return placeholderRatios;
}

/**
 * Group the set of items by the isSimilar function if one is supplied
 * otherwise create a group for each item.
 *
 * @param items Set of items that should be grouped by the isSimilar function.
 * @param isSimilar Funcion used to group the items.
 * @returns A set of grouped items.
 */
export function groupItems<T>(items: T[], isSimilar?: (item1: T, item2: T) => boolean): T[][] {
  const groups: T[][] = [];
  let group: T[] | undefined;

  // First pass build all the groups that end up end a resulting tile.
  for (const item of items) {
    // Before we display this photo we will apply grouping logic and only display the first photo of the group.
    if (group && isSimilar && isSimilar(group[group.length - 1], item)) {
      group.push(item);
    } else {
      group = [item];
      groups.push(group);
    }
  }

  return groups;
}

export function max(values: number[]): { index: number; value: number } {
  let index = 0;
  let value = 0;

  for (let columnIndex = 0; columnIndex < values.length; columnIndex++) {
    if (values[columnIndex] > value) {
      value = values[columnIndex];
      index = columnIndex;
    }
  }

  return { index, value };
}

export function min(values: number[]): { index: number; value: number } {
  let index = 0;
  let value = Number.MAX_SAFE_INTEGER;

  for (let columnIndex = 0; columnIndex < values.length; columnIndex++) {
    if (values[columnIndex] < value) {
      value = values[columnIndex];
      index = columnIndex;
    }
  }

  return { index, value };
}
