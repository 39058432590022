import React from "react";

export function format(resource: string, params?: any): string {
  if (params) {
    for (const param of Object.keys(params)) {
      resource = resource.replace(new RegExp(`{{${param}}}`, "g"), params[param]);
    }
  }

  return resource;
}

const componentRegex = /(?:\[\[)([^\]]*)(?:\]\])/;

/**
 * Segments returned from the callback should have unique keys added to them.
 * This prevents React from generating warnings and improves re-render
 * performance by allowing element diff to properly line up changes.
 *
 * @param resource the format string with segments that will be replaced by child
 * React elements.
 * @param formatComponent function used to create the replacement values within
 * the given format string. It is called with the segment content and its index.
 * @returns A react element that contains the fully rendered resource and its
 * children.
 */
export function formatComponent(resource: string, formatComponent: (content: string, index: number) => React.ReactElement): React.ReactElement {
  const children: React.ReactNode[] = [];
  const segments = resource.split(componentRegex);

  // Go through each segments of the split resource. The segments come with alternating elements.
  // The pattern is [text, component, text, component, ..., text].
  // The segments start with text and end with text, and alternate components in between.
  for (let segmentIndex = 0, componentIndex = 0; segmentIndex < segments.length; segmentIndex++) {
    if (segmentIndex & 1) {
      children.push(formatComponent(segments[segmentIndex], componentIndex++));
    } else {
      if (segments[segmentIndex]) {
        children.push(segments[segmentIndex]);
      }
    }
  }

  return React.createElement(React.Fragment, null, children);
}

/**
 * Formats the given date into a format expected by our APIs yyyy-MM-ddTHH:mm:ss.sssZ
 *
 * @param date Date to stringify
 * @param convertUtc if the date isnt UTC, function will use getTimezoneOffset to convert it - Default is true
 * @returns
 */
export function formatDateAPI(date: Date, convertUtc: boolean = true): string {
  // Make sure we show the day for our timezone, we dont want to use GMT.
  const utcDate = convertUtc ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000) : date;

  return format(
    `${utcDate.getFullYear()}-${(utcDate.getMonth() + 1).toString().padStart(2, "0")}-${utcDate.getDate().toString().padStart(2, "0")}T${utcDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${utcDate.getMinutes().toString().padStart(2, "0")}:${utcDate.getSeconds().toString().padStart(2, "0")}.${utcDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0")}Z`
  );
}

export function formatURL(url: string, params: { [paramName: string]: string | number | boolean | URLSearchParams }): string {
  const encodedParams: { [paramName: string]: string | number | boolean } = {};

  Object.keys(params).forEach((param) => {
    const paramValue = params[param];

    if (typeof paramValue === "object") {
      encodedParams[param] = paramValue.toString();
    } else if (typeof paramValue === "string") {
      encodedParams[param] = encodeURIComponent(paramValue);
    } else {
      encodedParams[param] = paramValue;
    }
  });

  return format(url, encodedParams);
}
