/**
 * An INetworkError is available from a parsed network failure when parseFailure
 * is supplied.
 */
export interface INetworkError {
  /**
   * A canceled error should not be processed through normal processing. Code
   * has defined the response as being handled.
   */
  isCanceled?: boolean;

  /**
   * If the resposne wasn't JSON or couldn't be parsed a basic error object will
   * be returned describing the error.
   */
  error?: { code: string; message: string };

  /**
   * The network will contain the reference to the response that created it.
   * If the network error was generated from a response object.
   */
  response?: Response;

  /**
   * The contents of the JSON resposne are returned in the network error. The
   * contents of the error depend on the response from the server. If the body
   * can't be parsed as JSON it will be basic object with some details about
   * the request.
   */
  [propertyName: string]: any;
}

/**
 * Given a network failure this method will attempt to produce an error that
 * best matches the result. If the reponse is JSON it will read the body and
 * convert the payload into the error contents, otherwise it will build a basic
 * error object with some details from the response.
 *
 * @param response The network response that contains the error being parsed.
 * @returns Promise of the the network error.
 */
export function parseNetworkError(response: Response): Promise<INetworkError> {
  const contentType = response.headers.get("content-type");

  // For errors in the 400 - 599 range we will extract the body
  // and use it as the result.
  if (response.status >= 400 && response.status <= 599) {
    if (!response.bodyUsed) {
      return response
        .clone()
        .json()
        .then((result) => {
          return { ...result, response };
        })
        .catch(() => {
          const contentLength = response.headers.get("content-length");
          return Promise.resolve({
            error: {
              code: "nonJsonError",
              message: `Unexpected content type ${contentType}, length ${contentLength}`
            },
            response
          });
        });
    } else {
      return Promise.resolve({ error: { code: "bodyUsed", message: "Unable to retrieve response details" }, response });
    }
  } else {
    return Promise.resolve({ error: { code: "unexpectedStatus", message: `Unexpected network response ${contentType}` }, response });
  }
}
