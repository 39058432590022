import { Button } from "azure-devops-ui/Button";
import { Callout, ContentJustification, ContentLocation } from "azure-devops-ui/Callout";
import { IconSize } from "azure-devops-ui/Icon";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { IFallbackProps } from "../../../common/components/boundary/boundary";
import { EventContext } from "../../../common/contexts/event";
import { useObservableArray, useSubscription, useSubscriptionArray } from "../../../common/hooks/useobservable";
import { useTimeout } from "../../../common/hooks/usetimeout";
import { IMessage } from "../../types/message";
import { Error, ISVGIconProps, Information, OneDrive, Refresh, Success } from "../illustration/icons";

import "./messagecenter.css";

const { DismissButton } = window.Resources.Common;

let messageId = 0;

interface IActiveMessage {
  id: number;
  message: IMessage;
  cardRef: React.MutableRefObject<IMessageCard | undefined>;
}

export interface IMessageCenterProps {
  /**
   * contentJustification defines horizontally where the messages will appear.
   *
   * @default ContentJustification.Center
   */
  contentJustification?: ContentJustification;

  /**
   * contentLocation defines vertically where the messages will appear.
   *
   * @default ContentLocation.End
   */
  contentLocation?: ContentLocation;

  /**
   * Milliseconds before a message is hidden when the hideMessage is called
   *
   * @default 3000 (3 second)
   */
  hideDelay?: number;

  /**
   * Maximum number of messages that are shown at a given time.
   *
   * @default 3
   */
  maxVisible?: number;

  /**
   * The current fully quailified pathname?search value. If we navigate to a new
   * location we will clear any messages immediately.
   */
  route: string;
}

export function MessageCenter(props: IMessageCenterProps): React.ReactElement | null {
  const {
    contentJustification = ContentJustification.Center,
    contentLocation = ContentLocation.End,
    hideDelay = 3000,
    maxVisible = 3,
    route
  } = props;

  const eventContext = React.useContext(EventContext);

  const [activeMessages] = useObservableArray<IActiveMessage>([]);
  const messages = useSubscriptionArray(activeMessages);

  // Attach to the event context to handle the
  React.useEffect(() => {
    eventContext.addEventListener("hideMessage", hideMessage);
    eventContext.addEventListener("showMessage", showMessage);

    return () => {
      eventContext.removeEventListener("hideMessage", hideMessage);
      eventContext.removeEventListener("showMessage", showMessage);
    };

    function hideMessage(message: IMessage): void {
      const activeMessage = activeMessages.value.find((activeMessage) => activeMessage.message === message);

      if (activeMessage && activeMessage.cardRef.current) {
        activeMessage.cardRef.current.dismiss(false, message.hideDelay ?? hideDelay);
      }
    }

    function showMessage(message: IMessage): void {
      activeMessages.push({
        id: ++messageId,
        message,
        cardRef: { current: undefined }
      });

      // If the message contains a promise we will wait on it and automatically
      // trigger a hide when it completes (success or failure).
      if (message.promise) {
        message.promise.finally(() => {
          hideMessage(message);
        });
      }
    }
  }, [activeMessages, eventContext, hideDelay]);

  // If the route changes we will clear all current messages.
  React.useEffect(() => {
    activeMessages.splice(0, activeMessages.length);
  }, [activeMessages, route]);

  return (
    <Callout
      blurDismiss={false}
      className="message-center margin-12 pointer-events-none"
      contentClassName="transparent"
      contentJustification={contentJustification}
      contentLocation={contentLocation}
      escDismiss={false}
      fixedLayout={true}
      modal={false}
      portalProps={{ className: "message-center-portal" }}
    >
      <div aria-live="assertive" className="flex-column overflow-hidden" data-modal-generation="-1" role="status">
        {messages
          .map((activeMessage, index) => {
            const { cardRef, id, message } = activeMessage;

            return index < maxVisible ? (
              <MessageCard
                componentRef={cardRef}
                key={id}
                message={message}
                onDismiss={() => {
                  const messageIndex = activeMessages.value.indexOf(activeMessage);
                  if (messageIndex >= 0) {
                    activeMessages.splice(messageIndex, 1);
                  }
                }}
              />
            ) : null;
          })
          .reverse()}
      </div>
    </Callout>
  );
}

interface IMessageCard {
  dismiss: (userAction?: boolean, hideDelay?: number) => void;
}

interface IMessageCardProps {
  componentRef?: React.MutableRefObject<IMessageCard | undefined>;
  message: IMessage;
  onDismiss: () => void;
}

function MessageCard(props: IMessageCardProps): React.ReactElement {
  const { componentRef, onDismiss, message } = props;
  const { timeout } = message;
  let StatusIcon: (props: ISVGIconProps) => React.ReactElement;
  let content: React.ReactNode;
  let customIcon: React.ReactNode;
  let title: React.ReactNode;

  const eventContext = React.useContext(EventContext);

  const cardRef = React.useRef<HTMLDivElement>(null);

  // Expose the public API of the message card component.
  React.useImperativeHandle(componentRef, () => ({ dismiss }));

  const [animationClassName, setAnimationClassName] = React.useState<string | undefined>(undefined);
  const [maxHeight, setMaxHeight] = React.useState<number | undefined>(undefined);

  // Monitor the work values and update the progress indicator.
  const completedWork = useSubscription(message.completedWork);
  const messageType = useSubscription(message.messageType);
  const totalWork = useSubscription(message.totalWork);

  const { setTimeout: setAutoHideTimeout } = useTimeout();
  const { setTimeout: setDismissTimeout } = useTimeout();

  // After the animation max-height has been set, we will add the close-animation.
  // If we don't put this delay in place the animation will use the initial max-height
  // and the height animation will have noticable delays for short cards.
  React.useEffect(() => {
    if (maxHeight) {
      setAnimationClassName("close-animation");
    }
  }, [maxHeight]);

  // If this message has an auto timeout, set it up to dismiss after the timeout.
  React.useEffect(() => {
    if (timeout) {
      setAutoHideTimeout(() => setupCloseAnimation(), timeout);
    }
  }, [setAutoHideTimeout, timeout]);

  // Resolve the title to the ReactNode used in the message.
  if (typeof message.title === "function") {
    title = message.title();
  } else {
    title = message.title;
  }

  // Resolve any optional content that may be included in the message.
  if (typeof message.content === "function") {
    content = message.content();
  } else {
    content = message.content;
  }

  // Resolve optional custom icon that may be included in the message.
  if (typeof message.customIcon === "function") {
    customIcon = message.customIcon();
  } else {
    customIcon = message.customIcon;
  }

  // Resolve the status icon to appropriate image.
  switch (messageType) {
    case "error":
      StatusIcon = Error;
      break;

    case "progress":
      StatusIcon = Refresh;
      break;

    case "success":
      StatusIcon = Success;
      break;

    case "warning":
      StatusIcon = Information;
      break;

    default:
      StatusIcon = OneDrive;
  }

  const titleElement = (
    <span className="message-card-title flex-row flex-align-center flex-grow flex-wrap margin-horizontal-8 overflow-hidden whitespace-prewrap">
      {title}
    </span>
  );

  return (
    <div
      className={css(
        "message-card relative flex-column flex-grow margin-bottom-4 padding-12 rounded-4 depth-4 pointer-events-auto overflow-hidden",
        animationClassName
      )}
      data-theme={message.theme || "dark"}
      onTransitionEnd={(event) => {
        if (event.target === cardRef.current) {
          onDismiss();
        }
      }}
      ref={cardRef}
      style={{ maxHeight }}
    >
      <div className="flex-row flex-grow flex-align-center overflow-hidden">
        {customIcon ? (
          <div className="flex-noshrink margin-left-4 invisible">{customIcon}</div>
        ) : (
          <StatusIcon className="flex-noshrink margin-left-4 invisible" />
        )}
        {content ? (
          <div className="flex-column flex-align-start flex-grow overflow-hidden">
            {titleElement}
            <div className="message-card-content flex-row margin-horizontal-8">{content}</div>
          </div>
        ) : (
          titleElement
        )}
        <Button className="invisible" iconProps={{ iconName: "Clear", size: IconSize.small }} role="presentation" subtle={true} tabIndex={-1} />
      </div>
      {totalWork !== undefined && totalWork > 0 && completedWork !== undefined && (
        <div className="message-card-progress flex-row flex-noshrink margin-top-4 overflow-hidden rounded-8">
          <div className="message-card-completed" style={{ width: `${Math.min(100, Math.max(0, (completedWork / totalWork) * 100))}%` }}></div>
        </div>
      )}
      <div className={css("message-card-overlay absolute flex-row flex-align-center padding-horizontal-12 pointer-events-none", message.className)}>
        {!message.customIcon ? <StatusIcon className="flex-noshrink" /> : <div className="flex-noshrink">{message.customIcon}</div>}
        <div className="flex-grow flex-self-stretch margin-horizontal-8" />
        <Button
          ariaLabel={DismissButton}
          className="pointer-events-auto"
          iconProps={{ iconName: "Clear", size: IconSize.small }}
          onClick={() => dismiss(true, 0)}
          subtle={true}
        />
      </div>
    </div>
  );

  function dismiss(userAction: boolean, hideDelay: number): void {
    // If the user dismissed the message and it isn't closed via a timeout
    // or some other API call record telemetry for this action.
    if (userAction) {
      eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "dismissMessage" });
    }

    // If there is a hideDelay supplied setup a timeout for the delay.
    if (hideDelay) {
      setDismissTimeout(setupCloseAnimation, hideDelay);
    } else {
      setupCloseAnimation();
    }
  }

  // Setup the max-height in preparation for transition to 0.
  function setupCloseAnimation(): void {
    setMaxHeight(cardRef.current?.getBoundingClientRect().height);
  }
}

/**
 * Small component that can be used with a React ErrorBoundary to show the details
 * when an unhandled error occurs in rendering the component subtree.
 *
 * @param props Error information generated by an unhandled exception.
 * @returns The UX to show in the place of the failed components.
 */
export function ErrorBoundaryMessage(props: IFallbackProps): null {
  const { error, errorInfo } = props;

  const eventContext = React.useContext(EventContext);

  React.useEffect(() => {
    eventContext.dispatchEvent("showMessage", { messageType: "error", title: error });
  }, [error, errorInfo, eventContext]);

  return null;
}
