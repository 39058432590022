import React from "react";

export function useOnce<T extends any[]>(func?: (...args: T) => void): (...args: T) => void {
  const called = React.useRef<boolean>(false);

  return once;

  function once(...args: T): void {
    if (!called.current) {
      func && func(...args);
      called.current = true;
    }
  }
}
