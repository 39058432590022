/**
 * getFocusElements is used to determine the set of Elements within a root element
 * that can recieve focus upon tab/shift tab. The elements returned are in tab order
 * first to last including the root element (first) if it is focusable.
 *
 * @param rootElement The root element to find all focusable elements within.
 * @param skipHiddenCheck Careful when supplying skip hidden check. This will avoid
 * specific tests like visibility and display validation. The caller needs to ensure
 * there are no invisible or hidden tab elements before supplying this.
 * @returns An array of elements in tab order first to last within the rootElement
 * including the rootElement if it is focusable.
 */
export function getFocusElements(rootElement: Element | null, skipHiddenCheck?: boolean): HTMLElement[] {
  const focusElements: Element[] = [];
  let selector = "a[href],button,iframe,input,select,textarea,[tabIndex]";

  // Filter the elements that matched our query to the elements that are elligible
  // for receiving focus in this focuszone.
  if (rootElement) {
    const focusChildren = rootElement.querySelectorAll(selector);

    // Check if the root element matches our selector.
    if (rootElement.matches(selector) && isFocusElement(rootElement, skipHiddenCheck)) {
      focusElements.push(rootElement);
    }

    // Check all the children of the root that are potential focus elements.
    for (let rootIndex = 0; rootIndex < focusChildren.length; rootIndex++) {
      const element = focusChildren[rootIndex] as HTMLElement;
      if (isFocusElement(element, skipHiddenCheck)) {
        focusElements.push(element);
      }
    }
  }

  return focusElements as HTMLElement[];
}

/**
 * isFocusElement is used to determine whether or not an element can be focused.
 *
 * NOTE: You can get significant preformance improvements by avoiding force layouts
 * when using the skipHiddenCheck parameter.
 *
 * @param element HTML Element that you are testing as a valid focus element.
 *
 * @param skipHiddenCheck Careful when supplying skip hidden check. This will avoid
 * specific tests like visibility and display validation. The caller needs to ensure
 * there are no invisible or hidden tab elements before supplying this.
 */
export function isFocusElement(element: Element, skipHiddenCheck?: boolean): boolean {
  // Filter out elements that are disabled.
  if (element.hasAttribute("disabled")) {
    return false;
  }

  // Filter out elements that are not visible.
  if (!skipHiddenCheck) {
    const style = window.getComputedStyle(element);
    if (
      style.visibility === "hidden" ||
      style.display === "none" ||
      !(element.clientWidth || element.clientHeight || element.getClientRects().length)
    ) {
      return false;
    }
  }

  return true;
}
