import React from "react";
import { SettingsContext } from "../../common/contexts/settings";
import { UXSetting } from "../types/settings";

export function useSetting<T extends object>(settingName: string): [T | undefined, (settingValue: Partial<T>) => void];
export function useSetting(settingName: "uxSetting"): [UXSetting | undefined, (settingValue: Partial<UXSetting>) => void];

/**
 * General API for reading settings based on a given setting name.
 *
 * NOTE: Using the setter API to update a setting does not cause the hook
 * to set state. This is not a stateful hook, it is designed to make
 * reading/writing settings easier and have a type safe check for photos.
 *
 * @param settingName Specific setting that should be returned.
 * @returns A pair of values, 1st element is the value of the setting,
 * the second is a method for updating the setting (using a merge).
 */
export function useSetting<T extends object>(settingName: string): [T | undefined, (settingValue: Partial<T>) => void] {
  const settingsContext = React.useContext(SettingsContext);

  return [
    settingsContext.getSetting<T>(settingName),
    (settingValue) => {
      settingsContext.mergeSetting(settingName, settingValue);
    }
  ];
}
