import { Tooltip } from "azure-devops-ui/TooltipEx";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { format } from "../../../common/utilities/format";
import { DateContext } from "../../contexts/date";
import { IPhoto } from "../../types/photo";
import { formatLocation, formatSize } from "../../utilities/format";
import { getPhotoTakenDate } from "../../utilities/image";
import { getDriveRelativePath, getOtherLocations } from "../../utilities/util";

const { CameraType, DateTaken, DateUploaded, MyFiles, ImageDimensions, Location, OtherLocation, OtherOneLocation, PhotoDirectoryLabel, PhotoSize } =
  window.Resources.Common;

export interface IPhotoInformationProps {
  photo: IPhoto;
}

export function PhotoInformation(props: IPhotoInformationProps): React.ReactElement {
  const { photo } = props;

  const dateContext = React.useContext(DateContext);

  // Get the dimensions of this photo if they are available.
  const dimensions = photo.image || photo.video;
  const relativePath = getDriveRelativePath(photo.parentReference);
  const elementSegments = relativePath.split("/");

  // Get the locations of this photo's copies
  const otherLocations = getOtherLocations(photo);

  function renderBreadcrumb(elementSegments: string[]): JSX.Element {
    return (
      <div className="flex-row flex-wrap body-s rhythm-horizontal-4">
        {elementSegments.map((segment, index, segments) => {
          return segment ? (
            <React.Fragment key={index}>
              <span className={css(index === segments.length - 1 ? "font-weight-semibold" : "secondary-text")}>{segment}</span>
              {index !== segments.length - 1 && <span className="secondary-text">&gt;</span>}
            </React.Fragment>
          ) : (
            <React.Fragment key={index}>
              <span className={css(index === segments.length - 1 ? "font-weight-semibold" : "secondary-text")}>{MyFiles}</span>
              {index !== segments.length - 1 && <span className="secondary-text">&gt;</span>}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  return (
    <div className="flex-column overflow-hidden">
      <Tooltip overflowOnly={true}>
        <span className="body-m font-weight-semibold text-ellipsis">{photo.name}</span>
      </Tooltip>
      <div className="flex-row body-s margin-top-12">
        <div className="flex-column rhythm-vertical-8">
          {photo.photo?.cameraMake && (
            <div>
              <div className="font-weight-semibold">{CameraType}</div>
              <div className="secondary-text">{`${photo.photo.cameraMake}${photo.photo.cameraModel ? `, ${photo.photo.cameraModel}` : ""}`}</div>
            </div>
          )}
          {photo.size && (
            <div>
              <div className="font-weight-semibold">{PhotoSize}</div>
              <div className="secondary-text white-space-nowrap">{formatSize(photo.size)}</div>
            </div>
          )}
          {dimensions?.width && dimensions?.height ? (
            <div>
              <div className="font-weight-semibold">{ImageDimensions}</div>
              <div className="secondary-text white-space-nowrap">{`${dimensions.width} x ${dimensions.height}`}</div>
            </div>
          ) : null}
        </div>
        <div className="separator-line-right margin-horizontal-16" />
        <div className="flex-column rhythm-vertical-8">
          {photo.photo?.takenDateTime ? (
            <div>
              <div className="font-weight-semibold">{DateTaken}</div>
              <div className="secondary-text white-space-nowrap">{dateContext.formatDate(getPhotoTakenDate(photo), "full")}</div>
            </div>
          ) : (
            photo.fileSystemInfo && (
              <div>
                <div className="font-weight-semibold">{DateUploaded}</div>
                <div className="secondary-text white-space-nowrap">
                  {dateContext.formatDate(new Date(photo.fileSystemInfo.createdDateTime), "full")}
                </div>
              </div>
            )
          )}
          {photo.location && (
            <div>
              <div className="font-weight-semibold">{Location}</div>
              <div className="secondary-text">{formatLocation(photo.location)}</div>
            </div>
          )}
        </div>
      </div>
      {elementSegments && (
        <>
          <div className="flex-row separator-line-bottom margin-vertical-8" />
          <span aria-label={PhotoDirectoryLabel} />
          {renderBreadcrumb(elementSegments)}
        </>
      )}
      {otherLocations.length > 0 && (
        <>
          <div className="flex-row separator-line-bottom margin-vertical-8" />
          <div className="flex-column flex-wrap body-m rhythm-vertical-8">
            <div className="secondary-text">
              {otherLocations.length === 1 ? OtherOneLocation : format(OtherLocation, { locationCount: otherLocations.length })}
            </div>
            {otherLocations.map((location, index, locations) => (
              <React.Fragment key={index}>
                <span className="flex-column">{renderBreadcrumb(location.relativePath.split("/"))}</span>
                {index < locations.length - 1 && <div className="separator-line-bottom " />}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
