import React from "react";
import { unstable_batchedUpdates } from "react-dom";

const defaultTimeoutOptions: ITimeoutOptions = {
  preserve: false
};

/**
 * Options that control how the timeout behaves.
 */
export interface ITimeoutOptions {
  /**
   * If the caller wants the timeout to continue with its current value before
   * another should be started. This is a form of debounce where the timeout
   * will complete every timeout (ms) instead of restarting each time setTimeout
   * is called.
   *
   * @default false
   */
  preserve?: boolean;
}

/**
 * Custom hook to create managed timeouts.
 *
 * When the component unmounts the any active timeouts will be canceled.
 *
 * Example:
 * ```
 * const { clearTimeout, setTimeout } = useTimeout();
 * useEffect(() => {
 *   setTimeout(() => doSomething..., 1000);
 * });
 * ```
 */
export function useTimeout(options?: ITimeoutOptions): {
  clearTimeout(): void;
  setTimeout<T extends any[]>(delegate: (...args: T) => void, delay?: number, ...args: T): number;
} {
  const { preserve } = options || defaultTimeoutOptions;

  const timeout = React.useRef<number>(-1);

  React.useEffect(() => {
    return clearTimeout;
  }, []);

  return { clearTimeout: React.useCallback(clearTimeout, []), setTimeout: React.useCallback(setTimeout, [preserve]) };

  /**
   * Used to clear an active timeout before the delegate is called.
   */
  function clearTimeout(): void {
    if (timeout.current !== -1) {
      window.clearTimeout(timeout.current);
      timeout.current = -1;
    }
  }

  /**
   * setTimeout creates a delayed function execution. The supplied delegate
   * is called after delay milliseconds.
   *
   * @param delegate Delegate function that should be called once the delay has elapsed
   * @param delay Delay in milliseconds before the delegate is called.
   * @param args Values supplied to the delegate.
   */
  function setTimeout<T extends any[]>(delegate: (...args: T) => void, delay?: number, ...args: T): number {
    // If the caller requested the timeout debounce calls to setTimeout, we will
    // ignore any setTimeout calls while it is active and just return the existing
    // timeout.
    if (preserve && timeout.current !== -1) {
      return timeout.current;
    }

    // Make sure we dont have an active timeout currently running, clear it if we do.
    clearTimeout();

    // Start the underlying timeout now.
    timeout.current = window.setTimeout(
      (...args: T) => {
        unstable_batchedUpdates(() => {
          timeout.current = -1;
          delegate(...args);
        });
      },
      delay,
      ...args
    );

    return timeout.current;
  }
}

/**
 * Custom hook to create managed intervals.
 *
 * When the component unmounts the any active intervals will be canceled.
 *
 *
 * Example:
 * ```
 * const { clearInterval, setInterval } = useInterval();
 * useEffect(() => {
 *   setInterval(() => doSomething..., 1000);
 * });
 * ```
 */
export function useInterval(): {
  clearInterval(): void;
  setInterval<T extends any[]>(delegate: (...args: T) => void, delay?: number, ...args: T): number;
} {
  const interval = React.useRef<number>(-1);

  React.useEffect(() => {
    return clearInterval;
  }, []);

  return { clearInterval: React.useCallback(clearInterval, []), setInterval: React.useCallback(setInterval, []) };

  /**
   * Used to clear an active interval before the delegate is called.
   */
  function clearInterval(): void {
    if (interval.current !== -1) {
      window.clearInterval(interval.current);
      interval.current = -1;
    }
  }

  /**
   * setInterval creates a delayed function execution. The supplied delegate
   * is called after delay milliseconds.
   *
   * @param delegate Delegate function that should be called once the delay has elapsed
   * @param delay Delay in milliseconds before the delegate is called.
   * @param args Values supplied to the delegate.
   */
  function setInterval<T extends any[]>(delegate: (...args: T) => void, delay?: number, ...args: T): number {
    // Make sure we dont have an active interval currently running, clear it if we do.
    clearInterval();

    // Start the underlying interval.
    interval.current = window.setInterval(
      (...args: T) => {
        unstable_batchedUpdates(() => {
          delegate(...args);
        });
      },
      delay,
      ...args
    );

    return interval.current;
  }
}
