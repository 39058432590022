import { parseNetworkError } from "../../common/utilities/network";
import { ITelemetryEvent } from "./platformdispatch";

/**
 * When a Scenario completes it give the caller the opportunity to add
 * be notified before the promise is fulfilled. The delegate can return
 * a set of properties that should be included in the result.
 *
 * @param secnarioDetails These are the details of the scenario completeion,
 * minus the properties returned from the completecallback.
 *
 * @param result The resulting object from the Scenario completion.
 *
 * @returns An object with properties that should be included in the
 * final scenario event.
 */
export type CompleteCallback<T> = (secnarioDetails: IScenarioDetails, value: PromiseSettledResult<T>) => void;

/**
 * A scenarioEvent which is dispatched through the telemetry provider with
 * the "scenarioComplete" action.
 */
export interface IScenarioEvent extends IScenarioDetails, ITelemetryEvent {
  /**
   * Scenarios are comprised of a root and 0 or more children
   * which form a hierarchy. Each child reports data about its
   * execution.
   */
  children: IScenarioEvent[];

  /**
   * Duration this scenario took to execute in milliseconds.
   */
  duration?: number;
}

/**
 * An IScenarioEvent represents the set of data being tracked during
 * an active scenario. This data is then reported through the telemetry
 * sub-system.
 */
export interface IScenarioDetails {
  /**
   * Scenarios are comprised of a root and 0 or more children
   * which form a hierarchy. Each child reports data about its
   * execution.
   */
  children: IScenarioDetails[];

  /**
   * Optional completion callback that is called when the scenario is complete.
   */
  completeCallback?: CompleteCallback<unknown>;

  /**
   * The amount of time that the app is being inactive
   */
  inactiveTime?: number;

  /**
   * The UTC time this event ended. (Javascript numerical value). If
   * the scenario hasn't yet completed, the endTime is not available.
   */
  endTime: number;

  /**
   * Scenarios can be in a tree and the parent scenario is used to track
   * the parent. If there is no parent, this is the root scenario.
   */
  parentScenario?: IScenarioDetails;

  /**
   * The promise of the underlying work.
   */
  promise?: Promise<unknown>;

  /**
   * Arbitraty set of properties that are associated with this Scenario.
   */
  properties: { [propertyName: string]: unknown };

  /**
   * Result of the scenario.
   */
  result?: Partial<PromiseSettledResult<unknown>>;

  /**
   * The name of the scenario or work that was associated with this event.
   */
  scenarioName: string;

  /**
   * The scenario type is used to help clarify what the scenario was doing.
   * Frequently senarios will share a name but have different purposes.
   *
   * Example: deletePhoto OPERATION vs deletePhoto API
   */
  scenarioType: string;

  /**
   * The UTC time this event start. (Javascript numerical value)
   */
  startTime: number;

  /**
   * The current status of the scenario.
   */
  status: ScenarioStatus;
}

/**
 * IScenarioOptions can be used to control the behaviors of the asynchronous work
 * within the scenario.
 */
export interface IScenarioOptions {
  /**
   * Set of properties that will be included in the scenario completion data.
   */
  properties?: { [propertyName: string]: any };

  /**
   * The name of the asynchronous operation we are waiting for. ie: "getTenants"
   */
  scenarioName: string;

  /**
   * The scenario type is used to help clarify what the scenario was doing.
   * Frequently senarios will share a name but have different purposes.
   *
   * Example: deletePhoto OPERATION vs deletePhoto API
   */
  scenarioType: string;

  /**
   * The caller can supply a startTime when that better represents when the promise
   * being wait on was started, if one is not supplied the current time is used as the
   * start time.
   */
  startTime?: number;

  /**
   * waitForPaint is used to delay the recording of the scenario completion
   * until the next paint occurs. This ensures the user sees what you are
   * measuring. This is only used on the root scenario. Values supplied to
   * child scenarios are ignored.
   *
   * @default true
   */
  waitForPaint?: boolean;
}

/**
 * The scenaio is "complete" after the promise is fulfilled on the
 * scenario and all pending child work has completed. This is the final
 * state of the scenario.
 *
 * The scnerio is "pending" when the scenario promise has been fulfilled
 * but we are still waiting on the children to complete. New children
 * can't be added while in the pending state.
 *
 * The scenario is "working" when the scenario has started and the complete
 * method has yet to be called, there may or may not be additional
 * asynchronous work associated with the scenario.
 */
export type ScenarioStatus = "complete" | "pending" | "working";

/**
 * A Scenario is used to measure a set of work and aggregate the details of
 * the work for tracking. The most common methods for interacting with a scenario
 * are through the <Scenario /> component used to create and manage the lifespan
 * of a scenario, or through the ScenarioContext where the caller may
 * log extra properties to the scenario or add asynchronous work to the scenario
 * through the waitUntil method.
 *
 * The scenario goes through these states:  working -> complete.
 */
export interface IScenario<T> {
  /**
   * Log is a simple method that just merges the supplied proerties into the
   * current scenario properties.
   *
   * @param properties Extra properties that should be added to the set of
   *  properties currently available within the scenario. Any properties
   * supplied will overright existing property values.
   */
  log(properties: { [propertyName: string]: unknown }): void;

  /**
   * The name of the scenario. This can be used to identify the humarn readable
   * value that describes the scenario. This carries the top level scenario
   * name even when looking at the child scenario.
   */
  scenarioName: string;

  /**
   * The promise that represents the current status of the scenario. Once the
   * scenario completes the promise will the resolved.
   */
  scenarioPromise: Promise<T>;

  /**
   * status can be used to determine the current state of the scenario.
   *
   * @returns The current status of the scenario.
   */
  status(): ScenarioStatus;

  /**
   * waitUntil is used to queue up asynchronous work associated with the scenario.
   * Any asynchronous work will hold up the completion of the scenario until the
   * promises are complete.
   *
   * @param promise The promise used to track the state of the asynchronouse work.
   *
   * @param scenarioOptions Set of features this scenario will track.
   *
   * @param completeCallback An optional callback that will be called to supply
   * properties that should be tracked with the asynchronous work once it is
   * complete.
   *
   * @returns The child scenario created for this call is returned. If the parent
   * scenario is complete the returned scenario will be a new root scenario.
   */
  waitUntil<T>(promise: Promise<T>, scenarioOptions: IScenarioOptions, completeCallback?: CompleteCallback<T>): IScenario<T>;
}

/**
 * startScenario is used to create a new scenario. A scenario is an asynchronous
 * operation that has zero to many children that form a tree of scenarios. The
 * scenario is considered "working" until the root promise is complete. After
 * that the scenario is "pending" until all children are complete.
 *
 * To create a child scenario you should call waitUntil on an existing scenario.
 *
 * @param promise The promise that defines the asynchronous work for the scenario.
 * @param options Options that help control the features of the options.
 * @param completeCallback An optional callback that is called once the scenario
 *  is complete. The callback is given the details scenario.
 * @returns A pending scenario.
 */
export function startScenario<T>(promise: Promise<T>, options: IScenarioOptions, completeCallback?: CompleteCallback<T>): IScenario<T> {
  return _startScenario(promise, options, completeCallback);
}

function _startScenario<T>(
  promise: Promise<T>,
  options: IScenarioOptions,
  completeCallback?: CompleteCallback<T>,
  parentScenario?: IScenarioDetails
): IScenario<T> {
  const { properties = {}, scenarioName, scenarioType, startTime, waitForPaint = false } = options;

  const scenarioDetails: IScenarioDetails = {
    completeCallback,
    children: [],
    endTime: 0,
    parentScenario,
    properties,
    scenarioName,
    scenarioType,
    status: "working",
    startTime: startTime || Date.now()
  };

  // Make sure we are added to the children of our parent.
  if (parentScenario) {
    parentScenario.children.push(scenarioDetails);
  }

  const scenarioPromise = new Promise<T>((resolve, reject) => {
    let result: PromiseSettledResult<T>;

    // Once our promise is complete we will wait for all the children to complete.
    // Once all children are complete we will complete ourselves.
    promise
      .then((value) => {
        result = { status: "fulfilled", value };
      })
      .catch((reason) => {
        if (reason instanceof Response) {
          return parseNetworkError(reason).then((error) => {
            result = { reason: error, status: "rejected" };
          });
        }
        result = { status: "rejected", reason };
      })
      .finally(() => {
        scenarioDetails.status = "pending";

        // Wait for all children to complete before completing ourselves.
        Promise.allSettled(scenarioDetails.children.map((details) => details.promise)).then((values) => {
          // If we are waiting for the next paint and the document is visible
          // complete on the next frame. We first wait for requestAnimationFrame
          // which denotes the time JUST before the browser paints. We then
          // use a timeout to allow the paint to occur before completing our
          // scenario.
          if (waitForPaint && document.visibilityState === "visible") {
            window.requestAnimationFrame(() => {
              window.setTimeout(_completeScenario, 0);
            });
          } else {
            _completeScenario();
          }

          function _completeScenario() {
            const { completeCallback } = scenarioDetails;

            // Mark this scenario as complete.
            scenarioDetails.endTime = Date.now();
            scenarioDetails.result = result;
            scenarioDetails.status = "complete";

            // If a completion callback was supplied for this scenario we will execute it
            if (completeCallback) {
              try {
                completeCallback(scenarioDetails, result);
              } catch (error) {
                // Ignore errors from propertyCallback's we will complete the
                // child work without the extra properties.
              }
            }

            // We are now complete with the scenario processing.
            if (result.status === "fulfilled") {
              resolve(result.value);
            } else {
              reject(result.reason);
            }
          }
        });
      });
  });

  // Save the promise within the scenarioDetails.
  scenarioDetails.promise = scenarioPromise;

  return { log, scenarioName, scenarioPromise, status, waitUntil };

  function log(properties: { [propertyName: string]: any }): void {
    scenarioDetails.properties = Object.assign(scenarioDetails.properties, properties);
  }

  function status(): ScenarioStatus {
    return scenarioDetails.status;
  }

  function waitUntil<T>(scenarioPromise: Promise<T>, scenarioOptions: IScenarioOptions, completeCallback?: CompleteCallback<T>): IScenario<T> {
    // Use our parent it is hasn't completed, if so create a new scenario.
    const parentScenarioDetails = scenarioDetails.status === "working" ? scenarioDetails : undefined;

    // Create a child scenario with the current details as the parent.
    const childScenario = _startScenario(scenarioPromise, scenarioOptions, completeCallback, parentScenarioDetails);

    // We don't want to return the scenario details so move to another object.
    return {
      log: childScenario.log,
      scenarioName,
      scenarioPromise: childScenario.scenarioPromise,
      status: childScenario.status,
      waitUntil: childScenario.waitUntil
    };
  }
}

export function getScenarioEvent(action: string, details: IScenarioDetails): IScenarioEvent {
  return {
    action,
    children: details.children.map((child) => getScenarioEvent(action, child)),
    duration: details.endTime - details.startTime - (details.inactiveTime || 0),
    endTime: details.endTime,
    properties: details.properties,
    result: details.result,
    scenarioName: details.scenarioName,
    scenarioType: details.scenarioType,
    startTime: details.startTime,
    status: details.status
  };
}
