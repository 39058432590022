import { Button } from "azure-devops-ui/Button";
import { IMenuItem, MenuButton } from "azure-devops-ui/Menu";
import { css, getSafeIdSelector } from "azure-devops-ui/Util";
import { Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { ResponsiveLayout } from "../../../common/components/responsivelayout/responsivelayout";
import { FeatureContext } from "../../../common/contexts/feature";
import { NavigationContext } from "../../../common/contexts/navigation";
import { useSubscription } from "../../../common/hooks/useobservable";
import { SessionContext } from "../../contexts/session";
import { UserPreferencesContext } from "../../contexts/userpreferences";
import { getLayoutStyle } from "../../utilities/layout";

import "./pivots.css";

const { AlbumsLink, GalleryLink, ExploreLink, PeopleLink } = window.Resources.Common;
const { MediaTitle } = window.Resources.AllPhotos;

export type PivotId = "albums" | "gallery" | "explore" | "people";

interface IPivot {
  href: string;
  id: PivotId;
  name: string;
  text: string;
}

export interface IPivotsProps {
  activeId: PivotId;
  className?: string;
}

export function Pivots(props: IPivotsProps): React.ReactElement {
  const { activeId, className } = props;

  const navigationContext = React.useContext(NavigationContext);
  const featureContext = React.useContext(FeatureContext);
  const sessionContext = React.useContext(SessionContext);
  const userPreferencesContext = React.useContext(UserPreferencesContext);

  const pivotElement = React.useRef<HTMLDivElement>(null);

  const photoPreferences = useSubscription(userPreferencesContext.getUserPreferences("photo"));
  const showAlbums = useSubscription(featureContext.featureEnabled("showAlbums"));

  const layoutStyle = getLayoutStyle();
  const desktopLayout = layoutStyle === "desktop";
  const pivots = React.useMemo(() => {
    const pivots: IPivot[] = [];
    pivots.push({
      href: "/",
      id: "gallery",
      name: "navigateGalleryPivot",
      text: GalleryLink
    });

    if (showAlbums) {
      pivots.push({
        href: "/albums",
        id: "albums",
        name: "navigateAlbumsPivot",
        text: AlbumsLink
      });
    }

    pivots.push({
      href: "/explore",
      id: "explore",
      name: "navigateExplorePivot",
      text: ExploreLink
    });

    if (photoPreferences?.biometricConsent) {
      pivots.push({
        href: "/people",
        id: "people",
        name: "navigatePeoplePivot",
        text: PeopleLink
      });
    }

    return pivots;
  }, [photoPreferences?.biometricConsent, showAlbums]);

  const { activeText, buttons, menuItems } = React.useMemo(() => {
    const buttons: React.ReactElement[] = [];
    const menuItems: IMenuItem[] = [];
    let activeText: string | undefined;

    for (let pivotIndex = 0; pivotIndex < pivots.length; pivotIndex++) {
      const pivot = pivots[pivotIndex];

      const href = navigationContext.prepare(pivot.href);
      const isActive = pivot.id === activeId;

      if (isActive) {
        activeText = pivot.text;
      }

      menuItems.push({
        checked: isActive,
        href,
        id: pivot.id,
        onActivate: (_, event) => navigationContext.navigate(event, pivot.name),
        readonly: true,
        text: pivot.text
      });

      buttons.push(
        <Button
          className="pivot-button strong-button title-s"
          href={href}
          id={pivot.id}
          key={pivot.id}
          onClick={(event) => navigationContext.navigate(event, pivot.name)}
          primary={isActive}
          role="menuitem"
          subtle={!isActive}
        >
          {pivot.text}
        </Button>
      );
    }

    return { activeText, buttons, menuItems };
  }, [activeId, navigationContext, pivots]);

  return (
    <ResponsiveLayout
      containerElement={pivotElement}
      key="pivot-layout"
      responsiveSelectors={[getSafeIdSelector("gallery"), getSafeIdSelector("albums"), getSafeIdSelector("explore")]}
    >
      {({ responsiveIndex }) =>
        !sessionContext.embedded ? (
          <div className={css(className, "flex-row flex-align-center rhythm-horizontal-4 overflow-hidden")} ref={pivotElement} role="group">
            {!desktopLayout || responsiveIndex !== -1 ? (
              <MenuButton
                className="pivot-dropdown strong-button"
                contextualMenuProps={{
                  anchorOrigin: { horizontal: Location.start, vertical: Location.end },
                  menuOrigin: { horizontal: Location.start, vertical: Location.start },
                  menuProps: {
                    className: "compact-menu",
                    id: "overflow-menu",
                    items: menuItems
                  }
                }}
                subtle={true}
              >
                {activeText}
              </MenuButton>
            ) : (
              buttons
            )}
          </div>
        ) : (
          <div aria-level={1} className={css(className, "title-s flex-row flex-align-center")} role="heading">
            {MediaTitle}
          </div>
        )
      }
    </ResponsiveLayout>
  );
}
