import React from "react";
import { getDurationElements } from "../utilities/format";

export type DateStyle = "short" | "long" | "full" | "weekday" | "year";

/**
 * Represents the context for handling dates.
 */
export interface IDateContext {
  /**
   * Formats a date value according to the specified style.
   * @param value The date value to format.
   * @param style The style to use for formatting.
   * @returns The formatted date string.
   */
  formatDate(value: Date, style: DateStyle): string;

  /**
   * Formats a duration label.
   * @param duration The duration to format in seconds.
   * @returns The formatted duration label.
   */
  formatDurationLabel(duration: number): string;
}

export class FormatDateContext implements IDateContext {
  private _yearFormat?: Intl.DateTimeFormat;
  private _weekdayFormat?: Intl.DateTimeFormat;
  private _shortFormat?: Intl.DateTimeFormat;
  private _longFormat?: Intl.DateTimeFormat;
  private _dateTimeFormat?: Intl.DateTimeFormat;
  private _hourFormat?: Intl.NumberFormat;
  private _minuteFormat?: Intl.NumberFormat;
  private _secondFormat?: Intl.NumberFormat;

  private get yearFormat(): Intl.DateTimeFormat {
    if (!this._yearFormat) {
      this._yearFormat = Intl.DateTimeFormat(this.locale, { year: "numeric" });
    }
    return this._yearFormat;
  }

  private get weekdayFormat(): Intl.DateTimeFormat {
    if (!this._weekdayFormat) {
      this._weekdayFormat = Intl.DateTimeFormat(this.locale, { weekday: "long" });
    }
    return this._weekdayFormat;
  }

  private get shortFormat(): Intl.DateTimeFormat {
    if (!this._shortFormat) {
      this._shortFormat = Intl.DateTimeFormat(this.locale, { month: "short", day: "numeric" });
    }
    return this._shortFormat;
  }

  private get longFormat(): Intl.DateTimeFormat {
    if (!this._longFormat) {
      this._longFormat = Intl.DateTimeFormat(this.locale, { year: "numeric", month: "short", day: "numeric" });
    }
    return this._longFormat;
  }

  private get dateTimeFormat(): Intl.DateTimeFormat {
    if (!this._dateTimeFormat) {
      this._dateTimeFormat = Intl.DateTimeFormat(this.locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      });
    }
    return this._dateTimeFormat;
  }

  private get hourFormat(): Intl.NumberFormat {
    if (!this._hourFormat) {
      this._hourFormat = new Intl.NumberFormat(this.locale, { style: "unit", unit: "hour", unitDisplay: "long" });
    }
    return this._hourFormat;
  }

  private get minuteFormat(): Intl.NumberFormat {
    if (!this._minuteFormat) {
      this._minuteFormat = new Intl.NumberFormat(this.locale, { style: "unit", unit: "minute", unitDisplay: "long" });
    }
    return this._minuteFormat;
  }

  private get secondFormat(): Intl.NumberFormat {
    if (!this._secondFormat) {
      this._secondFormat = new Intl.NumberFormat(this.locale, { style: "unit", unit: "second", unitDisplay: "long" });
    }
    return this._secondFormat;
  }

  constructor(private locale: string) {}

  formatDate(value: Date, style: DateStyle): string {
    let formattedDate: string;
    if (style === "short") {
      formattedDate = this.shortFormat.format(value);
    } else if (style === "full") {
      formattedDate = this.dateTimeFormat.format(value);
    } else if (style === "year") {
      formattedDate = this.yearFormat.format(value);
    } else if (style === "weekday") {
      formattedDate = this.weekdayFormat.format(value);
    } else {
      formattedDate = this.longFormat.format(value);
    }

    return formattedDate.replace("\u{202f}", " ");
  }

  formatDurationLabel(duration: number): string {
    const { hours, minutes, seconds } = getDurationElements(duration);
    let text = "";

    if (hours) {
      text += this.hourFormat.format(hours);
    }

    if (minutes) {
      if (text) {
        text += " ";
      }

      text += this.minuteFormat.format(minutes);
    }

    if (seconds) {
      if (text) {
        text += " ";
      }

      text += this.secondFormat.format(seconds);
    }

    return text;
  }
}

export const DateContext = React.createContext<IDateContext>(new FormatDateContext("en-us"));
