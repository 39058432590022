import React from "react";
import { unstable_batchedUpdates } from "react-dom";

/**
 * If you want an explicit passive listener you can use these options. Creating
 * a new object each call will cause unwanted listener updates. Instead use one
 * of the followin objects.
 */
export const nonPassiveOptions: AddEventListenerOptions = { passive: false };
export const passiveOptions: AddEventListenerOptions = { passive: true };

/**
 * The useEventListener hook is designed to listen to and register the cleanup.
 * This helps ensure you are adding/removing the same function instance.
 *
 * @param target event target being listened too.
 * @param type type of event being listened too.
 * @param listener delegate function to be called on event.
 * @param options options for event handling.
 */
export function useEventListener(
  target: EventTarget | undefined | null,
  type: string,
  listener: EventListener | null,
  options?: boolean | AddEventListenerOptions
): void {
  React.useEffect(() => {
    if (target) {
      // Add the event listener to the event target.
      target.addEventListener(type, eventCallback, options);

      // Return the cleanup function to remove the event listener.
      return () => target.removeEventListener(type, eventCallback, options);
    }

    function eventCallback(event: Event): void {
      unstable_batchedUpdates(() => {
        listener && listener(event);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, type, listener, options]);
}
