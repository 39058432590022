import { ICommand, IResult } from "@ms/utilities-cross-window";
import { IEventDispatch } from "../../common/utilities/dispatch";
import { IEmbedContext } from "../contexts/embed";

const { LoadFailure } = window.Resources.Common;

/**
 * Utility function that contains the boiler plate code for sending a command to the host client and handling errors
 */
export function sendCommand<T extends ICommand>(
  embedContext: IEmbedContext,
  options: {
    command: T;
    eventName: string;
    eventDispatch: IEventDispatch;
  }
): Promise<IResult> {
  const { command, eventName, eventDispatch } = options;

  const startTime = Date.now();

  if (embedContext.hostClient) {
    return embedContext.hostClient.ready
      .then(() => {
        return embedContext.hostClient!.sendCommand(command, { timeoutDelayMs: 120000 /* 2 minutes */ }).then((response: IResult) => {
          const endTime = Date.now();
          let additionalData =
            response.result === "success"
              ? {
                  result: {
                    status: "fulfilled"
                  }
                }
              : {
                  result: {
                    status: "rejected",
                    reason: {
                      error: {
                        code: response.error.code,
                        message: response.error.message
                      },
                      expectedFailure: response.isExpected
                    }
                  }
                };

          eventDispatch.dispatchEvent("telemetryAvailable", {
            action: "commandComplete",
            duration: endTime - startTime,
            name: eventName,
            ...additionalData
          });
          return response;
        });
      })
      .catch((error: Error) => {
        eventDispatch.dispatchEvent("telemetryAvailable", { name: eventName, action: "exception", error });
        eventDispatch.dispatchEvent("showMessage", { messageType: "error", title: LoadFailure });
        return Promise.reject({
          result: "error",
          isExpected: false,
          error: {
            code: error.name,
            message: error.message
          }
        });
      });
  } else {
    const code = "HostClientMissing";
    eventDispatch.dispatchEvent("telemetryAvailable", { name: eventName, action: "exception", error: { code } });
    eventDispatch.dispatchEvent("showMessage", { messageType: "error", title: LoadFailure });
    return Promise.reject({
      result: "error",
      isExpected: false,
      error: {
        code,
        message: LoadFailure
      }
    });
  }
}
