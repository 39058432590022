import { IReadonlyObservableValue, ObservableArray } from "azure-devops-ui/Core/Observable";
import { announce } from "azure-devops-ui/Core/Util/Accessibility";
import { IMenuItem, MenuButton, MenuItemType } from "azure-devops-ui/Menu";
import { css } from "azure-devops-ui/Util";
import { Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { EventContext } from "../../../common/contexts/event";
import { FeatureContext, IFeatureContext } from "../../../common/contexts/feature";
import { IEventDispatch } from "../../../common/utilities/platformdispatch";
import { ISessionContext, SessionContext } from "../../contexts/session";
import { LayoutAlgorithm } from "../../types/layout";
import * as Icons from "../illustration/icons";

const {
  CheckboxStateChecked,
  CheckboxStateUnchecked,
  GridLayoutLabel,
  GroupPhotosTitle,
  InlineHeaderTitle,
  MasonryLayoutLabel,
  RiverLayoutLabel,
  SelectLayoutLabel,
  ShowFilenameTitle,
  ViewAsMenuHeader
} = window.Resources.Common;

interface IMenuFeature {
  checked: IReadonlyObservableValue<boolean>;
  flagValue: string;
  iconProp?: (className?: string) => React.ReactElement;
  name: string;
  text: string;
  tooltip?: string;
}

function getLayoutFeatures(sessionContext: ISessionContext, featureContext: IFeatureContext, layoutAlgorithm: LayoutAlgorithm): IMenuFeature[] {
  const menuFeatures: IMenuFeature[] = [];

  if (!sessionContext.embedded) {
    menuFeatures.push({
      checked: featureContext.featureEnabled("autoGroupPhotos"),
      flagValue: "autoGroupPhotos",
      iconProp: (className) => <Icons.GroupPhotos className={className} />,
      name: "autoGroupPhotos",
      text: GroupPhotosTitle
    });
  }

  if (layoutAlgorithm !== "masonry") {
    menuFeatures.push({
      checked: featureContext.featureEnabled("showHeaders"),
      flagValue: "showHeaders",
      iconProp: (className) => <Icons.InlineHeader className={className} />,
      name: "showHeaders",
      text: InlineHeaderTitle
    });

    menuFeatures.push({
      checked: featureContext.featureEnabled("showFilenames"),
      flagValue: "showFilenames",
      iconProp: (className) => <Icons.Filename className={className} />,
      name: "showFilenames",
      text: ShowFilenameTitle
    });
  }

  return menuFeatures;
}

export function getLayoutMenuItems(
  eventContext: IEventDispatch,
  sessionContext: ISessionContext,
  featureContext: IFeatureContext,
  layoutAlgorithm: LayoutAlgorithm,
  selectAlgorithm: (layoutAlgorithm: LayoutAlgorithm) => void,
  showExtendedOptions?: boolean
): IMenuItem[] {
  const list: IMenuItem[] = [
    {
      id: "view-header",
      itemType: MenuItemType.Header,
      text: ViewAsMenuHeader
    },
    {
      checked: layoutAlgorithm === "river",
      className: "simple-checkbox",
      iconProps: {
        render: (className?: string) => <Icons.River className={className} />
      },
      id: "river",
      onActivate: () => selectAlgorithm("river"),
      readonly: true,
      text: RiverLayoutLabel
    },
    {
      checked: layoutAlgorithm === "masonry",
      className: "simple-checkbox",
      iconProps: {
        render: (className?: string) => <Icons.Masonry className={className} />
      },
      id: "masonry",
      onActivate: () => selectAlgorithm("masonry"),
      readonly: true,
      text: MasonryLayoutLabel
    },
    {
      checked: layoutAlgorithm === "grid",
      className: "simple-checkbox",
      iconProps: {
        render: (className?: string) => <Icons.Grid className={className} />
      },
      id: "grid",
      onActivate: () => selectAlgorithm("grid"),
      readonly: true,
      text: GridLayoutLabel
    }
  ];

  if (showExtendedOptions) {
    // Add the layout features that are based on the featureContext to the end.
    const extendedOptions = getLayoutFeatures(sessionContext, featureContext, layoutAlgorithm);

    if (extendedOptions.length) {
      // Add a divider between the layouts and the extended options.
      list.push({
        className: "feature-divider",
        id: "feature-divider",
        itemType: MenuItemType.Divider
      });

      extendedOptions.forEach((feature) => {
        list.push({
          ariaLabel: feature.tooltip,
          checked: feature.checked,
          className: "feature-row",
          iconProps: {
            render: feature.iconProp
          },
          id: feature.name,
          onActivate: () => {
            const newValue = !feature.checked.value;
            featureContext.setFeatureEnabled(feature.flagValue, newValue);

            eventContext.dispatchEvent("telemetryAvailable", {
              action: "userAction",
              feature: feature.flagValue,
              name: "toggleFeature",
              value: newValue
            });

            announce(newValue ? CheckboxStateChecked : CheckboxStateUnchecked, true);
          },
          text: feature.text
        });
      });
    }
  }

  return list;
}

export interface ILayoutMenuProps {
  className?: string;
  layoutAlgorithm: LayoutAlgorithm;
  role?: string;
  selectAlgorithm: (layoutAlgorithm: LayoutAlgorithm) => void;
  showExtendedOptions?: boolean;
}

export function LayoutMenu(props: ILayoutMenuProps): React.ReactElement {
  const { className, layoutAlgorithm, role = "menuitem", selectAlgorithm, showExtendedOptions = true } = props;

  const sessionContext = React.useContext(SessionContext);
  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);

  const layoutMenuItems = React.useMemo(
    () =>
      new ObservableArray<IMenuItem>(
        getLayoutMenuItems(eventContext, sessionContext, featureContext, layoutAlgorithm, selectAlgorithm, showExtendedOptions)
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featureContext, layoutAlgorithm, selectAlgorithm, showExtendedOptions]
  );

  return (
    <MenuButton
      buttonClassName="normal-button"
      contextualMenuProps={{
        anchorOffset: { horizontal: 0, vertical: 4 },
        anchorOrigin: { horizontal: Location.start, vertical: Location.end },
        menuOrigin: { horizontal: Location.start, vertical: Location.start },
        menuProps: {
          className: css(className, "compact-menu status-menu"),
          id: "layoutAlgorithm",
          items: layoutMenuItems
        }
      }}
      hideDropdownIcon={true}
      iconProps={{ render: (className?: string) => renderLayoutIcon(layoutAlgorithm, className) }}
      id="layout-dropdown"
      key="layout-dropdown"
      role={role}
      subtle={true}
      tooltipProps={{
        showOnFocus: true,
        text: SelectLayoutLabel
      }}
    />
  );
}

function renderLayoutIcon(layoutAlgorithm: LayoutAlgorithm, className?: string): React.ReactElement {
  return layoutAlgorithm === "grid" ? (
    <Icons.Grid className={className} />
  ) : layoutAlgorithm === "river" ? (
    <Icons.River className={className} />
  ) : (
    <Icons.Masonry className={className} />
  );
}
