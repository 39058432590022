import { IEventDispatch } from "../../common/utilities/dispatch";
import { FetchFunction, IFetchOptions } from "../../common/utilities/fetch";
import { defer } from "../../common/utilities/promise";
import { IScenario, IScenarioDetails, getScenarioEvent, startScenario } from "../../common/utilities/scenario";
import { ICustomerPromiseEvent, IVeto, OperationPillar, evaluateScenarioVetoes } from "./customerpromise";
import { createRequest } from "./fetch";

// Operation count for the session. Useful for identifying API calls that are part of the same operation, and understanding order of operations
let operationId: number = 0;

export type OperationFunction<T> = (
  createRequest: <T, P extends any[]>(fetchFunction: FetchFunction<T, P>, options?: IFetchOptions) => (...args: P) => Promise<T>,
  dispatchChange: <E extends { changeType: string }>(change: E) => void,
  operationScenario: IScenario<T>
) => Promise<T>;

/**
 * Options for the operation.
 * @param customerPromise The necessary information for customer promise data and ASHA reporting.
 */
export interface IOperationOptions {
  customerPromise?: {
    perfGoal: number;
    pillar: OperationPillar;
    name?: string;
    modifyVetoes?: (scenarioDetails: IScenarioDetails, vetoes: IVeto[]) => void;
  };
}

export function executeOperation<T>(
  operationName: string,
  eventDispatch: IEventDispatch,
  operationFunction: OperationFunction<T>,
  options?: IOperationOptions
): Promise<T> {
  const { promise, reject, resolve } = defer<T>();
  const { customerPromise } = options || {};

  // Create the scenario for the operation.
  const operationScenario = startScenario<T>(promise, { scenarioName: operationName, scenarioType: "operationExecute" }, (scenarioDetails) => {
    // If customerPromise is defined, create the customer promise telemetry object.
    customerPromise && createCustomerPromise(customerPromise.name ? { ...scenarioDetails, scenarioName: customerPromise.name } : scenarioDetails);

    scenarioDetails.properties.operationId = ++operationId;

    // Dispatch the operationComplete to the application.
    eventDispatch.dispatchEvent("telemetryAvailable", getScenarioEvent("operationComplete", scenarioDetails));
  });

  // Call the delegate with the operation details.
  operationFunction(_createRequest, dispatchChange, operationScenario).then(resolve, reject);

  return operationScenario.scenarioPromise;

  function _createRequest<T, P extends any[]>(fetchFunction: FetchFunction<T, P>, options?: IFetchOptions): (...args: P) => Promise<T> {
    // Generate a function used the execute the underlying network request
    const requestFunction = createRequest(fetchFunction, { eventDispatch, ...options });

    return (...args: P): Promise<T> => {
      const { name = "__unnamed__" } = options || {};
      const startTime = Date.now();

      // Call the underlying fetch function and ensure it is canceled on unmount.
      const fetchPromise = requestFunction(...args);

      // Attach this request to the operation scenario.
      operationScenario.waitUntil<T>(fetchPromise, { scenarioName: name, scenarioType: "networkRequest", startTime });

      return fetchPromise;
    };
  }

  function dispatchChange(change: { changeType: string }): void {
    eventDispatch.dispatchEvent("dataChanged", change);
  }

  function createCustomerPromise(scenarioDetails: IScenarioDetails) {
    const customerPromiseDuration = scenarioDetails.endTime - scenarioDetails.startTime;

    const customerPromiseData: ICustomerPromiseEvent = {
      action: "customerPromise",
      duration: customerPromiseDuration,
      properties: scenarioDetails.properties,
      pillar: customerPromise!.pillar,
      resultType: "Success",
      scenarioName: scenarioDetails.scenarioName
    };

    // Add pillar to properties for veto evaluation
    scenarioDetails.properties.pillar = customerPromise!.pillar;

    // Evaluate potential vetoes
    const vetoes = evaluateScenarioVetoes(scenarioDetails);

    if (customerPromiseDuration > customerPromise!.perfGoal) {
      vetoes.push({
        name: `${customerPromise!.pillar}PerformanceGoalNotMet`,
        errorCode: `Performance goal not met`
      });
    }

    if (customerPromise?.modifyVetoes) {
      customerPromise.modifyVetoes(scenarioDetails, vetoes);
    }

    // ASHA currently only supports one veto. If there are multiple vetoes, pick one at random to reduce bias of the first veto.
    if (vetoes.length > 0) {
      const veto = vetoes[Math.floor(Math.random() * vetoes.length)];

      Object.assign(customerPromiseData, {
        resultType: "Failure",
        veto
      });
    }

    eventDispatch.dispatchEvent("telemetryAvailable", customerPromiseData);
  }
}
