export function binarySearch<T, V>(arrayValue: T[], value: V, compareFn: (a: T, b: V) => number): number {
  let end = arrayValue.length - 1;
  let start = 0;
  let mid = 0;
  let comparison = 0;

  while (start <= end) {
    mid = Math.floor((start + end) / 2);

    comparison = compareFn(arrayValue[mid], value);

    if (comparison === 0) {
      break;
    }

    if (comparison > 0) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  if (comparison > 0) {
    mid++;
  }

  return mid;
}
