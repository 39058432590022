import React from "react";

interface IObjectDetails {
  object: Blob | MediaSource;
  url: string;
}

/**
 * useObjectUrl can be used to create an ObjectUrl from a Blob or MediaSource.
 * This can be used as the src of an image or background image.
 *
 * This hook will release the memory associated with the ObjectUrl when the
 * component unmounts, or a new object is supplied to the hook. This will
 * invalidate the previous URL.
 *
 * @param object The object that represents the underlying image.
 * @returns A URL that represents the image.
 */
export function useObjectURL(): { createObjectURL: (object: Blob | MediaSource) => string; revokeObjectURL: () => void } {
  const objectDetails = React.useRef<IObjectDetails | undefined>();

  // When the component unmounts we want to revoke the object url.
  React.useEffect(() => {
    return revokeObjectURL;
  }, []);

  return { createObjectURL: React.useCallback(createObjectURL, []), revokeObjectURL: React.useCallback(revokeObjectURL, []) };

  function createObjectURL(object: Blob | MediaSource): string {
    // Release the current object if it is no longer needed.
    if (objectDetails.current && objectDetails.current.object !== object) {
      window.URL.revokeObjectURL(objectDetails.current.url);
      objectDetails.current = undefined;
    }

    // If no details have been created for this object we will create a new version.
    if (!objectDetails.current) {
      objectDetails.current = { object, url: window.URL.createObjectURL(object) };
    }

    return objectDetails.current.url;
  }

  function revokeObjectURL(): void {
    if (objectDetails.current) {
      window.URL.revokeObjectURL(objectDetails.current.url);
      objectDetails.current = undefined;
    }
  }
}
