import { IReadonlyObservableValue, useObservable } from "azure-devops-ui/Core/Observable";
import { IPoint } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { useMouseCapture } from "./usemousecapture";
import { useTouchCapture } from "./usetouchcapture";
import { useTimeout } from "./usetimeout";

/**
 * What input method is being used to create an active drag rect.
 */
declare type InputType = "mouse" | "touch";

/**
 * Basic rectangle structure that has top/left and width/height.
 */
export interface IDragRect {
  height: number;
  left: number;
  top: number;
  width: number;
}

export interface IDragRectOptions {
  /**
   * How long does the caller have to hold the touch to generate a drag rectangle.
   *
   * @delay 500 (milliseconds)
   */
  holdDelay?: number;

  /**
   * minDistance is used to describe how far the user has to drag from the initial
   * starting location before a drag rectangle is started. This can help reduce
   * false drag operations from minor mouse movements.
   *
   * @default 8px
   */
  minDistance?: number;
}

/**
 * DragRectStatus is returned when the caller initiaties a usage of the hook.
 * The caller should attach the onMouseDown handler to the element that contains
 * the drag rectangle and subscribe to changes in the dragRect observable.
 */
export interface IDragRectStatus {
  /**
   * The dragRect represents a rectangle relative to the element the mouse down
   * handler has been attached to.
   */
  dragRect: IReadonlyObservableValue<IDragRect | undefined>;

  /**
   * A standard mouse down handler used to track the mouse actions for starting
   * the drag rectangle. This should be attached to the element that will contain
   * the drag rectangle.
   */
  onMouseDown: (event: React.MouseEvent) => void;

  /**
   * A standard touch start handler used to track the touch actions for starting
   * the drag rectangle. This should be attached to the element that will contain
   * the drag rectangle.
   */
  onTouchStart: (event: React.TouchEvent) => void;
}

export function useDragRect(options?: IDragRectOptions): IDragRectStatus {
  const { holdDelay = 500, minDistance = 8 } = options || {};

  const activeType = React.useRef<InputType | undefined>(undefined);
  const containingElement = React.useRef<HTMLElement | undefined>(undefined);
  const startPoint = React.useRef<IPoint | undefined>(undefined);

  const [dragRect, setDragRect] = useObservable<{ height: number; left: number; top: number; width: number } | undefined>(undefined);

  const { clearTimeout, setTimeout } = useTimeout();

  const cancelDragRect = React.useCallback((): void => {
    activeType.current = undefined;
    containingElement.current = undefined;

    clearTimeout();
    setDragRect(undefined);
  }, [clearTimeout, setDragRect]);

  const startDragRect = React.useCallback(
    (inputType: InputType, currentTarget: HTMLElement, clientX: number, clientY: number, minDistance: number): void => {
      const clientRect = currentTarget.getBoundingClientRect();

      activeType.current = inputType;
      containingElement.current = currentTarget;
      startPoint.current = { x: clientX - clientRect.x, y: clientY - clientRect.y };

      if (minDistance === 0) {
        setDragRect({ height: 0, left: startPoint.current.x, top: startPoint.current.y, width: 0 });
      }
    },
    [setDragRect]
  );

  const updateDragRect = React.useCallback(
    (clientX: number, clientY: number, minDistance: number): void => {
      const _containingElement = containingElement.current;
      const _startPoint = startPoint.current;

      // istanbul ignore else - can't force the element to not exist (safety).
      if (_containingElement && _startPoint) {
        const clientRect = _containingElement.getBoundingClientRect();
        const x = clientX - clientRect.x;
        const y = clientY - clientRect.y;

        // If we are in the process of starting the drag determine if we should start it.
        if (!dragRect.value) {
          if (Math.abs(_startPoint.x - x) >= minDistance || Math.abs(_startPoint.y - y) >= minDistance) {
            setDragRect(rectFromPoints(_startPoint, { x, y }));
          }
        } else {
          // Update the end drag point with the current mouse location if we have
          // started the drag operation.
          setDragRect(rectFromPoints(_startPoint, { x, y }));
        }
      }
    },
    [dragRect.value, setDragRect]
  );

  const onMouseMove = React.useCallback(
    (event: MouseEvent) => {
      if (event.buttons & 1) {
        updateDragRect(event.clientX, event.clientY, minDistance);
      }
    },
    [minDistance, updateDragRect]
  );

  const onMouseUp = React.useCallback(
    (event: MouseEvent) => {
      if (!(event.buttons & 1)) {
        cancelDragRect();
      }
    },
    [cancelDragRect]
  );

  const { onMouseDown: onCaptureMouseDown } = useMouseCapture(onMouseMove, onMouseUp);

  const onMouseDown = React.useCallback(
    (event: React.MouseEvent): void => {
      if (!event.defaultPrevented) {
        // Forward the mousedown event to the underlying capture management.
        onCaptureMouseDown(event);

        if (event.button === 0) {
          startDragRect("mouse", event.currentTarget as HTMLElement, event.clientX, event.clientY, minDistance);
        }
      }
    },
    [minDistance, onCaptureMouseDown, startDragRect]
  );

  const onTouchMove = React.useCallback(
    (event: TouchEvent) => {
      if (event.touches.length === 1) {
        // If the a dragrect is active via a touch
        if (activeType.current === "touch") {
          updateDragRect(event.touches[0].clientX, event.touches[0].clientY, 0);
        } else if (
          startPoint.current &&
          (Math.abs(startPoint.current.x - event.touches[0].clientX) >= minDistance ||
            Math.abs(startPoint.current.y - event.touches[0].clientY) >= minDistance)
        ) {
          cancelDragRect();
        }
      }
    },
    [cancelDragRect, minDistance, updateDragRect]
  );

  const { onTouchStart: onCaptureTouchStart } = useTouchCapture(onTouchMove, cancelDragRect);

  const onTouchStart = React.useCallback(
    (event: React.TouchEvent): void => {
      if (!event.defaultPrevented) {
        // Forward the touchstart event to the underlying capture management.
        onCaptureTouchStart(event);

        if (event.touches.length === 1) {
          const currentTarget = event.currentTarget as HTMLElement;
          setTimeout(() => startDragRect("touch", currentTarget, event.touches[0].clientX, event.touches[0].clientY, 0), holdDelay);
        } else {
          cancelDragRect();
        }
      }
    },
    [cancelDragRect, holdDelay, onCaptureTouchStart, setTimeout, startDragRect]
  );

  return { dragRect, onMouseDown, onTouchStart };
}

function rectFromPoints(point1: IPoint, point2: IPoint): { height: number; left: number; top: number; width: number } {
  const left = Math.min(point1.x, point2.x);
  const top = Math.min(point1.y, point2.y);

  return { height: Math.max(point1.y, point2.y) - top, left, top, width: Math.max(point1.x, point2.x) - left };
}
