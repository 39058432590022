import React from "react";
import { getCookie, getCookies } from "../../common/utilities/browser";
import { IEventDispatch } from "../../common/utilities/dispatch";
import { interceptRejection } from "../../common/utilities/promise";
import { refreshToken } from "../api/auth";
import { createRequest } from "../utilities/fetch";
import { clearUserCookies, getSignInUri, ISessionContext } from "./session";

export interface IAccessTokenOptions {
  forceRefresh?: boolean;
}

export interface IAuthorizationContext {
  authkey?: string;
  accessToken: (scope: string, tokenOptions?: IAccessTokenOptions) => Promise<string>;
  badgerToken?: string;
}

export const AuthorizationContext = React.createContext<IAuthorizationContext>({ accessToken: () => Promise.resolve("") });

const defaultTokenOptions: IAccessTokenOptions = {};

/**
 * Authorization scopes for OneDrive.
 */
export const enum AuthScopeOneDrive {
  /**
   * The default authorization scope for OneDrive.
   */
  Default = "OneDrive.ReadWrite",
  /**
   * The authorization scope for OneDrive sandbox environment.
   * Note: Must be lower case otherwise it would return 401.
   */
  Sandbox = "onedrive-devbox.readwrite"
}

export class PhotoAuthorizationContext implements IAuthorizationContext {
  private acquireHostToken: (scope: string) => Promise<string> = () => Promise.resolve("");
  private refreshToken: (scope: string, refresh_token: string) => Promise<void>;
  private sessionContext: ISessionContext;
  private tokenCache: { [token: string]: Set<string> } = {};
  private tokenCookies: { [cookieName: string]: string } | undefined;
  public authkey: string | undefined;
  public badgerToken: string | undefined;

  constructor(
    eventDispatch: IEventDispatch,
    sessionContext: ISessionContext,
    authKey?: string,
    badgerToken?: string,
    acquireHostToken?: (scope: string) => Promise<string>
  ) {
    this.authkey = authKey;
    this.badgerToken = badgerToken;
    this.refreshToken = createRequest(refreshToken, { eventDispatch, name: "refreshToken" });
    this.sessionContext = sessionContext;

    if (acquireHostToken) {
      this.acquireHostToken = acquireHostToken;
    }
  }

  public accessToken(scope: string, tokenOptions?: IAccessTokenOptions): Promise<string> {
    const { forceRefresh } = tokenOptions || defaultTokenOptions;
    const requestedScopes = scope.split(" ");

    // If the page is embedded we MUST only use access tokens from the host.
    // This prevents us from leaking access tokens stored in cookies to a
    // host that we can't trust.
    // @NOTE: A better check would be if the host customized the network endpoints.
    if (this.sessionContext.embedded) {
      return this.acquireHostToken(scope);
    } else {
      // If the token wasn't available we will process the tokens from the
      // cookies and check if it is available.
      if (!forceRefresh) {
        const cookieToken = this.matchScopes(requestedScopes);

        if (cookieToken) {
          return Promise.resolve(cookieToken);
        }
      }

      // If no token was found in the cache or we are forcing a refresh, make
      // the call to get a new token.
      const refresh_token = getCookie("RefreshToken");

      if (refresh_token) {
        return this.refreshToken(scope, refresh_token)
          .then(() => {
            const accessToken = this.matchScopes(requestedScopes);

            if (accessToken) {
              return accessToken;
            } else {
              return Promise.reject(new Error(`Token refresh succeeded, ${scope} unavailable.`));
            }
          })
          .catch(
            interceptRejection(() => {
              this.refreshFailed();
            })
          );
      } else {
        return this.refreshFailed();
      }
    }
  }

  private matchScopes(requestedScopes: string[]): string | undefined {
    // If our token cache is based on a different cookie, rebuild it.
    const cookies = getCookies();

    if (cookies !== this.tokenCookies) {
      // We no longer use timeouts to clear the cache to avoid conditions where
      // the timeout doesn't run when the browser is activating. This can lead
      // to using expired tokens.
      this.tokenCache = {};
      this.tokenCookies = cookies;

      // Go through each cookie and if it is a valid access token add it to the
      // set of access tokens currently available.
      for (let cookieName in cookies) {
        if (cookieName.indexOf("AccessToken-") === 0) {
          const token = cookies[cookieName];
          let scopes = cookieName.substring(12).split("+");

          // If this token contains OneDrive.ReadWrite it is compact token and can't
          // be used for anything but OneDrive.ReadWrite.
          const targetScope = this.sessionContext.sandboxEnv && this.sessionContext.migrated ? AuthScopeOneDrive.Sandbox : AuthScopeOneDrive.Default;
          if (scopes.indexOf(targetScope) !== -1) {
            scopes = [targetScope];
          }

          // Save the scopes to the tokenCache.
          if (!this.tokenCache[token]) {
            this.tokenCache[token] = new Set(scopes);
          } else {
            for (let scope of scopes) {
              this.tokenCache[token].add(scope);
            }
          }
        }
      }
    }

    // Evaluate the cache to determine if we have the token for the requestedScopes available.
    for (const token in this.tokenCache) {
      const supportedScopes = this.tokenCache[token];
      let scopeIndex = 0;

      for (; scopeIndex < requestedScopes.length; scopeIndex++) {
        if (!supportedScopes.has(requestedScopes[scopeIndex])) {
          break;
        }
      }

      if (scopeIndex === requestedScopes.length) {
        return token;
      }
    }

    return undefined;
  }

  private refreshFailed(): Promise<string> {
    // Make sure we clear the user cookies before we navigate to ensure
    // we get updated cookies all around.
    clearUserCookies();

    // Navigate to AAD to get a new set of tokens.
    window.location.href = getSignInUri(this.sessionContext, "rf");

    // @NOTE: We could show UX to the user before doing the login loop that
    // we need to do this. Right now this is a little jarring.

    // We will return a promise that never resolves. This will stop the
    // caller from trying to make the request and we will quickly navigate
    // the browser through a login loop.
    // istanbul ignore next - We can't return a promise that doesn't resolve in the test environment.
    if (process.env.NODE_ENV === "test") {
      return Promise.resolve("");
    } else {
      return new Promise(() => {});
    }
  }
}
