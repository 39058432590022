import { IObservableLikeArray, IObservableLikeValue, IObservableValue, ObservableLike } from "azure-devops-ui/Core/Observable";
import React from "react";

interface ISubscription {
  delegate: (value: any, action: string) => void;
  observableValue: IObservableValue<any>;
  resolvedValue: any;
}

/**
 * Represents the type of the underlying Observable
 */
type UnpackObservable<T> = T extends IObservableLikeValue<infer Q> ? Q : T extends IObservableLikeArray<infer R> ? R[] : T;

/**
 * Reprsents an object where all properties are IObservableLikeValue's.
 */
type ObservableValues = { [propName: string]: IObservableLikeValue<any> };

export interface IObserverProps<T extends ObservableValues> {
  /**
   * The child of an Observer much be a function which returns an element
   */
  children?: (props: {
    [propName in keyof T]: UnpackObservable<T[propName]>;
  }) => React.ReactElement | null;

  /**
   * Set of properties that should be observed. The object should consist of
   * properties that are IObservableLikeValue's.
   */
  values: T;
}

/**
 * The Observer takes a set of properties in its values object. These properties
 * can be either IObservableValue's or any other value. When the property is an
 * observable the component will subscribe to it and re-render when the value
 * changes, passing the result of the values object to the child.
 *
 * <Observer values={{ showInput, inputValue, rawValue: 1 }}>
 *  {({ showInput, inputValue, rawValue}) => {
 *    return showInput ? <input value={inputValue} /> : null
 *  }}
 * </Observer>
 */
export function Observer<T extends { [propName: string]: IObservableLikeValue<any> }>(props: IObserverProps<T>): React.ReactElement | null {
  const { children, values } = props;

  const [, setCount] = React.useState(0);
  const [subscriptions] = React.useState(() => new Set<ISubscription>());

  const resolvedValues = {} as { [propName in keyof T]: UnpackObservable<T[propName]> };

  // Go through and unsubscribe from the previous values.
  subscriptions.forEach(({ delegate, observableValue }) => observableValue.unsubscribe(delegate));
  subscriptions.clear();

  // Build the set of values that have been resolved from the incoming ObservableLike props.
  // Setup any required subscriptions for the observable props.
  for (const propName in values) {
    const observableValue = values[propName] as IObservableValue<any>;

    // NOTE: The obserableValue is typed as an IObservableValue, but that is ONLY true
    // in the then case, it is not an IObservableValue in the else. This was done to make
    // reading the code much simpler.
    if (ObservableLike.isObservable(observableValue)) {
      const resolvedValue = values[propName].value;
      const delegate = (value: any) => {
        if (value !== resolvedValue) {
          setCount((count) => count + 1);
        }
      };

      // Subscribe to the observable for changes.
      observableValue.subscribe(delegate);

      // Save the subscription for future management.
      subscriptions.add({ delegate, observableValue, resolvedValue });
      resolvedValues[propName] = resolvedValue;
    } else {
      resolvedValues[propName] = values[propName];
    }
  }

  // Cleanup subscriptions when we unmount.
  React.useEffect(() => {
    return () => {
      subscriptions.forEach(({ delegate, observableValue }) => observableValue.unsubscribe(delegate));
      subscriptions.clear();
    };
  }, [subscriptions]);

  if (children) {
    return children(resolvedValues);
  }

  return null;
}
