import { css } from "azure-devops-ui/Util";
import React from "react";
import { match, matchPath, useLocation } from "react-router-dom";
import { lazy } from "./common/components/lazy/lazy";
import { Observer } from "./common/components/observer/observer";
import { Scenario } from "./common/components/scenario/scenario";
import { EventContext } from "./common/contexts/event";
import { FeatureContext } from "./common/contexts/feature";
import { NavigationContext } from "./common/contexts/navigation";
import { useEventListener } from "./common/hooks/uselistener";
import { useObservable, useSubscription } from "./common/hooks/useobservable";
import { format } from "./common/utilities/format";
import { downloadPhoto } from "./photos/api/photo";
import { Background } from "./photos/components/background/background";
import { Beta } from "./photos/components/beta/beta";
import { CustomerPromise } from "./photos/components/customerpromise/customerpromise";
import { MessageCenter } from "./photos/components/messagecenter/messagecenter";
import { SecurityBoundary } from "./photos/components/securityboundary/securityboundary";
import { UploadTarget } from "./photos/components/uploadtarget/uploadtarget";
import { BackgroundContext } from "./photos/contexts/background";
import { SessionContext, getSignInUri } from "./photos/contexts/session";
import { UserPreferencesContext } from "./photos/contexts/userpreferences";
import { Header } from "./photos/frame/header";
import { Navigation } from "./photos/frame/navigation";
import { useSetting } from "./photos/hooks/usesetting";
import { IDragDropOptions, IViewProps } from "./photos/types/view";
import { getViewRoute } from "./photos/utilities/image";
import { LayoutStyle, getLayoutStyle } from "./photos/utilities/layout";
import { BadRouteView } from "./photos/views/badrouteview";

// NOTE: We are directly importing the AllPhotosView into the shell. Since this
// is the default home page and the most likely to be needed we want to optimize
// for this scenario.
import { AllPhotosView } from "./photos/views/allphotosview";

import "./shell.css";

const AlbumView = lazy(() => import("./photos/views/albumview"));
const AlbumsView = lazy(() => import("./photos/views/albumsview"));
const ExploreView = lazy(() => import("./photos/views/exploreview"));
const FaceAiConsentDialog = lazy(() => import("./photos/dialogs/faceaiconsentdialog"));
const FirstRunDialog = lazy(() => import("./photos/dialogs/firstrundialog"));
const LocalView = lazy(() => import("./photos/views/localview"));
const MomentsView = lazy(() => import("./photos/views/momentsview"));
const PeopleView = lazy(() => import("./photos/views/peopleview"));
const PersonView = lazy(() => import("./photos/views/personview"));
const PhotoView = lazy(() => import("./photos/views/photoview"));
const RecommendationsView = lazy(() => import("./photos/views/recommendationsview"));
const SearchFirstRun = lazy(() => import("./photos/components/search/searchfirstrun"));
const SearchView = lazy(() => import("./photos/views/searchview"));
const TagsView = lazy(() => import("./photos/views/tagsview"));
const TagView = lazy(() => import("./photos/views/tagview"));

const { DocumentTitle } = window.Resources.Common;
const viewPerformanceGoal = 4000;

interface IView {
  element: React.ReactElement | null;
  fullScreen?: boolean;
  pathname: string;
  route: IViewRoute<unknown>;
  search: string;
  title?: string;
  uploadOptions?: IDragDropOptions;
}

interface IViewResult {
  element: React.ReactElement | null;
  fullScreen?: boolean;
}

interface IViewRoute<T = {}> {
  allowLayering?: boolean;
  anonymousAccess?: boolean;
  exact?: boolean;
  path: string | string[];
  currentPage: string;
  renderView: (routeMatch: match<{ [K in keyof T]?: string | undefined }>, props: IViewProps) => IViewResult;
}

export function Shell(): React.ReactElement {
  const backgroundContext = React.useContext(BackgroundContext);
  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);
  const navigationContext = React.useContext(NavigationContext);
  const sessionContext = React.useContext(SessionContext);
  const userPreferencesContext = React.useContext(UserPreferencesContext);

  const shownFRE = React.useRef<React.ReactElement | null>(null);

  const [uxSetting, setUxSetting] = useSetting("uxSetting");
  const [expanded, setExpanded] = useObservable<boolean>(!!uxSetting?.expandNavigation);
  const [layoutStyle, setLayoutStyle] = useObservable<LayoutStyle>(getLayoutStyle());
  const [hideBetaFRE, setHideBetaFRE] = useObservable(!!uxSetting?.hideBetaFRE);
  const [hideFaceAiConsent, setHideFaceAiConsent] = useObservable(true);
  const [dismissFaceAiConsent, setDismissFaceAiConsent] = useObservable(false);
  const [hideSearchFRE, setHideSearchFRE] = useObservable(!!uxSetting?.hideSearchFRE);

  // This tracks the set of rendered views, as we navigate some will layer on
  // top of the existing views, whiles other will replace it.
  const [renderedViews] = React.useState<IView[]>([]);

  // Get the router history, we will use this to manage navigation state.
  const location = useLocation<any>();

  // Determine if window size change forces a change in the navigation style.
  useEventListener(window, "resize", () => {
    const updatedLayoutStyle = getLayoutStyle();

    if (updatedLayoutStyle !== layoutStyle.value) {
      setLayoutStyle(updatedLayoutStyle);
    }
  });

  // Get the currently configured background, if it changes we will re-render.
  const background = useSubscription(backgroundContext.getBackground("shell"));

  // When the FaceAI feature changes we need to reload the shell since it adds
  // extra routes to the routing table.
  const photoPreferences = useSubscription(userPreferencesContext.getUserPreferences("photo"));

  // Once we know if we have consent or not, show the consent dialog
  useSubscription(userPreferencesContext.getUserPreferences("photo"), (preferences) => {
    const biometricConsentStatus = preferences?.biometricConsent;
    setHideFaceAiConsent(!(biometricConsentStatus === "DefaultOptIn" || biometricConsentStatus === "PendingConsent"));
  });

  // Determine if the current session is for an authenticated user.
  const userAuthenticated = sessionContext.authenticated();

  // After we mount, if the server sent a message to the client we will show
  // the message to the user.
  React.useEffect(() => {
    const { message } = sessionContext;

    if (message) {
      eventContext.dispatchEvent("showMessage", message);
    }
  }, [eventContext, sessionContext]);

  const url = new URL(window.location.href);

  // Generate a document title function that tracks the title with the active route.
  const routeMap: IViewRoute<unknown>[] = [
    {
      exact: true,
      path: ["/", "/photos"],
      currentPage: "Gallery",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="allphotosview" scenarioName="allphotosview">
              <AllPhotosView {...props} />
            </CustomerPromise>
          )
        };
      }
    },
    {
      allowLayering: true,
      exact: true,
      path: "/recent",
      currentPage: "RecentPlaces",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="recentPlaces" scenarioName="recentPlaces">
              <MomentsView {...props} search={location.search} type="RecentPlaces" />
            </CustomerPromise>
          )
        };
      }
    },
    {
      exact: true,
      path: "/search",
      currentPage: photoPreferences?.florenceSearch ? "FlorenceSearch" : "Search",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="search" scenarioName="search">
              <SearchView {...props} search={location.search} />
            </CustomerPromise>
          )
        };
      }
    },
    {
      allowLayering: true,
      exact: true,
      path: "/moments/:type/:momentid",
      currentPage: "Moments",
      renderView: (routeMatch: match<{ type: string; momentid: string }>, props: IViewProps) => {
        let component;
        let fullScreen = false;

        const mojExperimentValue = featureContext.experimentGroup<string>("EnableMOJDirectToOneup").value;
        eventContext.dispatchEvent("telemetryAvailable", {
          action: "experimentEvaluation",
          name: "EnableMOJDirectToOneup",
          variant: mojExperimentValue
        });

        if (mojExperimentValue === "ShowOneUp") {
          const id = url.searchParams.get("id");

          if (id) {
            navigationContext.navigate(undefined, "mojOneUpNavigation", {
              href: navigationContext.prepare(
                getViewRoute(
                  [{ id }],
                  "moment",
                  routeMatch.params.momentid,
                  navigationContext.prepare(`/moments/${routeMatch.params.type}/${routeMatch.params.momentid}`)
                )
              ),
              useReplace: true
            });

            component = <PhotoView {...props} id={id} navigationSource="mojOneUpNavigation" search={window.location.search} />;
            fullScreen = true;
          }
        }

        component = component || <MomentsView {...props} id={routeMatch.params.momentid} search={location.search} type={routeMatch.params.type} />;

        // React router doesn't re-render the page when history.push/replace is called during the initial rendering
        // therefore we will load the PhotoView component here if id is passed
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="moments" scenarioName={routeMatch.params.type}>
              {component}
            </CustomerPromise>
          ),
          fullScreen
        };
      }
    },
    {
      exact: true,
      path: "/albums",
      currentPage: "Albums",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="albumsview" scenarioName="albumsview">
              <AlbumsView {...props} />
            </CustomerPromise>
          )
        };
      }
    },
    {
      allowLayering: true,
      exact: true,
      path: "/album/:id",
      currentPage: "Album",
      renderView: (routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="albumview" scenarioName="albumview">
              <AlbumView {...props} album={location.state?.album} id={routeMatch.params.id} />
            </CustomerPromise>
          )
        };
      }
    },
    {
      exact: true,
      path: "/explore",
      currentPage: "Explore",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="exploreview" scenarioName="exploreview">
              <ExploreView {...props} />
            </CustomerPromise>
          )
        };
      }
    },
    {
      exact: true,
      path: "/explore/places",
      currentPage: "Places",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="placesview" scenarioName="placesview">
              <TagsView {...props} tagType="locations" />
            </CustomerPromise>
          )
        };
      }
    },
    {
      allowLayering: true,
      exact: true,
      currentPage: "Location",
      path: "/explore/places/place",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="exploreLocation" scenarioName="exploreLocation">
              <TagView {...props} search={location.search} type="ExploreLocations" />
            </CustomerPromise>
          )
        };
      }
    },
    {
      exact: true,
      path: "/explore/things",
      currentPage: "Things",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="thingsview" scenarioName="thingsview">
              <TagsView {...props} tagType="things" />
            </CustomerPromise>
          )
        };
      }
    },
    {
      allowLayering: true,
      exact: true,
      path: "/explore/things/thing",
      currentPage: "Tag",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="exploreThing" scenarioName="exploreThing">
              <TagView {...props} search={location.search} type="ExploreThings" />
            </CustomerPromise>
          )
        };
      }
    },
    {
      exact: true,
      path: "/favorites",
      currentPage: "Favorites",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="favoritesview" scenarioName="favoritesview">
              <TagView {...props} search={location.search} type="Favorites" />
            </CustomerPromise>
          )
        };
      }
    },
    {
      allowLayering: true,
      exact: true,
      path: "/photo/:id",
      currentPage: "OneUp",
      renderView: (routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="photoview" scenarioName="photoview">
              <PhotoView
                {...props}
                feedId={location.state?.feedId}
                id={routeMatch.params.id}
                photos={location.state?.photos}
                search={window.location.search}
              />
            </CustomerPromise>
          ),
          fullScreen: true
        };
      }
    },
    {
      allowLayering: false,
      anonymousAccess: true,
      exact: true,
      path: "/command/open",
      currentPage: "LocalOpen",
      renderView: (_routeMatch: match<{}>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="localview" scenarioName="localview">
              <LocalView {...props} />
            </CustomerPromise>
          ),
          fullScreen: true
        };
      }
    },

    {
      allowLayering: true,
      anonymousAccess: true,
      exact: false,
      path: ["/share/:id", "/shared/:id"],
      currentPage: "OneUp.Shared",
      renderView: (routeMatch: match<{ id: string }>, props: IViewProps) => {
        const ithint = (url.searchParams.get("ithint") || "").split(",", 1)[0];
        const migrated = url.searchParams.has("migratedtospo");

        switch (ithint) {
          case "photo":
          case "video":
            return {
              element: (
                <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="photoview" scenarioName="photoview">
                  <SecurityBoundary badgerToken={location.state?.badgerToken} id={routeMatch.params.id} migrated={migrated} url={url}>
                    <PhotoView
                      {...props}
                      feedId={location.state?.feedId}
                      id={routeMatch.params.id}
                      photos={location.state?.photos}
                      search={window.location.search}
                      shared={true}
                    />
                  </SecurityBoundary>
                </CustomerPromise>
              ),
              fullScreen: true
            };

          case "album":
            return {
              element: (
                <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="albumview" scenarioName="albumview">
                  <SecurityBoundary badgerToken={location.state?.badgerToken} id={routeMatch.params.id} migrated={migrated} url={url}>
                    <AlbumView
                      {...props}
                      album={location.state?.album}
                      id={routeMatch.params.id}
                      ownerCid={url.searchParams.get("cid") || undefined}
                      shared={true}
                    />
                  </SecurityBoundary>
                </CustomerPromise>
              ),
              fullScreen: true
            };

          default:
            const cid = url.searchParams.get("cid") || undefined;
            const authkey = url.searchParams.get("authkey") || undefined;

            navigationContext.navigate(undefined, "unsupportedsharingtype", {
              href: `https://onedrive.live.com/?cid=${cid}&id=${routeMatch.params.id}&sff=1&authkey=${authkey}`
            });

            return { element: null };
        }
      }
    },
    {
      exact: true,
      path: "/people",
      currentPage: "People",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="peopleview" scenarioName="peopleview">
              <PeopleView {...props} />
            </CustomerPromise>
          )
        };
      }
    },
    {
      allowLayering: true,
      exact: true,
      path: "/people/person/:id",
      currentPage: "Person",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="personview" scenarioName="personview">
              <PersonView {...props} id={_routeMatch.params.id} search={location.search} />
            </CustomerPromise>
          )
        };
      }
    }
  ];

  // Add the memories page route
  if (featureContext.featureEnabled("EnableMemoriesPage").value) {
    routeMap.push({
      exact: true,
      path: "/explore/memories",
      currentPage: "Memories",
      renderView: (_routeMatch: match<{ id: string }>, props: IViewProps) => {
        return {
          element: (
            <CustomerPromise pillar="View" perfGoal={viewPerformanceGoal} key="memoriesview" scenarioName="memoriesview">
              <RecommendationsView {...props} />
            </CustomerPromise>
          )
        };
      }
    });
  }

  // Add the fallback route
  routeMap.push({
    anonymousAccess: true,
    exact: false,
    path: "/",
    currentPage: "Fallback",
    renderView: () => {
      return {
        element: (
          <Scenario key="fallback" scenarioName="fallback" properties={{ pathname: window.location.pathname }}>
            <BadRouteView />
          </Scenario>
        )
      };
    }
  });

  // Go through the primary routes to determine if this request matches.
  for (let routeIndex = 0; routeIndex < routeMap.length; routeIndex++) {
    const viewRoute = routeMap[routeIndex];

    const routeMatch = matchPath(location.pathname, { exact: viewRoute.exact, path: viewRoute.path });
    if (routeMatch) {
      const pathname = window.location.pathname;
      const search = window.location.search;

      let existingView = false;

      // Look back through the currently rendered views and if you see this view
      // in the pre-rendered viewswe will move back to that view as the top level view.
      for (let viewIndex = renderedViews.length - 1; viewIndex >= 0; viewIndex--) {
        const renderedView = renderedViews[viewIndex];
        // Check both the url and changes in search params.
        if (renderedView.pathname === pathname && renderedView.search === search) {
          renderedViews.splice(viewIndex + 1, renderedViews.length - viewIndex - 1);
          existingView = true;

          // If we navigate back to a previous view, we will update the document
          // title to the views resolved title if it exists.
          if (renderedView.title) {
            document.title = renderedView.title;
          }

          break;
        }
      }

      sessionContext.setCurrentPage(viewRoute.currentPage);

      if (existingView) {
        break;
      }

      // If this route supports layering on top of the existing view we will
      // push the view definition, otherwise we will clear the view history
      // and this will be the first view.
      if (!viewRoute.allowLayering) {
        renderedViews.splice(0, renderedViews.length);
      }

      const viewResult = viewRoute.renderView(routeMatch, {
        setDocumentTitle: (title: string) => {
          // If this view is active when the setDocumentTitle method is called
          // we will update the title, otherwise ignore the update
          if (pathname === window.location.pathname && search === window.location.search)
            if (!sessionContext.standalone) {
              if (title) {
                title = format(DocumentTitle, { pageTitle: title });
              } else {
                title = "OneDrive";
              }
            }

          document.title = view.title = title;
        },
        setDragDropOptions: (uploadOptions: IDragDropOptions) => {
          view.uploadOptions = uploadOptions;
        }
      });

      const view: IView = {
        ...viewResult,
        pathname: window.location.pathname,
        route: viewRoute,
        search: window.location.search
      };

      renderedViews.push(view);
      break;
    }
  }

  const topLevelView = renderedViews[renderedViews.length - 1];

  // We will split the views into fullscreen, and in navigation views.
  const fullscreenViews: IView[] = [];
  const navigationViews: IView[] = [];

  renderedViews.forEach((view) => {
    if (view.fullScreen) {
      fullscreenViews.push(view);
    } else {
      navigationViews.push(view);
    }
  });

  let pageContents = (
    <>
      {navigationViews.length ? (
        <>
          {!sessionContext.embedded && userAuthenticated && (
            <Header aria-hidden={topLevelView.fullScreen} className={css(topLevelView.fullScreen && "aria-invisible")} />
          )}
          <div
            aria-hidden={topLevelView.fullScreen}
            className={css("flex-row flex-grow overflow-hidden", topLevelView.fullScreen && "aria-invisible")}
          >
            {!sessionContext.embedded && userAuthenticated && (
              <Observer values={{ expanded, layoutStyle }}>
                {({ expanded, layoutStyle }) =>
                  layoutStyle === "desktop" ? (
                    <Navigation
                      expanded={expanded}
                      key="navigation"
                      setExpanded={(expanded: boolean) => {
                        setUxSetting({ expandNavigation: expanded });
                        setExpanded(expanded);
                      }}
                    />
                  ) : null
                }
              </Observer>
            )}
            <div className="navigation-views-container relative flex-row flex-grow overflow-hidden">
              {navigationViews.map((view) =>
                userAuthenticated || view.route.anonymousAccess ? (
                  <div
                    aria-hidden={view !== topLevelView}
                    className={css(
                      "navigation-view-container absolute-fill flex-row transparent overflow-hidden",
                      view !== topLevelView && "aria-invisible"
                    )}
                    data-view={view.pathname}
                    key={`${view.pathname}${view.search}`}
                  >
                    <React.Suspense fallback={<div className="absolute-fill" />}>{view.element}</React.Suspense>
                  </div>
                ) : (
                  <UnauthenticatedView key={`${view.pathname}${view.search}`} />
                )
              )}
            </div>
          </div>
        </>
      ) : null}
      {fullscreenViews.length
        ? fullscreenViews.map((view) =>
            userAuthenticated || view.route.anonymousAccess ? (
              <div
                aria-hidden={view !== topLevelView}
                className={css(
                  "fullscreen-view-container absolute-fill flex-row transparent overflow-hidden",
                  view !== topLevelView && "aria-invisible"
                )}
                data-view={view.pathname}
                key={`${view.pathname}${view.search}`}
              >
                <React.Suspense fallback={<div className="absolute-fill" />}>{view.element}</React.Suspense>
              </div>
            ) : (
              <UnauthenticatedView key={`${view.pathname}${view.search}`} />
            )
          )
        : null}
      {!sessionContext.embedded ? (
        <>
          <Observer values={{ dismissFaceAiConsent, hideBetaFRE, hideFaceAiConsent, hideSearchFRE }}>
            {({ dismissFaceAiConsent, hideBetaFRE, hideFaceAiConsent, hideSearchFRE }) => {
              if (!shownFRE.current && fullscreenViews.length === 0 && userAuthenticated) {
                shownFRE.current = !hideBetaFRE ? (
                  <React.Suspense fallback={null}>
                    <Scenario scenarioName="FREDialog">
                      <FirstRunDialog
                        onDismiss={() => {
                          setUxSetting({ hideBetaFRE: true });
                          setHideBetaFRE(true);
                          shownFRE.current = <></>;
                        }}
                      />
                    </Scenario>
                  </React.Suspense>
                ) : photoPreferences?.biometricConsent &&
                  !dismissFaceAiConsent &&
                  !hideFaceAiConsent &&
                  topLevelView.route.currentPage !== "People" ? (
                  <React.Suspense fallback={null}>
                    <Scenario
                      scenarioName="faceAIFREDialog"
                      properties={{ RestrictedLocation: photoPreferences.biometricConsent === "PendingConsent" }}
                    >
                      <FaceAiConsentDialog
                        onCompletion={() => {
                          setHideFaceAiConsent(true);
                          setDismissFaceAiConsent(true);
                          shownFRE.current = <></>;
                        }}
                        restricted={photoPreferences.biometricConsent === "PendingConsent"}
                      />
                    </Scenario>
                  </React.Suspense>
                ) : photoPreferences?.florenceSearch && !hideSearchFRE ? (
                  <Scenario scenarioName="florenceFREDialog">
                    <React.Suspense fallback={null}>
                      <SearchFirstRun
                        onDismiss={() => {
                          setUxSetting({ hideSearchFRE: true });
                          setHideSearchFRE(true);
                          shownFRE.current = <></>;
                        }}
                      />
                    </React.Suspense>
                  </Scenario>
                ) : null;
              }

              return shownFRE.current;
            }}
          </Observer>
          {fullscreenViews.length === 0 && userAuthenticated && <Beta />}
        </>
      ) : null}
      <MessageCenter route={`${window.location.pathname}?${window.location.search}`} />
    </>
  );

  // If the user is authenticated we will wrap the entire page in an element we use for
  // managing the drag-drop upload.
  if (userAuthenticated) {
    pageContents = (
      <UploadTarget
        className={css("shell-container", background && "background-active")}
        data-theme={background && "dark"}
        getDragDropOptions={getDragDropOptions}
      >
        <Background downloadPhoto={downloadPhoto} name="shell" />
        {pageContents}
      </UploadTarget>
    );
  }

  return pageContents;

  function getDragDropOptions(): IDragDropOptions | undefined {
    // After each of the views has rendered we will recompute the uploadOptions to
    // ensure they are correct for the latest view.
    let dragDropOptions: IDragDropOptions | undefined = undefined;

    renderedViews.forEach((view) => {
      // Track the custom upload options that are nearest the top level view.
      if (view.uploadOptions) {
        dragDropOptions = view.uploadOptions;
      }
    });

    return dragDropOptions;
  }
}

export function UnauthenticatedView(): null {
  const sessionContext = React.useContext(SessionContext);

  // Right now we cant do this. They will end up at the OneDrive page and we
  // wont have any auth tokens. Unless the user clicks the Photo link this is
  // a broken scenario. We need to redirect back to us after login completes
  // to complete the login scenario.

  // We need to get a specific marketing page tailored for the Photos experience
  // and use this to redirect to when the user hits the home page without being
  // signed in.
  // if (window.location.pathname === "/") {
  // window.location.href = "https://www.microsoft.com/microsoft-365/onedrive/online-cloud-storage";
  // }

  window.location.href = getSignInUri(sessionContext, "uv");
  return null;
}
