import { Tooltip } from "azure-devops-ui/TooltipEx";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { DateContext } from "../../contexts/date";

export interface IDateRange {
  endDate: Date;
  startDate: Date;
}

export interface IDateRangeProps {
  className?: string;
  dateRange: IDateRange;
}

export function DateRange(props: IDateRangeProps): React.ReactElement {
  const {
    className,
    dateRange: { endDate, startDate }
  } = props;

  const dateContext = React.useContext(DateContext);

  const dateText =
    endDate.getFullYear() === startDate.getFullYear()
      ? endDate.getMonth() === startDate.getMonth() && endDate.getDate() === startDate.getDate()
        ? dateContext.formatDate(startDate, "long")
        : `${dateContext.formatDate(endDate, "short")} - ${dateContext.formatDate(startDate, "long")}`
      : `${dateContext.formatDate(endDate, "long")} - ${dateContext.formatDate(startDate, "long")}`;

  return (
    <Tooltip overflowOnly={true}>
      <time className={css(className, "text-ellipsis")}>{dateText}</time>
    </Tooltip>
  );
}
