import { Button } from "azure-devops-ui/Button";
import React, { Suspense } from "react";
import { lazy } from "../../common/components/lazy/lazy";

import "./badrouteview.css";

const LoadFailure = lazy(() => import("../../photos/components/illustration/loadfailure"));

const { GotoODButton, SubTitle1, SubTitle2, Title } = window.Resources.BadRouteView;

export function BadRouteView(): React.ReactElement {
  return (
    <Suspense fallback={null}>
      <div
        className="bad-route-view standard-view flex-column flex-grow flex-align-center flex-justify-center padding-40 rhythm-vertical-16"
        role="main"
      >
        <LoadFailure />
        <span className="title-s">{Title}</span>
        <span className="flex-column flex-align-center margin-bottom-20">
          <span>{SubTitle1}</span>
          <span>{SubTitle2}</span>
        </span>
        <Button href="/" primary={true}>
          {GotoODButton}
        </Button>
      </div>
    </Suspense>
  );
}
