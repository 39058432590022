import { formatURL } from "../../common/utilities/format";
import { parseNetworkError } from "../../common/utilities/network";
import { IAccount } from "../types/account";
import { graphResponse, hasError } from "./network";

const accountApi = "{{vroomOrigin}}/{{accountVersion}}/drive?expand=unifiedStorageQuota(expand=services)&prefer=isusqenlightened";

export function getAccount(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string): Promise<IAccount> {
  return graphResponse<IAccount>(
    fetch(formatURL(accountApi, { driveId })).then((response) => {
      // If the status is BadRequest with apiNotFound, we will convert this into
      // a migrated user failure.
      if (response.status === 400) {
        return parseNetworkError(response)
          .then((error) => {
            if (hasError(error, ["apiNotFound"])) {
              return new Response(
                JSON.stringify({ error: "generalException", innererror: { code: "userContentMigrated" }, message: "User migrated." }),
                {
                  headers: { "Content-Type": "application/json" },
                  status: 404
                }
              );
            }

            return response;
          })
          .catch(() => response);
      }

      return response;
    })
  );
}

const unlockApi = "{{vroomOrigin}}/{{apiVersion1}}/drive/status/action.unlockDrive";

export function unlockAccount(fetch: (url: string, init?: RequestInit) => Promise<Response>): Promise<string | null> {
  return fetch(unlockApi, {
    headers: { "Content-Type": "application/json" },
    method: "POST"
  }).then((response) => {
    if (response.status === 202) {
      return Promise.resolve(response.headers.get("Location"));
    }

    return Promise.resolve(null);
  });
}
