import React from "react";
import { EventContext } from "../contexts/event";
import { ScenarioContext } from "../contexts/scenario";
import { createRequest, FetchFunction, IFetchOptions } from "../utilities/fetch";
import { IScenario } from "../utilities/scenario";
import { usePromise } from "./usepromise";

/**
 * IUseFetchOptions extend the IFetchOptions and integrates the scenario tracking.
 */
export interface IUseFetchOptions extends IFetchOptions {
  /**
   * The fetch hook doesnt directly use the blocking value to govern behavior. Instead it
   * is intended to communicate to handlers that any activity/scenarios that are active
   * that are dependant on this request are not complete until this request is complete.
   *
   * @default true
   */
  blocking?: boolean;

  /**
   * The caller can optionally supply a scenario this fetch call should be attributed
   * to. If a parent scenario is not supplied the active scenario is used.
   *
   * @default ScenarioContext.getScenario
   */
  parentScenario?: IScenario<unknown> | null;
}

/**
 * useFetch is used to execute a fetch network request and process the result into
 * a state change in the calling component.
 *
 * If a network calls complete our of order or after a new request has been issued
 * it will not call the setState/setError of the original call. It will complete
 * scenarios it was attached too.
 *
 * @param fetchFunction Function that executes the fetch request and binds the result.
 * @param options A set of options that control the hook integration.
 */
export function useFetch<T, P extends any[]>(fetchFunction: FetchFunction<T, P>, options?: IUseFetchOptions): (...args: P) => Promise<T> {
  const eventContext = React.useContext(EventContext);
  const scenarioContext = React.useContext(ScenarioContext);

  const [currentScenario] = React.useState(scenarioContext.getScenario());

  // Get the option values being used by the fetchFunction.
  const { blocking = true, data, parentScenario = currentScenario, telemetryProperties = {}, ...fetchOptions } = options || {};
  const { allowDuplicate, eventDispatch = eventContext, name = "__unnamed__" } = fetchOptions;

  // If an explicit scenarioName was listed for this request use that, otherwise
  // use the current scenario's name if it exists.
  if (!telemetryProperties.scenarioName && parentScenario?.scenarioName) {
    telemetryProperties.scenarioName = parentScenario.scenarioName;
  }

  // We want to trackPromise on the network request, it will be cancelled when
  // we unmount to prevent state updates on an unmounted component. We need to
  // pass in our dependency list to make sure newly created requests aren't
  // canceled during the current useEffect pass.
  const { trackPromise } = usePromise([data, eventDispatch, fetchFunction, allowDuplicate, name]);

  // Generate a function used the execute the underlying network request
  const requestFunction = React.useMemo(
    () => createRequest(fetchFunction, { ...fetchOptions, data, eventDispatch, name, telemetryProperties }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowDuplicate, data, eventDispatch, fetchFunction, name]
  );

  // Return our local function, this has captured all the required state
  // in the local scope to support future invocations.
  return React.useCallback(
    (...args: P): Promise<T> => {
      const startTime = Date.now();

      // Call the underlying fetch function and ensure it is canceled on unmount.
      const fetchPromise = trackPromise(requestFunction(...args), `useFetch ${name}`);

      // If this fetch operation is associated with the current scenario, add
      // the result to the list of things the scenario is waiting for.
      if (blocking && parentScenario) {
        parentScenario.waitUntil(fetchPromise, { scenarioName: name, scenarioType: "networkRequest", startTime });
      }

      return fetchPromise;
    },
    [blocking, parentScenario, name, requestFunction, trackPromise]
  );
}
