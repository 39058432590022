import { Checkbox } from "azure-devops-ui/Checkbox";
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { EventContext } from "../../../common/contexts/event";
import { format } from "../../../common/utilities/format";
import { ISelection } from "../../../common/utilities/selection";
import { DateContext } from "../../contexts/date";
import { IRectangle } from "../../types/layout";
import { IPhoto } from "../../types/photo";
import { formatLocation } from "../../utilities/format";
import { getPhotoTakenDate } from "../../utilities/image";

import "./photoheader.css";

const { SelectAllForDate } = window.Resources.Common;

// A default styled group header should have the defaultHeaderHeight.
export const headerHeight = 40;

export interface IPhotoHeaderProps {
  className?: string;
  format?: (date: Date) => string;
  photos: IPhoto[];
  rect: IRectangle;
  showLocation?: boolean;
  selection?: ISelection;
}

export function PhotoHeader(props: IPhotoHeaderProps & { key?: string }): React.ReactElement {
  const { className, photos, format: formatDate, rect, selection, showLocation = false } = props;

  const dateContext = React.useContext(DateContext);
  const eventContext = React.useContext(EventContext);

  const [dateSelected, setDateSelected] = React.useState(selection && selection.allSelected(photos));

  const date = getPhotoTakenDate(photos[0]);
  // Determine which date formatter we will use for the heading.
  const formattedDate = formatDate
    ? formatDate(date)
    : new Date().getFullYear() !== date.getFullYear()
      ? dateContext.formatDate(date, "long")
      : dateContext.formatDate(date, "short");

  let bestLocation: string | undefined;
  if (showLocation) {
    const locations: { [location: string]: number } = {};

    for (const photo of photos) {
      if (photo.location) {
        const displayLocation = formatLocation(photo.location);

        if (displayLocation) {
          // Track a count for each different location.
          if (!locations[displayLocation]) {
            locations[displayLocation] = 1;
          } else {
            locations[displayLocation]++;
          }
        }
      }
    }

    // If there are multiple locations represented in the viewport we want to show the
    // location of the most represented location instead of just the first photo.
    for (const location of Object.keys(locations)) {
      if (!bestLocation) {
        bestLocation = location;
      } else if (locations[location] > locations[bestLocation]) {
        bestLocation = location;
      }
    }
  }

  const allSelected = React.useMemo(() => {
    if (selection) {
      if (!dateSelected) {
        return false;
      }

      // find the first unselected photo thats in the group
      // this might trigger when we load more photos for the date due to pagination
      // the newly loaded photos will be unselected. Therefore we want to unselect the date
      return selection.allSelected(photos);
    }

    return false;
  }, [dateSelected, photos, selection]);

  // subscribe to the selection to make sure everything under the date is still selected when selection changes
  selection?.subscribe((_, action) => {
    if (dateSelected && action === "unselect" && !selection.allSelected(photos)) {
      setDateSelected(false);
    } else if (!dateSelected && action === "select" && selection.allSelected(photos)) {
      setDateSelected(true);
    }
  });

  return (
    <div
      aria-hidden={true}
      aria-level={2}
      className={css("photo-header", className, "absolute body-m flex-row flex-align-center overflow-hidden")}
      role="heading"
      style={{
        height: rect.height,
        left: rect.left,
        top: rect.top,
        width: rect.width
      }}
    >
      <div
        className="photo-header-container flex-row flex-align-center cursor-pointer"
        onClick={(event) => {
          if (!event.defaultPrevented) {
            selectPhotos(event, "title");
          }
        }}
      >
        {selection && (
          <Checkbox
            ariaLabel={format(SelectAllForDate, { date: formattedDate })}
            checked={allSelected}
            className="photo-header-checkbox photo-checkbox"
            excludeFocusZone={true}
            onChange={(event) => {
              if (!event.defaultPrevented) {
                selectPhotos(event, "checkbox");
              }
            }}
          />
        )}
        <time className="photo-header-date font-weight-semibold text-ellipsis">{formattedDate}</time>
        {showLocation && bestLocation && (
          <Tooltip overflowOnly={true}>
            <span className="photo-header-location secondary-text text-ellipsis padding-left-8">{bestLocation}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );

  function selectPhotos(event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, source: string) {
    if (selection) {
      eventContext.dispatchEvent("telemetryAvailable", {
        action: "userAction",
        mode: source,
        name: dateSelected ? "unselectForDate" : "selectForDate"
      });

      dateSelected
        ? selection.unselect(photos.map((p) => p.id))
        : selection.select(
            photos.map((p) => p.id),
            true
          );

      setDateSelected(!dateSelected);
      event.preventDefault();
    }
  }
}
