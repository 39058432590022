import { IPhotoTilePhoto } from "../components/phototile/phototile";
import { ILocation } from "../types/photo";

const { PhotoLabelPrefix, VideoLabelPrefix } = window.Resources.Common;

export function formatAltTags(photo: IPhotoTilePhoto): string {
  const prefix = photo.video ? VideoLabelPrefix : PhotoLabelPrefix;

  const tags = photo
    .tags!.filter((tag) => tag.source !== "ObjectDetectorsUICategory")
    .map((tag) => tag.name)
    .join(", ");

  return prefix.concat(tags);
}

export function formatDuration(duration: number): string {
  const { hours, minutes, seconds } = getDurationElements(duration);
  let text = "";

  // With more than an hour left start with hours
  if (hours) {
    text += hours + ":";
  }

  if (minutes) {
    if (text.length && minutes < 10) {
      text += "0";
    }

    text += minutes + ":";
  } else {
    text += text.length ? "00:" : "0:";
  }

  if (seconds < 10) {
    text += "0";
  }

  return (text += seconds);
}

export function formatLocation(location: ILocation): string | undefined {
  const { address, displayName } = location;

  if (address) {
    const { city, locality, state } = address;

    return state ? `${city || locality}, ${state}` : city || locality;
  }

  return displayName;
}

const numberFormat = new Intl.NumberFormat(undefined, { maximumSignificantDigits: 3 });
const sizeString = ["B", "KB", "MB", "GB", "TB", "PB"];

export function formatSize(size: number): string {
  let count = 0;

  // Determine the magnitude of the number in 1024 increments.
  while (size > 1024 && count < sizeString.length - 1) {
    count++;
    size /= 1024;
  }

  return `${numberFormat.format(Math.floor(size * 10) / 10)} ${sizeString[count]}`;
}

export function formatQuotaSize(size: number): string {
  // If we are more than a GB just show the standard value.
  if (size > 1073741824) {
    return formatSize(size);
  }

  // For less than .1 gb we will show just < 0.1 GB
  if (size < 107374182) {
    return "< 0.1 GB";
  }

  return `${Math.floor((size / 1073741824) * 10) / 10} GB`;
}

export function getDurationElements(duration: number): { hours: number; minutes: number; seconds: number } {
  let hours = 0;
  let minutes = 0;
  let remaining = Math.floor(duration / 1000);
  let seconds = 0;

  if (remaining >= 3600) {
    hours = Math.floor(remaining / 3600);
    remaining -= 3600 * hours;
  }

  if (remaining >= 60) {
    minutes = Math.floor(remaining / 60);
    remaining -= 60 * minutes;
  }

  seconds = remaining;

  return { hours, minutes, seconds };
}
