import { noop } from "./func";

export interface IDeferred<T> {
  reject: (reason: any) => void;
  resolve: (value: T) => void;
  promise: Promise<T>;
}

/**
 * Returns a completion delegate, and a promise. The promise will complete when
 * the resolve or reject method is called the first time. The result of the
 * promise will be based on the called resolution method.
 *
 * @returns an object with the completion callback and the pending promise.
 */
export function defer<T>(): IDeferred<T> {
  let reject: (reason?: any) => void = noop;
  let resolve: (value: T | PromiseLike<T>) => void = noop;

  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });

  return { reject, resolve, promise };
}

/**
 * Return a promise that resolves at least delayMs from now.
 * Rejections are not delayed.
 *
 * @param promise Underlying promise that is being used.
 * @param delayMs Minimum amount of time before the returned promise can be resolved.
 * @returns A promise that represents the underlying result that is delayed by delay ms.
 */
export function delay<T>(promise: Promise<T>, delayMs: number): Promise<T> {
  return Promise.all([promise, wait(delayMs, undefined)]).then((results) => {
    return results[0];
  });
}

/**
 * interceptRejection is a simple helper to make it easier to process a promise
 * rejection, and return the rejection to the next handler. This is useful when
 * you want to peek or the failure but let it continue to fail.
 *
 * @param error The incoming rejection error.
 * @param delegate The method used to receive the process the error. The
 * delegate can customize the response with either a resolved or rejected
 * follow-up promise. The default is to return a rejected promise with the
 * same error as the incoming error.
 * @returns The resulting promise of the failure, by default it returns the
 * same rejection as came into the method.
 */
export function interceptRejection<T>(delegate: (error: any) => Promise<T> | void): (error: any) => Promise<T> {
  function interceptor(error: any): Promise<T> {
    const rejection = delegate(error);

    if (rejection) {
      return rejection;
    }

    return Promise.reject(error);
  }

  return interceptor;
}

/**
 * When a promise is made cancelable we return a CancelablePromise<T>.
 */
export interface ICancelablePromise<T> {
  /**
   * Cancelling the promise results in the promise rejecting even if the underlying
   * promise resolves. The promise is still pending as long as the underlying promise
   * is pending, it doesn't reject early. A canceled promise always rejects with
   * an ICancelReason along with the optional reason supplied to the cancel function.
   * The isCanceled property can't be overriden in the cancel result.
   *
   * @param reason An optional reason to include in the cancel object.
   */
  cancel: (reason?: any) => void;

  /**
   * Attaches a callback for only the rejection of the Promise.
   * @param onrejected The callback to execute when the Promise is rejected.
   * @returns A Promise for the completion of the callback.
   */
  catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult>;

  /**
   * Attaches a callback that is invoked when the Promise is settled (fulfilled or rejected). The
   * resolved value cannot be modified from the callback.
   * @param onfinally The callback to execute when the Promise is settled (fulfilled or rejected).
   * @returns A Promise for the completion of the callback.
   */
  finally(onfinally?: (() => void) | undefined | null): Promise<T>;

  /**
   * Attaches callbacks for the resolution and/or rejection of the Promise.
   * @param onfulfilled The callback to execute when the Promise is resolved.
   * @param onrejected The callback to execute when the Promise is rejected.
   * @returns A Promise for the completion of which ever callback is executed.
   */
  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null
  ): Promise<TResult1 | TResult2>;
}

/**
 * When a cancelable promise is used it may return an ICancelReason or the underlying
 * rejection from the wrapped promise.
 */
export interface ICancelReason {
  isCanceled: boolean;
  reason: any;
  tag?: string;
}

/**
 * makeCancelable is used to wrap an existing promise and support canceling
 * the promise. This doesnt actually stop the promise from completing, instead
 * it will send an isCanceled value to the resolve and reject methods when
 * the promise is canceled.
 *
 * @param promise Underlying promise that is made cancelable.
 * @param tag A string that can optionally be added to the promise that will be included
 * in the cancelation reason. This can help the developer understand what originated the
 * cancelable promise.
 * @returns A promise that represents the underlying promises result that can be canceled.
 */
export const makeCancelable = <T>(promise: Promise<T>, tag?: string): ICancelablePromise<T> => {
  const { promise: localPromise, reject, resolve } = defer<T>();

  promise.then(resolve, reject);

  return {
    cancel(reason?: any) {
      reject({ isCanceled: true, reason, tag });
    },
    catch: localPromise.catch.bind(localPromise),
    finally: localPromise.finally.bind(localPromise),
    then: localPromise.then.bind(localPromise)
  };
};

/**
 * Returns a promise that, if the given promise resolves in less than timeoutMs, resolves to the
 * resolution (or rejection) of the given promise. If the given promise does not resolve in less
 * than timeoutMs, reject with the given message.
 *
 * @param promise Underlying promise that will timeout
 * @param timeoutMs The timeout period in milliseconds before the promise should reject.
 * @param error message to send with the rejection when the timeout expires
 */
export function timeout<T>(promise: PromiseLike<T>, timeoutMs: number, error?: any | (() => any)): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const timeoutHandle = window.setTimeout(() => {
      reject(typeof error === "function" ? error() : error || `Timed out after ${timeoutMs} ms.`);
    }, timeoutMs);

    // Maybe use finally when it's available.
    promise.then(
      (result) => {
        resolve(result);
        window.clearTimeout(timeoutHandle);
      },
      (reason) => {
        reject(reason);
        window.clearTimeout(timeoutHandle);
      }
    );
  });
}

/**
 * Returns a promise that resolves after timeoutMs.
 *
 * @param timeoutMs The number of milliseconds before the promise is resolved.
 * @param value Value the promise should be resolved with.
 */
export function wait<T>(timeoutMs: number, value: T): Promise<T> {
  return new Promise<T>((resolve) => {
    window.setTimeout(() => resolve(value), timeoutMs);
  });
}
