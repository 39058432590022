import { IReadonlyObservableValue } from "azure-devops-ui/Core/Observable";
import { MediaPlayerClass, MetricEvent, PlaybackErrorEvent, RequestsQueue } from "dashjs";
import React from "react";
import { getPhoto } from "../../photos/api/photo";
import { addManifestParams } from "../../photos/api/util";
import { IDashHttpRequest, authorizationRequestModifier, videoStart } from "../../photos/components/video/video";
import { AuthorizationContext } from "../../photos/contexts/authorization";
import { SessionContext } from "../../photos/contexts/session";
import { useRejection } from "../../photos/hooks/userejection";
import { EventContext } from "../contexts/event";
import { FeatureContext } from "../contexts/feature";
import { supportsMediaSource } from "../utilities/browser";
import { useFetch } from "./usefetch";
import { useSubscription } from "./useobservable";
import { useOnce } from "./useonce";
import { usePromise } from "./usepromise";

const { VideoGeoLocked, VideoUnavailable, VideoUnhandledError, VideoBlockItalianLanguage } = window.Resources.Common;

export interface IVideoSourceOptions {
  /**
   * Value of the contents driveId
   */
  driveId?: string;

  /**
   * Function to call when there is an error with the player
   */
  loadFailure?: (() => void) | undefined;

  /**
   * Value to determine if player initializes when the component loads. If set to false
   * dashjs is not imported until the property is set to true
   */
  loadPlayer: boolean;

  /**
   * Debug level for the player. By default set to 0
   */
  logLevel?: number;

  /**
   * Url for the manifest. if passed as an observable, the hook doesnt run until the url is calculated
   */
  manifestUrl: string | IReadonlyObservableValue<string>;

  /**
   * PhotoId is used for eventdispatch when there is a playback error
   */
  photoId?: string;

  /**
   * An ID identifying the current playback (not user) session, unique to each video loaded
   */
  playbackSession?: string;

  /**
   * If you need to access the local player, you can pass a reference that the loalplayer will be assigned to
   */
  player?: React.MutableRefObject<MediaPlayerClass | undefined>;

  /**
   * Function to show the error message
   * @param displayMessage
   * @returns
   */
  setFeedback?: (displayMessage: React.ReactNode | undefined) => void;

  /**
   * Element to pass for closed captions
   */
  ttmlElement?: React.RefObject<HTMLDivElement>;

  /**
   * Reference to the HTMLVideoElement
   */
  videoElement: React.RefObject<HTMLVideoElement>;
}

/**
 * React hook to import dashjs and play video formats that are not natively supported by the browser
 * @param props
 */
export function useVideoSource(props: IVideoSourceOptions): void {
  const {
    driveId,
    loadFailure,
    logLevel = 0,
    loadPlayer,
    manifestUrl,
    photoId,
    playbackSession,
    player,
    setFeedback,
    ttmlElement,
    videoElement
  } = props;

  const authorizationContext = React.useContext(AuthorizationContext);
  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);
  const sessionContext = React.useContext(SessionContext);

  const [, setCount] = React.useState(0);

  const canPlayVideo = !(
    featureContext.featureEnabled("DisableVideoPlaybackForItalianLanguageUsers").value &&
    sessionContext.locale.split("-", 1)[0].toLowerCase() === "it"
  );

  const { trackPromise } = usePromise();
  const failLoading = useOnce(loadFailure);
  const _getPhoto = useFetch(getPhoto, { blocking: false, name: "downloadManifest" });

  // Create a rejection handler to communicate failures to the user.
  const handleRejection = useRejection();
  const url = useSubscription(manifestUrl);

  const supportsDash = supportsMediaSource();
  const loadDashjs = supportsDash && loadPlayer && !!url && canPlayVideo;

  if (!canPlayVideo) {
    failLoading();
    setFeedback && setFeedback(VideoBlockItalianLanguage);
  }

  const dashPromise = React.useMemo(() => {
    return loadDashjs ? trackPromise(import("dashjs")) : undefined;
  }, [loadDashjs, trackPromise]);

  React.useEffect(() => {
    let _player: MediaPlayerClass | undefined;

    if (dashPromise && videoElement.current) {
      dashPromise
        .then((dashjs) => {
          const localPlayer = dashjs.MediaPlayer().create();
          const reportedRequests: object[] = [];

          // Save the player to the outer scope for cleanup
          _player = localPlayer;

          // If an error occurs during the loading phase of the video we will
          // trigger a load failure.
          localPlayer.on(dashjs.MediaPlayer.events.ERROR, failLoading);

          // On a RequestsQueue metric is added we will look for completed network requests to
          // record telemetry.
          localPlayer.on(dashjs.MediaPlayer.events.METRIC_ADDED, (event: MetricEvent) => {
            // When a request gets executed, it will get added to the RequestsQueue.executedRequests.
            if (event.metric === "RequestsQueue") {
              // The request in the queue doesn't have infomation about the response.
              // The HttpRequest from the dashMetrics contains such info.
              const currentHttpRequest = localPlayer.getDashMetrics().getCurrentHttpRequest(event.mediaType);
              if (!currentHttpRequest) {
                return;
              }

              const { responsecode = 0, trequest, type = "", url = "", _responseHeaders, _tfinish } = currentHttpRequest as IDashHttpRequest;

              // Keep track of the requests that we have reported and only
              // report them the first time they are complete. We will look
              // at the tfinish to look for completed requests. We don't want
              // to report requests that are still in progress.
              if (!reportedRequests.includes(currentHttpRequest) && _tfinish) {
                const endTime = _tfinish && _tfinish.getTime ? _tfinish.getTime() : 0;
                const name = "getVideoSegment";
                const startTime = trequest && trequest.getTime ? trequest.getTime() : 0;
                let clientErrorCode: string | undefined;
                let message = VideoUnhandledError;
                let ms_cv: string | undefined;
                let result: PromiseSettledResult<unknown>;

                // Extract the important headers from the response if they are available.
                if (_responseHeaders && typeof _responseHeaders === "string") {
                  const headers = _responseHeaders.split("\r\n");

                  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
                    const values = headers[headerIndex].split(":", 2);
                    const value = values[1] ? values[1].trim() : "";

                    if (values[0] === "ms-cv") {
                      ms_cv = value;
                    }

                    // Use the clientErrorCode returned from server side if it is defined
                    if (values[0] === "x-clienterrorcode") {
                      clientErrorCode = value;
                      if (clientErrorCode === "GeoLocInvalidRequest") {
                        message = VideoGeoLocked;
                      }
                    }
                  }
                }

                // Setup the result based on the response code, a 200's response code is success.
                if (responsecode < 200 || responsecode > 299) {
                  if (responsecode === 404) {
                    clientErrorCode = clientErrorCode || "itemNotFound";
                    message = VideoUnavailable;
                  }

                  // Prepare more info to help debugging
                  const executed = (event.value as RequestsQueue).executedRequests;
                  const recentExecutedRequest = executed.find((request) => request.url === url);

                  if (recentExecutedRequest) {
                    const extraInfo = {
                      bytesLoaded: recentExecutedRequest.bytesLoaded,
                      codec: recentExecutedRequest.mediaInfo?.codec,
                      index: recentExecutedRequest.index,
                      segmentDuration: recentExecutedRequest.duration,
                      startTime: recentExecutedRequest.startTime,
                      timescale: recentExecutedRequest.timescale,
                      totalVideoDuration: recentExecutedRequest.mediaInfo?.streamInfo?.duration
                    };
                    message = `${message} ${JSON.stringify(extraInfo)}`;
                  }

                  result = {
                    reason: {
                      error: { code: clientErrorCode || "videoNetworkFailure", message },
                      response: new Response(JSON.stringify({ error: { code: clientErrorCode || "vidoNetworkFailure", message } }), {
                        headers: { "content-type": "application/json" },
                        status: responsecode
                      })
                    },
                    status: "rejected"
                  };
                  // Show the failure message to the user.
                  setFeedback && setFeedback(message);
                } else {
                  result = { status: "fulfilled", value: null };
                }

                eventContext.dispatchEvent("fetchStart", { name, options: { scenarioName: type }, url });
                eventContext.dispatchEvent("fetchComplete", {
                  duration: endTime - startTime,
                  endTime,
                  ms_cv,
                  name,
                  options: { scenarioName: type },
                  result,
                  startTime,
                  telemetryProperties: { httpStatus: responsecode },
                  url
                });

                // Add the request to our set of reported requests, dash will
                // report them multiple times through the metrics.
                reportedRequests.push(currentHttpRequest);
              }
            }
          });

          // If there is an error during playback we will record a telemetry
          // record and show the user a message.
          localPlayer.on(dashjs.MediaPlayer.events.PLAYBACK_ERROR, (event: PlaybackErrorEvent) => {
            eventContext.dispatchEvent("telemetryAvailable", {
              action: "exception",
              error: {
                code: "videoPlaybackError",
                message: event.error
              },
              name: "videoPlaybackError",
              playbackId: playbackSession,
              videoId: photoId
            });

            setFeedback && setFeedback(VideoUnhandledError);
          });

          // Once the stream is initialzed we will save our player locally.
          if (player) {
            localPlayer.on("streamInitialized", () => {
              player.current = localPlayer;

              // We need to cause a state refresh when the player is resolved.
              // This allows the caller to update state when the player is available.
              setCount((count) => count + 1);
            });
          }

          // Don't perform retries on the manifest download.
          localPlayer.updateSettings({ debug: { logLevel }, streaming: { retryAttempts: { MPD: 0 } } });

          // Add a request modification that will add our access token to the requets
          // when they are made.
          localPlayer.extend("RequestModifier", authorizationRequestModifier(authorizationContext, sessionContext), true);

          // Setup the dash player on our video element.
          // The videoElement will always be available since the promise is tracked.
          // autoplay by default is false and uses the videoElements autoplay attribute
          localPlayer.initialize(videoElement.current!, url, false, videoStart);

          if (ttmlElement) {
            localPlayer.attachTTMLRenderingDiv(ttmlElement.current!);
          }
        })
        .catch(handleRejection);
    }

    return () => {
      if (player) {
        player.current = undefined;
      }

      if (_player) {
        _player.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashPromise, videoElement, url]);

  React.useMemo(() => {
    if (!supportsDash && canPlayVideo) {
      if (photoId && driveId) {
        _getPhoto(driveId, photoId, {
          // id is mandatory to select downloadUrl
          select: `id,${sessionContext.migrated ? "content.downloadUrl" : "@microsoft.graph.downloadUrl"}`
        })
          .then((video) => {
            const _videoElement = videoElement.current;
            // iphones dont support MediaExtensions and video playing using blob uri (createObjectURl)
            // inorder to hide the access token we are fetching the video and appending the format at the end
            // of the download url
            if (video["@content.downloadUrl"] && _videoElement) {
              const manifestUrl = new URL(video["@content.downloadUrl"]);
              addManifestParams(manifestUrl);

              _videoElement.src = manifestUrl.toString();
              _videoElement.currentTime = videoStart;
              loadPlayer && _videoElement.load();
            }
          })
          .catch(handleRejection);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driveId, photoId, videoElement]);
}
