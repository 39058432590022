import { IReadonlyObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";

/**
 * Options used to control how requested features are treated.
 */
export interface IFeatureOptions {}

/**
 * An IFeature represents a single feature and the configuration of the feature.
 */
export interface IFeature {
  /**
   * Features can be categorized. This is not used for any functional value, it
   * is only used to group related features.
   */
  featureCategory?: string;

  /**
   * The featureDescription is required and is a human readable string that
   * describes the purpose of the feature.
   */
  featureDescription: string;

  /**
   * featureEnabled defines whether or no the feature is currently enabled for
   * use in the product. It is an observableValue which allows the caller to
   * observe it and react to changes in the feature state.
   */
  featureEnabled: IReadonlyObservableValue<boolean>;

  /**
   * Human readable form of the feature. This is a short name that should convey
   * the purpose of the feature.
   */
  featureName: string;

  /**
   * requireRefresh defines whether or not the feature can be updated live. If
   * this is set to true, a change to this feature will result in the page
   * reloading. This is similar to requiring reboot on the OS when patches are
   * made. Setting this to true should be avoided whenever possible since it is
   * disruptive.
   */
  requireRefresh?: boolean;

  /**
   * If the client application can persist users choices this flag should be
   * used as a filter to control whether or not the feature state is saved
   * locally. Transient features should NOT be saved.
   *
   * @default false
   */
  transient?: boolean;

  /**
   * userVisible defines whether or not the user should be able to see and modify
   * this setting. If set to false, UX that shows features should not reveal
   * this feature to the user.
   */
  userVisible?: boolean;
}

export interface IExperiment<T> {
  /**
   * Human readable string that describes the purpose of the experiment
   */
  description: string;

  /**
   * Value of the experiment with type T. In an A/B experiment this could be any
   * value such as a string "GroupA" and "GroupB". It is an observableValue which allows the caller to
   * observe it and react to changes in the feature state.
   */
  experimentGroup: IReadonlyObservableValue<T | undefined>;
}

/**
 * The IFeatureContext is used to describe what features are available for the given
 * react tree. It offers the caller access to read and update the features
 */
export interface IFeatureContext {
  /**
   * Set of available A/B experiments
   */
  experiments: Readonly<{ [experimentId: string]: IExperiment<any> }>;

  /**
   * Returns the group that the user is participating in for an A/B experiment
   *
   * @param experimentId Name of the experiment being ran
   */
  experimentGroup<T>(experimentId: string): IReadonlyObservableValue<T | undefined>;

  /**
   * Set of available features
   */
  features: Readonly<{ [featureId: string]: IFeature }>;

  /**
   * featureEnabled is used to retrieve the state of a given feature. If a featureId
   * is supplied that doesn't exist a disabled feature value is returned.
   *
   * @param featureId Unique identifier for the feature. This is NOT the featureName.
   * @param options Options that control how the feature is returned.
   * @returns FeatureState as an ObservableValue<boolean>, use .value, an Observer,
   * or useSubscription to access the current state.
   */
  featureEnabled: (featureId: string, options?: IFeatureOptions) => IReadonlyObservableValue<boolean>;

  /**
   * setExperimentGroup is used to update the experiments current state. This is used for development purposes
   * as the user shouldnt be able to alter which experiment group they can be in
   *
   * @param experimentId Unique identifier for the experiment.
   * @param value value to set the experiment to
   */
  setExperimentGroup: <T>(experimentId: string, value: T) => void;

  /**
   * setFeatureEnabled is used to update the features current state.
   *
   * @param featureId Unique identifier for the feature. This is NOT the featureName.
   * @param featureEnabled true to enable the feature, false to disable it.
   */
  setFeatureEnabled: (featureId: string, featureEnabled: boolean) => void;
}

const disabledFeature = new ObservableValue<boolean>(false);
const unknownExperiment = new ObservableValue(undefined);

export const FeatureContext = React.createContext<IFeatureContext>({
  experiments: {},
  experimentGroup: () => unknownExperiment,
  features: {},
  featureEnabled: () => disabledFeature,
  setExperimentGroup: () => {},
  setFeatureEnabled: () => {}
});
