import React from "react";
import { EventContext } from "../../common/contexts/event";
import { noop } from "../../common/utilities/func";
import { MessageType } from "../types/message";

const defaultOptions: IRejectionOptions = {};

export interface IRejectionOptions {
  /**
   * A filterFunction can be supplied that allows the caller to filter the error
   * and either return the original error, nothing (undefined or null), or an
   * altered error more suitable for presentation.
   *
   * @param error The underlying error that caused the rejection.
   * @returns The resulting error for presentation, or undefined for no presentation.
   */
  filterFunction?: (error: any) => any;

  /**
   * The type of message to show when an error is processed.
   *
   * @default "error"
   */
  messageType?: MessageType;

  /**
   * A custom timeout used for this message when it is shown.
   */
  timeout?: number;
}

/**
 * useRejection generates a function that can be passed to a promise's catch
 * handler, or called from an execption handler to report the error to the UX
 * through the message center.
 *
 * The common pattern is to call:
 *  const handleRejection = useRejection();
 *
 *  proimse.then(...).catch(handleRejection)
 *
 * @param options specify specific control over the messages generated by the error.
 * @returns A function that can be used to handle promise failures & exceptions.
 */
export function useRejection(options?: IRejectionOptions): (error: any) => void {
  const { filterFunction, messageType = "error", timeout } = options || defaultOptions;

  const eventContext = React.useContext(EventContext);

  function handleRejection(error: any): void {
    // If the caller supplied a filter function we will run it through the filter
    // before we proceeding with the processing.
    if (filterFunction) {
      error = filterFunction(error);
    }

    if (error) {
      // If the error is the cancelation of a promise, we dont want to report this.
      // These are expected when outstanding calls are not complete before a
      // component is unmounted.
      if (error.isCanceled) {
        return;
      }

      if (typeof error === "object") {
        // Extract a useful message from it if we still have an object
        error = extractError(error);
      }

      eventContext.dispatchEvent("showMessage", {
        messageType,
        timeout: getMessageTimeout(),
        title: () => {
          return <span>{error}</span>;
        }
      });
    }

    function getMessageTimeout(): number {
      return timeout === undefined ? (messageType === "error" ? 0 : 2000) : timeout;
    }
  }

  return React.useCallback(handleRejection, [eventContext, filterFunction, messageType, timeout]);
}

/**
 * Returns a rejection handler that does nothing with the error.
 *
 * @returns A function that can be used to handle promise failures & exceptions.
 */
export function useRejectionNoOp(): (error: any) => void {
  return useRejection({ filterFunction: noop });
}

/**
 * ErrorMessageOptions can be used to control how network responses are handled.
 */
export interface IErrorMessageOptions {
  processResponse?: (response: Response, responseText: string) => Promise<string>;
}

export function extractError(error: any, root: boolean = true): string {
  if (typeof error === "string") {
    return error;
  }

  if (error.localizedMessage) {
    return error.localizedMessage;
  }

  if (error.message) {
    if (typeof error.message === "string") {
      return error.message;
    }

    return JSON.stringify(error.message);
  }

  if (error.error) {
    let message = extractError(error.error, false);

    if (!message && root) {
      message = JSON.stringify(error);
    }

    return message;
  }

  return "";
}
