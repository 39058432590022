import React from "react";
import { IUseFetchOptions, useFetch as _useFetch } from "../../common/hooks/usefetch";
import { FetchFunction, IFetchTelemetry } from "../../common/utilities/fetch";
import { SessionContext } from "../contexts/session";
import { WorkerContext } from "../contexts/worker";
import { retryCallback } from "../utilities/fetch";

/**
 * useFetch is used to execute a fetch network request and process the result into
 * a state change in the calling component.
 *
 * If a network calls complete our of order or after a new request has been issued
 * it will not call the setState/setError of the original call. It will complete
 * scenarios it was attached too.
 *
 * @param fetchFunction Function that executes the fetch request and binds the result.
 * @param options A set of options that control the hook integration.
 */
export function useFetch<T, P extends any[]>(fetchFunction: FetchFunction<T, P>, options?: IUseFetchOptions): (...args: P) => Promise<T> {
  const sessionContext = React.useContext(SessionContext);
  const workerContext = React.useContext(WorkerContext);

  const telemetryProperties: IFetchTelemetry = { workerAvailable: workerContext.workerDetails.value.available };

  // @NOTE: We need to pass the state of migration at the start of the request
  // since this can change between the start and end of the request.
  // This can be removed once there is no more ODC support.
  if (sessionContext.migrated !== undefined) {
    telemetryProperties.migrated = sessionContext.migrated;
  }

  return _useFetch(fetchFunction, { retryCallback, telemetryProperties, ...options });
}
