import {
  IObservableArray,
  IObservableArrayEventArgs,
  IReadonlyObservableArray,
  IReadonlyObservableValue,
  ObservableArray,
  ObservableLike,
  ObservableValue
} from "azure-devops-ui/Core/Observable";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";

/**
 * React Hooks extension that allows the consumer to track Observables with a useState like
 * hooks API.
 *
 * @param initialState Initial value for the state, or a function that will resolve the value
 * the when the value is initialized.
 */
export function useObservable<T>(initialState: T | (() => T)): [IReadonlyObservableValue<T>, React.Dispatch<React.SetStateAction<T>>] {
  const [underlyingState] = React.useState<T>(initialState);
  const [reactState] = React.useState<ObservableValue<T>>(new ObservableValue<T>(underlyingState));

  const updateState = React.useCallback(
    (updatedState: T | ((prevState: T) => T)) => {
      if (typeof updatedState === "function") {
        reactState.value = (updatedState as (prevState: T) => T)(reactState.value);
      } else {
        reactState.value = updatedState;
      }
    },
    [reactState]
  );

  return [reactState, updateState];
}

/**
 * React Hooks extension that allows the consmer to track ObservableArrays with a useState like
 * hooks API.
 *
 * @param initialState Initial value for the state, or a function that will resolve the value
 * the when the value is initialized.
 */
export function useObservableArray<T>(initialState: T[] | (() => T[])): [IObservableArray<T>, React.Dispatch<React.SetStateAction<T[]>>] {
  const [underlyingState] = React.useState<T[]>(initialState);
  const [reactState] = React.useState<ObservableArray<T>>(new ObservableArray<T>(underlyingState));

  const updateState = React.useCallback(
    (updatedState: T[] | ((prevState: T[]) => T[])) => {
      if (typeof updatedState === "function") {
        reactState.value = (updatedState as (prevState: T[]) => T[])(reactState.value);
      } else {
        reactState.value = updatedState;
      }
    },
    [reactState]
  );

  return [reactState, updateState];
}

/**
 * The useSubscription hook is designed to observe changes to an IObservableValue
 * and call the specified delegate when the observable notifys us of changes.
 *
 * @observable The object that should be observed. This can be a non-observable
 * value and the hook will noop.
 *
 * @delegate The callback that is called when the observable notifies us of a
 * change. If the delegate returns true the hook will setState and trigger a
 * component render.
 *
 * @action The action that should be subscribed too. By default when no action
 * is supplied, all actions will be subscribed too.
 */
export function useSubscription<T>(
  observable: T | IReadonlyObservableValue<T>,
  delegate?: (value: T, action: string) => boolean | void,
  action?: string
): T {
  // Since we are subscribing during render we need to track it to unsubscribe
  // if we render again with a new delegate. If we don't do this we will have
  // multiple active subscriptions with the old delegate.
  const delegateRef = React.useRef<((value: T, action: string) => boolean | void) | undefined>(undefined);

  const [, setValue] = React.useState<T>(ObservableLike.getValue<T>(observable));

  // Create a subscription delegate for wrapping the callers delegate and
  // handling setting state when required.
  const _delegate = React.useCallback(
    (value: T, action: string) => {
      unstable_batchedUpdates(() => {
        if (delegate) {
          if (delegate(value, action)) {
            setValue(value);
          }
        } else {
          setValue(value);
        }
      });
    },
    [delegate]
  );

  // We need to subscribe immediately and not after the mounting process. This
  // will handle the value changing between the time we start mounting and our
  // subscription gets setup.
  React.useMemo(() => {
    if (delegateRef.current) {
      ObservableLike.unsubscribe(observable, delegateRef.current, action);
    }

    ObservableLike.subscribe(observable, _delegate, action);
    delegateRef.current = _delegate;
  }, [action, _delegate, delegateRef, observable]);

  React.useEffect(() => {
    // Make sure to unsubscribe when we change the observable or unmount.
    // Subscribe to the observable on the first render pass.
    return () => {
      ObservableLike.unsubscribe(observable, _delegate, action);
    };
  }, [action, _delegate, observable]);

  return ObservableLike.getValue<T>(observable);
}

/**
 * The useSubscription hook is designed to observe changes to an IObservableValue
 * and call the specified delegate when the observable notifys us of changes.
 *
 * @observable The object that should be observed. This can be a non-observable
 * value and the hook will noop.
 *
 * @delegate The callback that is called when the observable notifies us of a
 * change. If the delegate returns true the hook will setState and trigger a
 * component render.
 *
 * @action The action that should be subscribed too. By default when no action
 * is supplied, all actions will be subscribed too.
 */
export function useSubscriptionArray<T>(
  observable: T[] | IReadonlyObservableArray<T>,
  delegate?: (changes: IObservableArrayEventArgs<T>, action: string) => boolean | void,
  action?: string
): T[] {
  // Since we are subscribing during render we need to track it to unsubscribe
  // if we render again with a new delegate. If we don't do this we will have
  // multiple active subscriptions with the old delegate.
  const delegateRef = React.useRef<((changes: IObservableArrayEventArgs<T>, action: string) => boolean | void) | undefined>(undefined);

  // Since we are tracking an object that doesnt change, we use the count to
  // trigger an update and not a change to the underlying object itself.
  const [, setCount] = React.useState(0);

  // Create a subscription delegate for wrapping the callers delegate and
  // handling setting state when required.
  const _delegate = React.useCallback(
    (changes: IObservableArrayEventArgs<T>, action: string) => {
      unstable_batchedUpdates(() => {
        if (delegate) {
          if (delegate(changes, action)) {
            setCount((count) => count + 1);
          }
        } else {
          setCount((count) => count + 1);
        }
      });
    },
    [delegate]
  );

  // We need to subscribe immediately and not after the mounting process. This
  // will handle the value changing between the time we start mounting and our
  // subscription gets setup.
  React.useMemo(() => {
    if (delegateRef.current) {
      ObservableLike.unsubscribe(observable, delegateRef.current, action);
    }

    ObservableLike.subscribe(observable, _delegate, action);
    delegateRef.current = _delegate;
  }, [action, _delegate, observable]);

  React.useEffect(() => {
    return () => {
      ObservableLike.unsubscribe(observable, _delegate, action);
    };
  }, [action, _delegate, observable]);

  return ObservableLike.getValue<T>(observable);
}
