import { IReadonlyObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";
import { IWorkerDetails } from "../types/worker";

export interface IWorkerContext {
  workerDetails: IReadonlyObservableValue<IWorkerDetails>;
}

export const WorkerContext = React.createContext<IWorkerContext>({
  workerDetails: new ObservableValue<IWorkerDetails>({ available: false, capabilities: [] })
});
