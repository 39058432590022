// mediasourcesupportcheck.ts
export function supportsMediaSource() {
  return "WebKitMediaSource" in window || "MediaSource" in window;
}

// debug.ts
// istanbul ignore next - We cant mock "debugger" so it is not possible to verify this.
/**
 * debugBreak can be used to force the development tools to break into the
 * debugger when the debugger is attached. If the debugger is not attached
 * this function no-ops.
 *
 * It is a separate function to allow tests to mock it and prevent breaking
 * into the debugger but verifying the debugger was requested.
 */
export function debugBreak() {
  debugger;
}

// cookie.ts
let cookieCache: { [cookieValue: string]: { [cookieName: string]: string } } = {};

/**
 * Deletes the cookie with the specified name
 *
 * @param name Name of the cookie to delete
 * @param path Path for which to delete the cookie, defaults to '/'
 */
export function deleteCookie(name: string, path: string = "/") {
  setCookie(name, "", path, "Thu, 01 Jan 1970 00:00:01 GMT");
}

/**
 * getCookie returns the value for the specified cookie. If there is no value for the cookie
 * an empty string will be returned for the value.
 *
 * @param cookieName Name of the cookie
 * @returns Value for the cookie if it is set, otherwise empty string.
 */
export function getCookie(cookieName: string): string {
  const cookieValue = document.cookie || "";
  const cachedValue = cookieCache[cookieValue];

  if (cachedValue) {
    return cachedValue[cookieName] || "";
  }

  return parseCookies(cookieValue)[cookieName] || "";
}

/**
 * getCookies returns the set of all parsed cookies.
 *
 * @returns All cookies parsed into a dictionary.
 */
export function getCookies(): { [cookieName: string]: string } {
  const cookieValue = document.cookie || "";
  const cachedValue = cookieCache[cookieValue];

  if (cachedValue) {
    return cachedValue;
  }

  return parseCookies(cookieValue);
}

/**
 * Sets a cookie with the specified options
 *
 * @param name Name of cookie
 * @param value Value of cookie
 * @param path Path for which to set cookie, defaults to '/'
 * @param expires Optional, data in GMTString format, indicating the time of expiration
 * @param maxAge Optional, maximum age of the cookie in seconds
 */
export function setCookie(name: string, value: string, path: string = "/", expires?: string, maxAge?: number) {
  const attributes: string[] = [`${name}=${value}`, `path=${path}`];

  if (expires) {
    attributes.push(`expires=${expires}`);
  }

  if (maxAge) {
    attributes.push(`max-age=${maxAge}`);
  }

  // Set secure flag if current connection is https
  if (window.location.protocol.indexOf("https") !== -1) {
    attributes.push("secure");
  }

  document.cookie = attributes.join(";");
}

function parseCookies(cookieValue: string): { [cookieName: string]: string } {
  const cookies = cookieValue.split(";");

  // Create a new cookie cache, we only cache for the latest value.
  cookieCache = {};

  // Create a cache for these cookies.
  const cachedValue: { [cookieName: string]: string } = {};
  cookieCache[cookieValue] = cachedValue;

  // Go through each cookie and find the one that starts with <name>=
  for (let index = 0; index < cookies.length; index++) {
    const cookie = cookies[index].trimStart();
    const parsedCookie = cookie.split("=");

    if (parsedCookie.length === 2) {
      cachedValue[parsedCookie[0]] = decodeURIComponent(parsedCookie[1]);
    }
  }

  return cachedValue;
}
