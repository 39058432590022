import { formatURL } from "../../common/utilities/format";
import { IGraphProfile } from "../types/profile";
import { graphImage, graphResponse } from "./network";

const profileApi = "{{graphOrigin}}/v1.0/me?$select=";

export function getProfile(fetch: (url: string, init?: RequestInit) => Promise<Response>): Promise<IGraphProfile> {
  return graphResponse(fetch(profileApi));
}

const profileImageApi = "{{graphOrigin}}/v1.0/me/photos/{{size}}/$value";

export function getProfileImage(fetch: (url: string, init?: RequestInit) => Promise<Response>, size: string = "48x48"): Promise<Blob | null> {
  return graphResponse(fetch(formatURL(profileImageApi, { size })), graphImage);
}

const profileOdcApi = "{{odcOrigin}}/users/0x{{userId}}/myprofile/expressionprofile/profilephoto:UserTileStatic";

export function getProfileImageOdc(fetch: (url: string, init?: RequestInit) => Promise<Response>, userId: string): Promise<Blob | null> {
  return graphResponse(fetch(formatURL(profileOdcApi, { userId })), graphImage);
}
