import { IReadonlyObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";
import { IPhoto } from "../types/photo";

export interface IBackground {
  /**
   * Set of attributes to include in the background image element.
   */
  attributes?: React.EmbedHTMLAttributes<HTMLElement>;

  /**
   * Custom content can be rendered into the background (under the overlay)
   */
  customContent?: React.ReactNode | ((name: string) => React.ReactNode);

  /**
   * Optional className that will be added to the background overlay.
   */
  overlayAttributes?: React.HTMLAttributes<HTMLElement>;

  /**
   * The photo / video that should be shown in the background.
   */
  photo: Pick<IPhoto, "dimensions" | "id">;

  /**
   * Should the video be treated as a video and auto played in the background?
   */
  video?: boolean;
}

export interface IBackgroundContext {
  getBackground: (name: string) => IReadonlyObservableValue<IBackground | undefined>;
  setBackground: (name: string, background?: IBackground) => void;
}

const backgrounds: { [name: string]: ObservableValue<IBackground | undefined> } = {};

export const BackgroundContext = React.createContext<IBackgroundContext>({
  getBackground: (name: string) => {
    let background = backgrounds[name];

    if (!background) {
      backgrounds[name] = background = new ObservableValue<IBackground | undefined>(undefined);
    }

    return background;
  },
  setBackground: (name: string, background?: IBackground) => {
    let existingBackground = backgrounds[name];

    if (!existingBackground) {
      backgrounds[name] = new ObservableValue<IBackground | undefined>(background);
    } else {
      existingBackground.value = background;
    }
  }
});
