import React from "react";
import { useEventListener } from "../../hooks/uselistener";
import { getFocusElements } from "../../utilities/focus";

// Each time we apply the aria-hidden algorthim we need a new generation.
// This allows us to open multiple modals at the same time and understand
// which elements to reset when the modal is closed.
let _generation = 0;

const hiddenStack: number[] = [];
const trackingAttribute = "data-modal-generation";

export interface IAriaSiblingsHiddenProps {
  selector: string;
}

export function AriaSiblingsHidden(props: IAriaSiblingsHiddenProps & { children?: React.ReactNode }): JSX.Element {
  const { selector } = props;

  const [generation] = React.useState(() => _generation++);

  // Handle focus events moving focus outside the current siblings and move focus
  // within the current root element.
  useEventListener(
    document,
    "focusin",
    (event) => {
      if (generation === hiddenStack[hiddenStack.length - 1]) {
        const { body } = document;
        const rootElement = body.querySelector<HTMLElement>(selector);
        let current = event.target as HTMLElement;

        // Dont allow focus within any element with data-modal-generation since
        // this element is blocked by an active modal.
        while (current && current !== body && current.parentElement !== null) {
          if (current.getAttribute("data-modal-generation")) {
            const focusableElements = getFocusElements(rootElement);

            if (focusableElements.length) {
              let elementIndex = 0;

              // Skip elements with -1 tabIndex since they are not normally focusable
              // without explicit request to focus it. If we dont find one by the
              // last element we will just use the last one.
              while (elementIndex < focusableElements.length - 1) {
                if (focusableElements[elementIndex].getAttribute("tabIndex") !== "-1") {
                  break;
                }

                elementIndex++;
              }

              // Move focus to the element we want to receive it.
              focusableElements[elementIndex].focus();

              // NOTE: You should generally NEVER stopPropagation, instead us
              // preventDefault / defaultPrevented. The issue is focus is not use
              // these feature and we are resetting focus during capture of an
              // existing focus event. This will stop the initial event from
              // being acted upon.
              event.stopImmediatePropagation();
              break;
            }
          }

          current = current.parentElement;
        }
      }
    },
    { capture: true }
  );

  React.useEffect(() => {
    const { body } = document;
    const rootElement = body.querySelector<HTMLElement>(selector);

    if (rootElement) {
      let current: HTMLElement | null = rootElement;

      // Track the stack of hidden generations, this will let us know which is
      // the top/current generation.
      hiddenStack.push(generation);

      while (current !== body && current.parentElement !== null) {
        // For every sibling of currentElement, we mark with aria-hidden="true" except ours.
        // We add a tracking attribute that has a generational value of our ownership.
        const children: HTMLCollection = current.parentElement.children;
        for (const child of children) {
          if (
            child !== current &&
            child.nodeName !== "SCRIPT" &&
            child.nodeName !== "STYLE" &&
            !child.getAttribute("aria-hidden") &&
            !child.querySelector("[data-modal-generation]")
          ) {
            child.setAttribute(trackingAttribute, generation.toString());
            child.setAttribute("aria-hidden", "true");
          }
        }

        // Walk up the tree and process the next set of siblings.
        current = current.parentElement;
      }

      // Return the cleanup code to reset the aria-hidden attributes we affected.
      return () => {
        const resetElements = body.querySelectorAll(`*[data-modal-generation='${generation}']`);

        for (const resetElement of resetElements) {
          resetElement.removeAttribute("aria-hidden");
          resetElement.removeAttribute(trackingAttribute);
        }

        // Remove of generation when we are cleaned up, we may not be the top
        // element on the stack, make sure we get out index.
        hiddenStack.splice(hiddenStack.indexOf(generation), 1);
      };
    }
  }, [generation, selector]);

  return <>{props.children}</>;
}
