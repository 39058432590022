import { ISelection } from "../../common/utilities/selection";
import { ISessionContext } from "../contexts/session";
import { photoDimensions } from "../layout/util";
import { IDimensions, IParentReference } from "../types/item";
import { IRectangle } from "../types/layout";
import { IDuplicatePhotoParentReference, IPersonThumbnail, IPhoto } from "../types/photo";

/**
 * computeSquare takes a rectangle and a set of dimensions. Creates a square
 * using the longest dimension + an optional scaling factor. It will also
 * optionally adjust the target square to ensure there is no overflow.
 *
 * @param sourceRectangle The source rectangle to be scaled into a square.
 * @param dimensions The maximum dimensions of the rectangle.
 * @param factor The scaling factor that should be applied to the rectangle.
 * @param allowOverflow Should the rectangle be trimmed to ensure no overflow.
 * @returns A square (rectangle) that matches the requested values.
 */
export function computeSquare(sourceRectangle: IRectangle, dimensions: IDimensions, factor: number = 1, allowOverflow: boolean = false): IRectangle {
  // Find the centerpoint of the crop region
  const centerX = sourceRectangle.left + sourceRectangle.width / 2;
  const centerY = sourceRectangle.top + sourceRectangle.height / 2;

  // Build a square whose side length is the max of the two axes * plus a factor.
  let longestAxis = Math.max(sourceRectangle.width, sourceRectangle.height) * factor;
  let halfAxis = longestAxis / 2;

  // Determine if the rectangle overflows the dimensions.
  if (!allowOverflow) {
    const leftOverflow = centerX - halfAxis;
    const topOverflow = centerY - halfAxis;
    const rightOverflow = dimensions.width - (centerX + halfAxis);
    const bottomOverflow = dimensions.height - (centerY + halfAxis);

    // Adjust the length by the most negative * 2 (once for each side).
    const overflow = Math.min(leftOverflow, topOverflow, rightOverflow, bottomOverflow);
    if (overflow < 0) {
      longestAxis += overflow * 2;
      halfAxis = longestAxis / 2;
    }
  }

  return { left: centerX - halfAxis, top: centerY - halfAxis, width: longestAxis, height: longestAxis };
}

/**
 * filterUrl is used to prepare a URL for logging. We need to ensure we are not
 * logging EUII and other sensitive data.
 *
 * General URL filtering guidance can be found here: https://dev.azure.com/onedrive/SPARC/_wiki/wikis/ODSP-Observability-Wiki/57033/Client-EUII-Logging
 *
 * @param url URL that should be filtered, it is filtered in place.
 */
export function filterUrl(url: URL): URL {
  const params = Array.from(url.searchParams.keys());

  // Remove search params values but leave the keys.
  params.forEach((name) => {
    url.searchParams.delete(name);
    url.searchParams.set(name, "");
  });

  // Replace the path for any preauths with a fixed value.
  if (url.hostname.indexOf(".files.1drv.com") > 0) {
    url.pathname = "--preauth--";
  }

  // Remove any filenames from the telemetry
  const contentSegment = url.pathname.indexOf("/content/");
  if (contentSegment >= 0) {
    url.pathname = url.pathname.substring(0, contentSegment + 8);
  }

  return url;
}

/**
 * Function that returns that value of the current search term when you are
 * viewing the search results for it.
 *
 * @param sessionContext The current context of the application
 * @returns The active search term if one is available or empty string.
 */
export function getActiveSearch(sessionContext: ISessionContext): string {
  const currentPage = sessionContext.getPage().current;
  return ((currentPage === "Search" || currentPage === "FlorenceSearch") && new URLSearchParams(window.location.search).get("q")) || "";
}

export function getDriveRelativePath(parentReference: Pick<IParentReference, "path" | "driveId"> | undefined): string {
  const driveId = parentReference?.driveId;

  // Ensure we remove any escape sequences from the string.
  let relativePath = decodeURIComponent(parentReference?.path || "");

  // Strip off the root so we only show the relative path.
  if (relativePath.indexOf("/drive/root:") === 0) {
    relativePath = relativePath.substring(12);
  } else if (driveId && relativePath) {
    // Cob files have root in the format of '/drives/driveId/root:'
    const cobPathFormat = `/drives/${driveId}/root:`;

    if (relativePath.indexOf(cobPathFormat) === 0) {
      relativePath = relativePath.substring(cobPathFormat.length);
    }
  }

  return relativePath;
}

/**
 * Function that gets all the other locations of a photo.
 *
 * @param photo The origin photo
 * @returns An array of IOtherLocation which denote copies' locations info
 */
export function getOtherLocations(photo: Pick<IPhoto, "duplicates" | "parentReference">): IDuplicatePhotoParentReference[] {
  const otherLocations: IDuplicatePhotoParentReference[] = [];

  // If the origin photo have copies
  if (photo.duplicates && photo.duplicates.length > 0) {
    const originPath: string = getDriveRelativePath(photo.parentReference);
    const paths: string[] = [originPath];

    for (const item of photo.duplicates) {
      const { parentReference } = item;

      if (parentReference) {
        const { driveId, id } = parentReference;
        if (id) {
          const relativePath = getDriveRelativePath(parentReference);

          // Make sure we don't add the same path twice
          if (paths.indexOf(relativePath) === -1) {
            paths.push(relativePath);
            otherLocations.push({ driveId, id, relativePath });
          }
        }
      }
    }
  }

  return otherLocations;
}

/**
 * Get actual all selected photos including the duplicated photos from current selection.
 * @param selection
 * @param getItem The function provide by photoFeed to get item object via id
 * @returns all selected photos.
 */
export function getAllSelectedPhotos(selection: ISelection, getItem: (id: string) => IPhoto | undefined): IPhoto[] {
  const actualSelectedPhoto: IPhoto[] = [];
  Array.from(selection.value).forEach((id) => {
    const photo = getItem(id);
    if (photo) {
      actualSelectedPhoto.push(photo);
      if (photo.duplicates) {
        actualSelectedPhoto.push(...photo.duplicates);
      }
    }
  });
  return actualSelectedPhoto;
}

/**
 * Get all duplicated photos (excludes the default photo) parent folder name.
 * @param selection
 * @param getItem The function provide by photoFeed to get item object via id
 * @returns all selected duplicated photos' parent path.
 */
export function getDuplicatedPhotosParent(selection: ISelection, getItem: (id: string) => IPhoto | undefined): string[] {
  const duplicatePhotoParent: string[] = [];
  Array.from(selection.value).forEach((id) => {
    const photo = getItem(id);
    if (photo) {
      duplicatePhotoParent.push(...getDuplicatedPhotosParentFromPhoto(photo));
    }
  });
  return duplicatePhotoParent;
}

/**
 * Get all duplicated photos (excludes the default photo) parent folder name from single Photo.
 * @param photo
 * @returns all selected duplicated photos' parent path.
 */
export function getDuplicatedPhotosParentFromPhoto(photo: IPhoto): string[] {
  const duplicatePhotoParent: string[] = [];
  if (photo.duplicates) {
    photo.duplicates.forEach((duplicate) => {
      if (duplicate.parentReference && duplicate.parentReference.path) {
        const parentPath: string = duplicate.parentReference.path;
        const pathSegments = parentPath.split("/");
        duplicatePhotoParent.push(pathSegments[pathSegments.length - 1]);
      }
    });
  }
  return duplicatePhotoParent;
}

const charsWithinParenthesisRegex = /\(([^)]*)\)/gi;
// eslint-disable-next-line no-control-regex
const excludedCharactersRegex = /\([^)]*\)|[\0-\u001F!-/:-@[-`{-\u00BF\u0250-\u036F\uD800-\uFFFF]/gi;
const multipleSpacesRegex = /\s+/gi;
/**
 * Returns the initials of a display name.
 *
 * @param name Display name of the user
 * @returns
 */
export function getInitial(name: string): string {
  let initials = "";
  if (!name) {
    return initials;
  }
  name = name.replace(charsWithinParenthesisRegex, ""); // remove chars inside paranthesis
  name = name.replace(excludedCharactersRegex, ""); // remove non-alphanumeric chars
  name = name.replace(multipleSpacesRegex, " "); // replace multiple spaces with single
  name = name.trim();

  let splits = name.split(" ");
  if (splits.length >= 2) {
    initials += splits[0].charAt(0).toUpperCase();
    initials += splits[splits.length - 1].charAt(0).toUpperCase();
  } else if (splits.length === 1) {
    initials += splits[0].charAt(0).toUpperCase();
  }
  return initials;
}

/**
 * Gets the appropriate crop rectangle and image dimensions for the given person's
 * thumbnail (item.representativeItemId)
 * @param thumbnail The thumbnail information of the person
 * @param thumbnailCropScale The scale of the cropping with a default value of 1
 * @returns A pair of crop rectangle and total image dimensions
 */
export function getPersonThumbnailInfo(
  thumbnail?: IPersonThumbnail,
  thumbnailCropScale: number = 1
): { cropRect: IRectangle | undefined; imageDimensions: IDimensions } {
  let cropRect: IRectangle | undefined;
  let imageDimensions: IDimensions = photoDimensions.dimension160Rect;

  if (thumbnail) {
    const src = thumbnail.source;

    imageDimensions = {
      width: src.width,
      height: src.height
    };

    if (src.sourceCropRegions && src.sourceCropRegions.length) {
      // The service only sends us one cropRegion
      const cropRegion = src.sourceCropRegions[0];

      // Compute the image cropRect based on the region and dimensions
      cropRect = computeSquare(
        { left: cropRegion.boundingBoxX, height: cropRegion.boundingBoxHeight, top: cropRegion.boundingBoxY, width: cropRegion.boundingBoxWidth },
        imageDimensions,
        thumbnailCropScale
      );
    }
  }

  return { cropRect, imageDimensions };
}

/**
 * prepareRelativeUrl is used to ensure the incoming URL is relative to the the
 * current domain. If it is not, null is returned.
 *
 * @param url Incoming url to prepare.
 * @returns A URL relative to the current location or null.
 */
export function prepareRelativeUrl(url: string | null | undefined): string | null | undefined {
  if (url) {
    try {
      const fullyQualifiedUrl = new URL(url, window.location.origin);
      if (fullyQualifiedUrl.origin === window.location.origin) {
        return fullyQualifiedUrl.toString();
      }
    } catch (error) {
      // Ignore any errors and just return null.
    }
  }

  return null;
}

/**
 * reduce is used to reduce a fraction to its simplest form.
 *
 * @param numerator Fractional numerator
 * @param denominator Fractional denominator
 * @returns The simplest form with [numerator, denominator]
 */
export function reduce(numerator: number, denominator: number): [number, number] {
  const gcd = (n: number, d: number): number => {
    return d ? gcd(d, n % d) : n;
  };

  const divisor = gcd(numerator, denominator);

  if (divisor) {
    return [numerator / divisor, denominator / divisor];
  }

  return [0, 0];
}

/**
 * Round a date object to the current day, it
 * @param currentDate
 * @returns
 */
export function roundDate(currentDate: Date): Date {
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
}

/**
 * We only upload images with content types image/* or video/*, or extensions .heic
 * @param file
 * @returns if the file type supported for upload
 */
export function uploadSupported(file: File): boolean {
  return file.type.startsWith("image/") || file.type.startsWith("video/") || file.name.endsWith(".heic");
}

/**
 * Checks if the album has a valid name
 * Does not contain special characters *:?|<>/\ and does not end with a period
 *
 * @param name Album name
 * @returns
 */
export function validAlbumName(name: string): boolean {
  // eslint-disable-next-line no-control-regex
  return !/^.*[:?|<>*/\\].*$|.*\.$/g.test(name);
}
