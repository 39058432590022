import React from "react";
import { useEventContextListener } from "../../common/hooks/useeventcontextlistener";
import { IFetchCompleteEvent } from "../../common/utilities/fetch";

interface IRateLimit {
  limit: number;
  remaining: number;
  reset: number;
}

export interface IThrottleContext {
  /**
   * The throttle method is used to determine the level of throttling that
   * exists for a given origin.
   *
   * @param origin The API origin whos throttle to retrieve.
   * @returns The percentage of throttling resources consumed, at 100 (1.0) the
   *  origin will not allow requests. The client can expect 429's in return.
   */
  throttle: (origin: string) => number;
}

export const ThrottleContext = React.createContext<IThrottleContext>({ throttle: () => 0 });

export function useThrottleContext(): IThrottleContext {
  const limits = new Map<string, IRateLimit>();

  /**
   * Listen to the completion of fetch requests and track the rate limits.
   * The rate limits are applied to each origin individually.
   */
  useEventContextListener("fetchComplete", (event: IFetchCompleteEvent) => {
    const { url } = event;

    const _url = new URL(url);
    const origin = _url.origin;
    const httpStatus = fromValue(event.telemetryProperties.httpStatus);
    const limit = fromValue(event.telemetryProperties.rateLimitLimit);

    // Evaluate the current rateLimit values and either update or delete them.
    // Some 429 responses don't have rate limit information or have rate limit information that says the remaining quota is nonzero.
    // Instead of reading from the response, set the rate limit based on the fact the request was throttled.
    if (httpStatus === 429 || httpStatus === 503) {
      const currentLimit = limits.get(origin);
      if (currentLimit?.remaining !== 0) {
        limits.set(origin, {
          limit: limit ?? 100,
          remaining: 0,
          reset: 18 // Arbitrary value. This should be tweaked if we start using it.
        });
      }
    } else if (limit) {
      const remaining = fromValue(event.telemetryProperties.rateLimitRemaining);
      const reset = fromValue(event.telemetryProperties.rateLimitReset);

      if (remaining !== undefined && reset !== undefined) {
        limits.set(origin, { limit, remaining, reset });
      }
    } else if (event.result.status === "fulfilled") {
      limits.delete(origin);
    }
  });

  return {
    throttle: (origin: string) => {
      const rateLimit = limits.get(origin);
      return rateLimit ? 1 - rateLimit.remaining / rateLimit.limit : 0;
    }
  };

  function fromValue(value: string | number | boolean | undefined): number | undefined {
    return typeof value === "number" ? value : typeof value === "string" ? Number.parseInt(value) : undefined;
  }
}
