import React, { useCallback } from "react";

import { DelayLoadedMeControlDeferredFluent as MeControl, type MeAccount } from "@mecontrol/fluent-web";
import { clearUserCookies, getSignInUri, IUserProfile, SessionContext } from "../../contexts/session";
import { ThemeContext } from "../../../common/contexts/theme";
import { useObservable, useSubscription } from "../../../common/hooks/useobservable";
import { format } from "../../../common/utilities/format";

const baseSignOutUrl = "https://login.live.com/logout.srf?ct=1665689758&id=250206&ru={{redirectUrl}}%3Fmkt%3D{{market}}&mkt={{market}}";
const baseUpdateProfilePictureUrl = "https://account.microsoft.com/profile/edit-picture?username={{username}}&ref=MeControl";
// Me.srf is returning 0x80041018 (PP_E_INVALIDSITEID). Is site misconfigured in MSM?
// const baseMeUrl = "https://login.live.com/Me.srf?wa=wsignin1.0&idpflag=indirect&id=250206&wreply={{redirectUrl}}";

function mapUserProfileToMeAccount(userProfile?: IUserProfile, pictureUrl?: string): MeAccount | null {
  return {
    id: userProfile?.oid || "",
    accountType: "MSA",
    preferredUsername: userProfile?.preferred_username || "",
    friendlyName: userProfile?.name,
    profilePicUrl: pictureUrl
  };
}

export function NpmMeControlContainer() {
  const sessionContext = React.useContext(SessionContext);
  const themeContext = React.useContext(ThemeContext);

  const userProfile = useSubscription(sessionContext.userProfile);
  const pictureUrl = useSubscription(sessionContext.profileUrl);

  const locale = sessionContext.locale;
  const signInUrl = getSignInUri(sessionContext, "me2");
  const signOutUrl: string = format(baseSignOutUrl, { redirectUrl: encodeURIComponent(window.location.origin), market: locale });
  const switchParams = new URLSearchParams({ wreply: signInUrl });
  const switchUrl = `${signOutUrl}&${switchParams.toString()}`;
  const updateProfilePictureUrl = format(baseUpdateProfilePictureUrl, { username: encodeURIComponent(userProfile?.preferred_username || "") });
  const isAuthenticated = sessionContext.authenticated();

  let currentAccount = null;
  let [rememberedAccounts] = useObservable<MeAccount[]>([]);

  // Address remembered accounts in follow-up PR
  // const meUrl: string = format(baseMeUrl, { redirectUrl: encodeURIComponent(window.location.origin), market: locale });
  // useState(() => {
  //   fetchRememberedAccounts({ msaUrl: meUrl }).then((response) => {
  //     setRememberedAccounts(response.rememberedAccounts);
  //   });
  // });

  if (isAuthenticated) {
    currentAccount = mapUserProfileToMeAccount(userProfile, pictureUrl);
  }

  const theme = useSubscription(themeContext.currentTheme);

  const addNewAccount = () => {
    window.location.href = switchUrl;
  };

  const signIn = () => {
    window.location.href = signInUrl;
  };

  const signOut = () => {
    clearUserCookies();
    window.location.href = signOutUrl;
  };

  // Once remembered accounts call to Me.srf is working, switch to the { targetAccountId: string } in this callback
  const switchTo = () => {};

  const updateProfilePicture = useCallback(() => {
    window.location.href = updateProfilePictureUrl;
  }, [updateProfilePictureUrl]);

  return (
    <MeControl
      locale={locale}
      currentAccount={currentAccount}
      theme={theme.dark ? "dark" : "light"}
      handlers={{
        addNewAccount,
        signIn,
        signOut,
        switchTo,
        updateProfilePicture
      }}
      rememberedAccounts={rememberedAccounts.value}
    />
  );
}
