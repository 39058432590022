import React from "react";
import { useHistory } from "react-router-dom";
import { EventContext } from "../../common/contexts/event";
import { NavigationContext, INavigationOptions } from "../../common/contexts/navigation";
import { isMetaNavigation } from "../../common/utilities/event";

export interface IMetaNavigationProps {
  navigationParameters?: URLSearchParams;
  children?: React.ReactNode;
}

export function MetaNavigation(props: IMetaNavigationProps): React.ReactElement {
  const { navigationParameters, children } = props;
  const locationHistory: string[] = [window.location.pathname];

  const eventContext = React.useContext(EventContext);

  // Get the current react router history.
  const history = useHistory();

  if (history) {
    history.listen((location, action) => {
      if (action === "POP") {
        // We may go back more than one entry go back until we match.
        while (locationHistory.length && locationHistory[locationHistory.length - 1] !== location.pathname) {
          locationHistory.splice(locationHistory.length - 1, 1);
        }
      } else if (action === "REPLACE") {
        locationHistory[locationHistory.length - 1] = location.pathname;
      } else {
        locationHistory.push(location.pathname);
      }
    });
  }

  function navigate<T>(event: React.KeyboardEvent | React.MouseEvent | undefined, name: string, options?: INavigationOptions<T>): void {
    const { href, state, telemetryProperties, useBack, useReplace } = options || {};

    // Determine if this a meta navigation (using Shift, Alt, Ctrl, etc).
    // This will open a new target, we don't want to intercept this one.
    const metaNavigation = event ? isMetaNavigation(event) : false;

    // If the current element has an href attribute of an href was supplied this
    // is the location we will navigate.
    let location =
      href ||
      (event && event.currentTarget.getAttribute("href")) ||
      (useBack && locationHistory.length > 1 && locationHistory[locationHistory.length - 2]) ||
      options?.fallback;

    // If the caller supplied a telemetry option we will fire an event with
    // the telemetry properties supplied.
    eventContext.dispatchEvent("telemetryAvailable", {
      action: "userAction",
      name,
      ...telemetryProperties,
      metaNavigation
    });

    // If the user didn't navigate in place we don't need to update the location.
    if (history && !metaNavigation && location) {
      const url = new URL(location, window.location.origin);

      if (url.origin === window.location.origin) {
        // Ensure the location is a relative path at this point.
        location = url.toString().substring(url.origin.length);

        // Since this is a meta navigation make sure we dont operate on the actual
        // navigation since we will be updating the router instead.
        event && event.preventDefault();

        // If the caller wants to navigate back using this when the history shows
        // the previous page matches the navigation target, use that model.
        if (useBack) {
          // Search backward in the history to find the first appropriate navigation
          // that matches the target location.
          for (let index = locationHistory.length - 2; index >= 0; index--) {
            if (locationHistory[index] === url.pathname) {
              history.go(index - locationHistory.length + 1);
              return;
            }
          }
        }

        // If the caller wants the previous navigation replaced we will do that
        // instead of using a push.
        if (useReplace) {
          history.replace(location, state);
          return;
        }

        // Navigate to the specified location, and record a history entry.
        history.push(location, state);
      } else {
        // Since we are navigating away from the site, we will process a
        // beforeNavigate event before we perform the navigation.
        // Right now we are going to navigate on success and failure, we could
        // have the promises return a well understood contract to stop navigation.
        new Promise<void>((resolve, reject) => {
          const pendingPromises: Promise<unknown>[] = [];

          eventContext.dispatchEvent("beforeNavigate", {
            location: new URL(location!),
            waitUntil: (promise: Promise<unknown>): void => {
              pendingPromises.push(promise);
            }
          });

          Promise.allSettled(pendingPromises).then(() => resolve(), reject);
        }).finally(() => {
          window.location.href = location!;
        });

        // If an event was supplied we will initially prevent default to allow
        // the flush operation to complete before moving on.
        if (event) {
          event.preventDefault();
        }
      }
    }

    // We will prevent navigations from being executed during test execution.
    // istanbul ignore next - We always enter this branch in tests.
    if (process.env.NODE_ENV === "test") {
      event?.preventDefault();
    }
  }

  function prepare(route: string): string;
  function prepare(route: string | undefined): string | undefined {
    if (route && navigationParameters) {
      // Prepare will only perform parameter additions to local / relative URL's.
      const href = new URL(route, window.location.origin);

      // If the URL is a local reference add the additional parameters
      if (href.origin === window.location.origin) {
        for (const parameter of navigationParameters) {
          href.searchParams.set(parameter[0], parameter[1]);
        }

        route = href.toString().substring(href.origin.length);
      }
    }

    return route;
  }

  return <NavigationContext.Provider value={{ navigate, prepare }}>{children}</NavigationContext.Provider>;
}
