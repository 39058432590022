import { IObservableArrayEventArgs, ObservableArray, ObservableValue } from "azure-devops-ui/Core/Observable";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSubscriptionArray } from "../../common/hooks/useobservable";
import { noop } from "../../common/utilities/func";
import { interceptRejection } from "../../common/utilities/promise";
import { FeedContext } from "../contexts/feed";
import { IItemPage } from "../types/item";
import { IFeedCursor, IItemFeed } from "../types/itemfeed";

export interface IItemFeedOptions<T> {
  /**
   * The ensureData flag can be supplied which will cause the feed to only
   * return pages with data when they are available. If an empty page is
   * returned with a next link, the link will be followed until a page with
   * data is returned.
   *
   * NOTE: This will NOT evaluate previous pages under any condition.
   *
   * @default false
   */
  ensureData?: boolean;

  /**
   * A filter function can be supplied to filter out photos that are not needed
   * in the feed. The caller should filter the array down to the set of items
   * that should be added.
   *
   * @param items The items being added to the feed. Filter out unwanted items.
   * @param getItem A function which will return an existing item from the feed.
   *  This can be used to help determine if a photo exists in the feed again.
   */
  filterFunction?: (items: T[], getItem: (id: string) => T | undefined) => void;

  /**
   * When the feed is initializing the items it will call the initialize method
   * before it processes any results. This allows callers to setup any state
   * associated with the current items in the feed, instead of all items seen
   * by the feed.
   */
  initialize?: () => void;

  /**
   * Enables getItemsFromFeed caching. This caches requests with the same URL so that
   * multiple feed consumers don't make duplicate network calls.
   *
   * @default false
   */
  useCache?: boolean;
}

/**
 * A feed represents a virtual set of items defined by the getItem call.
 *
 * @param getItems A method that is used to retrieve items from the feed. A call
 * with no URL should return the first page and calls with URL's should return
 * the appropriate page.
 * @param options Options used to control behaviors within the feed.
 * @returns A completed feed.
 */
export function useItemFeed<T extends { id: string }>(
  getItems: (url?: string) => Promise<IItemPage<T>>,
  options?: IItemFeedOptions<T>
): IItemFeed<T> & { feedId: number } {
  const { ensureData = false, filterFunction = noop, initialize = noop, useCache = false } = options || {};

  const feedContext = React.useContext(FeedContext);

  const [{ getItemsPageCache, itemMap, items, removedItems }] = React.useState(() => {
    return {
      getItemsPageCache: new Map<string, Promise<IItemPage<T>>>(),
      itemMap: new Map<string, T>(),
      items: new ObservableArray<T>([]),
      removedItems: new Set<string>()
    };
  });

  const itemFeed = React.useMemo(() => {
    const cursor: IFeedCursor = { nextPage: undefined, previousPage: undefined };
    const initialized = new ObservableValue(false);

    const getItemsFromFeed = (url?: string) => {
      const cacheKey = url ?? "";
      if (useCache) {
        let cached = getItemsPageCache.get(cacheKey);
        if (cached) {
          return cached;
        }
      }

      const feedPromise = getItems(url)
        .then(ensurePageData)
        .then((itemPage) => {
          unstable_batchedUpdates(() => {
            // Handle the initial request where no url was supplied.
            if (!url) {
              // Call the optional initialize method before processing the results.
              initialize();

              // Save the cursour next/previous page links.
              cursor.nextPage = itemPage["@odata.nextLink"];
              cursor.previousPage = itemPage["@oneDrive.previousPageLink"];

              // Apply the filter to the incoming page.
              filterFunction(itemPage.value, getItem);

              // Save the initial items as the entire collection.
              items.value = itemPage.value;

              // Mark the item feed as initialized now that the initial call is complete.
              initialized.value = true;
            } else if (url === cursor.nextPage) {
              // Update our cursor with the next page link.
              cursor.nextPage = itemPage["@odata.nextLink"];

              // Apply the filter to the incoming page.
              filterFunction(itemPage.value, getItem);

              // Add the records to the end of the tracked items.
              items.splice(items.length, 0, ...itemPage.value);
            } else if (url === cursor.previousPage) {
              const reversedItems = itemPage.value;
              const itemCount = reversedItems.length;

              // The previous elements come back in ascending order so we need to reverse them.
              for (let startIndex = 0, endIndex = itemCount - 1; startIndex < Math.floor(itemCount / 2); startIndex++, endIndex--) {
                const tempItem = reversedItems[startIndex];
                reversedItems[startIndex] = reversedItems[endIndex];
                reversedItems[endIndex] = tempItem;
              }

              // Update our cursor with the previous page link.
              cursor.previousPage = itemPage["@oneDrive.previousPageLink"];

              // Apply the filter to the incoming page.
              filterFunction(reversedItems, getItem);

              // Add the items to the beginning of the tracked items.
              items.splice(0, 0, ...reversedItems);
            }
          });

          return { nextPage: itemPage["@odata.nextLink"], previousPage: itemPage["@oneDrive.previousPageLink"], value: itemPage.value };
        })
        .catch(
          interceptRejection<IItemPage<T>>(() => {
            initialized.value = true;
          })
        );

      if (useCache) {
        getItemsPageCache.set(cacheKey, feedPromise);
      }

      return feedPromise;
    };

    // Generate a cache entry for this feed.
    const feedId = feedContext.add({ cursor, getItem, getItemsFromFeed, initialized, items });

    return { cursor, feedId, getItem, getItemsFromFeed, initialized, items };

    // ensurePageData will continue to request the nextPage if the returned
    // page returns 0 records but a nextLink.
    function ensurePageData(itemPage: IItemPage<T>): Promise<IItemPage<T>> {
      return ensureData && !itemPage.value.length && itemPage["@odata.nextLink"]
        ? getItems(itemPage["@odata.nextLink"]).then(ensurePageData)
        : Promise.resolve(itemPage);
    }

    function getItem(id: string, activeOnly?: boolean): T | undefined {
      return activeOnly && removedItems.has(id) ? undefined : itemMap.get(id);
    }
  }, [ensureData, feedContext, filterFunction, getItems, getItemsPageCache, initialize, itemMap, items, removedItems, useCache]);

  const { feedId } = itemFeed;

  // Keep the itemMap up to date with added and removed items.
  useSubscriptionArray(
    items,
    React.useCallback(
      (changes: IObservableArrayEventArgs<T>) => {
        changes.removedItems && changes.removedItems.forEach((item) => removedItems.add(item.id));
        changes.addedItems &&
          changes.addedItems.forEach((item) => {
            itemMap.set(item.id, item);
            removedItems.delete(item.id);
          });
        return true;
      },
      [itemMap, removedItems]
    )
  );

  React.useEffect(() => {
    return () => {
      feedContext.delete(feedId);
      getItemsPageCache.clear();
    };
  }, [feedContext, feedId, getItemsPageCache]);

  return itemFeed;
}
