import { Button } from "azure-devops-ui/Button";
import { FocusZone, FocusZoneDirection } from "azure-devops-ui/FocusZone";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { Observer } from "../../../common/components/observer/observer";
import { EventContext } from "../../../common/contexts/event";
import { useObservable, useSubscription } from "../../../common/hooks/useobservable";
import { ISelection, Selection } from "../../../common/utilities/selection";
import { NavigationExpand } from "../../components/illustration/icons";
import { SessionContext } from "../../contexts/session";
import { NavigationPanel } from "../../frame/navigation";
import { useLayoutStyle } from "../../hooks/uselayoutstyle";

import "./pagecommandbar.css";

const { ToggleNavigation } = window.Resources.Common;

interface IPageCommandbarProps {
  children?: (selection: Set<string>) => React.ReactElement | null;
  className?: string;
  defaultElementIds?: { selectionActive: string; selectionInactive: string };
  raised?: boolean;
  selection?: ISelection;
}

export function PageCommandbar(props: IPageCommandbarProps): React.ReactElement {
  const { children, className, defaultElementIds, raised } = props;

  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const rootElement = React.useRef<HTMLDivElement>(null);

  const [localSelection] = React.useState(() => new Selection());
  const [showNavigation, setShowNavigation] = useObservable(false);

  // Subscribe to changes in the selection and re-render the commandbar.
  const selection = useSubscription(props.selection || localSelection);

  const layoutStyle = useLayoutStyle(
    React.useCallback(
      (layoutStyle) => {
        // Hide the navigation if we expand and are no longer in mobile mode and
        // the navigation is currently visible.
        if (layoutStyle === "desktop" && showNavigation.value) {
          setShowNavigation(false);
        }
      },
      [setShowNavigation, showNavigation.value]
    )
  );

  const desktopLayout = layoutStyle === "desktop";
  const selectedCount = selection.size;
  const raisedCommandBar = selectedCount > 0 || raised;

  const defaultElementId =
    !desktopLayout && sessionContext.authenticated() && !sessionContext.embedded
      ? "open-navigation"
      : defaultElementIds
        ? selection.size
          ? defaultElementIds.selectionActive
          : defaultElementIds.selectionInactive
        : undefined;

  return (
    <div className="relative flex-row flex-noshrink" ref={rootElement}>
      <FocusZone direction={FocusZoneDirection.Horizontal} focusGroupProps={{ defaultElementId }} handleTabKey={false}>
        <div
          className={css(
            "page-command-bar command-bar",
            className,
            "flex-row flex-grow flex-align-center padding-vertical-8 overflow-hidden",
            raisedCommandBar && "page-command-bar-raised",
            !desktopLayout && raisedCommandBar && "depth-16",
            desktopLayout && selectedCount === 0 && !raised && "margin-left-12 margin-right-32",
            (sessionContext.embedded || desktopLayout) && raisedCommandBar && "margin-left-4 margin-right-24 padding-horizontal-8 depth-16"
          )}
          role="menubar"
        >
          {!desktopLayout && sessionContext.authenticated() && !sessionContext.embedded && (
            <div className={css("page-command-bar-inner flex-row flex-align-center margin-left-8")}>
              <Button
                ariaLabel={ToggleNavigation}
                className="toggle-navigation"
                iconProps={{ render: (className: string) => <NavigationExpand className={className} /> }}
                id="open-navigation"
                key="toggle-navigation"
                onClick={() => {
                  eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "showNavigationPanel" });
                  setShowNavigation(!showNavigation.value);
                }}
                role="menuitem"
                subtle={true}
              />
              <div className={css("separator-line-right margin-vertical-4 margin-left-8")} />
            </div>
          )}
          <div className={css("page-command-bar-inner flex-row flex-align-center flex-grow", !desktopLayout && "margin-horizontal-12")}>
            {children && children(selection)}
          </div>
        </div>
      </FocusZone>
      <Observer values={{ showNavigation }}>
        {({ showNavigation }) => (showNavigation ? <NavigationPanel onDismiss={() => setShowNavigation(false)} /> : null)}
      </Observer>
    </div>
  );
}
