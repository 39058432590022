import { Button } from "azure-devops-ui/Button";
import { ITooltipProps } from "azure-devops-ui/TooltipEx";
import { Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { EventContext } from "../../../common/contexts/event";
import { FavoritesContext } from "../../contexts/favorite";
import { IPhotoDetails } from "../../types/photo";
import { Delete, InformationInverted, Share, Star, StarHighlighted } from "../illustration/icons";
import { PhotoInformation } from "../photoinformation/photoinformation";

const { DeleteButton, RemoveFavorite, ShareButton, ShowPhotoDetails, Favorite } = window.Resources.Common;

export interface IQuickCommandProps {
  /**
   * This is a defined icon button and requires an aria label to help understand
   * the commands purpose.
   */
  ariaLabel: string;

  /**
   * The icon used to render this quick action.
   */
  icon: React.FunctionComponent<{ className?: string }>;

  /**
   * If the caller wants to perform processing they can add an onClick hander.
   */
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;

  /**
   * Name of the telemetry userAction fired when this command is executed.
   */
  telemetryAction: string;

  /**
   * Allow the caller to supply simple name value pairs as additional data that
   * can be included in telemetry events sourced by the command.
   */
  telemetryProperties?: { [propertyName: string]: string | number | boolean | undefined | null };

  /**
   * Optional tooltip properties for a custom tooltip
   */
  tooltipProps?: ITooltipProps;
}

export function QuickCommand(props: IQuickCommandProps): React.ReactElement {
  const { ariaLabel, icon, onClick, telemetryAction, telemetryProperties = {}, tooltipProps } = props;

  const eventContext = React.useContext(EventContext);

  return (
    <Button
      ariaLabel={ariaLabel}
      className="tile-button rounded-4"
      iconProps={{ render: (className: string) => React.createElement(icon, { className }) }}
      onClick={(event) => {
        eventContext.dispatchEvent("telemetryAvailable", { ...telemetryProperties, action: "userAction", name: telemetryAction });
        onClick && onClick(event);
        event.preventDefault();
      }}
      tooltipProps={tooltipProps}
    />
  );
}

export interface IDeleteQuickCommandProps {
  /**
   * If the caller wants to perform processing they can add an onClick hander.
   */
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;

  /**
   * The photo this quick command is operating on.
   */
  photo: IPhotoDetails;

  /**
   * Allow the caller to supply simple name value pairs as additional data that
   * can be included in telemetry events sourced by the command.
   */
  telemetryProperties?: { [propertyName: string]: string | number | boolean | undefined | null };
}

export function DeleteQuickCommand(props: IDeleteQuickCommandProps): React.ReactElement {
  return <QuickCommand {...props} ariaLabel={DeleteButton} icon={Delete} telemetryAction="deleteQuickAction" />;
}

export interface IDetailsQuickCommandProps {
  /**
   * If the caller wants to perform processing they can add an onClick hander.
   */
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;

  /**
   * The photo this quick command is operating on.
   */
  photo: IPhotoDetails;

  /**
   * Allow the caller to supply simple name value pairs as additional data that
   * can be included in telemetry events sourced by the command.
   */
  telemetryProperties?: { [propertyName: string]: string | number | boolean | undefined | null };
}

export function DetailsQuickCommand(props: IDetailsQuickCommandProps): React.ReactElement {
  const { photo } = props;

  return (
    <>
      <QuickCommand
        {...props}
        ariaLabel={ShowPhotoDetails}
        icon={InformationInverted}
        telemetryAction="detailsQuickAction"
        tooltipProps={{
          anchorOffset: { horizontal: 0, vertical: -5 },
          anchorOrigin: { horizontal: Location.start, vertical: Location.start },
          className: "tile-information",
          renderContent: () => <PhotoInformation photo={photo} />,
          tooltipOrigin: { horizontal: Location.start, vertical: Location.end }
        }}
      />
    </>
  );
}

export interface IFavoriteQuickCommandProps {
  /**
   * If the caller wants to perform processing they can add an onClick hander.
   */
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;

  /**
   * The photo this quick command is operating on.
   */
  photo: IPhotoDetails;

  /**
   * Allow the caller to supply simple name value pairs as additional data that
   * can be included in telemetry events sourced by the command.
   */
  telemetryProperties?: { [propertyName: string]: string | number | boolean | undefined | null };
}

export function FavoriteQuickCommand(props: IFavoriteQuickCommandProps): React.ReactElement {
  const favoritesContext = React.useContext(FavoritesContext);

  const favorite = favoritesContext.favorites.has(props.photo.id);

  return (
    <QuickCommand
      {...props}
      ariaLabel={favorite ? RemoveFavorite : Favorite}
      icon={favorite ? StarHighlighted : Star}
      telemetryAction="favoriteQuickAction"
    />
  );
}

export interface IShareQuickCommandProps {
  /**
   * If the caller wants to perform processing they can add an onClick hander.
   */
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;

  /**
   * The photo this quick command is operating on.
   */
  photo: IPhotoDetails;

  /**
   * Allow the caller to supply simple name value pairs as additional data that
   * can be included in telemetry events sourced by the command.
   */
  telemetryProperties?: { [propertyName: string]: string | number | boolean | undefined | null };
}

export function ShareQuickCommand(props: IShareQuickCommandProps): React.ReactElement {
  return <QuickCommand {...props} ariaLabel={ShareButton} icon={Share} telemetryAction="shareQuickAction" />;
}
