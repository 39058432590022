import { Button } from "azure-devops-ui/Button";
import { Checkbox } from "azure-devops-ui/Checkbox";
import { announce } from "azure-devops-ui/Core/Util/Accessibility";
import { FocusGroupContext } from "azure-devops-ui/FocusGroup";
import { FocusZone, FocusZoneContext } from "azure-devops-ui/FocusZone";
import { Spinner, SpinnerSize } from "azure-devops-ui/Spinner";
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { css, getSafeId } from "azure-devops-ui/Util";
import { getTabIndex } from "azure-devops-ui/Utilities/Focus";
import React from "react";
import { EventContext } from "../../../common/contexts/event";
import { FeatureContext } from "../../../common/contexts/feature";
import { NavigationContext } from "../../../common/contexts/navigation";
import { useFocusTracker } from "../../../common/hooks/usefocustracker";
import { useMouseTracker } from "../../../common/hooks/usemousetracker";
import { useSubscription } from "../../../common/hooks/useobservable";
import { useInterval, useTimeout } from "../../../common/hooks/usetimeout";
import { preventTouchMenu, useLongTouch } from "../../../common/hooks/usetouch";
import { format } from "../../../common/utilities/format";
import { getKeyState } from "../../../common/utilities/keystate";
import { IRangeSelection } from "../../../common/utilities/rangeselection";
import { IDownloadOptions } from "../../api/photo";
import { DateContext } from "../../contexts/date";
import { EmbedContext } from "../../contexts/embed";
import { FavoritesContext } from "../../contexts/favorite";
import { SessionContext } from "../../contexts/session";
import { useLayoutStyle } from "../../hooks/uselayoutstyle";
import { getPhotoContent } from "../../hooks/usephotocontent";
import { openPhotoODB } from "../../operations/photo";
import { IRectangle, ITilePadding } from "../../types/layout";
import { AspectRatio, IPhoto } from "../../types/photo";
import { formatAltTags, formatDuration } from "../../utilities/format";
import { GroupPhotos, PlayPreview, Shared, StarHighlighted } from "../illustration/icons";
import { Photo } from "../photo/photo";
import { Placeholder } from "../placeholder/placeholder";
import { IThrottleDelay, Throttle, ThrottleStatus } from "../throttle/throttle";
import { TileOverlay } from "../tileoverlay/tileoverlay";
import { IVideoControls, Video, videoStart } from "../video/video";

import "./phototile.css";

const { CheckboxStateChecked, CheckboxStateUnchecked, Duration, PhotoText, RangeSelected, SharedLabel, VideoText } = window.Resources.Common;
const { GroupedPhotoTooltip } = window.Resources.PhotoTile;

const animationFrameCount = 5;

// @NOTE: This is a prelimiary throttling configuration, we can refine over time.
const imageThrottling: IThrottleDelay[] = [
  { throttle: 1.0, delayMs: 18000 },
  { throttle: 0.95, delayMs: 15000 },
  { throttle: 0.9, delayMs: 10000 },
  { throttle: 0.85, delayMs: 5000 },
  { throttle: 0.75, delayMs: 3000 },
  { throttle: 0.6, delayMs: 1000 }
];

export type IPhotoCommand = () => React.ReactElement;

export interface IPhotoTilePhoto extends IPhoto {}

export interface IPhotoTileProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * the aspect ratio of CropRegion to crop image
   */
  aspectRatio?: AspectRatio;

  /**
   * Does this tile block the current scenario from completing?
   *
   * @default false
   */
  blocking?: boolean;

  /**
   * Optional css className added to the root element of the photo tile.
   */
  className?: string;

  /**
   * If commands are supplied, the caller can supply a function which is used
   * to determine if a command is active. This will allow the tile to prevent
   * any tile interaction while its active and keep the commands shown.
   *
   * @returns The name of the active command is one is active or undefined if
   * no command is active.
   */
  commandActive?: () => string | undefined;

  /**
   * Set of optional commands that can be added to the tile.
   * The commands appear in the bottom left corner of the tile.
   */
  commands?: React.ReactElement;

  /**
   * Optional set of information that will be presented on top of the phototile.
   * The containing element is marked with the .photo-debug-info css class.
   */
  debugInfo?: React.ReactNode;

  /**
   * The downloadPhoto function is used to retrieve the URL of the photo. It
   * can return a remote URL or one that references a local object created
   * via window.URL.createObjectURL.
   */
  downloadPhoto: (
    fetch: (url: string, init?: RequestInit) => Promise<Response>,
    driveId: string,
    photoId: string,
    options?: IDownloadOptions
  ) => Promise<Blob>;

  /**
   * State if using a fixed photo ensures the image appears at the supplied dimensions.
   *
   * @default: false
   */
  fixed?: boolean;

  /**
   * Set of photos that shown together with this photo. If only a single photo
   * is shown in this tile the photos array can be left out.
   */
  group?: IPhotoTilePhoto[];

  /**
   * Link to navigate to when the user activates the photo tile.
   */
  href?: string;

  /**
   * The caller can pass in label for overlay
   */
  label?: React.ReactNode;

  /**
   * State you want added to the navigation. This is used to pass data on to the
   * next view.
   */
  navigationState?: any;

  /**
   * Optional css className added to the Photo. It is either "portrait-mode" : "landscape-mode"
   */
  orientation?: "portrait-mode" | "landscape-mode";

  /**
   * Optional padding that was created around the tile. This may be supplied
   * to allow the tile to render content in reserved space outside the bounds
   * of the core items rectangle.
   */
  padding?: ITilePadding;

  /**
   * Main photo of the tile.
   */
  photo: IPhotoTilePhoto;

  /**
   * Rectangle to render the photo. This is given in absolute coordinates.
   */
  rect: IRectangle;

  /**
   * The caller can pass in a selection object and when this is available the photo
   * tile should be available for selection. If no selection is supplied then
   * no selection UI elements should be rendered.
   *
   * Supplying an IRangeSelection allows the tile to participate in range
   * selection. This is behaviors that involve selecting multiple tiles at
   * a time through a proposed selection.
   */
  selection?: IRangeSelection;

  /**
   * Optional command to show the shortcut favorite button
   */
  showFavorite?: boolean;

  /**
   * The caller can pass in subtitle for overlay
   */
  subtitle?: React.ReactNode;

  /**
   * Allow the caller to supply simple name value pairs as additional data that
   * can be included in telemetry events sourced by the photo tile.
   */
  telemetryProperties?: { [propertyName: string]: string | number | boolean | undefined | null };

  /**
   * State if the component will load the smaller image and transition to the full size once its loaded.
   *
   * @default true
   */
  transition?: boolean;
}

/**
 * PhotoTile
 *
 * @param props
 */
export function PhotoTile(props: IPhotoTileProps): React.ReactElement {
  const {
    aspectRatio,
    blocking = false,
    className = "absolute",
    commandActive,
    commands,
    debugInfo,
    downloadPhoto,
    fixed = false,
    group = [],
    href,
    label,
    navigationState,
    orientation,
    padding,
    photo,
    rect,
    selection,
    showFavorite,
    subtitle,
    telemetryProperties = {},
    transition = true,
    ...attributes
  } = props;
  const { id, video } = photo;
  const itemRect = padding ? { ...rect, height: rect.height - padding.bottom } : rect;

  const dateContext = React.useContext(DateContext);
  const embedContext = React.useContext(EmbedContext);
  const eventContext = React.useContext(EventContext);
  const favoritesContext = React.useContext(FavoritesContext);
  const featureContext = React.useContext(FeatureContext);
  const focusgroupContext = React.useContext(FocusGroupContext);
  const focuszoneContext = React.useContext(FocusZoneContext);
  const navigationContext = React.useContext(NavigationContext);
  const sessionContext = React.useContext(SessionContext);

  const playing = React.useRef<boolean>(false);
  const videoControls = React.useRef<IVideoControls | undefined>(undefined);

  // We need to know if the video is currently playing before we pause otherwise
  // we will errors. See - https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  const [loaded, setLoaded] = React.useState(false);
  const [loadPlayer, setLoadPlayer] = React.useState(false);

  const [autoPlayActive, setAutoPlayActive] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [proposed, setProposed] = React.useState(false);
  const [selected, setSelected] = React.useState(selection ? selection.selected(id) : false);

  const layoutStyle = useLayoutStyle();
  const mobileLayout = layoutStyle === "mobile";

  const showCompactLayout = useSubscription(featureContext.featureEnabled("showCompactLayout")) || mobileLayout;

  // Create an interval for auto play of grouped photos.
  const { clearInterval, setInterval } = useInterval();
  const { clearTimeout, setTimeout } = useTimeout();

  // Determine the element type we will use based on whether this is a link (has href).
  const ElementType: keyof JSX.IntrinsicElements = href ? "a" : "button";
  const type = video ? "video" : "photo";

  // Keep track of the state of focus and the mouse hover on our component.
  const { hasFocus, onBlur, onFocus } = useFocusTracker({ onFocusChange: (hasFocus: boolean) => processChange(hasFocus, hasMouse) });
  const { hasMouse, onMouseEnter, onMouseLeave } = useMouseTracker({ onMouseChange: (hasMouse: boolean) => processChange(hasFocus, hasMouse) });
  const { onTouchStart } = useLongTouch(
    React.useCallback(() => {
      eventContext.dispatchEvent("telemetryAvailable", {
        ...telemetryProperties,
        action: "userAction",
        mode: "longTouch",
        name: "selectTile",
        type
      });

      selection?.select(id, true);
    }, [id, eventContext, selection, telemetryProperties, type])
  );

  // Subscribe to the selection and if the state of our photo changes update.
  useSubscription<Set<string> | undefined>(selection, (value: Set<string>, action: string) => {
    const inSelection = value.has(id);

    if (action === "proposeSelection") {
      if (proposed !== inSelection) {
        setProposed(inSelection);
        return true;
      }
    } else if (action === "select" && inSelection) {
      setSelected(true);
    } else if (action === "unselect" && inSelection) {
      setSelected(false);
    }

    return false;
  });

  // Compute the optional badge for this photo.
  const gifPhoto = photo.file?.mimeType === "image/gif";
  const sharedContent = photo.shared?.owner?.user && photo.shared.owner.user.id !== sessionContext.driveId;
  const badgeVisible = group.length > 1 || gifPhoto || !!photo.video;
  const hoverBadge = group.length > 1 ? group.length : photo.video ? formatDuration(photo.video.duration) : "";
  const hoverBadgeAriaLabel = photo.video ? format(Duration, { duration: dateContext.formatDurationLabel(photo.video.duration) }) : undefined;
  const thumbnailSupported = !photo.file?.mimeType.includes("mpeg");

  // You ALWAYS need a badge element and it needs to be a single element, the tile
  // includes the badge in a row with the selection checkbox and this span is
  // used to position with justify-between in the row.
  const badgeContents =
    badgeVisible || sharedContent ? (
      <span className="tile-badge body-s flex-row flex-align-center">
        {badgeVisible && (
          <span className="inline-flex-row">
            {group.length > 1 ? <GroupPhotos /> : gifPhoto ? "GIF" : <PlayPreview />}
            {hoverBadge && (
              <span aria-label={hoverBadgeAriaLabel} className="hover-badge-text">
                {hoverBadge}
              </span>
            )}
          </span>
        )}
        {sharedContent && (
          <span aria-label={SharedLabel} className={css("inline-flex-row", badgeVisible && "separator-line-left margin-left-4 padding-left-4")}>
            <Shared />
          </span>
        )}
      </span>
    ) : null;

  const checkboxContents =
    selection && (hasMouse || hasFocus || selected) ? (
      // If the tile has the ability to be selected and either the mouse, focus or
      // selection is active the checkbox will be shown.
      <div
        className="tile-selection flex-row pointer-events-auto cursor-pointer"
        onClick={(event) => {
          if (!event.defaultPrevented && !event.shiftKey) {
            eventContext.dispatchEvent("telemetryAvailable", {
              ...telemetryProperties,
              action: "userAction",
              mode: "padding",
              name: selection.selected(id) ? "unselectTile" : "selectTile",
              type
            });

            selection.toggle(id);
            event.preventDefault();
          }
        }}
      >
        <Checkbox
          ariaLabel={video ? VideoText : PhotoText}
          checked={selected}
          className="photo-checkbox pointer-events-none"
          excludeTabStop={true}
          onChange={(event) => {
            if (!event.defaultPrevented && !event.shiftKey) {
              eventContext.dispatchEvent("telemetryAvailable", {
                ...telemetryProperties,
                action: "userAction",
                mode: "checkbox",
                name: selection.selected(id) ? "unselectTile" : "selectTile",
                type
              });

              selection.toggle(id);
              announce(selection.selected(id) ? CheckboxStateChecked : CheckboxStateUnchecked, true);
              event.preventDefault();
            }
          }}
        />
      </div>
    ) : null;

  let itemContents: React.ReactElement;
  let tileOverlayContents: React.ReactElement | null = null;

  const showCommands = (commandActive && !!commandActive()) || ((hasMouse || hasFocus) && (!selection || selection.selectedCount === 0));

  const tileOverlayBottomSection = (
    <div className="tile-banner flex-row flex-align-start flex-justify-between overflow-hidden">
      {label && (
        <div className="tile-caption flex-column padding-bottom-12 padding-horizontal-12 overflow-hidden">
          <span className="title-xs font-weight-semibold text-ellipsis">{label}</span>
          {subtitle && <span className="tile-subtitle body-m text-ellipsis">{subtitle}</span>}
        </div>
      )}
      {showCommands && <div className="flex-row flex-grow overflow-hidden">{commands}</div>}
    </div>
  );

  if (video) {
    // Manage the auto play status, when you have focus or mouse hover we will
    // start/stop the video.
    if (videoControls.current && loaded) {
      if (hasFocus || hasMouse) {
        if (!playing.current) {
          videoControls.current.seek(videoStart);
          videoControls.current.play();
        }
      } else if (playing.current) {
        // Pause the player and move it back to the start.
        videoControls.current.seek(videoStart);
        videoControls.current.pause();
      }
    }

    itemContents = (
      <React.Fragment key={`${photo.id}-container`}>
        <Video
          autoPlay={false}
          controlsRef={videoControls}
          controls={false}
          debugInfo={debugInfo}
          dimensions={{ height: itemRect.height, width: itemRect.width }}
          disablePictureInPicture={true}
          draggable={false}
          getPhotoContent={getPhotoContent(downloadPhoto)}
          loadPlayer={loadPlayer}
          messageTimeoutMs={5000}
          muted={true}
          onEnded={() => (playing.current = false)}
          onLoadFailure={() => setLoaded(true)}
          onLoadedData={() => {
            eventContext.dispatchEvent("telemetryAvailable", { ...telemetryProperties, action: "userAction", name: "startVideoTile" });
            setLoaded(true);
          }}
          onPause={() => (playing.current = false)}
          onPlay={() => (playing.current = true)}
          photo={photo}
          showPoster={thumbnailSupported}
          videoDetails={video}
        />
        {showFavorite && favoritesContext.favorites.has(photo.id) && (
          <Button
            ariaHidden={true}
            className="favorite-button tile-button rounded-4"
            excludeFocusZone={true}
            excludeTabStop={true}
            iconProps={{
              render: (className: string) => React.createElement(StarHighlighted, { className })
            }}
            tabIndex={-1}
          />
        )}
      </React.Fragment>
    );

    tileOverlayContents = (
      <>
        {(hasMouse || hasFocus) && loadPlayer && !loaded && (
          <div className="tile-overlay-spinner absolute-fill flex-row flex-align-center flex-justify-center pointer-events-none">
            <Spinner size={SpinnerSize.large} />
          </div>
        )}
        <TileOverlay className={css("flex-justify-between", showCompactLayout ? "photo-tile-compact" : "photo-tile-expanded")}>
          <div className="tile-banner flex-row flex-align-start flex-justify-between">
            {badgeContents}
            {checkboxContents}
          </div>
          {tileOverlayBottomSection}
        </TileOverlay>
      </>
    );
  } else {
    // Compute the active photoIndex based on which of the first photos is visible.
    const activeIndex = photoIndex % Math.min(group.length, animationFrameCount);

    itemContents = (
      <>
        {group.map((photo, index) => {
          const firstTile = index === 0;

          return index < animationFrameCount ? (
            <Photo
              aria-hidden={true}
              blocking={firstTile && blocking}
              className={css("absolute", !firstTile && "screenshot-ignore", index === activeIndex ? "active" : "inactive", orientation)}
              cropRect={aspectRatio ? buildCropRect(photo) : undefined}
              debugInfo={debugInfo}
              dimensions={{ height: itemRect.height, width: itemRect.width }}
              downloadScale={(hasMouse || hasFocus) && photo.file?.mimeType === "image/gif" ? "full" : undefined}
              draggable={false}
              fixed={fixed}
              getPhotoContent={getPhotoContent(downloadPhoto)}
              key={`${id}-${index}`}
              photo={photo}
              transition={transition}
            />
          ) : null;
        })}
        {showFavorite && favoritesContext.favorites.has(photo.id) && (
          <Button
            ariaHidden={true}
            className="favorite-button tile-button rounded-4 pointer-events-none"
            excludeFocusZone={true}
            iconProps={{
              render: (className: string) => React.createElement(StarHighlighted, { className })
            }}
            tabIndex={-1}
          />
        )}
      </>
    );

    if (hasMouse || hasFocus || badgeContents || selected || showCommands || proposed || label) {
      tileOverlayContents = (
        <TileOverlay className={css("flex-justify-between", showCompactLayout ? "photo-tile-compact" : "photo-tile-expanded")}>
          <>
            <div className="tile-banner flex-row flex-align-start flex-justify-between">
              {badgeContents || <span />}
              {checkboxContents}
            </div>
            {tileOverlayBottomSection}
          </>
        </TileOverlay>
      );
    }
  }

  // Build the core tile with its contents and overlay.
  let tileContents = (
    <FocusZone handleTabKey={true}>
      <div
        className={css(
          "photo-tile",
          className,
          "tile-overlay-container inline-flex-row",
          selected && "selected",
          proposed && "proposed-selection",
          showCompactLayout ? "rounded-4" : "rounded-8"
        )}
        onBlur={onBlur}
        onClick={(event) => {
          if (!event.defaultPrevented) {
            // If we have an active selection we will select it, otherwise we will
            // activate it and open the 1-up viewer.
            if (selection) {
              if (event.shiftKey && selection.selectedCount) {
                eventContext.dispatchEvent("telemetryAvailable", {
                  ...telemetryProperties,
                  action: "userAction",
                  mode: "range",
                  name: "selectTile",
                  type
                });

                selection.selectRange(id);
                announce(RangeSelected, true);
              } else {
                eventContext.dispatchEvent("telemetryAvailable", {
                  ...telemetryProperties,
                  action: "userAction",
                  mode: "tile",
                  name: selection.selected(id) ? "unselectTile" : "selectTile",
                  type
                });

                selection.toggle(id);
                announce(selection.selected(id) ? CheckboxStateChecked : CheckboxStateUnchecked, true);
              }

              event.preventDefault();
            }
          }
        }}
        onFocus={(event) => {
          if (selection && getKeyState("Shift")) {
            selection.proposeSelection(id);
          }

          onFocus(event);
        }}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (!event.defaultPrevented && selection) {
            if (event.key === "Shift") {
              if (!event.repeat) {
                selection.proposeSelection(id);
              }
            } else if (event.key === " ") {
              if (event.shiftKey) {
                eventContext.dispatchEvent("telemetryAvailable", {
                  ...telemetryProperties,
                  action: "userAction",
                  mode: "range",
                  name: "selectTile",
                  type
                });

                selection.selectRange(id);
                announce(RangeSelected, true);
              } else {
                eventContext.dispatchEvent("telemetryAvailable", {
                  ...telemetryProperties,
                  action: "userAction",
                  mode: "keyboard",
                  name: selection.selected(id) ? "unselectTile" : "selectTile",
                  type
                });

                selection.toggle(id);
                announce(selection.selected(id) ? CheckboxStateChecked : CheckboxStateUnchecked, true);
              }

              event.preventDefault();
            }
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={(event) => {
          if (selection && event.shiftKey && document.hasFocus()) {
            selection.proposeSelection(id);
          }
        }}
        onTouchStart={onTouchStart}
        role={attributes.role ?? "listitem"}
        style={{ height: itemRect.height, left: itemRect.left, top: itemRect.top, width: itemRect.width }}
      >
        <FocusGroupContext.Provider value={{ onFocus: () => {} }}>
          <FocusZoneContext.Consumer>
            {(childFocuszoneContext) => {
              let elementLabel = "";

              if (label) {
                if (subtitle) {
                  elementLabel = `${label.toString()}, ${subtitle.toString()}`;
                } else {
                  elementLabel = label.toString();
                }
              } else if (group.length > 1) {
                elementLabel = format(GroupedPhotoTooltip, { photoCount: group.length });
              } else if (group.length === 1) {
                if (photo.tags?.length) {
                  elementLabel = formatAltTags(photo);
                } else if (photo.name) {
                  elementLabel = photo.name;
                }
              }

              return (
                <>
                  <div className="inline-flex-row flex-grow">
                    <ElementType
                      {...attributes}
                      aria-label={elementLabel}
                      className="tile inline-flex-row flex-grow flex-align-center flex-justify-center"
                      data-focuszone={`${focuszoneContext.focuszoneId} ${childFocuszoneContext.focuszoneId}`}
                      draggable={false}
                      href={href}
                      id={getSafeId(attributes.id || id)}
                      onClick={(event) => {
                        if (!event.defaultPrevented && href && !event.shiftKey && (!selection || selection.selectedCount === 0)) {
                          if (sessionContext.accountType === "business") {
                            openPhotoODB(eventContext, embedContext, photo);
                            event.preventDefault();
                          } else {
                            navigationContext.navigate(event, "activateTile", {
                              state: navigationState,
                              telemetryProperties: { ...telemetryProperties, type }
                            });
                          }
                        }
                      }}
                      onContextMenu={preventTouchMenu}
                      onFocus={(event) => {
                        focusgroupContext.onFocus(attributes.id || id);
                        attributes.onFocus && attributes.onFocus(event);
                      }}
                      tabIndex={attributes.tabIndex ?? getTabIndex({ id: attributes.id || id }, focusgroupContext)}
                    >
                      {itemContents}
                    </ElementType>
                  </div>
                  {tileOverlayContents}
                </>
              );
            }}
          </FocusZoneContext.Consumer>
        </FocusGroupContext.Provider>
      </div>
    </FocusZone>
  );

  if (padding && padding.bottom) {
    tileContents = (
      <>
        {tileContents}
        <div
          aria-hidden={true}
          className="photo-tile-nameplate absolute flex-row flex-align-center flex-justify-center overflow-hidden"
          style={{ height: padding.bottom, left: rect.left, width: rect.width, top: rect.top + rect.height - padding.bottom }}
        >
          <Tooltip overflowOnly={true}>
            <span className="photo-tile-name margin-horizontal-12 text-ellipsis">{photo.name}</span>
          </Tooltip>
        </div>
      </>
    );
  }

  return (
    <Throttle
      blocking={blocking}
      configuration={imageThrottling}
      origin={sessionContext.apiOrigin["vroomOrigin"]}
      placeholder={<Placeholder className="absolute" rect={rect} />}
      throttleCallback={(throttleDelay: IThrottleDelay, throttleStatus: ThrottleStatus) => {
        eventContext.dispatchEvent("telemetryAvailable", {
          action: "operationComplete",
          name: throttleStatus,
          duration: throttleDelay.delayMs
        });
      }}
    >
      {tileContents}
    </Throttle>
  );

  function buildCropRect(photo: IPhotoTilePhoto): IRectangle | undefined {
    const cropRegion = photo.image?.cropRegions?.find((cropRegion) => cropRegion.aspectRatio === aspectRatio);
    if (cropRegion) {
      return {
        left: cropRegion.boundingBoxX,
        top: cropRegion.boundingBoxY,
        width: cropRegion.boundingBoxWidth,
        height: cropRegion.boundingBoxHeight
      };
    }
    return undefined;
  }

  function processChange(hasFocus: boolean, hasMouse: boolean): void {
    if (video) {
      if (hasFocus || hasMouse) {
        setTimeout(() => {
          setLoadPlayer(true);
        }, 1000);
      } else {
        clearTimeout();
      }
    } else {
      if (!hasFocus && !hasMouse) {
        setPhotoIndex(0);
        clearInterval();

        setAutoPlayActive(false);
      } else if (!autoPlayActive) {
        if (group.length > 1) {
          setInterval(() => setPhotoIndex((index) => index + 1), 3000);
        }

        setAutoPlayActive(true);
      }
    }
  }
}
