import { IReadonlyObservableValue, ObservableValue } from "azure-devops-ui/Core/Observable";
import { ISettingsContext } from "../../common/contexts/settings";
import { ITheme, IThemeContext } from "../../common/contexts/theme";
import { UXSetting } from "../types/settings";

const { Light, Dark } = window.Resources.Theme;

export class PhotoThemeContext implements IThemeContext {
  private _currentTheme: ObservableValue<ITheme>;
  private _themes: { [themeId: string]: ITheme } = {
    light: { backgroundColor: "#fff", dark: false, foregroundColor: "#c8c8c8", themeId: "light", themeName: Light, userVisible: true },
    dark: { backgroundColor: "#201918", dark: true, foregroundColor: "#605d5b", themeId: "dark", themeName: Dark, userVisible: true }
  };

  constructor(settingsContext: ISettingsContext) {
    const uxSetting = settingsContext.getSetting("uxSetting") as UXSetting | undefined;

    // Create the current theme, it starts with light as the default, but if the setting
    // has a different value it will automatically update before completing.
    this._currentTheme = new ObservableValue(this._themes["light"]);

    // If the user has specified a non-default theme switch to it.
    if (uxSetting?.themeId) {
      this.setCurrentTheme(uxSetting.themeId);
    }
  }

  public get currentTheme(): IReadonlyObservableValue<ITheme> {
    return this._currentTheme;
  }

  public setCurrentTheme = (themeId: string) => {
    const updatedTheme = this._themes[themeId];

    if (updatedTheme) {
      const themeElement = document.querySelector("[data-theme]");
      if (themeElement) {
        themeElement.setAttribute("data-theme", updatedTheme.themeId);
      }

      // Update the currently selected theme
      this._currentTheme.value = this._themes[themeId];
    }
  };

  public get themes(): { [themeId: string]: ITheme } {
    return this._themes;
  }
}
